import React, { ForwardedRef, forwardRef } from 'react';

import {
  interestPeriods,
  offeringScopes,
  offshoreEligibilities,
  productCodes,
} from '../../../product-schema/pdw-select-options';
import { isWeekend } from '../../../utilities/utilities';
import {
  PFSelect,
  PFNumberInput,
  PFTextInput,
  PFDatePicker,
} from '../reusable-form-component/BaseFormComponents';

import { clientSpecificFields } from '../../../product-schema/pdw-field-subsets';
import PFCheckbox from '../reusable-form-component/BaseFormComponents/PFCheckbox';

const ClientSpecifiesFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFTextInput
            label={'Client Product ID'}
            fieldPath={clientSpecificFields.clientProductId}
          />
          <PFSelect
            label="Product Code"
            data={productCodes}
            fieldPath={clientSpecificFields.productCode}
          />

          <PFSelect
            label="Offering Scope"
            data={offeringScopes}
            fieldPath={clientSpecificFields.productCode}
          />
          <PFSelect
            label="Offshore Eligibility"
            data={offshoreEligibilities}
            fieldPath={clientSpecificFields.offshoreEligibility}
          />
          <PFCheckbox
            label={'Locked'}
            fieldPath={clientSpecificFields.locked}
          />

          <PFTextInput
            label={'PrOS Request ID'}
            fieldPath={clientSpecificFields.prosRequestId}
          />

          <PFTextInput
            label={'Deal Lead'}
            fieldPath={clientSpecificFields.dealLead}
          />

          <PFTextInput
            label={'Product Symbol'}
            fieldPath={clientSpecificFields.productSymbol}
          />
        </div>
        <div className={'form-subsection'}>
          <PFTextInput
            label={'Legal Team'}
            fieldPath={clientSpecificFields.legalTeam}
          />

          <PFTextInput
            label={'Issuer Credit Code'}
            fieldPath={clientSpecificFields.issuerCreditCode}
          />

          <PFTextInput
            label={'Security ID'}
            fieldPath={clientSpecificFields.securityId}
          />

          <PFTextInput
            label={'Deal Backup'}
            fieldPath={clientSpecificFields.dealBackup}
          />

          <PFTextInput
            label={'Internal Trading Desk'}
            fieldPath={clientSpecificFields.internalTradingDesk}
          />

          <PFTextInput
            label={'RAM Code'}
            fieldPath={clientSpecificFields.ramCode}
          />

          <PFTextInput
            label={'Trader'}
            fieldPath={clientSpecificFields.trader}
          />

          {/*todo: is this pv fund less fees value mapped to a decimal on submit?? aka do we need parsing*/}
          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="PV Fund Less Fees"
            fieldPath={clientSpecificFields.pvFundingLessFeesPercentage}
          ></PFNumberInput>
        </div>
        <div className={'form-subsection'}>
          {/*todo: is this value mapped to a decimal on submit?? aka do we need parsing*/}
          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="Legal Print Estimate"
            fieldPath={clientSpecificFields.legalPrintEstimate}
          ></PFNumberInput>

          <PFNumberInput
            label="Break Point Shares"
            fieldPath={clientSpecificFields.breakpointShares}
          ></PFNumberInput>

          <PFNumberInput
            label="Number Breakpoint Investors"
            fieldPath={clientSpecificFields.numBreakpointInvestors}
          ></PFNumberInput>

          <PFCheckbox
            label={'Broad Offering'}
            fieldPath={clientSpecificFields.broadOffering}
          />

          <PFCheckbox
            label={'Core Approval'}
            fieldPath={clientSpecificFields.coreApproval}
          />

          <PFSelect
            label="Interest Periods"
            data={interestPeriods}
            fieldPath={clientSpecificFields.interestPeriod}
          />

          <PFDatePicker
            label="First Payment Date"
            excludeDate={isWeekend}
            fieldPath={clientSpecificFields.firstPaymentDate}
          />

          {/*  todo:split id field: do we need it???: if so: set up input array for split ids: splitIds (arary of strings)*/}
        </div>
      </div>
    );
  },
);
ClientSpecifiesFormComponent.displayName = 'ClientSpecifiesFormComponent';
export default ClientSpecifiesFormComponent;
