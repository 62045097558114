import {
  Center,
  Loader,
  MantineColorScheme,
  Stack,
  StackProps,
  Table,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  getEventConfirmationsAuditLog,
  EventTypes,
  getEventConfirmationsAuditLogKey,
} from '../../../API/event-service';
import { convertToTimezone } from '../../../utilities/utilities';
import {
  AuditLogRow,
  EventConfirmationUtils,
  isLumaUser,
} from './EventConfirmationUtils';
import BasicError from '../../../components/error-boundries/BasicError';
import { LUMA_LOGOS, LumaLogo } from '../../../components/core/LumaLogo';

interface IMSWMAuditLogTableProps extends StackProps {
  eventDate?: string;
  eventType?: EventTypes;
  productIdentifier?: string;
}

const getUser = (row: AuditLogRow, colorScheme: MantineColorScheme) => {
  if (isLumaUser(row.user)) {
    return (
      <>
        <LumaLogo
          logoType={LUMA_LOGOS.SVG_LUMA_DARK}
          style={{
            display: colorScheme === 'dark' ? 'none' : 'block',
          }}
        />
        <LumaLogo
          logoType={LUMA_LOGOS.SVG_LUMA_NEUTRAL}
          style={{
            display: colorScheme === 'light' ? 'none' : 'block',
          }}
        />
        {row.adminOnBehalfOfIssuer &&
        !['Luma', 'Confirmed'].some((keyword) =>
          row.description.includes(keyword),
        ) ? (
          <div>On Behalf Of Issuer</div>
        ) : null}
      </>
    );
  } else {
    return row.user;
  }
};

export const MSWMAuditLogTable = (props: IMSWMAuditLogTableProps) => {
  const { eventDate, eventType, productIdentifier, ...restProps } = props;
  const { colorScheme } = useMantineColorScheme();

  const { data, isFetching, isSuccess, isError } = useQuery({
    enabled: !!eventDate && !!eventType && !!productIdentifier,
    queryFn: () =>
      getEventConfirmationsAuditLog({
        eventDate: eventDate ?? '',
        eventType: eventType ?? '',
        productIdentifier: productIdentifier ?? '',
      }),
    queryKey: getEventConfirmationsAuditLogKey({
      eventDate: eventDate ?? '',
      eventType: eventType ?? '',
      productIdentifier: productIdentifier ?? '',
    }),
  });

  const rawData = data?.data;
  const isCoupon = eventType === EventTypes.COUPON_PAYMENT;

  const processedData = useMemo(() => {
    if (!rawData) return null;

    return EventConfirmationUtils.formatAuditLogComponents(rawData, isCoupon);
  }, [rawData, isCoupon]);

  const _data = processedData?.map((item) => [
    item.description,
    convertToTimezone(item.date),
    getUser(item, colorScheme),
    item.audit ? item.audit : 'N/A',
  ]);

  return (
    <Stack
      flex={1}
      gap="xs"
      justify="flex-start"
      {...restProps}
      styles={{
        root: {
          overflow: 'auto',
        },
      }}
      // style={{ overFlow: 'auto' }}
    >
      <Title order={3} fz="h5" fw="bold" ta="center">
        Audit Log
      </Title>
      {isFetching && (
        <Center flex={1}>
          <Loader color="yellow.8" size="sm" />
        </Center>
      )}
      {!isFetching && isSuccess && (
        <Table
          data={{
            head: ['Action Detail', 'Action Date', 'User', 'Audit Note'],
            body: _data,
          }}
          fz={15}
          withColumnBorders
          striped
        />
      )}
      {!isFetching && isError && (
        <Center flex={1}>
          <BasicError />
        </Center>
      )}
    </Stack>
  );
};
