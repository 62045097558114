import { useQuery } from '@tanstack/react-query';
import { getPublishers } from '../../API/user.service';
import { Button, LoadingOverlay, MultiSelect, Text } from '@mantine/core';
import { useMemo, useState } from 'react';
import { ANLearningCenterCourseList } from './ANLearningCenterCourseList';
import { isEqual } from 'lodash';

export const LearningCenterCourseConfig = () => {
  const [publishersSelected, setPublishersSelected] = useState<
    string[] | undefined
  >();
  const [publishersForCourseRetrieval, setPublishersForCourseRetrieval] =
    useState<string[] | undefined>();

  const {
    data: publishersResponse,
    isLoading: publishersLoading,
    isError: errorLoadingPublishers,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['getPublishers'],
    queryFn: getPublishers,
    refetchOnWindowFocus: false,
  });

  const publishers = useMemo(
    () => publishersResponse?.data,
    [publishersResponse],
  );

  const handlePublisherSelected = (publishersSelected: string[] | null) => {
    if (!publishersSelected || publishersSelected?.length === 0) {
      setPublishersSelected([]);
      return;
    }
    setPublishersSelected(publishersSelected);
  };

  const handleUsePublishersForCourses = () => {
    setPublishersForCourseRetrieval([...(publishersSelected ?? [])]);
  };

  const publishersChanged = useMemo(
    () =>
      publishersSelected?.length !== 0 &&
      !isEqual(publishersSelected, publishersForCourseRetrieval),
    [publishersSelected, publishersForCourseRetrieval],
  );

  return (
    <div style={{ padding: '1rem 2rem' }}>
      {errorLoadingPublishers ? (
        <div>
          <Text c={'red'}>Something went wrong</Text>
          <Text>Error Loading Publishers</Text>
          <Button
            variant="primary"
            disabled={isRefetching}
            onClick={() => refetch()}
          >
            Retry
          </Button>
        </div>
      ) : (
        <>
          <div
            style={{
              position: 'relative',
              width: 'fit-content',
              display: 'flex',
              gap: '2rem',
              alignItems: 'center',
            }}
          >
            <LoadingOverlay
              visible={publishersLoading}
              overlayProps={{ blur: 1 }}
            />
            <MultiSelect
              multiple={true}
              style={{ width: '20rem', marginBottom: '2rem' }}
              placeholder={'Search Publishers'}
              label="Select Publisher"
              searchable={true}
              clearable={true}
              data={publishers?.filter((x) => x) || []}
              onChange={handlePublisherSelected}
            />
            <Button
              variant="primary"
              disabled={!publishersChanged}
              onClick={handleUsePublishersForCourses}
            >
              Pull Courses For Publishers
            </Button>
          </div>
          {publishersForCourseRetrieval && (
            <>
              <h4>{publishersForCourseRetrieval.join(', ')}</h4>
              <ANLearningCenterCourseList
                publishersSelected={publishersForCourseRetrieval}
                publishers={publishers}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
