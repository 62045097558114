export const dateCompartor = (
  date1: string | number,
  date2: string | number,
): number => {
  const date1Number = date1 ? new Date(date1).getTime() : null;
  const date2Number = date2 ? new Date(date2).getTime() : null;

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
};
