import React, { ForwardedRef, forwardRef } from 'react';
import {
  DayCountFractions,
  originatorDeskIDs,
  swapMaturities,
} from '../../../product-schema/pdw-select-options';
import {
  PFNumberInput,
  PFSelect,
  PFTextInput,
} from '../reusable-form-component/BaseFormComponents';
import {
  clientSpecificFields,
  nonCoreDetailsFields,
} from '../../../product-schema/pdw-field-subsets';
import PFCheckbox from '../reusable-form-component/BaseFormComponents/PFCheckbox';

const NonCoreDetailsFormComponent = forwardRef(
  (propps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFNumberInput
            decimalScale={6}
            label="Notional Cap"
            fieldPath={nonCoreDetailsFields.productNotionalCap}
          ></PFNumberInput>

          {/*question: this field was removed, double check that it doesn't need to be there*/}
          {/*'Product Note List'*/}
          <PFNumberInput
            decimalScale={6}
            label="Pnl"
            fieldPath={nonCoreDetailsFields.totalPnl}
          ></PFNumberInput>

          <PFSelect
            label="Swap Maturity"
            data={swapMaturities}
            fieldPath={nonCoreDetailsFields.swapMaturity}
          />

          <PFCheckbox
            label={'Long Term Capital Gain'}
            fieldPath={nonCoreDetailsFields.longTermCapitalGain}
          />

          <PFSelect
            label={'Originator Desk ID'}
            data={originatorDeskIDs}
            fieldPath={clientSpecificFields.originatorDeskId}
          ></PFSelect>
        </div>
        <div className={'form-subsection'}>
          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="Overnight Index Swap Discount Factor"
            fieldPath={nonCoreDetailsFields.oisDfPercentage}
          ></PFNumberInput>

          <PFTextInput
            label={'Upfront Deferred'}
            fieldPath={nonCoreDetailsFields.upfrontDeferred}
          ></PFTextInput>

          <PFSelect
            label="Day Count Fraction"
            data={[...Object.values(DayCountFractions)]}
            fieldPath={nonCoreDetailsFields.dayCountFraction}
          ></PFSelect>

          <PFTextInput
            label={'Originator'}
            fieldPath={nonCoreDetailsFields.originator}
          ></PFTextInput>
        </div>
      </div>
    );
  },
);
NonCoreDetailsFormComponent.displayName = 'NonCoreDetailsFormComponent';
export default NonCoreDetailsFormComponent;
