import React, { memo, useContext, useEffect } from 'react';
import {
  CallTypes,
  CouponTypes,
  GrowthTypes,
  ObservationFrequencies,
  PaymentBarrierObservationFrequencies,
  ProtectionTypes,
  TemplateOptions,
  TenorUnits,
  WrapperTypes,
} from '../../../../product-schema/pdw-select-options';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { PFSelect } from '../../reusable-form-component/BaseFormComponents';
import {
  DefaultSectionOrder,
  templateSectionOrders,
} from './template-to-section-order-mapping';
import { FormSectionProperties } from '../../form-section.types';
import { Button, Tooltip } from '@mantine/core';

interface TemplateSelectionProps {
  formSectionHandlers: any;
}

const TemplateSelection = ({ formSectionHandlers }: TemplateSelectionProps) => {
  const form = useProductFormContext();
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);

  const setLowerCallStrike = (lowerCallStrikeValue: number) => {
    if (
      !form.values.productGrowth.lowerCallStrikeList ||
      form.values.productGrowth.lowerCallStrikeList?.length === 0
    ) {
      form.insertListItem('productGrowth.lowerCallStrikeList', {
        lowerCallStrikeCurrent: undefined,
        lowerCallStrikeFinal: lowerCallStrikeValue,
        lowerCallStrikeLow: undefined,
        lowerCallStrikeHigh: undefined,
        lowerCallStrikeStartDate: undefined,
        lowerCallStrikeEndDate: undefined,
      });
    } else if (form.values.productGrowth.lowerCallStrikeList.length === 1) {
      form.setFieldValue(
        `productGrowth.lowerCallStrikeList.${0}.lowerCallStrikeFinal`,
        lowerCallStrikeValue,
      );
    }
  };

  // form.setFieldValue('productGrowth.lowerCallStrikeList', produce(form.values?.productGeneral?.underlierList, ((lowerCallStrikeList: any) => {
  //   lowerCallStrikeList.map((x: any) => { return {...x, lowerCallStrikeFinal: 100}})
  // })))

  const updateDefaultValuesForTemplate = (template: TemplateOptions) => {
    switch (template) {
      case TemplateOptions.UNCAPPED_POINT_TO_POINT:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        setLowerCallStrike(1);
        form.setFieldValue(
          'productGrowth.growthType',
          GrowthTypes.POINT_TO_POINT,
        );
        form.setFieldValue('productGrowth.bearish', false);
        break;
      case TemplateOptions.ALL_UP:
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productYield.paymentType', CouponTypes.ALL_UP);
        form.setFieldValue(
          'productYield.paymentFrequency',
          ObservationFrequencies.ANNUALLY,
        );
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        form.setFieldValue('productProtection.capitalProtectionLevelFinal', 1);
        break;
      case TemplateOptions.AUTO_CAP:
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productYield.paymentType', CouponTypes.AUTO_CAP);
        form.setFieldValue(
          'productYield.paymentFrequency',
          ObservationFrequencies.ANNUALLY,
        );
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        form.setFieldValue('productProtection.capitalProtectionLevelFinal', 1);
        break;
      case TemplateOptions.BEARISH:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productGrowth.bearish', true);
        form.setFieldValue('productProtection.putLeverageFinal', 1);
        break;
      case TemplateOptions.CAPPED_DUAL_DIRECTIONAL:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        setLowerCallStrike(1);
        form.setFieldValue(
          'productGrowth.growthType',
          GrowthTypes.CAPPED_DUAL_DIRECTIONAL,
        );
        form.setFieldValue('productGrowth.bearish', false);
        break;
      case TemplateOptions.CAPPED_POINT_TO_POINT:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        setLowerCallStrike(1);
        form.setFieldValue(
          'productGrowth.growthType',
          GrowthTypes.CAPPED_POINT_TO_POINT,
        );
        form.setFieldValue('productGrowth.bearish', false);
        break;
      case TemplateOptions.CLIQUET:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productYield.paymentType', CouponTypes.CLIQUET);
        form.setFieldValue('productGrowth.growthType', GrowthTypes.CLIQUET);
        form.setFieldValue('productGrowth.bearish', false);
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        form.setFieldValue('productProtection.capitalProtectionLevelFinal', 1);
        setLowerCallStrike(1);
        break;
      case TemplateOptions.CONTINGENT:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productYield.paymentType', CouponTypes.CONTINGENT);
        break;
      case TemplateOptions.DIGITAL:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productGrowth.digitalReturnBarrierFinal', 1);
        form.setFieldValue('productGrowth.bearish', false);
        break;
      case TemplateOptions.DIGITAL_DUAL_DIRECTIONAL: //fallthrough
      case TemplateOptions.DIGITAL_PLUS:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productGrowth.bearish', false);
        break;
      case TemplateOptions.P2P_WITH_CONTINGENT_COUPON:
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productYield.paymentType', CouponTypes.CONTINGENT);
        form.setFieldValue(
          'productYield.paymentFrequency',
          ObservationFrequencies.ANNUALLY,
        );
        setLowerCallStrike(1);
        form.setFieldValue('productGrowth.bearish', false);
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        break;
      case TemplateOptions.P2P_WITH_FIXED_COUPON:
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productYield.paymentType', CouponTypes.FIXED);
        form.setFieldValue(
          'productYield.paymentFrequency',
          ObservationFrequencies.ANNUALLY,
        );
        setLowerCallStrike(1);
        form.setFieldValue('productGrowth.bearish', false);
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        break;
      case TemplateOptions.RANGE_ACCRUAL:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue(
          'productYield.paymentType',
          CouponTypes.RANGE_ACCRUAL,
        );
        form.setFieldValue(
          'productYield.paymentBarrierObservationFrequency',
          PaymentBarrierObservationFrequencies.DAILY,
        );
        break;
      case TemplateOptions.RATE_BUILDER:
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue(
          'productYield.paymentType',
          CouponTypes.RATE_BUILDER,
        );
        form.setFieldValue(
          'productYield.rateBuilderGrouping',
          '0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10',
        );
        form.setFieldValue(
          'productYield.paymentFrequency',
          ObservationFrequencies.ANNUALLY,
        );
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        form.setFieldValue('productProtection.capitalProtectionLevelFinal', 1);
        break;
      case TemplateOptions.SNOWBALL:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productGrowth.bearish', false);
        form.setFieldValue('productGrowth.growthType', GrowthTypes.SNOWBALL);
        form.setFieldValue('productCall.callType', CallTypes.AUTOCALL_STEP);
        break;
      case TemplateOptions.TIME_SERIES:
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productYield.paymentType', CouponTypes.TIME_SERIES);
        form.setFieldValue(
          'productYield.paymentFrequency',
          ObservationFrequencies.ANNUALLY,
        );
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        form.setFieldValue('productProtection.capitalProtectionLevelFinal', 1);
        break;
      case TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        setLowerCallStrike(1);
        form.setFieldValue(
          'productGrowth.growthType',
          GrowthTypes.DUAL_DIRECTIONAL,
        );
        form.setFieldValue('productGrowth.bearish', false);
        break;
      case TemplateOptions.FIXED_TO_FLOATING:
        form.setFieldValue('productGeneral.wrapperType', WrapperTypes.CD);
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue(
          'productYield.paymentType',
          CouponTypes.FIXED_TO_FLOATING,
        );
        form.setFieldValue(
          'productCall.callObservationFrequency',
          ObservationFrequencies.ANNUALLY,
        );
        form.setFieldValue(
          'productProtection.downsideType',
          ProtectionTypes.FULL,
        );
        form.setFieldValue('productProtection.protectionLevel', 1);
        break;
      case TemplateOptions.FIXED:
        form.setFieldValue('productGeneral.tenorUnit', TenorUnits.Years);
        form.setFieldValue('productYield.paymentType', CouponTypes.FIXED);
        break;
    }
  };
  const updateHiddenFieldsForTemplate = (template: TemplateOptions) => {
    const alwaysHiddenWhenTemplate = [
      'productGeneral.structureLongNameExternal',
      'productGeneral.basketReturnAdjustment',
      'productGeneral.performanceStrike',
      'productGeneral.productLaunchTimestamp',
      'productGeneral.issuePriceFinal',
      'productGeneral.fairPriceFinal',
      'productGeneral.basketReturnAdjustment',
      'productProtection.putStrikeFinal',
      'productProtection.putType',
      'productGeneral.reofferClientList',
      'productGeneral.salesCreditFinal',
      'productGeneral.structureFee',
      'productGeneral.totalPnl',
      'clientSpecific.offeringNotification',
      'productGeneral.fundingSpread',
      'productGeneral.oisDfPercentage',
      'productGeneral.swapMaturity',
      'clientSpecific.proceedToOfferingStatus',
      'productGeneral.upfrontDeferred',
      'productGeneral.dayCountFraction',
      'productGeneral.originator',
    ];
    switch (template) {
      case TemplateOptions.UNCAPPED_POINT_TO_POINT:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'dateShift', // todo: make dateShift check hidden
          'productGeneral.reofferClientList',
          'productGeneral.structureLongNameExternal',
          'productGeneral.basketReturnAdjustment',
          'productGeneral.performanceStrike',
          'productGeneral.productLaunchTimestamp',
          'productGeneral.issuePrice',
          'productGeneral.fairPriceFinal',
          'productGeneral.basketReturnAdjustment',
          'productGrowth.digitalReturn',
          'productGrowth.underlierReturnCapLevel',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // question: is this anywhere on the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.absoluteReturnBarrierLevel',
          'productGrowth.absoluteReturnParticipationRate',
          'productGrowth.maximumReturn',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: do we have this in the form??
          'productGrowth.digitalReturnBarrierObservationFrequency',
          'productYield.paymentMemory', // question: is this anywhere in the form
          'productGeneral.salesCredit',
          'productGeneral.structureFee',
          'productGeneral.totalPnl',
          'clientSpecific.offeringNotification',
          'productGeneral.fundingSpread',
          'productGeneral.oisDfPercentage',
          'productGeneral.swapMaturity',
          'clientSpecific.proceedToOfferingStatus',
          'productGeneral.upfrontDeferred',
          'productGeneral.dayCountFraction',
          'productGeneral.originator',
        ]);
        break;
      case TemplateOptions.ALL_UP:
        hiddenFieldsContext.setHiddenFields(
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.variablePayInitialObservationDate',
          'productYield.stockReturnFloorFinal',
          'productYield.stockReturnCapFinal',
          'productYield.variablePayFinalObservationDate',
          'productYield.floatSpread',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productCall.stepIncrement', // question: is this anywhere in the form???
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere in the form???
          'productCall.callScheduleUnderlierList', // question: is this anywhere in the form???
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere in the form???
          'productCall.numberNoCallPeriods',
          'productCall.stepType',
          'productProtection.principalBarrierLevelFinal',
          'productProtection.principalBufferLevelFinal',
          'productProtection.putObservationFrequency',
          'productProtection.putObservationDateList', // question: is this anywhere in the form?
          'productProtection.putLeverageFinal',
          'productYield.paymentMemory', // question: is this anywhere in the form
          'productGrowth.minimumReturnFinal',
        );
        break;
      case TemplateOptions.AUTO_CAP:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.paymentBarrierFinal',
          'productYield.paymentRatePerAnnumFinal',
          'productYield.paymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.variablePayFinalObservationDate',
          'productYield.floatSpread',
          // 'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productProtection.principalBarrierLevelFinal',
          'productProtection.principalBufferLevelFinal',
          'productProtection.putObservationFrequency',
          'productProtection.putObservationDateList', // question: is this anywhere
          'productProtection.putLeverageFinal',
          'productYield.paymentMemory', // question: is this anywhere
          'productGrowth.minimumReturnFinal',
        ]);
        break;
      case TemplateOptions.BEARISH:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere
          'productGrowth.upsideAboveDigitalReturn', // question: is this anywhere on the form?
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productYield.paymentMemory', // question: is this anywhere on the form?
        ]);
        break;
      case TemplateOptions.CAPPED_DUAL_DIRECTIONAL:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          // 'notInPdw.settlementOffsetDays',
          'productGeneral.businessDayConvention',
          'productGrowth.digitalReturn',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // question: is this anywhere on the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: do we have this in the form??
          'productGrowth.digitalReturnBarrierObservationFrequency',
          'productProtection.capitalProtectionLevelFinal',
          'productYield.paymentMemory', // question: is this anywhere in the form
        ]);
        break;
      case TemplateOptions.CAPPED_POINT_TO_POINT:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          // 'notInPdw.settlementOffsetDays',
          'productGeneral.businessDayConvention',
          'productGrowth.digitalReturn',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: is this anywhere in the form?
          'productGrowth.digitalReturnBarrierObservationFrequency',
          'productYield.paymentMemory', // question: is this anywhere in the form? todo: it's in the ancillary section
        ]);
        break;
      case TemplateOptions.CLIQUET:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.paymentBarrierFinal',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.paymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.paymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          // 'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal', // question: is this anywhere in the form????
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.paymentFrequency',
          'productYield.paymentEvaluationFrequency',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productGrowth.digitalReturnFinal',
          'productGrowth.upsideParticipationRateFinal',
          'productGrowth.underlierReturnCapLevelFinal',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productGrowth.maximumReturnFinal',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: is this anywhere in the form?
          'productGrowth.digitalReturnBarrierObservationFrequency',
          'productProtection.principalBarrierLevelFinal',
          'productProtection.principalBufferLevelFinal',
          'productProtection.putObservationFrequency',
          'productProtection.putObservationDateList', // question: is this anywhere
          'productProtection.putLeverageFinal',
          'productYield.paymentMemory', // question: is this anywhere in the form?
        ]);
        break;
      case TemplateOptions.CONTINGENT:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.stockReturnFloorFinal',
          'productYield.stockReturnCapFinal',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          // 'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere
          'productProtection.capitalProtectionLevelFinal',
          'productGrowth.minimumReturnFinal',
        ]);
        break;
      case TemplateOptions.DIGITAL:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          // 'notInPdw.settlementOffsetDays',
          'productGeneral.businessDayConvention',
          'productGrowth.upsideParticipationRateFinal',
          'productGrowth.lowerCallStrike',
          'productGrowth.underlierReturnCapLevelFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productGrowth.maximumReturnFinal',
          'productProtection.putStrikeFinal',
          'productProtection.capitalProtectionLevelFinal',
          'productYield.paymentMemory', // question: is this anywhere in the form?
        ]);
        break;
      case TemplateOptions.DIGITAL_DUAL_DIRECTIONAL:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          // 'notInPdw.settlementOffsetDays',
          'productGeneral.businessDayConvention',
          'productGrowth.upsideParticipationRateFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productProtection.putStrikeFinal',
          'productProtection.capitalProtectionLevelFinal',
          'productYield.paymentMemory',
        ]);
        break;
      case TemplateOptions.DIGITAL_PLUS:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          // 'notInPdw.settlementOffsetDays',
          'productGeneral.businessDayConvention',
          'productGrowth.upsideParticipationRateFinal',
          'productGrowth.upsideAboveDigitalReturn',
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productProtection.putStrikeFinal',
          'productProtection.capitalProtectionLevelFinal',
          'productYield.paymentMemory',
        ]);
        break;
      case TemplateOptions.P2P_WITH_CONTINGENT_COUPON:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          // 'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere
          'productGrowth.digitalReturnFinal',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: is this anywhere in the form?
          'productGrowth.digitalReturnBarrierObservationFrequency',
        ]);
        break;
      case TemplateOptions.P2P_WITH_FIXED_COUPON:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.paymentBarrierFinal',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          // 'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere
          'productGrowth.digitalReturnFinal',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: is this anywhere in the form?
          'productGrowth.digitalReturnBarrierObservationFrequency',
          'productYield.paymentMemory', // question: is this anywhere in the form?
        ]);
        break;
      case TemplateOptions.RANGE_ACCRUAL:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.stockReturnFloorFinal',
          'productYield.stockReturnCapFinal',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          // 'notInPdw.couponResetDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere
          'productProtection.capitalProtectionLevelFinal',
          'productGrowth.minimumReturnFinal',
        ]);
        break;
      case TemplateOptions.RATE_BUILDER:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.paymentBarrierFinal',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.paymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.paymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.stockReturnFloorFinal',
          'productYield.stockReturnCapFinal',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productProtection.principalBarrierLevelFinal',
          'productProtection.principalBufferLevelFinal',
          'productProtection.putObservationFrequency',
          'productProtection.putObservationDateList', // question: is this anywhere
          'productProtection.putLeverageFinal',
          'productYield.paymentMemory',
          'productGrowth.minimumReturnFinal',
        ]);
        break;
      case TemplateOptions.SNOWBALL:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere
          'productGrowth.digitalReturnFinal',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.absoluteReturnBarrierLevelFinal',
          'productGrowth.absoluteReturnParticipationRateFinal',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: is this anywhere in the form?
          'productGrowth.digitalReturnBarrierObservationFrequency',
          'productYield.paymentMemory', // question: is this anywhere in the form?
        ]);
        break;
      case TemplateOptions.TIME_SERIES:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.paymentBarrierFinal',
          'productYield.paymentRatePerAnnumFinal',
          'productYield.paymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.stockReturnFloorFinal',
          'productYield.stockReturnCapFinal',
          'productYield.variablePayFinalObservationDate',
          'productYield.floatSpread',
          'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productProtection.principalBarrierLevelFinal',
          'productProtection.principalBufferLevelFinal',
          'productProtection.putObservationFrequency',
          'productProtection.putObservationDateList', // question: is this anywhere
          'productProtection.putLeverageFinal',
          'productYield.paymentMemory',
          'productGrowth.minimumReturnFinal',
        ]);
        break;
      case TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'notInPdw.settlementOffsetDays',
          'productGeneral.businessDayConvention',
          'productGrowth.digitalReturnFinal',
          'productGrowth.underlierReturnCapLevelFinal',
          'productGrowth.digitalReturnBarrierFinal',
          'productGrowth.upsideAboveDigitalReturn', // is this anywhere in the form?
          'productGrowth.upsideParticipationAboveDigitalReturnFinal',
          'productGrowth.maximumReturnFinal',
          'productProtection.putStrikeFinal',
          'productGrowth.digitalReturnBarrierObservationDateList', // question: is this anywhere in the form?
          'productGrowth.digitalReturnBarrierObservationFrequency',
          'productProtection.capitalProtectionLevelFinal',
          'productYield.paymentMemory', // question: is this anywhere in the form?
        ]);
        break;
      case TemplateOptions.FIXED_TO_FLOATING:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productProtection.capitalProtectionLevelFinal',
          'productGrowth.minimumReturnFinal',
        ]);
        break;
      case TemplateOptions.FIXED:
        hiddenFieldsContext.setHiddenFields([
          ...alwaysHiddenWhenTemplate,
          'productYield.interest',
          'productYield.optionPremium',
          'productYield.paymentBarrierFinal',
          'productYield.minPaymentRatePerAnnumFinal',
          'productYield.minPaymentRatePerPeriodFinal',
          'productYield.variablePayInitialObservationDate',
          'productYield.stockReturnFloorFinal',
          'productYield.stockReturnCapFinal',
          'productYield.variablePayFinalObservationDate',
          'productYield.minCouponPayPeriod',
          'productYield.floatSpread',
          'notInPdw.couponResetDate',
          'productYield.paymentTypeChangeDate',
          'productYield.rateBuilderValuesFinal',
          'productYield.rateBuilderGrouping',
          'productYield.rateBuilderReturns',
          'productYield.leverageFactors',
          'productYield.upperBarrierLevelFinal',
          'productYield.paymentBarrierObservationFrequency',
          'productCall.expectedMaturity',
          'productCall.observationDateList', // question: is this anywhere
          'productCall.callScheduleUnderlierList', // question: is this anywhere
          'productCall.callBarrierLevelUnderlierList', // question: is this anywhere
          'productProtection.capitalProtectionLevelFinal',
          'productYield.paymentMemory', // question: is this anywhere in the form?, // question: is this anywhere
          'productGrowth.minimumReturnFinal',
        ]);
        break;
      default:
        hiddenFieldsContext.setHiddenFields([]);
        break;
    }
  };

  const updateOrderOfSectionsForTemplate = (template: TemplateOptions) => {
    const sectionOrder = template
      ? templateSectionOrders[template]
      : DefaultSectionOrder;
    formSectionHandlers.setState((prevState: any) => {
      const newOrderedSections = [];
      for (let i = 0; i < prevState.length; i++) {
        const sectionIndex = prevState.findIndex(
          (section: FormSectionProperties) => section.id === sectionOrder[i],
        );
        if (sectionIndex < 0) {
          throw new Error(
            `Section ${sectionOrder[i]} not found in form sections`,
          );
        } else {
          newOrderedSections.push({ ...prevState[sectionIndex] });
        }
      }
      return newOrderedSections;
    });
  };

  // this triggers form validation in general on load and template selecetion change
  useEffect(() => {
    form.validate();
    updateHiddenFieldsForTemplate(
      form.values.templateSelection as TemplateOptions,
    );
    updateOrderOfSectionsForTemplate(
      form.values.templateSelection as TemplateOptions,
    );
  }, [form.values.templateSelection]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ display: 'flex', gap: '1em', alignItems: 'flex-end' }}>
      <PFSelect
        style={{ width: '14em' }}
        label={'Template'}
        fieldPath={'templateSelection'}
        data={[...Object.values(TemplateOptions)]}
      />
      <Tooltip label={'Populate default values for the selected template'}>
        <Button
          variant="primary"
          type={'button'}
          onClick={() =>
            updateDefaultValuesForTemplate(
              form.values.templateSelection as TemplateOptions,
            )
          }
        >
          AutoFill
        </Button>
      </Tooltip>
    </div>
  );
};

export default memo(TemplateSelection);
