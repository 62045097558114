import { useQuery } from '@tanstack/react-query';
import { Company, getCompanies } from '../../API/company.service';
import { useMemo, useState } from 'react';
import { Select } from '@mantine/core';
import { LegacyCompanyLevelConfig } from './LegacyCompanyLevelConfig';
import { getLegacyCompanyByShortName } from '../../API/desk-service';

export const DeskConfig = () => {
  const { data: companiesResponse } = useQuery({
    queryKey: ['getComparies'],
    queryFn: getCompanies,
  });

  const companies = useMemo(
    () =>
      companiesResponse?.data?.map((x) => ({
        value: x.id + '',
        label: x.shortName,
      })),
    [companiesResponse],
  );

  const [companySelected, setCompanySelected] = useState<
    Company | null | undefined
  >(null);

  const { data: legacyCompany } = useQuery({
    queryKey: ['getLegacyCompanyByShortName', companySelected?.shortName],
    queryFn: () =>
      getLegacyCompanyByShortName([companySelected?.shortName as string]),
    enabled: !!companySelected?.shortName,
  });

  const handleCompanySelected = (companyId: string | null) => {
    if (!companyId) {
      setCompanySelected(undefined);
      return;
    }
    const company = companiesResponse?.data?.find((x) => x.id === +companyId);
    setCompanySelected(company);
  };

  return (
    <div style={{ padding: '1rem 2rem' }}>
      <Select
        style={{ width: '300px', marginBottom: '2rem' }}
        placeholder={'Search Company'}
        label="Select company"
        searchable={true}
        clearable={true}
        data={companies || []}
        onChange={handleCompanySelected}
      ></Select>
      {/*<Select data={companies?.map(x => ({value: x.id, label: x.shortName}))} onChange={(e) => setCompanySelected(e)}></Select>*/}

      {companySelected && (
        <div>
          {legacyCompany?.data?.[0].id ? (
            <LegacyCompanyLevelConfig
              key={legacyCompany?.data?.[0].id}
              legacyDeskId={legacyCompany?.data?.[0].id}
            />
          ) : (
            <div> No company found</div>
          )}
        </div>
      )}
    </div>
  );
};
