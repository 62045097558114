import React, { useCallback, useMemo } from 'react';
import { Stack, useMantineColorScheme } from '@mantine/core';
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import {
  getProductByCriteriaWithAudit,
  SearchCriteria,
} from '../../API/product-data-warehouse.service';
import { AgGridReact } from 'ag-grid-react';
import { dateCompartor } from '../../utilities/comparators';
import { Link } from 'react-router-dom';
import { PRODUCT_EDIT_ROUTE } from '../../Routes';

export interface ReviewQueueProps {
  isSpottyReview: boolean;
}

const paginationPageSize = 50;

const rowDataSource = (isSpottyReview: boolean) => {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      const searchCriteria: SearchCriteria = {
        searchCriteriaList: [
          {
            key: 'productGeneral.stage',
            operation: 'IN',
            value: ['OPS_REVIEW'],
          },
          {
            key: 'productGeneral.status',
            operation: 'IN',
            value: isSpottyReview
              ? ['UPDATE_PRODUCT_DETAILS']
              : ['REVIEW_REQUIRED'],
          },
        ],
        junctionOperation: 'AND',
      };
      const filterKeys = Object.keys(params?.request?.filterModel || {});
      console.log('filterKeys', filterKeys, params);
      if (filterKeys.length > 0) {
        filterKeys.forEach((filterKey) => {
          // const filterParams: any = params?.request?.filterModel?.[filterKey as any] as any;
          const filterParams: any = null;
          if (
            filterKey === 'productGeneral.cusip' &&
            filterParams?.filter?.length === 12
          ) {
            filterKey = 'productGeneral.isin';
          }
          searchCriteria.searchCriteriaList.push({
            key: filterKey,
            operation: 'IN',
            value: [filterParams?.filter],
          });
        });
      }
      let paginationPageNumber = params.request.startRow
        ? params.request.startRow / paginationPageSize
        : 0;
      const pageRequest = {
        page: paginationPageNumber,
        size: paginationPageSize,
        sort:
          params.request.sortModel.length > 0
            ? params.request.sortModel[0].colId +
              ',' +
              params.request.sortModel[0].sort
            : 'createTimestamp,asc',
      };
      getProductByCriteriaWithAudit(searchCriteria, pageRequest)
        .then((result) => {
          params.success({
            rowData: mapTableData(result?.data?.content || []),
            rowCount: result?.data?.totalElements || 0,
          });
        })
        .catch((err) => {
          params.fail();
        });
    },
  };
};

const mapTableData = (data: any): any[] => {
  const tableData: any[] = [];
  data.forEach((row: any) => {
    const productInfo = row.product;
    const entryDate = new Date(productInfo.createTimestamp);
    const tradeDate = new Date(productInfo.productGeneral.tradeDate + ' ');
    const identifier = productInfo.productGeneral.cusip
      ? productInfo.productGeneral.cusip
      : productInfo.productGeneral.isin;
    const product = {
      cusip: identifier,
      structureType: productInfo.productGeneral.structureNameInternal,
      processor: row.audit?.user,
      tradeDate: !isNaN(tradeDate.getTime())
        ? tradeDate.getMonth() +
          1 +
          '/' +
          tradeDate.getDate() +
          '/' +
          tradeDate.getFullYear()
        : '',
      productEntryDate:
        entryDate.getMonth() +
        1 +
        '/' +
        entryDate.getDate() +
        '/' +
        entryDate.getFullYear(),
      productId: productInfo.productId,
    };
    tableData.push(product);
  });
  return tableData;
};

const ReviewQueue = (props: ReviewQueueProps) => {
  const { colorScheme } = useMantineColorScheme();
  const defaultColDef: ColDef = {
    sortable: true,
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // const datasource = rowDataSource(props.isSpottyReview);
    // params.api.(datasource);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = [
    {
      colId: 'productGeneral.cusip',
      field: 'cusip',
      headerName: 'CUSIP/ISIN',
      cellRenderer: function (params: ICellRendererParams) {
        if (params.value == null) {
          return (
            <Link to={`/${PRODUCT_EDIT_ROUTE}/${params.data?.productId}`}>
              {params.data?.productId}
            </Link>
          );
        } else {
          return (
            <Link to={`/${PRODUCT_EDIT_ROUTE}/${params.value}`}>
              {params.value}
            </Link>
          );
        }
      },
    },
    {
      colId: 'productGeneral.structureNameInternal',
      field: 'structureType',
      headerName: 'Structure Type',
      flex: 1,
      minWidth: 350,
    },
    {
      colId: 'processor',
      field: 'processor',
      headerName: 'Processor',
      filter: false,
      sortable: false,
    },
    {
      headerName: 'Trade Date',
      field: 'tradeDate',
      colId: 'productGeneral.tradeDate',
      comparator: dateCompartor,
      filter: false,
      width: 130,
    },
    {
      headerName: 'Entry Date',
      field: 'productEntryDate',
      colId: 'createTimestamp',
      width: 150,
      filter: false,
    },
    {
      colId: 'productId',
      headerName: 'Product Id',
      field: 'productId',
      width: 340,
    },
  ];

  const dataSource = useMemo(() => {
    return rowDataSource(props.isSpottyReview);
  }, [props.isSpottyReview]);
  return (
    //   --global-header-height == 50px
    <Stack gap={0} style={{ height: 'calc(100vh - 50px)', padding: '1rem' }}>
      <h1>{props.isSpottyReview ? 'Spotty Review Queue' : 'Review Queue'}</h1>
      <div
        className={
          colorScheme === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz'
        }
        style={{
          minHeight: '50rem',
          flexGrow: 1,
          width: '100%',
          margin: '1em auto',
        }}
      >
        <AgGridReact
          pagination={true}
          paginationPageSize={50}
          cacheBlockSize={50}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowModelType={'serverSide'}
          serverSideDatasource={dataSource}
          // serverSideStoreType={'partial'}
          // serverSideInfiniteScroll={true}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </Stack>
  );
};

export default ReviewQueue;
