import React, { ReactNode } from 'react';
import { Text, Space, Grid, Card } from '@mantine/core';
import { startCase } from 'lodash';

interface KeyValueCardProps {
  entries: [string, any][];
  style?: React.CSSProperties;
  footerComponent?: ReactNode;
}

export const KeyValue = ({ label, value }: { label: string; value: any }) => (
  <Grid.Col span={6}>
    <Text size="sm" fw={700}>
      {startCase(label)}
    </Text>
    <Space />
    <Text style={{ wordBreak: 'break-word' }} size="sm" ta="left">
      {value.toString()}
    </Text>
  </Grid.Col>
);

const KeyValueCard = ({
  entries,
  style,
  footerComponent,
}: KeyValueCardProps) => (
  <Card padding="md" shadow="xs" style={style}>
    <Grid gutter="md">
      {entries.map(([key, value]) => {
        if (value !== null && value !== undefined) {
          return (
            <KeyValue
              key={key}
              label={key}
              value={typeof value === 'object' ? JSON.stringify(value) : value}
            />
          );
        }
        return null;
      })}
    </Grid>
    {footerComponent}
  </Card>
);

export default KeyValueCard;
