// This context is used to determine whether form is disabled for components that don't
// get directly impacted by fieldSet disabled={true}
// initial use case: reactDatePicker.
import React, { useState } from 'react';

export interface ProductFormDisabledInfo {
  formIsDisabled: boolean;
  enabledFields?: string[] | null;
}

export interface ProductFormDisabledContextProps {
  formDisabledInfo: ProductFormDisabledInfo;
  setFormDisabledInfo: (x: ProductFormDisabledInfo) => void;
}

export const ProductFormDisabledContext =
  React.createContext<ProductFormDisabledContextProps>({
    formDisabledInfo: { formIsDisabled: false },
    setFormDisabledInfo: (x: ProductFormDisabledInfo) => {},
  });
export const useProductFormDisabled = () => {
  return React.useContext(ProductFormDisabledContext);
};

export const DisabledFieldsContextProvider = ({ children }: any) => {
  const [formDisabledInfo, setFormDisabledInfo] =
    useState<ProductFormDisabledInfo>({ formIsDisabled: false });

  const providerValue = React.useMemo(
    () => ({
      formDisabledInfo,
      setFormDisabledInfo,
    }),
    [formDisabledInfo],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProductFormDisabledContext.Provider value={providerValue}>
      {children}
    </ProductFormDisabledContext.Provider>
  );
};
