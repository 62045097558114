import React from 'react';
import classes from './splitButton.module.scss';
import {
  ActionIcon,
  Button,
  Group,
  Menu,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

export interface SplitButtonInfo {
  label: string;
  fn: () => void;
  disabled?: boolean;
  icon?: any;
}

export interface SplitButtonProps {
  primaryButton: SplitButtonInfo;
  secondaryButtons: SplitButtonInfo[];
}

export const SplitButton = ({
  primaryButton,
  secondaryButtons,
}: SplitButtonProps) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  // todo: menuIcon colors haven't been set up yet: we didn't need them initially.
  const menuIconColor =
    theme.colors[theme.primaryColor][colorScheme === 'dark' ? 5 : 6];
  // const menuIconColor = theme.colorScheme === 'dark' ? theme.colors.dark[6] : colors.blueMedium;
  return (
    <Group wrap={'nowrap'} gap={0}>
      <Button
        variant="primary"
        type={'button'}
        style={{ cursor: primaryButton.disabled ? 'not-allowed' : 'pointer' }}
        onClick={primaryButton.fn}
        disabled={primaryButton.disabled}
        className={classes.button}
      >
        {primaryButton.label}
      </Button>
      <Menu transitionProps={{ transition: 'pop' }} position="bottom-end">
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            disabled={secondaryButtons.every((x) => x.disabled)}
            className={classes.menuControl}
          >
            <IconChevronDown size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {secondaryButtons.map((btnProps) => (
            <Menu.Item
              disabled={btnProps.disabled}
              key={btnProps.label}
              onClick={btnProps.fn}
              leftSection={
                btnProps.icon ? (
                  React.cloneElement(btnProps.icon, {
                    color: { menuIconColor },
                  })
                ) : (
                  <></>
                )
              }
            >
              {btnProps.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};
