import { apiUrl } from '../enviroments/.env';

export const AUTH_SERVICE_ROOT = `${apiUrl}/api/auth-service`;
export const oauth_client_id = 'boston';
export const webclient = 'webclient';
export const PRODUCT_MAINTENANCE_ROOT = `${apiUrl}/api/product-maintenance-service`;
export const PRODUCT_DATA_WAREHOUSE_ROOT = `${apiUrl}/api/pdw-service`;
export const PERMISSION_SERVICE_ROOT = `${apiUrl}/api/permission-service`;
export const PRODUCT_METRIC_ROOT = `${apiUrl}/api/product-metric-service`;
export const PDW_SERVICE = `${apiUrl}/api/pdw-service`;
export const PDW_SERVICE_STAGED = `${PDW_SERVICE}/v2/products/stage`;
export const DOCUMENT_SERVICE_ROOT = `${apiUrl}/api/document-service`;
export const COMPARE_PRODUCT_SEARCH_PARAM = 'compareProduct';
export const PREFERENCE_SERVICE_ROOT = `${apiUrl}/api/preference-service`;
export const COMPANY_SERVICE_ROOT = `${apiUrl}/api/company`;
export const DESK_SERVICE_ROOT = `${apiUrl}/api/desk-service`;
export const MARKET_DATA_MAINTENANCE_SERVICE = `${apiUrl}/api/market-data-maintenance-service`;
export const MARKET_DATA_SERVICE = `${apiUrl}/api/market-data-service`;
export const USER_SERVICE_ROOT = `${apiUrl}/api/user-service`;
export const EVENT_SERVICE_ROOT = `${apiUrl}/api/event-service`;
