import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUserPermissions } from '../../API/misc-services';
import AccessDenied from '../AccesDenied';
import { WidgetPermissions } from '../../enviroments/WidgetPermissions';

export interface PermissionGuardProps {
  perms: WidgetPermissions[];
  children?: any;
}

const PermissionGuard = ({ perms, children }: PermissionGuardProps) => {
  const { data: permissions } = useQuery({
    queryKey: ['getPermissions'],
    queryFn: getUserPermissions,
    enabled: false,
  });

  const canAccess = () => {
    return permissions?.data?.some((x) => perms.includes(x));
  };

  return canAccess() ? <>{children}</> : <AccessDenied />;
};

export default PermissionGuard;
