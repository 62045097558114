import {
  ConfirmationStatus,
  emptyPhysicalSettlementList,
  EventConfirmation,
  EventTypes,
  getEventConfirmationsAuditLogKey,
  PhysicalSettlementType,
  putEventConfirmation,
  PutEventConfirmationRequest,
  SettlementTypeOptions,
} from '../../../API/event-service';
import { useForm } from '@mantine/form';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Checkbox,
  Autocomplete,
  LoadingOverlay,
  Title,
  Divider,
  Menu,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { UpdateStatusButton } from './UpdateStatusButton';
import { useDidUpdate } from '@mantine/hooks';
import {
  isNullOrEmptyString,
  replaceEmptyStringWithNull,
} from '../../../utilities/utilities';

interface CashFormProps {
  event: EventConfirmation | undefined;
  updateGrid?: (event: EventConfirmation) => void;
  removeEvent?: (event: EventConfirmation | undefined) => void;
}

const LumaAuditOptions = [
  'Calculated Result Changed from End of Market Price to End of Day Price',
  'Adjusted to match issuer',
  'Updated product data which produced a new calculated value',
  'Adjusted Luma Value',
];

const IssuerAuditOptions = [
  'API Data Transfer Issue',
  'Issuer Value was Mistyped',
  'Issuer agreed with Luma Calculated Value',
  'Issuer advised payout was adjusted due to dividends',
];

const buttonLabelMapping: Partial<Record<EventTypes, any>> = {
  [EventTypes.COUPON_PAYMENT]: {
    missed: 'Missed',
    paid: 'Confirmed',
  },
  [EventTypes.ISSUER_CALL]: {
    missed: 'Not Called',
    paid: 'Called',
  },
  [EventTypes.AUTO_CALL]: {
    missed: 'Not Called',
    paid: 'Called',
  },
  [EventTypes.MATURITY]: {
    paid: 'Matured',
  },
};

type EventConfirmationForm = Pick<
  EventConfirmation,
  | 'physicalSettlements'
  | 'lumaAuditNote'
  | 'lumaIssuerAuditNote'
  | 'cashSettlementRates'
  | 'confirmationStatus'
  | 'settlementType'
  | 'issuerMarkedAsMissed'
  | 'escalationRequired'
>;

export const MSWMEventConfirmationForm = ({
  event,
  updateGrid,
  removeEvent,
}: CashFormProps) => {
  const [submissionState, setSubmissionState] = useState<
    'success' | 'error' | null
  >(null);

  const queryClient = useQueryClient();
  useDidUpdate(() => {
    const newInitialValues = getInitialFormValues();
    form.setValues(newInitialValues);
    form.resetDirty();
  }, [event]);

  const showPhysicalForm = useMemo(() => {
    return (
      event?.settlementType === SettlementTypeOptions.PHYSICAL &&
      event.eventType === EventTypes.MATURITY
    );
  }, [event]);
  const getInitialFormValues = useCallback((): EventConfirmationForm => {
    const underlierFallback =
      event?.underlierList?.length === 1 ? event?.underlierList[0] : undefined;

    const initialValue = {
      escalationRequired: event?.escalationRequired,
      lumaAuditNote: event?.lumaAuditNote,
      lumaIssuerAuditNote: event?.lumaIssuerAuditNote,
      cashSettlementRates: {
        rate: event?.cashSettlementRates?.rate ?? '',
        lumaRate: event?.cashSettlementRates?.lumaRate ?? '',
        issuerRate: event?.cashSettlementRates?.issuerRate ?? '',
      },
      confirmationStatus: event?.confirmationStatus,
      settlementType: event?.settlementType,
      issuerMarkedAsMissed: event?.issuerMarkedAsMissed,
      physicalSettlements:
        (event?.physicalSettlements?.length || 0) > 0
          ? event?.physicalSettlements
          : emptyPhysicalSettlementList,
    };

    const sharesIndex = initialValue?.physicalSettlements?.findIndex(
      (x) => x.physicalSettlementType === PhysicalSettlementType.SHARES,
    );
    // poplulate the underlier if there's only one.
    if (
      sharesIndex &&
      underlierFallback &&
      initialValue?.physicalSettlements?.[sharesIndex] &&
      initialValue?.physicalSettlements?.[sharesIndex].securityId == null
    ) {
      initialValue.physicalSettlements[sharesIndex].securityId =
        underlierFallback.ticker;
      initialValue.physicalSettlements[sharesIndex].securityIdType =
        underlierFallback.type;
    }
    return initialValue;
  }, [event]);

  const timeoutRef = useRef<any>(null);

  const { mutate: submit, isLoading } = useMutation(
    (payload: PutEventConfirmationRequest) => putEventConfirmation(payload),
    {
      onSuccess: (res) => {
        updateGrid?.(JSON.parse(res.config?.data));
        setSubmissionState('success');
        if (event?.eventDate && event?.eventType && event?.productIdentifier)
          queryClient.invalidateQueries(
            getEventConfirmationsAuditLogKey({
              eventDate: event?.eventDate,
              eventType: event?.eventType,
              productIdentifier: event?.productIdentifier,
            }),
          );
        // form.resetDirty();
      },
      onError: (err: AxiosError<any>) => {
        notifications.show({
          id: 'submit-event-notifications-error',
          title: 'Error Submitting Event',
          message: err?.response?.data?.message,
          color: 'red',
          withCloseButton: true,
          autoClose: 15000,
        });
        setSubmissionState('error');
      },
      onSettled: () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setSubmissionState(null);
        }, 10000);
      },
    },
  );

  const form = useForm<EventConfirmationForm>({
    initialValues: getInitialFormValues(),
    validate: {
      lumaAuditNote: (value: string | null | undefined, values: any) => {
        if (
          (value == null || value === '') &&
          values.cashSettlementRates?.lumaRate != null &&
          event?.cashSettlementRates?.lumaRate != null &&
          values.cashSettlementRates?.lumaRate !==
            event?.cashSettlementRates?.lumaRate
        ) {
          return 'Audit note required when making changes';
        }
        return null;
      },
      lumaIssuerAuditNote: (value: string | null | undefined, values: any) => {
        if (
          (value == null || value === '') &&
          values.cashSettlementRates?.issuerRate != null &&
          event?.cashSettlementRates?.issuerRate != null &&
          values.cashSettlementRates?.issuerRate !==
            event?.cashSettlementRates?.issuerRate
        ) {
          return 'Audit note required when making changes';
        }
        return null;
      },
    },
    mode: 'controlled',
  });

  const reviewPaidEnabled = useMemo(() => {
    return (
      (form.values.cashSettlementRates?.lumaRate ||
        form.values?.cashSettlementRates?.issuerRate) &&
      form.values.cashSettlementRates?.lumaRate !==
        form.values?.cashSettlementRates?.issuerRate
    );
  }, [form.values]);

  const reviewAndConfirmMissedEnabled = useMemo(() => {
    return (
      replaceEmptyStringWithNull(form.values.cashSettlementRates?.lumaRate) ==
        null ||
      replaceEmptyStringWithNull(form.values.cashSettlementRates?.issuerRate) ==
        null
    );
  }, [form.values]);

  const confirmPaidEnabled = useMemo(() => {
    return (
      (form.values.cashSettlementRates?.lumaRate ||
        form.values?.cashSettlementRates?.issuerRate) &&
      form.values.cashSettlementRates?.lumaRate ===
        form.values?.cashSettlementRates?.issuerRate
    );
  }, [form.values]);

  const handleSubmit = (status: ConfirmationStatus) => {
    form.validate();
    if (!form.isValid()) {
      return;
    }
    if (!event) {
      return;
    }
    const rate =
      form.values.cashSettlementRates?.lumaRate ===
      form.values.cashSettlementRates?.issuerRate
        ? replaceEmptyStringWithNull(form.values.cashSettlementRates?.lumaRate)
        : null;
    // todo: check if this is needed
    let physicalSettlements = form.values.physicalSettlements?.filter((x) => {
      return x.lumaValue || x.lumaValue;
    });
    if (physicalSettlements?.length === 0) {
      physicalSettlements = undefined;
    }
    const eventConfirmationObj: EventConfirmation = {
      ...event,
      ...form.values,
      confirmationStatus: status,
      cashSettlementRates: {
        lumaRate: replaceEmptyStringWithNull(
          form.values.cashSettlementRates?.lumaRate,
        ),
        issuerRate: replaceEmptyStringWithNull(
          form.values.cashSettlementRates?.issuerRate,
        ),
        rate,
      },
      physicalSettlements,
    };
    const payload: PutEventConfirmationRequest = {
      event: eventConfirmationObj,
      asIssuer: false,
    };
    submit(payload);
  };

  const disabled = !form.isDirty() || isLoading || !form.isValid();

  const buttonLabels =
    event?.eventType != null
      ? (buttonLabelMapping[event.eventType as EventTypes] ?? null)
      : null;

  const ratesMatch =
    form.getValues()?.cashSettlementRates?.lumaRate &&
    form.getValues()?.cashSettlementRates?.issuerRate &&
    form.getValues()?.cashSettlementRates?.lumaRate ===
      form.getValues()?.cashSettlementRates?.issuerRate;

  const sharesIndex = form.values?.physicalSettlements?.findIndex(
    (x) => x.physicalSettlementType === PhysicalSettlementType.SHARES,
  );
  const cashIndex = form.values?.physicalSettlements?.findIndex(
    (x) => x.physicalSettlementType === PhysicalSettlementType.CASH_IN_LIEU,
  );

  const sharesMatch =
    sharesIndex != null &&
    sharesIndex >= 0 &&
    form.values?.physicalSettlements?.[sharesIndex]?.lumaValue &&
    form.values?.physicalSettlements?.[sharesIndex]?.issuerValue &&
    form.values?.physicalSettlements?.[sharesIndex]?.lumaValue ===
      form.values?.physicalSettlements?.[sharesIndex]?.issuerValue;

  const cashMatch =
    cashIndex &&
    form.values?.physicalSettlements?.[cashIndex]?.lumaValue &&
    form.values?.physicalSettlements?.[cashIndex]?.issuerValue &&
    form.values?.physicalSettlements?.[cashIndex]?.lumaValue ===
      form.values?.physicalSettlements?.[cashIndex]?.issuerValue;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Stack style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} overlayProps={{ blur: 1 }} />
        <Group>
          <Title order={4}>
            {event?.productIdentifier} - {event?.eventType} - {event?.eventDate}
          </Title>

          {form.isDirty() && (
            <Button
              size={'compact-sm'}
              onClick={() => form.reset()}
              variant={'light'}
              color={'red.6'}
            >
              Undo Changes
            </Button>
          )}

          {submissionState === 'success' && (
            <Title order={3} c={'green.5'}>
              Submission Successful
            </Title>
          )}
          {submissionState === 'error' && (
            <Title order={3} c={'red.5'}>
              Error Updating Event!!!
            </Title>
          )}
        </Group>
        {showPhysicalForm && (
          <Select
            style={{
              minWidth: '30rem',
            }}
            label={'Underlier'}
            comboboxProps={{ withinPortal: false }}
            data={event?.underlierList
              ?.filter((underlier) => underlier.ticker != null)
              ?.map((x) => ({
                value: x.ticker as string,
                label: `${x.ticker} -- ${x.name}  (${x.type})`,
              }))}
            {...form.getInputProps(
              `physicalSettlements.${sharesIndex}.securityId`,
            )}
            onChange={(e) => {
              const { onChange } = form.getInputProps(
                `physicalSettlements.${sharesIndex}.securityId`,
              );

              const securityIdType = event?.underlierList?.find(
                (underlier) => underlier.ticker === e,
              )?.type;
              form.setFieldValue(
                `physicalSettlements.${sharesIndex}.securityIdType`,
                securityIdType,
              );
              onChange(e);
            }}
          />
        )}
        <Group>
          {/*<Text> Luma</Text>*/}
          <NumberInput
            rightSectionWidth={30}
            rightSection={
              ratesMatch ? <IconCheck size={20} color={'green'} /> : <div />
            }
            styles={{
              input: {
                ...(ratesMatch ? { borderColor: 'green' } : {}),
              },
            }}
            label={'Luma Rate'}
            hideControls
            data-autofocus
            {...form.getInputProps('cashSettlementRates.lumaRate')}
            onChange={(e: any) => {
              const { onChange } = form.getInputProps(
                'cashSettlementRates.lumaRate',
              );
              onChange(e);
              form.validate();
            }}
          />

          {showPhysicalForm && (
            <>
              <Divider orientation={'vertical'} />
              <NumberInput
                rightSectionWidth={30}
                rightSection={
                  sharesMatch ? (
                    <IconCheck size={20} color={'green'} />
                  ) : (
                    <div />
                  )
                }
                label={'Shares (Luma)'}
                {...form.getInputProps(
                  `physicalSettlements.${sharesIndex}.lumaValue`,
                )}
              />
              <NumberInput
                rightSectionWidth={30}
                rightSection={
                  cashMatch ? <IconCheck size={20} color={'green'} /> : <div />
                }
                label={'Cash In Lieu (Luma)'}
                {...form.getInputProps(
                  `physicalSettlements.${cashIndex}.lumaValue`,
                )}
              />
            </>
          )}

          {form.values.escalationRequired ? (
            <Autocomplete
              style={{
                minWidth: '30rem',
              }}
              data={LumaAuditOptions}
              styles={{
                label: {
                  color: 'yellow.8',
                },
              }}
              label={'Luma Audit Note (Escalated)'}
              placeholder="Enter Audit Note"
              {...form.getInputProps('lumaAuditNote')}
            />
          ) : (
            <Select
              style={{
                minWidth: '30rem',
              }}
              comboboxProps={{ withinPortal: false }}
              disabled={
                !form.isDirty('cashSettlementRates.lumaRate') ||
                (isNullOrEmptyString(
                  form.values?.cashSettlementRates?.lumaRate,
                ) &&
                  isNullOrEmptyString(event?.cashSettlementRates?.lumaRate))
              }
              label={'Luma Audit Notes'}
              data={LumaAuditOptions}
              {...form.getInputProps('lumaAuditNote')}
            />
          )}
        </Group>

        <Group>
          {/*<Text>Issuer</Text>*/}
          <NumberInput
            rightSectionWidth={30}
            rightSection={
              ratesMatch ? <IconCheck size={20} color={'green'} /> : <div />
            }
            label={'Issuer Rate'}
            styles={{
              input: {
                ...(ratesMatch ? { borderColor: 'green' } : {}),
              },
            }}
            hideControls
            disabled={form.values.issuerMarkedAsMissed}
            {...form.getInputProps('cashSettlementRates.issuerRate')}
            onChange={(e: any) => {
              const { onChange } = form.getInputProps(
                'cashSettlementRates.issuerRate',
              );
              onChange(e);
              form.validate();
            }}
          />

          {showPhysicalForm && (
            <>
              <Divider orientation={'vertical'} />
              <NumberInput
                rightSectionWidth={30}
                rightSection={
                  sharesMatch ? (
                    <IconCheck size={20} color={'green'} />
                  ) : (
                    <div />
                  )
                }
                label={'Shares (Issuer)'}
                {...form.getInputProps(
                  `physicalSettlements.${sharesIndex}.issuerValue`,
                )}
              />
              <NumberInput
                rightSectionWidth={30}
                rightSection={
                  cashMatch ? <IconCheck size={20} color={'green'} /> : <div />
                }
                label={'Cash In Lieu (Luma)'}
                {...form.getInputProps(
                  `physicalSettlements.${cashIndex}.issuerValue`,
                )}
              />
            </>
          )}
          <Select
            style={{
              minWidth: '30rem',
            }}
            comboboxProps={{ withinPortal: false }}
            disabled={
              !form.isDirty('cashSettlementRates.issuerRate') ||
              (isNullOrEmptyString(
                form.values?.cashSettlementRates?.issuerRate,
              ) &&
                isNullOrEmptyString(event?.cashSettlementRates?.issuerRate))
            }
            label={'Issuer Audit Notes'}
            data={IssuerAuditOptions}
            {...form.getInputProps('lumaIssuerAuditNote')}
          />
        </Group>
        {/*{event?.escalationRequired && (*/}
        {/*  <Checkbox*/}
        {/*    label={'Escalation Required: Luma Audit Note is free-form text'}*/}
        {/*    disabled={true}*/}
        {/*    {...form.getInputProps('escalationRequired', { type: 'checkbox' })}*/}
        {/*    onChange={() => {*/}
        {/*      return;*/}
        {/*      // form.setFieldValue(*/}
        {/*      //   'escalationRequired',*/}
        {/*      //   event.currentTarget.checked,*/}
        {/*      // );*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        {event?.eventType !== EventTypes.MATURITY && (
          <Stack>
            {/*<Checkbox*/}
            {/*  {...form.getInputProps('lumaMarkedAsMissed', {*/}
            {/*    type: 'checkbox',*/}
            {/*  })}*/}
            {/*  label={'Marked as Missed by Luma'}*/}
            {/*/>*/}
            <Checkbox
              {...form.getInputProps('issuerMarkedAsMissed', {
                type: 'checkbox',
              })}
              label={'Marked as Missed by Issuer'}
            />
          </Stack>
        )}
        <Group>
          <Button
            variant={'outline'}
            onClick={() => handleSubmit(ConfirmationStatus.REQUIRES_RESOLUTION)}
            disabled={disabled || !reviewPaidEnabled}
          >
            Review
          </Button>

          {buttonLabels?.missed && (
            <>
              <Menu>
                <Menu.Target>
                  <Button
                    variant={'light'}
                    color={'red.6'}
                    // onClick={() => handleSubmit(ConfirmationStatus.NOT_CONFIRMED)}
                    disabled={
                      isLoading ||
                      !reviewAndConfirmMissedEnabled ||
                      form.values.issuerMarkedAsMissed
                    }
                  >
                    {buttonLabels?.missed}
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() =>
                      handleSubmit(ConfirmationStatus.NOT_CONFIRMED)
                    }
                    disabled={
                      isLoading ||
                      !reviewAndConfirmMissedEnabled ||
                      form.values.issuerMarkedAsMissed
                    }
                  >
                    Confirm {buttonLabels?.missed}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>

              <Button
                variant={'outline'}
                color={'red.6'}
                onClick={() =>
                  handleSubmit(ConfirmationStatus.REQUIRES_RESOLUTION)
                }
                disabled={isLoading || !reviewAndConfirmMissedEnabled}
              >
                {buttonLabels?.missed} (Review)
              </Button>
            </>
          )}

          <Button
            variant="primary"
            onClick={() => handleSubmit(ConfirmationStatus.CONFIRMED)}
            disabled={disabled || !confirmPaidEnabled}
          >
            {buttonLabels?.paid}
          </Button>

          <Group ml={'4rem'}>
            {event?.confirmationStatus !== ConfirmationStatus.PENDING && (
              <UpdateStatusButton
                event={event}
                updateGrid={updateGrid}
                action={'resetToPending'}
              />
            )}
            <UpdateStatusButton
              event={event}
              removeEvent={removeEvent}
              action={'delete'}
            />
          </Group>
        </Group>
      </Stack>
    </form>
  );
};
