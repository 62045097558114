import { useState } from 'react';
import {
  Underlier,
  specialFields,
} from '../../../product-schema/underlier.schema';
import UnderlierFormUpdate from './UnderlierFormUpdate';
import { Button, Card } from '@mantine/core';
import KeyValueCard from '../../../components/KeyValueCard';

interface UnderlierItemProps {
  underlier: Underlier;
  setSelectedUnderlier: (underlier: Underlier | null) => void;
}

const UnderlierItem = ({
  underlier,
  setSelectedUnderlier,
}: UnderlierItemProps) => {
  const [isEditingForm, setIsEditingForm] = useState(false);

  const handleEdit = () => {
    setIsEditingForm(true);
  };

  const specialEntries = Object.entries(underlier).filter(
    ([key, value]) =>
      specialFields.includes(key) && value !== null && value !== undefined,
  );

  const factsetEntries = underlier.factsetData
    ? Object.entries(underlier.factsetData).filter(
        ([key, value]) => value !== null && value !== undefined,
      )
    : [];

  const otherEntries = Object.entries(underlier).filter(
    ([key, value]) =>
      !specialFields.includes(key) &&
      key !== 'factsetData' &&
      value !== null &&
      value !== undefined,
  );

  return (
    <Card padding="md" shadow="xs">
      {isEditingForm ? (
        <UnderlierFormUpdate
          initialValues={underlier}
          setIsEditing={setIsEditingForm}
          setSelectedUnderlier={setSelectedUnderlier}
        />
      ) : (
        <>
          <KeyValueCard
            entries={specialEntries}
            style={{ border: '1px solid #F8F9FA', marginTop: '20px' }}
          />
          {factsetEntries.length > 0 && (
            <KeyValueCard
              entries={factsetEntries}
              style={{ border: '1px solid #F8F9FA', marginTop: '20px' }}
            />
          )}
          <KeyValueCard
            entries={otherEntries}
            style={{ border: '1px solid #F8F9FA', marginTop: '20px' }}
          />
          <div style={{ width: '100%' }}>
            <Button
              variant="primary"
              style={{ marginLeft: 'auto', marginTop: '1rem' }}
              onClick={handleEdit}
            >
              Edit
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default UnderlierItem;
