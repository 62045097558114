import axios, { AxiosResponse } from 'axios';
import { DESK_SERVICE_ROOT } from './api-consts';

export interface LegacyCompany {
  id: number; // ex: 1039,
  legalName: string; // ex: "Cetera Investment Services, LLC",
  shortName: string; // ex: "Cetera Investment Services, LLC",
  defaultProgram: number; // ex: 5051,
  active: boolean; // ex: false,
  specialPricing: boolean;
  tradeExecutionCodesList: any; // unknown.
  programs: LegacyProgram[];
  productTypes: string[]; // "Market Linked CD's", "Structured Notes", "Annuities", "Variable Annuities"
  notificationSettings: LegacyNotificationSettings;

  PostIssuerProductDeadlineDateValue: any | null;
  PostIssuerProductDeadlineTime: any | null;
  PreIssuerProductDeadlineDateValue: any | null;
  PreIssuerProductDeadlineTime: any | null;
  accountMgr: any | null;
  creationHubTypes: any | null;
}

export interface LegacyNotificationSettings {
  secureOrderConfirmations: boolean; // ex: false,
  secureOrderPassword: string; // ex: "",
  secureOrderRecipients: string; // ex: "",
  secureOrderConfirmationMessage: string; // ex: "",
  deskEmailTo: string; // ex: "",
  salesAsstEmailTo: string | null; // ex: null,
  notifyDesk: boolean; // ex: false,
  notifyDeskEdit: boolean; // ex: false,
  notifyRegRep: boolean; // ex: false,
  notifyRegRepEdit: boolean; // ex: false,
  notifySA: boolean; // ex: false,
  notifySAEdit: boolean; // ex: false,
  notifyCDFGRM: boolean; // ex: true,
  notifyCDFGRMEdit: boolean; // ex: false,
  notifyOnTradeApprove: boolean; // ex: false,
  tradeDeskId: number; // ex: 1039
}
export interface LegacyProgram {
  id: number; // ex 5052,
  tradeDeskId: number; // ex 1039,
  legalName: string | null; // ex null,
  shortName: string; // ex "Cathay Bank Cetera",
  timeOffSet: number; // ex 1,
  closingTimeFixed: string | null; // ex null,
  noteMinimumOrderQty: number; // ex 0,
  cdMinimumOrderQty: number; // ex 0
}

export const getLegacyCompanyByShortName = (
  shortNames: string[],
): Promise<AxiosResponse<LegacyCompany[]>> =>
  axios.post<LegacyCompany[]>(
    `${DESK_SERVICE_ROOT}/desk/all/for-names`,
    shortNames,
  );
