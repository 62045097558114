import React from 'react';
import { Container } from '@mantine/core';

const UpcomingHolidays: React.FC = () => {
  return (
    <Container size="md">
      <h2>UpcomingHolidays Page</h2>
    </Container>
  );
};

export default UpcomingHolidays;
