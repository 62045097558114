import LumaFlame from '../../../assets/flame.svg';
import classNames from 'classnames';
import classes from './globalNav.module.scss';
import React, { useState } from 'react';
import {
  Menu,
  ActionIcon,
  Container,
  Burger,
  Paper,
  Transition,
  Kbd,
  Popover,
  useMantineColorScheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  IconSun,
  IconMoonStars,
  IconUser,
  IconLogout,
  IconChevronDown,
  IconSearch,
} from '@tabler/icons-react';
import { useAuth } from '../../Authentication/AuthProvider';
import { envName, isProd } from '../../../enviroments/.env';
import { LinkItem } from '../Root';
import { useQuery } from '@tanstack/react-query';
import { getUserPermissions } from '../../../API/misc-services';
import { spotlight } from '@mantine/spotlight';

interface HeaderResponsiveProps {
  links: LinkItem[];
}

export function GlobalNavComponent({ links }: HeaderResponsiveProps) {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const { token, onLogout } = useAuth();
  const [showSpotlightKBD, setShowSpotlightKBD] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);
  const { data: permissions } = useQuery({
    queryKey: ['getPermissions'],
    queryFn: getUserPermissions,
    enabled: false,
  });

  const userMenu = (
    <>
      <Menu position={'bottom'}>
        <Menu.Target>
          <div
            style={{ display: 'flex', marginLeft: '1em', cursor: 'pointer' }}
          >
            <div className={classes.userName}>{token?.name}</div>
            <IconUser style={{ cursor: 'pointer' }} size={30}></IconUser>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item leftSection={<IconLogout size={14} />} onClick={onLogout}>
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );

  const canAccess = (link: LinkItem) => {
    return (
      !link.permissions ||
      permissions?.data?.some((x) => link.permissions?.includes(x))
    );
  };

  const items = links.filter(canAccess).map((link) =>
    link.links ? (
      <Menu
        key={link.link}
        trigger="click"
        transitionProps={{ exitDuration: 0 }}
      >
        {/*<Menu.Target>*/}
        <NavLink
          style={{ padding: '0px' }}
          to={link.link}
          className={({ isActive }) =>
            isActive
              ? classNames(classes.linkActive, classes.link)
              : classes.link
          }
          onClick={(event) => event.preventDefault()}
        >
          <Menu.Target>
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                padding: '8px 12px',
              }}
            >
              <span style={{ paddingRight: '6px' }}>{link.label}</span>
              <IconChevronDown size={16} stroke={2.5} />
            </div>
          </Menu.Target>
        </NavLink>
        <Menu.Dropdown>
          {link.links.filter(canAccess).map((linkItem) => (
            <NavLink
              to={linkItem.link}
              key={linkItem.link}
              className={({ isActive }) =>
                isActive
                  ? classNames(classes.linkInner, classes.linkActive)
                  : classes.linkInner
              }
              onClick={() => {
                close();
              }}
            >
              <Menu.Item
                style={{
                  padding: '1em',
                  color: 'inherit',
                  backgroundColor: 'inherit',
                }}
              >
                {linkItem.label}
              </Menu.Item>
            </NavLink>
          ))}
        </Menu.Dropdown>
      </Menu>
    ) : (
      <NavLink
        end={link.end}
        key={link.link}
        to={link.link}
        className={({ isActive }) =>
          isActive ? classNames(classes.linkActive, classes.link) : classes.link
        }
        onClick={() => {
          close();
        }}
      >
        {link.label}
      </NavLink>
    ),
  );

  return (
    <nav className={classes.headerRoot}>
      <Container className={classes.header}>
        <div
          onClick={() => navigate('/')}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '1em',
          }}
        >
          <img src={LumaFlame} alt="luma-logo" />
          <h2>{isProd ? 'LUMA' : envName?.toUpperCase()}</h2>
        </div>

        {!token && (
          <ActionIcon
            variant="outline"
            color={colorScheme === 'dark' ? 'yellow' : 'blue'}
            onClick={toggleColorScheme}
            title="Toggle color scheme"
          >
            {colorScheme === 'dark' ? (
              <IconSun size={18} />
            ) : (
              <IconMoonStars size={18} />
            )}
          </ActionIcon>
        )}
        {token && (
          <div className={classes.links}>
            {items}
            <ActionIcon
              variant="outline"
              color={colorScheme === 'dark' ? 'yellow' : 'blue'}
              onClick={toggleColorScheme}
              title="Toggle color scheme"
            >
              {colorScheme === 'dark' ? (
                <IconSun size={18} />
              ) : (
                <IconMoonStars size={18} />
              )}
            </ActionIcon>

            <Popover
              width={70}
              position="bottom"
              withArrow
              shadow="md"
              opened={showSpotlightKBD}
            >
              <Popover.Target>
                <div
                  onMouseEnter={() => setShowSpotlightKBD(true)}
                  onMouseLeave={() => setShowSpotlightKBD(false)}
                >
                  <ActionIcon
                    variant="outline"
                    onClick={() => spotlight.open()}
                    title="Open Spotlight"
                  >
                    <IconSearch size={18} />
                  </ActionIcon>
                </div>
              </Popover.Target>
              <Popover.Dropdown
                style={{ pointerEvents: 'none', textAlign: 'center' }}
              >
                <Kbd size={'lg'}>/</Kbd>
              </Popover.Dropdown>
            </Popover>
            {userMenu}
          </div>
        )}
        {token && (
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          />
        )}

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
              <div
                style={{ cursor: 'pointer' }}
                className={classes.link}
                onClick={() => {
                  close();
                  onLogout();
                }}
              >
                Logout
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '1em',
                  margin: '0 1em 1em auto',
                  width: 'fit-content',
                }}
              >
                <ActionIcon
                  variant="outline"
                  color={colorScheme === 'dark' ? 'yellow' : 'blue'}
                  onClick={toggleColorScheme}
                  title="Toggle color scheme"
                >
                  {colorScheme === 'dark' ? (
                    <IconSun size={18} />
                  ) : (
                    <IconMoonStars size={18} />
                  )}
                </ActionIcon>
                <ActionIcon
                  variant="outline"
                  onClick={() => spotlight.open()}
                  title="Opet Spotlight"
                >
                  <IconSearch size={18} />
                </ActionIcon>
              </div>
            </Paper>
          )}
        </Transition>
      </Container>
    </nav>
  );
}
