import React from 'react';
import { minMax } from '../../../../product-schema/pdw-select-options';
import { isWeekend } from '../../../../utilities/utilities';
import {
  PFDatePicker,
  PFSelect,
} from '../../reusable-form-component/BaseFormComponents';
import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';

const WithLookbackInitialComponent = () => {
  return (
    <div>
      <h3>Lookback Initial</h3>
      {/*todo: make this a hint + style these components*/}
      {/*Trade date is assumed as the start of the lookback period*/}

      <PFDatePicker
        fieldPath={`productGeneral.lookbackInitialDate`}
        label="End Date of Lookback Period"
        excludeDate={isWeekend}
      />

      <PFSelect
        label="Valuation Type:"
        fieldPath={ancillaryFeaturesFields.lookbackInitialValuationType}
        data={minMax}
      />
    </div>
  );
};

export default WithLookbackInitialComponent;
