import React, { ForwardedRef, forwardRef, useContext, useEffect } from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { Button, Checkbox, Radio, Tooltip } from '@mantine/core';
import {
  CHProductStages,
  currencyCodes,
  IncompleteCategory,
  ProductCompletionStatus,
  ProductStages,
  ProductStatuses,
  ProtectionTypes,
  RegistrationTypes,
  TenorUnits,
  WrapperTypes,
} from '../../../../product-schema/pdw-select-options';
import { isWeekend, roundToNearest } from '../../../../utilities/utilities';
import {
  ExpandableNumberFieldset,
  PFNumberInput,
  PFRadio,
  PFSelect,
  PFTextInput,
} from '../../reusable-form-component/BaseFormComponents';
import { useQuery } from '@tanstack/react-query';
import {
  getCalculationAgents,
  getIssuers,
} from '../../../../API/product-maintenance.service';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import CusipEntry from './cusip-entry';
import IsinEntry from './isin-entry';
import { DateInput, DateTimePicker } from '@mantine/dates';
import {
  CoreDetailsFields,
  protectionFields,
} from '../../../../product-schema/pdw-field-subsets';
import { useParams } from 'react-router-dom';
import { useIsProductCalled } from '../../useIsProductCalled';
import { isFullProtection } from '../../product-form-mappings';
import Big from 'big.js';
import dayjs from 'dayjs';

const CoreProductDetails = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
    const urlParams = useParams();
    const productFromUrl = urlParams?.productId
      ? decodeURIComponent(urlParams?.productId)
      : undefined;
    const productIsCalled = useIsProductCalled(productFromUrl);

    const registrationTypeChange = (
      value: RegistrationTypes | null | string,
    ) => {
      if (isFullProtection(value)) {
        form.setFieldValue(protectionFields.downsideType, ProtectionTypes.FULL);
      } else if (
        form.values.productProtection.downsideType === ProtectionTypes.FULL
      ) {
        form.setFieldValue(protectionFields.downsideType, null);
      }
      form.setFieldValue(CoreDetailsFields.registrationType, value);
    };

    const wrapperTypeChange = (value: WrapperTypes | null | string) => {
      if (value === WrapperTypes.CD) {
        registrationTypeChange(RegistrationTypes.CD);
        // form.setFieldValue(CoreDetailsFields.registrationType, RegistrationTypes.CD);
      } else if (
        form.values.productGeneral.registrationType === RegistrationTypes.CD
      ) {
        registrationTypeChange(null);
      }
      form.setFieldValue(CoreDetailsFields.wrapperType, value);
    };
    // manually validate here since the initial validation doesn't validate because it happens before the enpoint fully returns
    useEffect(() => {
      if (productIsCalled) {
        form.validateField(CoreDetailsFields.status);
      }
    }, [productIsCalled]); // eslint-disable-line react-hooks/exhaustive-deps

    const { data: calculationAgents, isLoading: calculationAgentsLoading } =
      useQuery(['getCalculationAgents'], getCalculationAgents);
    const uniqueCalculationAgents = calculationAgents?.data
      ? [...Array.from(new Set([...(calculationAgents?.data || [])]))]
      : null;

    const { data: issuers, isLoading: issuersLoading } = useQuery(
      ['getIssuers'],
      getIssuers,
    );
    const uniqueIssuers = issuers?.data
      ? [...Array.from(new Set([...(issuers?.data || [])]))]
      : null;

    const statusOptions = () => {
      let options: ProductStatuses[] = [];
      switch (form.values.productGeneral.stage) {
        case ProductStages.NOT_TRADED: // fallthrough
        case ProductStages.POST_TRADE:
          options = [
            ProductStatuses.EXPIRED,
            ProductStatuses.RETIRED,
            ProductStatuses.LIVE,
            ProductStatuses.MATURED,
          ];
          if (form.values.productGeneral.status === ProductStatuses.CALLED) {
            options.unshift(ProductStatuses.CALLED);
          }
          break;
        case ProductStages.OPS_REVIEW:
          options = [
            ProductStatuses.REVIEW_REQUIRED,
            ProductStatuses.UPDATE_PRODUCT_DETAILS,
          ];
          break;
        case ProductStages.OFFER:
          options = [
            ProductStatuses.ACCEPTING_INDICATIONS,
            ProductStatuses.ACCEPTING_ORDERS,
            ProductStatuses.RESTRICTED,
            ProductStatuses.TRADED_NOT_SETTLED,
            ProductStatuses.TRADED_PENDING_DOCUMENTATION,
          ];
          break;
      }
      if (
        form.values.productGeneral.status === ProductStatuses.CALLED ||
        productIsCalled
      ) {
        return options.map((x) => ({
          value: x,
          label: x,
          disabled: x !== ProductStatuses.CALLED,
        }));
      }
      return options.map((x) => ({
        value: x,
        label: x,
        disabled: x === ProductStatuses.CALLED,
      }));
    };

    const handleStageChange = (e: any) => {
      form.setFieldValue(CoreDetailsFields.stage, e);
      form.setFieldValue(CoreDetailsFields.status, null);
    };

    const handleCompletionStatusChange = (e: any) => {
      form.setFieldValue(CoreDetailsFields.completionStatus, e);
      if (e !== ProductCompletionStatus.INCOMPLETE) {
        form.setFieldValue(CoreDetailsFields.incompleteCategory, null);
      }
    };

    const maturityDate = form.values.productGeneral.maturityDate;
    const issueDate = form.values.productGeneral.issueDate;
    const calculateTenor = () => {
      if (maturityDate && issueDate) {
        const diff = +new Big(
          dayjs(maturityDate).diff(dayjs(issueDate), 'year', true),
        );
        const roundedDiff = roundToNearest(diff, 0.25);
        form.setFieldValue(CoreDetailsFields.tenorFinal, roundedDiff);
        form.setFieldValue(CoreDetailsFields.tenorUnit, TenorUnits.Years);
      }
    };

    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFSelect
            label="Target Stage"
            onChange={handleStageChange}
            fieldPath={CoreDetailsFields.stage}
            data={[
              ...Object.values(ProductStages),
              ...Object.values(CHProductStages),
            ].map((x) => {
              return {
                value: x,
                label: x,
                disabled: Object.values(CHProductStages).some(
                  (value) => value === x,
                ),
              };
            })}
          />
          <PFSelect
            label="Target Status"
            fieldPath={CoreDetailsFields.status}
            searchable={false}
            placeholder={
              statusOptions().length === 0 ? 'Must Select Stage First' : ''
            }
            data={statusOptions()}
          />
          <Checkbox
            label={'Submit as QC Verified'}
            {...form.getInputProps('qcVerified', { type: 'checkbox' })}
          ></Checkbox>
          <PFSelect
            label="Completion Status"
            data={[...Object.values(ProductCompletionStatus)]}
            fieldPath={CoreDetailsFields.completionStatus}
            onChange={handleCompletionStatusChange}
          />
          {form?.values?.productGeneral?.completionStatus ===
            ProductCompletionStatus.INCOMPLETE && (
            <PFSelect
              label="Incomplete Category"
              data={[...Object.values(IncompleteCategory)]}
              fieldPath={CoreDetailsFields.incompleteCategory}
            />
          )}

          <CusipEntry />

          <IsinEntry />

          <PFTextInput
            label="Fundserv ID"
            fieldPath={CoreDetailsFields.fundservID}
          />
          <PFTextInput label="CINS" fieldPath={CoreDetailsFields.cins} />

          {uniqueIssuers && (
            <PFSelect
              searchable
              label="Issuer"
              fieldPath={CoreDetailsFields.issuer}
              data={uniqueIssuers}
            />
          )}
          {issuersLoading && (
            <PFSelect
              searchable
              label="Issuer"
              fieldPath={CoreDetailsFields.issuer}
              data={[]}
              readOnly={true}
              placeholder={'Loading Issuers'}
            />
          )}

          {!!uniqueCalculationAgents && (
            <PFSelect
              searchable
              label="Calculation Agent"
              data={uniqueCalculationAgents}
              fieldPath={CoreDetailsFields.calculationAgent}
            />
          )}
          {calculationAgentsLoading && (
            <PFSelect
              readOnly={true}
              label="Calculation Agent"
              data={[]}
              placeholder={'Loading Calc. Agents'}
              fieldPath={CoreDetailsFields.calculationAgent}
            />
          )}

          <PFSelect
            label="Registration Type"
            disabled={
              form.values.productGeneral.wrapperType === WrapperTypes.CD
            }
            data={[...Object.values(RegistrationTypes)]}
            onChange={registrationTypeChange}
            fieldPath={CoreDetailsFields.registrationType}
          />

          <PFSelect
            label="Wrapper Type"
            data={[...Object.values(WrapperTypes)]}
            fieldPath={CoreDetailsFields.wrapperType}
            onChange={wrapperTypeChange}
          />
        </div>
        <div className={'form-subsection'}>
          <Radio.Group
            label={'Settlement Type'}
            {...form.getInputProps(CoreDetailsFields.settlementType)}
          >
            <PFRadio
              fieldPath={CoreDetailsFields.settlementType}
              value={'Cash'}
              label={'Cash'}
            />
            <PFRadio
              fieldPath={CoreDetailsFields.settlementType}
              value={'Physical'}
              label={'Physical'}
            />
            <PFRadio
              fieldPath={CoreDetailsFields.settlementType}
              value={''}
              label={'None'}
            />
          </Radio.Group>
          <PFSelect
            label="Currency"
            data={currencyCodes}
            fieldPath={CoreDetailsFields.currency}
          />

          <Tooltip label={'Tenor is derived from Maturity and Issue Date'}>
            <Button
              disabled={!maturityDate || !issueDate}
              onClick={calculateTenor}
            >
              Derive Tenor
            </Button>
          </Tooltip>

          <ExpandableNumberFieldset
            primaryField={{
              property: CoreDetailsFields.tenorFinal,
              label: 'Tenor',
            }}
            subFields={[
              { property: CoreDetailsFields.tenorHigh, label: 'High Tenor' },
              { property: CoreDetailsFields.tenorLow, label: 'Low Tenor' },
              {
                property: CoreDetailsFields.tenorCurrent,
                label: 'Current Tenor',
              },
            ]}
            percentFormat={false}
            decimalScale={6}
          />

          <PFSelect
            label="Tenor Unit"
            data={[...Object.values(TenorUnits)]}
            fieldPath={CoreDetailsFields.tenorUnit}
          />

          {(!hiddenFieldsContext.hiddenFields.includes(
            CoreDetailsFields.productDeadlineTimestamp,
          ) ||
            hiddenFieldsContext.showAllHiddenFields) && (
            <div>
              <DateInput
                {...form.getInputProps(
                  CoreDetailsFields.productDeadlineTimestamp,
                )}
                clearable={true}
                firstDayOfWeek={0}
                valueFormat="MM/DD/YYYY HH:mm"
                label={'Product Deadline Timestamp'}
                placeholder="MM/DD/YYYY HH:mm"
                excludeDate={isWeekend}
              />
              {/*<DateTimePicker*/}
              {/*  {...form.getInputProps(CoreDetailsFields.productDeadlineTimestamp)}*/}
              {/*  clearable={true}*/}
              {/*  firstDayOfWeek={0}*/}
              {/*  valueFormat="MM/DD/YYYY HH:mm"*/}
              {/*  label={'Product Deadline Timestamp'}*/}
              {/*  placeholder="MM/DD/YYYY HH:mm"*/}
              {/*  excludeDate={isWeekend}*/}
              {/*/>*/}
            </div>
          )}

          <ExpandableNumberFieldset
            primaryField={{
              property: CoreDetailsFields.productNotionalFinal,
              label: 'Notional',
            }}
            subFields={[
              {
                property: CoreDetailsFields.productNotionalHigh,
                label: 'High Notional',
              },
              {
                property: CoreDetailsFields.productNotionalLow,
                label: 'Low Notional',
              },
              {
                property: CoreDetailsFields.productNotionalCurrent,
                label: 'Current Notional',
              },
            ]}
            percentFormat={false}
          />

          <PFNumberInput
            decimalScale={6}
            label="Est. Initial Value"
            fieldPath={CoreDetailsFields.estimatedInitialValue}
          />
          <PFNumberInput
            label="Face Value"
            fieldPath={CoreDetailsFields.productFaceValue}
          />

          <PFNumberInput
            label="Min. Trading Lot"
            fieldPath={'productGeneral.minimumTradingLot'}
          />
        </div>

        <div className={'form-subsection'}>
          <Radio.Group
            label={'Account Type'}
            {...form.getInputProps(CoreDetailsFields.accountType)}
          >
            <PFRadio
              fieldPath={CoreDetailsFields.accountType}
              value={'Advisory'}
              label={'Advisory'}
            />
            <PFRadio
              fieldPath={CoreDetailsFields.accountType}
              value={'Brokerage'}
              label={'Brokerage'}
            />
            <PFRadio
              fieldPath={CoreDetailsFields.accountType}
              value={''}
              label={'None'}
            />
          </Radio.Group>

          <PFTextInput
            label="Product Name"
            fieldPath={CoreDetailsFields.productName}
          />
          <PFTextInput
            label="Ext Structure Name"
            fieldPath={CoreDetailsFields.structureNameExternal}
          />
          <PFTextInput
            label="Ext Structure Name (short)"
            fieldPath={CoreDetailsFields.structureShortNameExternal}
          />
          <PFTextInput
            label="Ext Structure Name (long)"
            fieldPath={CoreDetailsFields.structureLongNameExternal}
          />
          {(!hiddenFieldsContext.hiddenFields.includes(
            CoreDetailsFields.productLaunchTimestamp,
          ) ||
            hiddenFieldsContext.showAllHiddenFields) && (
            <DateTimePicker
              {...form.getInputProps(CoreDetailsFields.productLaunchTimestamp)}
              clearable={true}
              firstDayOfWeek={0}
              valueFormat="DD/MM/YYYY HH:mm"
              label={'Product Launch Timestamp'}
              placeholder="MM/DD/YYYY HH:mm"
              excludeDate={isWeekend}
            />
          )}
          <PFNumberInput
            label="Fair Price"
            fieldPath={CoreDetailsFields.fairPriceFinal}
          ></PFNumberInput>

          <PFNumberInput
            label="Performance Strike"
            fieldPath={CoreDetailsFields.performanceStrike}
          ></PFNumberInput>

          <ExpandableNumberFieldset
            primaryField={{
              property: CoreDetailsFields.issuePriceFinal,
              label: 'Issue Price',
            }}
            subFields={[
              {
                property: CoreDetailsFields.issuePriceHigh,
                label: 'High Issue Price',
              },
              {
                property: CoreDetailsFields.issuePriceLow,
                label: 'Low Issue Price',
              },
              {
                property: CoreDetailsFields.issuePriceCurrent,
                label: 'Current Issue Price',
              },
            ]}
            percentFormat={false}
            decimalScale={6}
            rightSection={<>%</>}
          />
        </div>
      </div>
    );
  },
);

CoreProductDetails.displayName = 'CoreProductDetails';
export default CoreProductDetails;
