import React from 'react';

import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
// import { useQuery } from '@tanstack/react-query';
// import { getUserPermissions } from '../../API/misc-services';
// import { PRODUCT_EDIT_ROUTE, PRODUCT_ENTRY_ROUTE } from '../../Routes';

const ProtectedRouterOutlet = () => {
  const { token } = useAuth();
  const location = useLocation();
  // const {data: permissions} = useQuery(
  //   ['getPermissions'],
  //   getUserPermissions
  // );
  // console.log('perms/token', permissions?.data, token);
  // console.log('loc', location)
  if (!token) {
    // todo: this is maybe not necessary, but could be used for permissions
    console.log('protected router outlet navigation happening');
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  // if(location?.pathname === '/' + PRODUCT_ENTRY_ROUTE || location?.pathname.includes(PRODUCT_EDIT_ROUTE)
  // // todo: permission to restrict
  // ){
  // return <Navigate to="/" replace state={{ from: location }} />;
  // }
  return <Outlet />;
};

export default ProtectedRouterOutlet;
