import React, { ForwardedRef, forwardRef, useContext } from 'react';
import {
  BarrierCrossings,
  ProtectionTypes,
  putObservationFrequencies,
} from '../../../product-schema/pdw-select-options';
import ExpandableNumberFieldset from '../reusable-form-component/BaseFormComponents/ExpandableNumberFieldset';
import {
  PFNumberInput,
  PFSelect,
} from '../reusable-form-component/BaseFormComponents';
import { ProductFormHiddenFieldsContext } from '../entry-and-edit-contexts/HiddenFields-context';
import { isFieldHidden } from '../reusable-form-component/BaseFormComponents/PFNumberInput';
import {
  CoreDetailsFields,
  protectionFields,
} from '../../../product-schema/pdw-field-subsets';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { isFullProtection } from '../product-form-mappings';
import { get } from 'lodash';

const ProtectionDetailsFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
    const form = useProductFormContext();
    return (
      <div ref={ref} className={'form-section'}>
        <div className={'form-subsection'}>
          <PFSelect
            label="Protection Type"
            data={[...Object.values(ProtectionTypes)]}
            fieldPath={protectionFields.downsideType}
            disabled={isFullProtection(
              get(form.values, CoreDetailsFields.registrationType),
            )}
          />

          <PFSelect
            label="Put Observation Frequency"
            data={putObservationFrequencies}
            fieldPath={protectionFields.putObservationFrequency}
          />

          {!isFieldHidden(
            hiddenFieldsContext,
            protectionFields.principalBarrierLevelFinal,
          ) && (
            <>
              <ExpandableNumberFieldset
                primaryField={{
                  property: protectionFields.principalBarrierLevelFinal,
                  label: 'Principal Barrier Protection',
                }}
                subFields={[
                  {
                    property: protectionFields.principalBarrierLevelHigh,
                    label: 'High Principal Barrier Protection',
                  },
                  {
                    property: protectionFields.principalBarrierLevelLow,
                    label: 'Low Principal Barrier Protection',
                  },
                  {
                    property: protectionFields.principalBarrierLevelCurrent,
                    label: 'Current Principal Barrier Protection',
                  },
                ]}
              />

              <ExpandableNumberFieldset
                primaryField={{
                  property: protectionFields.principalBufferLevelFinal,
                  label: 'Principal Buffer Level',
                }}
                subFields={[
                  {
                    property: protectionFields.principalBufferLevelHigh,
                    label: 'High Principal Buffer Level',
                  },
                  {
                    property: protectionFields.principalBufferLevelLow,
                    label: 'Low Principal Buffer Level',
                  },
                  {
                    property: protectionFields.principalBufferLevelCurrent,
                    label: 'Current Principal Buffer Level',
                  },
                ]}
              />
            </>
          )}

          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="Protection Level"
            fieldPath={protectionFields.protectionLevel}
          ></PFNumberInput>
        </div>

        <div className={'form-subsection'}>
          <ExpandableNumberFieldset
            primaryField={{
              property: protectionFields.putLeverageFinal,
              label: 'Put Leverage',
            }}
            subFields={[
              {
                property: protectionFields.putLeverageHigh,
                label: 'High Put Leverage',
              },
              {
                property: protectionFields.putLeverageLow,
                label: 'Low Put Leverage',
              },
              {
                property: protectionFields.putLeverageCurrent,
                label: 'Current Put Leverage',
              },
            ]}
          />

          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="Put Strike"
            fieldPath={protectionFields.putStrikeFinal}
          ></PFNumberInput>

          <PFSelect
            label="Barrier Crossing"
            data={[...Object.values(BarrierCrossings)]}
            fieldPath={protectionFields.KIbarrierCrossing}
          />

          <PFSelect
            label="Final Crossing"
            data={[...Object.values(BarrierCrossings)]}
            fieldPath={protectionFields.finalFixingCrossing}
          />
        </div>

        <div className={'form-subsection'}>
          <ExpandableNumberFieldset
            primaryField={{
              property: protectionFields.capitalProtectionLevelFinal,
              label: 'Capital Protection Level',
            }}
            subFields={[
              {
                property: protectionFields.capitalProtectionLevelHigh,
                label: 'High Capital Protection Level',
              },
              {
                property: protectionFields.capitalProtectionLevelLow,
                label: 'Low Capital Protection Level',
              },
              {
                property: protectionFields.capitalProtectionLevelCurrent,
                label: 'Current Capital Protection Level',
              },
            ]}
          />

          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="One Star Level"
            fieldPath={protectionFields.oneStarLevel}
          ></PFNumberInput>
        </div>
      </div>
    );
  },
);
ProtectionDetailsFormComponent.displayName = 'ProtectionDetailsFormComponent';
export default ProtectionDetailsFormComponent;
