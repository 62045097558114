import classes from './productFormControls.module.scss';
import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import {
  Button,
  Stack,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { ProductFormHiddenFieldsContext } from '../entry-and-edit-contexts/HiddenFields-context';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { EditModeContext } from '../entry-and-edit-contexts/edit-mode-context';
import { getFrequencyNum } from '../../../utilities/utilities';
import {
  CHProductStages,
  GrowthTypes,
  ObservationFrequencies,
  ProductStages,
  ProductStatuses,
  ProtectionTypes,
} from '../../../product-schema/pdw-select-options';
import Big from 'big.js';
import { ProductFormDisabledContext } from '../entry-and-edit-contexts/disabled-fields-context';
import { SplitButton } from '../../../components/splitButton/SplitButton';
import {
  derivableFields,
  productGrowthFields,
  protectionFields,
} from '../../../product-schema/pdw-field-subsets';
import TemplateSelection from '../form-sections/Templates/TemplateSelection';
import { get } from 'lodash';
import { FormSectionProperties } from '../form-section.types';
import ProductMetaData from './ProductMetaData';
import { ProductFormTableOfContents } from './ProductFormTableOfContents';
import { IconArticle } from '@tabler/icons-react';

export interface ProductFormControlsProps {
  allExpanded: boolean;
  onExpandButtonClick: () => void;
  handleSoftResetProductEditForm?: () => void;
  handleNewEntryForm?: () => void;
  handleSoftResetProductForm?: () => void;
  handleValidateProduct: () => void;
  handleShowChanges?: () => void;
  handleShowJsonEdit?: () => void;
  handleShowProductMergeCompare?: () => void;
  handleProductFormSubmit: () => void;
  handleMoveActiveSectionsToTop: () => void;
  formSectionHandlers: any;
  formSectionAttributes: FormSectionProperties[];
  TimerComponent: ReactNode;
}

const ProductFormControls = ({
  allExpanded,
  onExpandButtonClick,
  handleSoftResetProductEditForm,
  handleNewEntryForm,
  handleProductFormSubmit,
  handleMoveActiveSectionsToTop,
  handleSoftResetProductForm,
  handleShowChanges,
  handleShowJsonEdit,
  handleShowProductMergeCompare,
  handleValidateProduct,
  formSectionHandlers,
  formSectionAttributes,
  TimerComponent,
}: ProductFormControlsProps) => {
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  const editMode = useContext(EditModeContext);
  const form = useProductFormContext();
  const isFormValid = useMemo(() => form.isValid(), [form.values]); // eslint-disable-line react-hooks/exhaustive-deps
  const onToggleShowAll = () => {
    hiddenFieldsContext.toggleShowAll();
  };

  const handleDeriveFields = () => {
    // Derive standard price
    if (
      form.values.productGeneral.accountType &&
      form.values.productGeneral.accountType === 'Advisory'
    ) {
      form.setFieldValue(derivableFields.standardPrice, 100);
    }

    if (
      form.values.productProtection?.downsideType === ProtectionTypes.BUFFER ||
      form.values.productProtection?.downsideType ===
        ProtectionTypes.GEARED_BUFFER
    ) {
      const bufferLevel = get(
        form.values,
        protectionFields.principalBufferLevelFinal,
      );
      if (bufferLevel) {
        const putValue = get(form.values, productGrowthFields.bearish)
          ? +new Big(100).plus(bufferLevel)
          : +new Big(100).minus(bufferLevel);
        form.setFieldValue(derivableFields.putStrikeFinal, putValue);
      }
    } else if (
      form.values.productProtection?.downsideType === ProtectionTypes.BARRIER ||
      form.values.productProtection?.downsideType ===
        ProtectionTypes.GEARED_BARRIER
    ) {
      form.setFieldValue(derivableFields.putStrikeFinal, 100);
    }

    // Derive underlier cap level values:
    // final
    if (
      form.values.productGrowth.maximumReturnFinal &&
      (form.values.productGrowth.upsideParticipationRateFinal ||
        form.values.productGrowth.upsideParticipationAboveDigitalReturnFinal)
    ) {
      const finalCapValue = deriveCapLevel(
        form.values.productGrowth.maximumReturnFinal,
        form.values.productGrowth.upsideParticipationRateFinal,
        form.values.productGrowth.upsideParticipationAboveDigitalReturnFinal,
      );
      form.setFieldValue(
        derivableFields.underlierReturnCapLevelFinal,
        finalCapValue,
      );
    }

    // low
    if (
      form.values.productGrowth.maximumReturnLow &&
      (form.values.productGrowth.upsideParticipationRateLow ||
        form.values.productGrowth.upsideParticipationAboveDigitalReturnLow)
    ) {
      const lowCapValue = deriveCapLevel(
        form.values.productGrowth.maximumReturnLow,
        form.values.productGrowth.upsideParticipationRateLow,
        form.values.productGrowth.upsideParticipationAboveDigitalReturnLow,
      );
      form.setFieldValue(
        derivableFields.underlierReturnCapLevelLow,
        lowCapValue,
      );
    }

    // High
    if (
      form.values.productGrowth.maximumReturnHigh &&
      (form.values.productGrowth.upsideParticipationRateHigh ||
        form.values.productGrowth.upsideParticipationAboveDigitalReturnHigh)
    ) {
      const highCapValue = deriveCapLevel(
        form.values.productGrowth.maximumReturnHigh,
        form.values.productGrowth.upsideParticipationRateHigh,
        form.values.productGrowth.upsideParticipationAboveDigitalReturnHigh,
      );
      form.setFieldValue(
        derivableFields.underlierReturnCapLevelHigh,
        highCapValue,
      );
    }

    // derive protection level
    if (form.values.productProtection.downsideType) {
      switch (form.values.productProtection.downsideType) {
        case ProtectionTypes.FULL:
          form.setFieldValue(derivableFields.capitalProtectionLevelFinal, 100);
          form.setFieldValue(derivableFields.protectionLevel, 100);
          break;
        case ProtectionTypes.PARTIAL:
          if (form.values.productProtection.protectionLevel) {
            form.setFieldValue(
              derivableFields.capitalProtectionLevelFinal,
              form.values.productProtection.protectionLevel,
            );
          }
          break;
        case ProtectionTypes.BARRIER: // fallThrough
        case ProtectionTypes.GEARED_BARRIER:
          if (form.values.productProtection.principalBarrierLevelFinal) {
            form.setFieldValue(
              derivableFields.protectionLevel,
              +new Big(100).minus(
                form.values.productProtection.principalBarrierLevelFinal,
              ),
            );
          }
          break;
        case ProtectionTypes.NO_PRINCIPAL_PROTECTION:
          form.setFieldValue(derivableFields.capitalProtectionLevelFinal, 0);
          form.setFieldValue(derivableFields.protectionLevel, 0);
          break;
        case ProtectionTypes.BUFFER: // fallthrough
        case ProtectionTypes.GEARED_BUFFER:
          if (form.values.productProtection.principalBufferLevelFinal) {
            form.setFieldValue(
              derivableFields.protectionLevel,
              form.values.productProtection.principalBufferLevelFinal,
            );
          }
          break;
      }

      if (
        [
          GrowthTypes.ATM_DIGITAL,
          GrowthTypes.CAPPED_ATM_DIGITAL,
          GrowthTypes.UNCAPPED_ATM_DIGITAL,
        ].includes(form.values.productGrowth.growthType)
      ) {
        form.setFieldValue(derivableFields.digitalReturnBarrierFinal, 100);
      }

      if (
        [
          GrowthTypes.UNCAPPED_ATM_DIGITAL,
          GrowthTypes.UNCAPPED_ITM_DIGITAL,
          GrowthTypes.UNCAPPED_OTM_DIGITAL,
        ].includes(form.values.productGrowth.growthType)
      ) {
        form.setFieldValue(
          derivableFields.lowerCalleStrikeFinal,
          +new Big(100).plus(form.values.productGrowth.digitalReturnFinal),
        );
      } else if (form.values.productGrowth.growthType) {
        form.setFieldValue(derivableFields.lowerCalleStrikeFinal, 100);
      }
    }

    // Derive coupon rate per annum:
    if (form.values.productYield.paymentFrequency) {
      // derive perAnnum based on perPeriod
      if (
        form.values.productYield.paymentRatePerPeriodFinal ||
        form.values.productYield.paymentRatePerPeriodLow ||
        form.values.productYield.paymentRatePerPeriodHigh
      ) {
        if (form.values.productYield.paymentRatePerPeriodFinal) {
          form.setFieldValue(
            derivableFields.paymentRatePerAnnumFinal,
            deriveCouponRate(
              form.values.productYield.paymentFrequency,
              form.values.productYield.paymentRatePerPeriodFinal,
            ),
          );
        }
        if (form.values.productYield.paymentRatePerPeriodLow) {
          form.setFieldValue(
            derivableFields.paymentRatePerAnnumLow,
            deriveCouponRate(
              form.values.productYield.paymentFrequency,
              form.values.productYield.paymentRatePerPeriodLow,
            ),
          );
        }
        if (form.values.productYield.paymentRatePerPeriodHigh) {
          form.setFieldValue(
            derivableFields.paymentRatePerAnnumHigh,
            deriveCouponRate(
              form.values.productYield.paymentFrequency,
              form.values.productYield.paymentRatePerPeriodHigh,
            ),
          );
        }
      } else if (
        form.values.productYield.paymentRatePerAnnumHigh ||
        form.values.productYield.paymentRatePerAnnumLow ||
        form.values.productYield.paymentRatePerAnnumFinal
      ) {
        // derive perPeriod based on perAnnum
        if (form.values.productYield.paymentRatePerAnnumFinal) {
          form.setFieldValue(
            derivableFields.paymentRatePerPeriodFinal,
            deriveCouponPerPeriod(
              form.values.productYield.paymentFrequency,
              form.values.productYield.paymentRatePerAnnumFinal,
            ),
          );
        }
        if (form.values.productYield.paymentRatePerAnnumLow) {
          form.setFieldValue(
            derivableFields.paymentRatePerPeriodLow,
            deriveCouponPerPeriod(
              form.values.productYield.paymentFrequency,
              form.values.productYield.paymentRatePerAnnumLow,
            ),
          );
        }
        if (form.values.productYield.paymentRatePerAnnumHigh) {
          form.setFieldValue(
            derivableFields.paymentRatePerPeriodHigh,
            deriveCouponPerPeriod(
              form.values.productYield.paymentFrequency,
              form.values.productYield.paymentRatePerAnnumHigh,
            ),
          );
        }
      }
    }

    // Derive MIN coupon rate per annum:
    if (form.values.productYield.minCouponPayPeriod) {
      if (form.values.productYield.minPaymentRatePerPeriodFinal) {
        form.setFieldValue(
          derivableFields.minPaymentRatePerAnnumFinal,
          deriveCouponRate(
            form.values.productYield.paymentFrequency,
            form.values.productYield.minPaymentRatePerPeriodFinal,
          ),
        );
      }
      if (form.values.productYield.minPaymentRatePerPeriodLow) {
        form.setFieldValue(
          derivableFields.minPaymentRatePerAnnumLow,
          deriveCouponRate(
            form.values.productYield.paymentFrequency,
            form.values.productYield.minPaymentRatePerPeriodLow,
          ),
        );
      }
      if (form.values.productYield.minPaymentRatePerPeriodHigh) {
        form.setFieldValue(
          derivableFields.minPaymentRatePerAnnumHigh,
          deriveCouponRate(
            form.values.productYield.paymentFrequency,
            form.values.productYield.minPaymentRatePerPeriodHigh,
          ),
        );
      }
    }
  };

  const logErrors = () => {
    console.log('errors', form.errors);
  };

  const deriveCapLevel = (
    maxReturn: number,
    upsideParticipationRate: number | null | undefined,
    upsideParticipationAboveDigitalReturn: number | null | undefined,
  ) => {
    if (
      maxReturn &&
      (upsideParticipationRate || upsideParticipationAboveDigitalReturn)
    ) {
      // @ts-ignore // even though we null check typescript is mad
      const participationRate: number = upsideParticipationRate
        ? upsideParticipationRate
        : upsideParticipationAboveDigitalReturn;
      // return +(((new Big(maxReturn).minus(1)).div(participationRate)).plus(1).round(6));
      return +new Big(maxReturn).div(participationRate).times(100).round(6);
    }
  };

  const deriveCouponRate = (
    paymentFrequency: ObservationFrequencies,
    paymentRatePerPeriod: number,
  ) => {
    const paymentFrequencyNum = getFrequencyNum(paymentFrequency);
    return +new Big(paymentFrequencyNum).times(paymentRatePerPeriod).round(6);
  };

  const deriveCouponPerPeriod = (
    paymentFrequency: ObservationFrequencies,
    paymentRatePerAnnum: number,
  ) => {
    const paymentFrequencyNum = getFrequencyNum(paymentFrequency);
    return +new Big(paymentRatePerAnnum).div(paymentFrequencyNum).round(6);
  };

  const disabledFormContext = useContext(ProductFormDisabledContext);
  const checkDisableForm = () => {
    if (
      [
        { stage: CHProductStages.QUOTE },
        { stage: CHProductStages.QUOTE },
        { stage: CHProductStages.QUOTE },
        { stage: CHProductStages.QUOTE },
        { stage: CHProductStages.REQUEST },
        { stage: CHProductStages.REQUEST },
        { stage: CHProductStages.CONFIRM_LOCK },
        { stage: ProductStages.OFFER, status: ProductStatuses.OFFER },
      ].some(
        (x) =>
          x.stage === form.values.productGeneral.stage &&
          (x.status ? x.status === form.values.productGeneral.status : true),
      )
    ) {
      disabledFormContext.setFormDisabledInfo({
        formIsDisabled: true,
      });
    } else {
      disabledFormContext.setFormDisabledInfo({
        formIsDisabled: false,
      });
    }
  };

  useEffect(() => {
    checkDisableForm();
  }, [form.values.productGeneral.status, form.values.productGeneral.stage]); // eslint-disable-line react-hooks/exhaustive-deps

  const formIsDirty = useMemo(() => form.isDirty(), [form]);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.containerScrollSection}>
          <div>
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                marginBottom: '1rem',
                paddingBottom: '1rem',
                borderBottom: '1px solid black',
              }}
            >
              <Button
                variant="primary"
                type={'button'}
                onClick={
                  editMode
                    ? handleSoftResetProductEditForm
                    : handleSoftResetProductForm
                }
              >
                Undo Changes
              </Button>
              {editMode && (
                <Button
                  variant={'secondary'}
                  style={
                    formIsDirty
                      ? {
                          color: theme.colors.blue[8],
                          border: `1px solid ${theme.colors.blue[8]}`,
                          backgroundColor:
                            colorScheme === 'dark'
                              ? theme.colors.gray[2]
                              : 'white',
                        }
                      : {}
                  }
                  type={'button'}
                  onClick={handleShowChanges}
                >
                  Show Changes
                  {formIsDirty && (
                    <span style={{ marginLeft: '0.5rem' }}>
                      <IconArticle size={'18'} />
                    </span>
                  )}
                </Button>
              )}
              {editMode && handleShowProductMergeCompare && (
                <Button
                  variant={'secondary'}
                  type={'button'}
                  onClick={handleShowProductMergeCompare}
                >
                  Compare Edit
                </Button>
              )}
              {!editMode && (
                <Button
                  variant={'secondary'}
                  type={'button'}
                  onClick={handleNewEntryForm}
                >
                  Hard Clear
                </Button>
              )}
            </div>
            <ProductFormTableOfContents
              {...{
                formSectionAttributes,
                formSectionHandlers,
                onExpandButtonClick,
                allExpanded,
                handleMoveActiveSectionsToTop,
              }}
            />
            <ProductMetaData />
          </div>
          <div className={classes.buttonSection}>
            {/*// todo: pass function down for this*/}

            <button type="button" onClick={logErrors}>
              Log Errors
            </button>
            <TemplateSelection formSectionHandlers={formSectionHandlers} />
            <div className={classes.primaryFooterButtons}>
              {hiddenFieldsContext.hiddenFields?.length > 0 && (
                <Button
                  variant={'secondary'}
                  type={'button'}
                  onClick={onToggleShowAll}
                >
                  {hiddenFieldsContext.showAllHiddenFields
                    ? 'Hide Fields'
                    : 'Show All Fields'}{' '}
                </Button>
              )}
              <Tooltip label={'Derive fields from already entered fields'}>
                <Button
                  variant="primary"
                  type={'button'}
                  onClick={handleDeriveFields}
                >
                  Derive Fields
                </Button>
              </Tooltip>

              {/*this disabled button allows us to prevent enter key from attempting to submit within the form!*/}
              <button
                type="submit"
                disabled
                style={{ display: 'none' }}
                aria-hidden="true"
              ></button>
              <Tooltip
                label={
                  'Manually changing JSON could lead to invalid data. Proceed with caution.'
                }
              >
                <Button
                  variant="primary"
                  type={'button'}
                  onClick={handleShowJsonEdit}
                >
                  Edit JSON
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <Stack>
          <div style={{ marginLeft: 'auto', paddingRight: '2rem' }}>
            {TimerComponent}
          </div>
          <div className={classes.submitSection}>
            <Button
              variant={'secondary'}
              type={'button'}
              onClick={handleValidateProduct}
            >
              Validate Form
            </Button>
            <SplitButton
              primaryButton={{
                label: 'Submit',
                fn: handleProductFormSubmit,
                disabled: !isFormValid,
              }}
              secondaryButtons={[
                {
                  label: 'Submit with errors',
                  fn: handleProductFormSubmit,
                },
              ]}
            ></SplitButton>
          </div>
        </Stack>
      </div>
    </>
  );
};

// todo: we can make this mobile responsive similar to how we did in globalNav
export default ProductFormControls;
