import classes from './editModeEntry.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Button, TextInput } from '@mantine/core';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { useGoToCopyProductEntry } from '../useGoToCopyProductEntry';

const EditModeEntry = () => {
  const ref = useRef<any>();
  const navigate = useNavigate();
  const { goToCopyProductEntry } = useGoToCopyProductEntry();
  const [productIdForEdit, setProductIdForEdit] = useState<string>('');
  const urlParams = useParams();
  const urlProductId = urlParams?.productId
    ? decodeURIComponent(urlParams?.productId)
    : undefined;

  useEffect(() => {
    if (!urlProductId) {
      ref?.current?.focus();
    }
  }, [urlProductId]);

  useEffect(() => {
    if (urlProductId && typeof urlProductId == 'string') {
      setProductIdForEdit(urlProductId);
    }
  }, [urlProductId]);

  const onSearchFieldKey = (e: any) => {
    if (e.key === 'Enter') {
      onProductIdSearch();
    }
  };

  const onProductIdSearch = () => {
    if (productHasChanged()) {
      navigate(`${encodeURIComponent(productIdForEdit)}`);
    }
  };

  const productHasChanged = () => {
    const path = window.location.pathname.split('/');
    return path[path.length - 1] !== productIdForEdit;
  };
  const onProductSearchChange = (e: any) => {
    setProductIdForEdit(e.target.value);
  };
  return (
    <>
      <div className={classes.entryAndEditHeader}>
        <h2 className={classes.title}>Product Edit</h2>
        <div className={classes.productSearchContainer}>
          <TextInput
            value={productIdForEdit}
            ref={ref}
            placeholder={'Search CUSIP or ISIN'}
            onChange={onProductSearchChange}
            onKeyPress={onSearchFieldKey}
          ></TextInput>
          <Button
            disabled={!productHasChanged()}
            type={'button'}
            onClick={onProductIdSearch}
          >
            Search Product
          </Button>
          <Button
            variant="primary"
            disabled={!urlProductId}
            onClick={() => goToCopyProductEntry(urlProductId)}
          >
            Copy To Entry
          </Button>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default EditModeEntry;
