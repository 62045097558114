import { useProductFormContext } from '../../../entry-and-edit-contexts/product-form-context';
import { ArrayNumberItem } from '../../../../../components/ArrayNumberItem';
import { isFieldDisabled, isWeekend } from '../../../../../utilities/utilities';
import { useProductFormDisabled } from '../../../entry-and-edit-contexts/disabled-fields-context';
import {
  PFDatePicker,
  PFNumberInput,
} from '../../../reusable-form-component/BaseFormComponents';
import { IconTrash } from '@tabler/icons-react';
import AutoAnimateChildren from '../../../../../components/AutoAnimateChildren';
import { Button } from '@mantine/core';

const RateCapsComponent = () => {
  const form = useProductFormContext();
  const disabledContext = useProductFormDisabled();
  const disableFields = isFieldDisabled(disabledContext, 'productCln.rateCap');
  const addLeverageFactor = () => {
    form.insertListItem(
      'productCln.rateCap',
      {
        rateCap: undefined,
        rateCapStartDate: undefined,
        rateCapEndDate: undefined,
      },
      0,
    );
  };
  const removeLeverageFactor = (index: number) => {
    form.removeListItem('productCln.rateCap', index);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: '1em',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: 0 }}>Rate Caps </h3>
        <Button
          variant="primary"
          type={'button'}
          disabled={disableFields}
          onClick={addLeverageFactor}
        >
          Add Rate Cap
        </Button>
      </div>
      <AutoAnimateChildren>
        {form.values.productCln.rateCap?.map((rateCap, index) => (
          <div className={'array-entry-section-container'} key={index}>
            <div className={'array-entry-section'}>
              <ArrayNumberItem>{index + 1}.</ArrayNumberItem>

              <PFNumberInput
                disabled={disableFields}
                decimalScale={6}
                rightSection={<div>%</div>}
                label="Rate Cap"
                fieldPath={`productCln.rateCap.${index}.rateCap`}
              ></PFNumberInput>

              <PFDatePicker
                label="Start Date"
                disabled={disableFields}
                excludeDate={isWeekend}
                fieldPath={`productCln.rateCap.${index}.rateCapStartDate`}
              />

              <PFDatePicker
                label="End Date"
                disabled={disableFields}
                excludeDate={isWeekend}
                fieldPath={`productCln.rateCap.${index}.rateCapEndDate`}
              />

              <Button
                variant={'secondary-small'}
                type={'button'}
                disabled={disableFields}
                onClick={() => removeLeverageFactor(index)}
              >
                <IconTrash size={18}></IconTrash>
              </Button>
            </div>
          </div>
        ))}
      </AutoAnimateChildren>
    </>
  );
};

export default RateCapsComponent;
