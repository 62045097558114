import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFinalReviewQueue } from '../../API/product-maintenance.service';
import { AgGridReact } from 'ag-grid-react';
import { Group, Stack, TextInput, useMantineColorScheme } from '@mantine/core';
import { ColDef, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { PRODUCT_EDIT_ROUTE } from '../../Routes';
import { IconSearch } from '@tabler/icons-react';
import DocumentLinkRenderer from '../../utilities/cell-renderers/DocumentLinkRenderer';

const FinalReviewQueue = () => {
  const gridRef = useRef<any>();
  const { data: productsInReview, isError: errorLoadingProducts } = useQuery(
    ['getFinalReviewQueue'],
    getFinalReviewQueue,
  );
  const { colorScheme } = useMantineColorScheme();
  const [quickFilterText, setQuickFilterText] = useState<string>('');
  const [rowCount, setRowCount] = useState();
  const rowData = productsInReview ? productsInReview.data?.content : null;
  const defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
  };

  const handleQuickFilterTextChange = (value: string) => {
    setQuickFilterText(value);
  };

  const displayRows = gridRef?.current?.api?.getDisplayedRowCount();

  const updateRowCount = useCallback(() => {
    const count = gridRef?.current?.api?.getDisplayedRowCount();
    if (count != null) {
      setRowCount(gridRef?.current?.api?.getDisplayedRowCount());
    }
  }, []);

  useEffect(() => {
    updateRowCount();
  }, [quickFilterText, displayRows, updateRowCount]);

  const columnDefs: ColDef[] = [
    {
      colId: 'cusip',
      headerName: 'CUSIP/ISIN',
      width: 150,
      valueGetter: (params) =>
        params.data.product.productGeneral.cusip ||
        params.data.product.productGeneral.isin,
      cellRenderer: function (params: ICellRendererParams) {
        return (
          <Link to={`/${PRODUCT_EDIT_ROUTE}/${params.value}`}>
            {params.value}
          </Link>
        );
      },
    },
    {
      colId: 'structureType',
      field: 'product.productGeneral.structureNameInternal',
      headerName: 'Structure Type',
      flex: 1,
    },
    {
      colId: 'tradeDate',
      field: 'product.productGeneral.tradeDate',
      headerName: 'Trade Date',
    },
    {
      colId: 'productDocuments',
      cellStyle: { overflow: 'visible' },
      autoHeight: true,
      field: 'productDocuments',
      sortable: false,
      headerName: 'Documents',
      cellRenderer: DocumentLinkRenderer,
      cellDataType: false,
    },
  ];

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      updateRowCount();
    },
    [updateRowCount],
  );

  return (
    //   --global-header-height == 50px
    <Stack gap={0} style={{ height: 'calc(100vh - 50px)', padding: '1rem' }}>
      <h1>Update Final Values</h1>
      <Group>
        {rowCount != null && (
          <div style={{ marginLeft: '1rem' }}>{rowCount} results</div>
        )}

        <TextInput
          style={{ marginLeft: '3em', width: '20em' }}
          placeholder="Search"
          value={quickFilterText}
          leftSection={<IconSearch size={14} />}
          onChange={(e) => handleQuickFilterTextChange(e.target.value)}
        />
      </Group>

      {errorLoadingProducts ? (
        <div> Error loading data</div>
      ) : (
        <div
          className={
            colorScheme === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz'
          }
          style={{
            minHeight: '50rem',
            flexGrow: 1,
            width: '100%',
            margin: '1em auto',
          }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'right',
                },
              ],
            }}
            quickFilterText={quickFilterText}
          ></AgGridReact>
        </div>
      )}
    </Stack>
  );
};

export default FinalReviewQueue;
