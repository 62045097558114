import { isSameDay } from 'date-fns';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import {
  basketTypeOptions,
  BasketTypes,
  initialFixingOptions,
  UnderlierSources,
} from '../../../../product-schema/pdw-select-options';
import Big from 'big.js';
import {
  createAccurateDateWithoutTime,
  formatDate,
  isFieldDisabled,
  isWeekend,
} from '../../../../utilities/utilities';
import {
  PFDatePicker,
  PFNumberInput,
  PFSelect,
  PFTextInput,
} from '../../reusable-form-component/BaseFormComponents';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import UnderlierTypeahead from './UnderlierTypeahead';
import { ArrayNumberItem } from '../../../../components/ArrayNumberItem';
import { IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import AutoAnimateChildren from '../../../../components/AutoAnimateChildren';
import { underlierFields } from '../../../../product-schema/pdw-field-subsets';
import { Badge, Button, Text, Tooltip } from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getUnderlier,
  getUnderlierPrices,
  GetUnderlierPricesRequest,
} from '../../../../API/market-data.service';
import { UnderlierFormSchema } from '../../../../product-schema/product.schema';
import { useDidUpdate } from '@mantine/hooks';

const UnderlierFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    const disabledContext = useProductFormDisabled();
    const disableFields = isFieldDisabled(
      disabledContext,
      underlierFields.underlierList,
    );
    const [updatedPriceIndexes, setUpdatedPriceIndexes] = useState<number[]>(
      [],
    );

    const getRicsAndBloombergTickersFromUnderlierForm = () => {
      const rics: string[] = [];
      const bloombergTickers: string[] = [];
      for (const underlier of form.values?.productGeneral?.underlierList ||
        []) {
        if (!underlier.underlierSymbol) {
          continue;
        }
        if (underlier.underlierSource === UnderlierSources.RIC) {
          rics.push(underlier.underlierSymbol);
        } else {
          bloombergTickers.push(underlier.underlierSymbol);
        }
      }
      return { rics, bloombergTickers };
    };

    const findFullDataUnderlier = (underlier: UnderlierFormSchema) => {
      if (underlier.underlierSource === UnderlierSources.RIC) {
        return fullUnderlierModels?.data?.find(
          (x) => x.ric === underlier.underlierSymbol,
        );
      } else {
        return fullUnderlierModels?.data?.find(
          (x) => x.bloombergTicker === underlier.underlierSymbol,
        );
      }
    };

    const { data: fullUnderlierModels } = useQuery({
      queryKey: [
        'underlierList',
        form.values?.productGeneral?.underlierList
          ?.map((x: any) => x.underlierSymbol)
          ?.join(',') || 'empty',
      ],
      queryFn: () =>
        getUnderlier(getRicsAndBloombergTickersFromUnderlierForm()),
      enabled:
        (form.values?.productGeneral?.underlierList &&
          form.values?.productGeneral?.underlierList?.length > 0) ||
        false,
    });

    const getUnderlierPricesPayload = (): GetUnderlierPricesRequest | null => {
      let startDate: Date | null = null;
      let endDate: Date | null = null;
      const underlierList = form.values?.productGeneral?.underlierList?.filter(
        (x) => x.underlierStrikeDate,
      );
      if (!underlierList || underlierList.length === 0) {
        return null;
      }
      for (const underlier of underlierList) {
        if (underlier.underlierStrikeDate == null) {
          continue;
        }
        if (startDate == null || startDate > underlier.underlierStrikeDate) {
          startDate = underlier.underlierStrikeDate;
        }
        if (endDate == null || endDate < underlier.underlierStrikeDate) {
          endDate = underlier.underlierStrikeDate;
        }
      }
      return {
        bloombergTickers:
          getRicsAndBloombergTickersFromUnderlierForm().bloombergTickers,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        priceType: 'FINAL',
      };
    };
    //

    const onGetPricesClick = () => {
      setUpdatedPriceIndexes([]);
      const payload = getUnderlierPricesPayload();
      if (
        payload == null ||
        payload?.startDate == null ||
        payload.endDate == null
      ) {
        notifications.show({
          id: 'no-underlier-dates-selected',
          title: `Error Getting Pricing`,
          message: 'Please select dates for underliers you want pricing for',
          loading: false,
          color: 'red',
          withCloseButton: true,
          autoClose: 10000,
        });
      } else {
        notifications.hide('no-underlier-dates-selected');
        notifications.show({
          id: 'underlier-prices-update',
          title: `Fetching underlier Prices`,
          message:
            'Fetching prices for the underliers with strike date entered',
          loading: true,
          autoClose: false,
          withCloseButton: false,
          color: 'blue',
        });
        getPrices.mutate(payload);
      }
    };

    const getPrices = useMutation(
      (payload: GetUnderlierPricesRequest) => getUnderlierPrices(payload),
      {
        onSuccess: (res) => {
          const updatedIndexes = [];
          const underlierList = form.values?.productGeneral?.underlierList;
          if (!underlierList || underlierList.length === 0) {
            return;
          }
          for (const pricing of res.data) {
            for (const [index, underlier] of underlierList.entries()) {
              if (
                underlier.underlierStrikeDate == null ||
                pricing.metadata.bloombergTicker !== underlier.underlierSymbol
              ) {
                continue;
              }
              const payloadDate = new Date(pricing.marketDateTimestamp);
              const underlierDate = createAccurateDateWithoutTime(
                formatDate(underlier.underlierStrikeDate),
              );
              if (payloadDate && underlierDate) {
                if (isSameDay(payloadDate, underlierDate)) {
                  updatedIndexes.push(index);
                }
              }

              if (
                payloadDate &&
                underlierDate &&
                isSameDay(payloadDate, underlierDate)
              ) {
                form.setFieldValue(
                  `${underlierFields.underlierList}.${index}.underlierLevel`,
                  pricing.price,
                );
              }
            }
          }
          setUpdatedPriceIndexes(updatedIndexes);
          notifications.update({
            id: 'underlier-prices-update',
            title: `Success`,
            message: 'Underlier strike prices successfully updated',
            loading: false,
            withCloseButton: true,
            color: 'green',
            autoClose: 10000,
          });
        },
        onError: () => {
          notifications.update({
            id: 'underlier-prices-update',
            title: `Error Getting Pricing`,
            message: 'Unable to get underlier prices at this time',
            loading: false,
            withCloseButton: true,
            color: 'red',
            autoClose: 10000,
          });
        },
      },
    );

    useDidUpdate(() => {
      if (
        !form.values?.productGeneral?.underlierList ||
        form.values?.productGeneral?.underlierList?.length === 0
      ) {
        form.setFieldValue(underlierFields.basketType, null);
        return;
      }
      if (form.values?.productGeneral?.underlierList?.length < 2) {
        // needs to check if it was 2 because the length update happens outside of this cycle.
        form.setFieldValue(underlierFields.basketType, BasketTypes.SINGLE);
      } else if (form.values?.productGeneral?.underlierList?.length >= 2) {
        if (form.values.productGeneral.basketType === BasketTypes.SINGLE) {
          form.setFieldValue(underlierFields.basketType, null);
        }
      }
      if (form.values.productGeneral.basketType === BasketTypes.EQUAL) {
        equalizeUnderlierWeights();
      }
    }, [form.values.productGeneral.underlierList?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectedUnderliers =
      form.values?.productGeneral?.underlierList?.reduce(
        (prev, current): any => [...prev, current.underlierSymbol],
        [],
      ) || [];
    const underlierSelected = (underlier: any) => {
      // TODO: add in entry for underlier rank
      form.insertListItem(underlierFields.underlierList, {
        underlierName: underlier.underlierName,
        currency: underlier.currency,
        exchangeCode: undefined,
        underlierWeight: undefined,
        underlierSource:
          underlier.bloombergTicker != null || underlier.ric == null
            ? UnderlierSources.Bloomberg
            : UnderlierSources.RIC,
        underlierLevel: undefined,
        underlierSymbol: underlier.bloombergTicker || underlier.ric,
        dividendYield: undefined,
        underlierStrikeDate: undefined,
        initialFixing: undefined,
      });
    };

    const removeUnderlier = (index: number) => {
      form.removeListItem(underlierFields.underlierList, index);
      form.clearFieldError(underlierFields.basketType);
      // TODO: remove entry for underlier rank
    };

    const underlierSourceChanged = (index: number, source: string | null) => {
      form.setFieldValue(
        `${underlierFields.underlierList}.${index}.underlierSource`,
        source,
      );
      const oldSource =
        source === UnderlierSources.RIC ? 'bloombergTicker' : 'ric';
      const newTicker = fullUnderlierModels?.data?.find((x) => {
        if (oldSource === 'bloombergTicker') {
          return (
            x.bloombergTicker ===
            form.values.productGeneral.underlierList?.[index]?.underlierSymbol
          );
        } else {
          return (
            x.ric ===
            form.values.productGeneral.underlierList?.[index]?.underlierSymbol
          );
        }
      });
      const newSymbol =
        source === UnderlierSources.RIC
          ? newTicker?.ric
          : newTicker?.bloombergTicker;
      form.setFieldValue(
        `${underlierFields.underlierList}.${index}.underlierSymbol`,
        newSymbol,
      );
    };

    const isRankWeighted =
      form.values.productGeneral.basketType === BasketTypes.RANK_WEIGHTED;

    const underliers = form.values.productGeneral.underlierList?.map(
      (underlier, index) => (
        <div className={'array-entry-section-container'} key={index}>
          <div className={'array-entry-section'}>
            <ArrayNumberItem>{index + 1}.</ArrayNumberItem>
            <Tooltip
              withArrow={true}
              label={underlier.underlierName || 'Name is null'}
            >
              <PFTextInput
                style={{ width: '8em' }}
                readOnly={true}
                label={'Ticker'}
                fieldPath={`${underlierFields.underlierList}.${index}.underlierSymbol`}
              />
            </Tooltip>

            <PFSelect
              label="Source"
              data={[...Object.values(UnderlierSources)]}
              disabled={disableFields}
              onChange={(e: string | null) => {
                underlierSourceChanged(index, e);
              }}
              fieldPath={`${underlierFields.underlierList}.${index}.underlierSource`}
            />
            <PFNumberInput
              style={{ width: '8em' }}
              styles={{
                input: {
                  border: updatedPriceIndexes.includes(index)
                    ? '1px solid blue'
                    : '',
                },
              }}
              disabled={disableFields}
              decimalScale={6}
              description={updatedPriceIndexes.includes(index) ? 'Updated' : ''}
              label={'Strike Level'}
              fieldPath={`${underlierFields.underlierList}.${index}.underlierLevel`}
            ></PFNumberInput>

            <PFDatePicker
              label="Strike Date"
              excludeDate={isWeekend}
              disabled={disableFields}
              fieldPath={`${underlierFields.underlierList}.${index}.underlierStrikeDate`}
            />

            <PFNumberInput
              style={{ width: '8em' }}
              decimalScale={6}
              rightSection={<div>%</div>}
              label={'Weight'}
              fieldPath={`${underlierFields.underlierList}.${index}.underlierWeight`}
              disabled={
                !(
                  form.values.productGeneral.basketType === 'Equal' ||
                  form.values.productGeneral.basketType === 'Custom' ||
                  isRankWeighted
                ) || disableFields
              }
            ></PFNumberInput>
            <PFSelect
              label="Initial Fixing"
              data={initialFixingOptions}
              disabled={disableFields}
              fieldPath={`${underlierFields.underlierList}.${index}.initialFixing`}
            />

            <Text
              size={'md'}
              style={{ width: '5em', textAlign: 'center', alignSelf: 'Left' }}
            >
              CCY <br />
              <Badge size={'xl'} color={'gray'}>
                {findFullDataUnderlier(underlier)?.currency}
              </Badge>
            </Text>

            <Text
              size={'md'}
              style={{ width: '5em', textAlign: 'center', alignSelf: 'Left' }}
            >
              Ex. Code <br />
              <Badge size={'xl'} color={'gray'}>
                {findFullDataUnderlier(underlier)?.exchangeCode}
              </Badge>
            </Text>
            <Button
              variant={'secondary-small'}
              type={'button'}
              disabled={disableFields}
              onClick={() => removeUnderlier(index)}
            >
              <IconTrash size={18}></IconTrash>
            </Button>
          </div>
        </div>
      ),
    );

    const equalizeUnderlierWeights = () => {
      const weightSplit = +new Big(100)
        .div(form.values.productGeneral.underlierList?.length || 1)
        .round(4);
      form.setFieldValue(
        underlierFields.underlierList,
        form.values.productGeneral.underlierList?.map((x: any) => {
          return { ...x, underlierWeight: weightSplit };
        }),
      );
    };

    const onBasketTypeChange = (value: string | null) => {
      if (value === BasketTypes.EQUAL) {
        equalizeUnderlierWeights();
      } else {
        form.setFieldValue(
          underlierFields.underlierList,
          form.values.productGeneral.underlierList?.map((x: any) => {
            return { ...x, underlierWeight: undefined };
          }),
        );
      }
      form.setFieldValue(underlierFields.basketType, value);
    };

    return (
      <div
        style={{ width: '90%', margin: '2em 5%', containerType: 'inline-size' }}
        ref={ref}
      >
        <div className={'form-subsection'}>
          {form.errors?.['productGeneral.underlierList'] && (
            <Text c={'red.6'} size={'md'}>
              {form.errors?.['productGeneral.underlierList']}
            </Text>
          )}
          <PFSelect
            label="Basket Type"
            disabled={
              (form?.values?.productGeneral?.underlierList?.length || 0) < 2
            }
            data={basketTypeOptions}
            fieldPath={underlierFields.basketType}
            onChange={(e: any) => {
              onBasketTypeChange(e);
            }}
          />
          <PFNumberInput
            label={'Basket Return Adjustment'}
            fieldPath={underlierFields.basketReturnAdjustment}
          ></PFNumberInput>
        </div>
        <div className={'form-subsection'}>
          {isRankWeighted && (
            <div>
              Underlier Ranked Weight
              {form?.values?.productGeneral?.underlierList?.map((_, index) => (
                <PFNumberInput
                  key={index}
                  style={{ width: '8em' }}
                  label={`Rank #${index + 1}`}
                  fieldPath={`${underlierFields.underlierRankWeightList}.${index}`}
                  rightSection={<div>%</div>}
                  decimalScale={6}
                />
              ))}
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '2em',
            flexFlow: 'row wrap',
            gap: '1em',
            alignItems: 'center',
          }}
        >
          <h3 style={{ margin: 0 }}>Enter Underliers: </h3>
          <UnderlierTypeahead
            disabled={disableFields}
            {...{
              selectedUnderliers,
              underlierSelected,
            }}
          ></UnderlierTypeahead>
          <Tooltip
            label={
              form?.values?.productGeneral?.underlierList?.some(
                (x) => x.underlierStrikeDate,
              )
                ? 'Get prices for underliers with strike dates entered'
                : getPrices.isLoading
                  ? ''
                  : 'Enter strike dates for underliers to enable price retrieval'
            }
            withArrow={true}
          >
            <div>
              <Button
                disabled={
                  !form?.values?.productGeneral?.underlierList?.some(
                    (x) => x.underlierStrikeDate,
                  ) || getPrices.isLoading
                }
                onClick={onGetPricesClick}
              >
                Get Prices
              </Button>
            </div>
          </Tooltip>
        </div>
        <AutoAnimateChildren>{underliers}</AutoAnimateChildren>
      </div>
    );
  },
);
UnderlierFormComponent.displayName = 'UnderlierFormComponent';
export default UnderlierFormComponent;
