import { Modal, Grid, Text, Button } from '@mantine/core';

type UnderlierModalProps = {
  isModalOpened: boolean;
  onClose: () => void;
  underlier: any;
};

const UnderlierModal = ({
  isModalOpened,
  onClose,
  underlier,
}: UnderlierModalProps) => (
  <Modal
    opened={isModalOpened}
    onClose={onClose}
    title={underlier?.securityName}
    padding="md"
    centered
    size="xl"
  >
    <Grid gutter="md" style={{ maxWidth: '100%' }}>
      {underlier &&
        Object.entries(underlier).map(([key, value], index) => {
          if (value !== null && value !== undefined) {
            return (
              <Grid.Col span={6} key={key}>
                <Text size="sm" style={{ marginBottom: '5px', weight: 700 }}>
                  {key}
                </Text>
                <Text size="xs" ta="left" c="dimmed">
                  {String(value)}
                </Text>
              </Grid.Col>
            );
          }
          return null;
        })}
    </Grid>
    <Button
      variant="primary"
      style={{ marginTop: '20px', alignSelf: 'flex-end' }}
      onClick={onClose}
    >
      Close
    </Button>
  </Modal>
);

export default UnderlierModal;
