import { useState } from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { Button } from '@mantine/core';
import { isWeekend } from '../../../../utilities/utilities';
import { PFDatePicker } from '../../reusable-form-component/BaseFormComponents';
import { ArrayNumberItem } from '../../../../components/ArrayNumberItem';
import { IconTrash } from '@tabler/icons-react';
import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';
import { DatePickerInput } from '@mantine/dates';
import { get } from 'lodash';

const WithAggregatingComponent = () => {
  const form = useProductFormContext();
  const addDate = () => {
    form.insertListItem(ancillaryFeaturesFields.aggregatingDates, undefined, 0);
  };
  const removeDate = (index: number) => {
    form.removeListItem(ancillaryFeaturesFields.aggregatingDates, index);
  };
  const [multiSelectDatesSelected, setMultiSelectDatesSelected] = useState<
    Date[]
  >([]);
  const handleAddMultipleDates = () => {
    const currentDates = get(
      form.values,
      ancillaryFeaturesFields.aggregatingDates,
    );
    const newDates = [...currentDates, ...multiSelectDatesSelected];
    form.setFieldValue(ancillaryFeaturesFields.aggregatingDates, newDates);
    setMultiSelectDatesSelected([]);
  };

  const handleSortDates = () => {
    form.setFieldValue(
      ancillaryFeaturesFields.aggregatingDates,
      get(form.values, ancillaryFeaturesFields.aggregatingDates)
        .filter((x: any) => x)
        .sort(function compare(a: Date, b: Date) {
          return a.getTime() - b.getTime();
        }),
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: '1em',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: 0 }}>With Aggregating Date</h3>
        <Button type={'button'} className={'basic-button'} onClick={addDate}>
          Add Date
        </Button>
        <Button onClick={handleSortDates} type={'button'}>
          Sort Dates
        </Button>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <DatePickerInput
            firstDayOfWeek={0}
            type="multiple"
            label="Select Multiple Dates"
            placeholder="Pick dates"
            value={multiSelectDatesSelected}
            onChange={setMultiSelectDatesSelected}
            mx="auto"
            clearable={true}
            valueFormat={'MM-DD-YYYY'}
            popoverProps={{ position: 'left-start' }}
            w={130}
          />
          <Button
            disabled={multiSelectDatesSelected?.length === 0}
            type={'button'}
            className={'basic-button'}
            onClick={handleAddMultipleDates}
          >
            Add Multiple Dates
          </Button>
        </div>
      </div>
      {form.values.productGeneral.aggregatingDates?.map((date, index) => (
        <div className={'array-entry-section-container'} key={index}>
          <div className={'array-entry-section'}>
            <ArrayNumberItem>{index + 1}.</ArrayNumberItem>

            <PFDatePicker
              label="With Aggregating Date"
              excludeDate={isWeekend}
              fieldPath={`${ancillaryFeaturesFields.aggregatingDates}.${index}`}
            />

            <Button
              variant={'secondary-small'}
              type={'button'}
              onClick={() => removeDate(index)}
            >
              <IconTrash size={18}></IconTrash>
            </Button>
          </div>
        </div>
      ))}
    </>
  );
};

export default WithAggregatingComponent;
