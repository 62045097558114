import React, { ForwardedRef, forwardRef } from 'react';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { Button, Divider } from '@mantine/core';
import {
  GrowthTypes,
  KnockOutBarrierObservationFrequencies,
  returnBarrierObsFrequencies,
} from '../../../product-schema/pdw-select-options';
import ExpandableNumberFieldset from '../reusable-form-component/BaseFormComponents/ExpandableNumberFieldset';
import { isFieldDisabled, isWeekend } from '../../../utilities/utilities';
import {
  PFNumberInput,
  PFSelect,
  PFDatePicker,
} from '../reusable-form-component/BaseFormComponents';
import { useProductFormDisabled } from '../entry-and-edit-contexts/disabled-fields-context';
import { ArrayNumberItem } from '../../../components/ArrayNumberItem';
import { IconTrash } from '@tabler/icons-react';

import { productGrowthFields } from '../../../product-schema/pdw-field-subsets';
import AutoAnimateChildren from '../../../components/AutoAnimateChildren';
import PFCheckbox from '../reusable-form-component/BaseFormComponents/PFCheckbox';

export const newLowerCallStrike = () => {
  return {
    lowerCallStrikeCurrent: undefined,
    lowerCallStrikeFinal: undefined,
    lowerCallStrikeLow: undefined,
    lowerCallStrikeHigh: undefined,
    lowerCallStrikeStartDate: undefined,
    lowerCallStrikeEndDate: undefined,
  };
};

const ProductGrowthFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    const disabledContext = useProductFormDisabled();
    const disableFields = isFieldDisabled(
      disabledContext,
      productGrowthFields.lowerCallStrikeList,
    );

    const addCallStrike = () => {
      if (disableFields) {
        return;
      }
      form.insertListItem(
        productGrowthFields.lowerCallStrikeList,
        newLowerCallStrike(),
      );
    };
    const removeCallStrike = (index: number) => {
      if (disableFields) {
        return;
      }
      form.removeListItem(productGrowthFields.lowerCallStrikeList, index);
    };

    const lowerCallStrikes = form.values.productGrowth.lowerCallStrikeList?.map(
      (callStrike, index) => (
        <div className={'array-entry-section-container'} key={index}>
          <div className={'array-entry-section'}>
            <ArrayNumberItem>{index + 1}.</ArrayNumberItem>
            <PFNumberInput
              decimalScale={6}
              rightSection={<div>%</div>}
              label="Lower Call Strike Final"
              disabled={disableFields}
              fieldPath={`${productGrowthFields.lowerCallStrikeList}.${index}.lowerCallStrikeFinal`}
            ></PFNumberInput>

            <PFNumberInput
              decimalScale={6}
              rightSection={<div>%</div>}
              label="Lower Call Strike Low"
              disabled={disableFields}
              fieldPath={`${productGrowthFields.lowerCallStrikeList}.${index}.lowerCallStrikeLow`}
            ></PFNumberInput>

            <PFNumberInput
              decimalScale={6}
              rightSection={<div>%</div>}
              label="Lower Call Strike High"
              disabled={disableFields}
              fieldPath={`${productGrowthFields.lowerCallStrikeList}.${index}.lowerCallStrikeHigh`}
            ></PFNumberInput>

            <PFDatePicker
              label="Start Date"
              excludeDate={isWeekend}
              disabled={disableFields}
              fieldPath={`${productGrowthFields.lowerCallStrikeList}.${index}.lowerCallStrikeStartDate`}
            />

            <PFDatePicker
              label="End Date"
              excludeDate={isWeekend}
              disabled={disableFields}
              fieldPath={`${productGrowthFields.lowerCallStrikeList}.${index}.lowerCallStrikeEndDate`}
            />

            <Button
              variant={'secondary-small'}
              type={'button'}
              disabled={disableFields}
              onClick={() => removeCallStrike(index)}
            >
              <IconTrash size={18} />
            </Button>
          </div>
        </div>
      ),
    );

    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFSelect
            label={'Growth Type'}
            data={[...Object.values(GrowthTypes)]}
            fieldPath={productGrowthFields.growthType}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: productGrowthFields.upsideParticipationRateFinal,
              label: 'Upside Participation Rate',
            }}
            subFields={[
              {
                property: productGrowthFields.upsideParticipationRateHigh,
                label: 'High Upside Participation Rate',
              },
              {
                property: productGrowthFields.upsideParticipationRateLow,
                label: 'Low Upside Participation Rate',
              },
              {
                property: productGrowthFields.upsideParticipationRateCurrent,
                label: 'Current Upside Participation Rate',
              },
            ]}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: productGrowthFields.underlierReturnCapLevelFinal,
              label: 'Underlier Return Cap Level',
            }}
            subFields={[
              {
                property: productGrowthFields.underlierReturnCapLevelHigh,
                label: 'High Underlier Return Cap',
              },
              {
                property: productGrowthFields.underlierReturnCapLevelLow,
                label: 'Low Underlier Return Cap',
              },
              {
                property: productGrowthFields.underlierReturnCapLevelCurrent,
                label: 'Current Underlier Return Cap',
              },
            ]}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: productGrowthFields.maximumReturnFinal,
              label: 'Maximum Return',
            }}
            subFields={[
              {
                property: productGrowthFields.maximumReturnHigh,
                label: 'High Maximum Return',
              },
              {
                property: productGrowthFields.maximumReturnLow,
                label: 'Low Maximum Return',
              },
              {
                property: productGrowthFields.maximumReturnCurrent,
                label: 'Current Maximum Return',
              },
            ]}
          />
        </div>

        <div className={'form-subsection'}>
          <ExpandableNumberFieldset
            primaryField={{
              property: productGrowthFields.digitalReturnBarrierFinal,
              label: 'Digital Return Barrier',
            }}
            subFields={[
              {
                property: productGrowthFields.digitalReturnBarrierHigh,
                label: 'High Digital Return Barrier',
              },
              {
                property: productGrowthFields.digitalReturnBarrierLow,
                label: 'Low Digital Return Barrier',
              },
              {
                property: productGrowthFields.digitalReturnBarrierCurrent,
                label: 'Current Digital Return Barrier',
              },
            ]}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: productGrowthFields.digitalReturnFinal,
              label: 'Digital Return',
            }}
            subFields={[
              {
                property: productGrowthFields.digitalReturnHigh,
                label: 'High Digital Return',
              },
              {
                property: productGrowthFields.digitalReturnLow,
                label: 'Low Digital Return',
              },
              {
                property: productGrowthFields.digitalReturnCurrent,
                label: 'Current Digital Return',
              },
            ]}
          />

          <PFSelect
            label={'Digital Return Barrier Obsv. Frequency'}
            data={returnBarrierObsFrequencies}
            fieldPath={
              productGrowthFields.digitalReturnBarrierObservationFrequency
            }
          />

          <ExpandableNumberFieldset
            primaryField={{
              property:
                productGrowthFields.upsideParticipationAboveDigitalReturnFinal,
              label: 'Upside Participation Above Digital Return',
            }}
            subFields={[
              {
                property:
                  productGrowthFields.upsideParticipationAboveDigitalReturnHigh,
                label: 'High Upside Participation Above',
              },
              {
                property:
                  productGrowthFields.upsideParticipationAboveDigitalReturnLow,
                label: 'Low Upside Participation Above',
              },
              {
                property:
                  productGrowthFields.upsideParticipationAboveDigitalReturnCurrent,
                label: 'Current Upside Participation Above',
              },
            ]}
          />
        </div>

        <div className={'form-subsection'}>
          <PFSelect
            label="Knockout Barrier Obs. Freq."
            data={[...Object.values(KnockOutBarrierObservationFrequencies)]}
            fieldPath={productGrowthFields.knockOutBarrierObservationFrequency}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: productGrowthFields.absoluteReturnBarrierLevelFinal,
              label: 'Absolute Return Barrier Level',
            }}
            subFields={[
              {
                property: productGrowthFields.absoluteReturnBarrierLevelHigh,
                label: 'High Absolute Return Barrier',
              },
              {
                property: productGrowthFields.absoluteReturnBarrierLevelLow,
                label: 'Low Absolute Return Barrier',
              },
              {
                property: productGrowthFields.absoluteReturnBarrierLevelCurrent,
                label: 'Current Absolute Return Barrier',
              },
            ]}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property:
                productGrowthFields.absoluteReturnParticipationRateFinal,
              label: 'Absolute Return Participation Rate',
            }}
            subFields={[
              {
                property:
                  productGrowthFields.absoluteReturnParticipationRateHigh,
                label: 'High Absolute Return Participation',
              },
              {
                property:
                  productGrowthFields.absoluteReturnParticipationRateLow,
                label: 'Low Absolute Return Participation',
              },
              {
                property:
                  productGrowthFields.absoluteReturnParticipationRateCurrent,
                label: 'Current Absolute Return Participation',
              },
            ]}
          />

          <PFCheckbox
            label={'Bearish'}
            fieldPath={productGrowthFields.bearish}
          />
        </div>

        <Divider style={{ width: '90%', margin: '1em' }} />
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            gap: '1em',
            alignItems: 'center',
            marginBottom: 'auto',
            marginTop: '1em',
            width: '100%',
          }}
        >
          <h3 style={{ margin: 0 }}>Lower Call Strikes</h3>
          <Button
            variant="primary"
            type={'button'}
            disabled={disableFields}
            onClick={addCallStrike}
          >
            Add Call Strike
          </Button>
        </div>
        <AutoAnimateChildren style={{ width: '100%' }}>
          {lowerCallStrikes}
        </AutoAnimateChildren>
      </div>
    );
  },
);
ProductGrowthFormComponent.displayName = 'ProductGrowthFormComponent';
export default ProductGrowthFormComponent;
