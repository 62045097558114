import { CSSProperties, useState } from 'react';
import { Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { searchUnderliersByPrefix } from '../../../../API/market-data-maintenance.service';
import { Underlier } from '../../../../product-schema/underlier.schema';

export interface UnderlierTypeaheadProps {
  underlierSelected: (underlier: any) => void;
  selectedUnderliers: any[] | any;
  disabled?: boolean;
  width?: CSSProperties['width'];
}

const UnderlierTypeahead = ({
  disabled,
  selectedUnderliers,
  underlierSelected,
  width,
}: UnderlierTypeaheadProps) => {
  const [value, setValue] = useState('');
  const [underlierSearchValue] = useDebouncedValue(value, 250);
  const searchActive = false;

  const { data, isFetching }: any = useQuery(
    [underlierSearchValue],
    () => searchUnderliersByPrefix(underlierSearchValue, searchActive),
    {
      enabled: !!underlierSearchValue,
      staleTime: 1000 * 60,
    },
  );
  const options = (data?.data || [])
    .filter((x: Underlier) => {
      if (Array.isArray(selectedUnderliers)) {
        return !selectedUnderliers?.includes(x.bloombergTicker);
      } else {
        return selectedUnderliers?.bloombergTicker !== x.bloombergTicker;
      }
    })
    .map((item: Underlier) => (
      <Combobox.Option
        disabled={!item.activeListing}
        value={item.underlierId}
        key={item.underlierId}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '1rem',
            color: item.activeListing ? 'inherit' : 'red',
          }}
        >
          <div style={{ minWidth: '6rem' }}>{item.bloombergTicker}</div>
          <div>
            {item.securityName}
            {!item.activeListing && ' (Inactive)'}
          </div>
          <div>
            {item.newBloombergTicker &&
              ' Ticker change: ' + item.newBloombergTicker}
          </div>
          <div style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}>
            {item.currency} | {item.exchangeCode}
          </div>
        </div>
      </Combobox.Option>
    ));

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  return (
    <div style={{ width: width ?? 'min(30em, 80%' }}>
      <Combobox
        onOptionSubmit={(e) => {
          const underlier = data?.data.find(
            (x: Underlier) => x.underlierId === e,
          );
          underlierSelected(underlier);
          setValue('');
        }}
        withinPortal={true}
        store={combobox}
        disabled={disabled}
      >
        <Combobox.Target>
          <TextInput
            placeholder="Enter Underlier Ticker"
            value={value}
            onChange={(event) => {
              setValue(event.currentTarget.value);
              combobox.resetSelectedOption();
              combobox.openDropdown();
            }}
            onClick={() => combobox.openDropdown()}
            onFocus={() => {
              combobox.openDropdown();
            }}
            onBlur={() => combobox.closeDropdown()}
            rightSection={isFetching && <Loader size={18} />}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={data === null || !value}>
          <Combobox.Options mah={320} style={{ overflowY: 'auto' }}>
            {options}
            {isFetching ? (
              <Combobox.Empty>Loading Underliers</Combobox.Empty>
            ) : data?.data?.length > 0 ? (
              ''
            ) : (
              <Combobox.Empty>No Underlier Found</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
};

export default UnderlierTypeahead;
