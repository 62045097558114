import React, { useContext } from 'react';
import { useProductFormContext } from '../../../entry-and-edit-contexts/product-form-context';
import { isFieldDisabled, isWeekend } from '../../../../../utilities/utilities';
import {
  PFDatePicker,
  PFNumberInput,
} from '../../../reusable-form-component/BaseFormComponents';
import { useProductFormDisabled } from '../../../entry-and-edit-contexts/disabled-fields-context';
import { ArrayNumberItem } from '../../../../../components/ArrayNumberItem';
import { ProductFormHiddenFieldsContext } from '../../../entry-and-edit-contexts/HiddenFields-context';
import { isFieldHidden } from '../../../reusable-form-component/BaseFormComponents/PFNumberInput';
import { IconTrash } from '@tabler/icons-react';
import AutoAnimateChildren from '../../../../../components/AutoAnimateChildren';
import { Button } from '@mantine/core';

const LeverageFactorsComponent = () => {
  const form = useProductFormContext();
  const disabledContext = useProductFormDisabled();
  const disableFields = isFieldDisabled(
    disabledContext,
    'productYield.leverageFactors',
  );
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  const isHidden = isFieldHidden(
    hiddenFieldsContext,
    'productYield.leverageFactors',
  );
  const addLeverageFactor = () => {
    if (disableFields) {
      return;
    }
    form.insertListItem(
      'productYield.leverageFactors',
      {
        leverageFactor: undefined,
        leverageFactorStartDate: undefined,
        leverageFactorEndDate: undefined,
      },
      0,
    );
  };
  const removeLeverageFactor = (index: number) => {
    if (disableFields) {
      return;
    }
    form.removeListItem('productYield.leverageFactors', index);
  };

  return (
    <>
      {!isHidden && (
        <>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              gap: '1em',
              alignItems: 'center',
            }}
          >
            <h3 style={{ margin: 0 }}>Leverage Factors </h3>
            <Button
              variant="primary"
              type={'button'}
              disabled={disableFields}
              onClick={addLeverageFactor}
            >
              Add Leverage Factor
            </Button>
          </div>
          <AutoAnimateChildren>
            {form.values.productYield.leverageFactors?.map(
              (leverageFactor, index) => (
                <div className={'array-entry-section-container'} key={index}>
                  <div className={'array-entry-section'}>
                    <ArrayNumberItem>{index + 1}.</ArrayNumberItem>

                    <PFNumberInput
                      decimalScale={6}
                      rightSection={<div>%</div>}
                      label="Factor"
                      disabled={disableFields}
                      fieldPath={`productYield.leverageFactors.${index}.leverageFactor`}
                    ></PFNumberInput>

                    <PFDatePicker
                      label="Start Date"
                      excludeDate={isWeekend}
                      disabled={disableFields}
                      fieldPath={`productYield.leverageFactors.${index}.leverageFactorStartDate`}
                    />

                    <PFDatePicker
                      placeholder="MM/DD/YYYY"
                      label="End Date"
                      excludeDate={isWeekend}
                      disabled={disableFields}
                      fieldPath={`productYield.leverageFactors.${index}.leverageFactorEndDate`}
                    />
                    <Button
                      variant={'secondary-small'}
                      type={'button'}
                      disabled={disableFields}
                      onClick={() => removeLeverageFactor(index)}
                    >
                      <IconTrash size={18}></IconTrash>
                    </Button>
                  </div>
                </div>
              ),
            )}
          </AutoAnimateChildren>
        </>
      )}
    </>
  );
};

export default LeverageFactorsComponent;
