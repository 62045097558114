import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { get } from 'lodash';
import { isFieldDisabled } from '../../../../utilities/utilities';
import PFNumberInput, { AdjustedNumberInputProps } from './PFNumberInput';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';

export const SHOW_RANGES = 'Show Ranges';
export const HIDE_RANGES = 'Hide Ranges';

export interface BasicComponentProps {
  property: string;
  label: string;
}

export interface ExpandableComponentProps extends AdjustedNumberInputProps {
  primaryField: BasicComponentProps;
  subFields: BasicComponentProps[];
  percentFormat?: boolean;
  decimalScale?: number;
}

const ExpandableNumberFieldset = ({
  primaryField,
  subFields,
  percentFormat = true,
  ...rest
}: ExpandableComponentProps) => {
  const form = useProductFormContext();
  const disabledContext = useProductFormDisabled();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  const handleExpansionChange = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    for (const field of subFields) {
      if (get(form?.values, field.property) != null) {
        setIsExpanded(true);
        return;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSecondaryFieldChange = (value: any, secondaryFieldProp: any) => {
    form.setFieldValue(secondaryFieldProp, value);
  };

  return (
    <>
      {(!hiddenFieldsContext.hiddenFields.includes(primaryField.property) ||
        hiddenFieldsContext.showAllHiddenFields) && (
        <div>
          <PFNumberInput
            label={primaryField.label}
            disabled={isFieldDisabled(disabledContext, primaryField.property)}
            fieldPath={primaryField.property}
            decimalScale={6}
            step={percentFormat ? 0.01 : 1}
            rightSection={percentFormat ? <div>%</div> : <></>}
            {...rest}
          ></PFNumberInput>
          {isExpanded && subFields && (
            <div className={'small-field-section'}>
              {subFields.map((subFieldProp) => (
                <PFNumberInput
                  key={subFieldProp.property}
                  disabled={isFieldDisabled(
                    disabledContext,
                    subFieldProp.property,
                  )}
                  label={subFieldProp.label}
                  fieldPath={subFieldProp.property}
                  onChange={(e: any) =>
                    onSecondaryFieldChange(e, subFieldProp.property)
                  }
                  decimalScale={6}
                  step={percentFormat ? 0.01 : 1}
                  rightSection={percentFormat ? <div>%</div> : <></>}
                  {...rest}
                ></PFNumberInput>
              ))}
            </div>
          )}
          <Button
            type={'button'}
            className="ranges-button"
            onClick={handleExpansionChange}
          >
            {isExpanded ? HIDE_RANGES : SHOW_RANGES}
          </Button>
        </div>
      )}
    </>
  );
};

export default ExpandableNumberFieldset;

ExpandableNumberFieldset.defaultProps = {
  percentFormat: true,
};
