import React, { useMemo } from 'react';
import { Container } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { getUsersRecentlyModifiedProducts } from '../../API/pdw.service';
import { useAuth } from '../../components/Authentication/AuthProvider';

import jwt_decode from 'jwt-decode';
import RecentProductsGrid from './RecentProductsGrid';

const HomeComponent = () => {
  const { token } = useAuth();
  const userName = useMemo(() => {
    return (jwt_decode(token.access_token) as any)?.user_name || '';
  }, [token]);
  const recentProducts = useQuery({
    queryKey: ['getRecentProducts'],
    queryFn: () => getUsersRecentlyModifiedProducts(userName, 50),
  });
  return (
    <>
      <Container size={'md'}>
        <h1>LUMA Administrator Portal</h1>
      </Container>
      <Container size={'xxl'}>
        {recentProducts?.data?.data &&
          recentProducts?.data?.data?.length > 0 && (
            <RecentProductsGrid data={recentProducts.data.data} />
          )}
      </Container>
    </>
  );
};

export default HomeComponent;
