import { Button, createTheme } from '@mantine/core';
import classes from './MantineTheme.module.scss';

export const PrimaryTheme = createTheme({
  components: {
    Button: Button.extend({
      classNames: classes,
    }),
    Popover: {
      defaultProps: {
        withinPortal: true,
      },
    },
    DatePickerInput: {
      defaultProps: {
        firstDayOfWeek: 0,
        hideOutsideDates: true,
      },
    },
    DateInput: {
      defaultProps: {
        firstDayOfWeek: 0,
        hideOutsideDates: true,
      },
    },
    InputWrapper: {
      styles: {
        label: {
          fontSize: '1.1em',
        },
      },
    },
    Radio: {
      styles: {
        root: { marginTop: '.5em', marginBottom: '.5em' },
      },
    },
    Tooltip: {
      defaultProps: {
        openDelay: 500,
      },
    },
  },
  fontSizes: {
    xs: '.8em',
    sm: '.9em',
    md: '1em',
    lg: '1.25em',
    xl: '1.5em',
  },
  fontFamily: 'IBM Plex Sans, sans-serif',
});
