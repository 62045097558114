import { useContext } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { isFieldDisabled } from '../../../../utilities/utilities';
import { FormFieldPath } from './FieldInput.interface';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import { derivableFieldValues } from '../../../../product-schema/pdw-field-subsets';
import { useGetConfidenceStyle } from '../../useIsProductFromLLM';

export interface PFSelectProps
  extends Omit<SelectProps, 'data'>,
    React.RefAttributes<HTMLInputElement>,
    FormFieldPath {
  data: any; // this was done to allow values to be booleans, ie: label: 'True', value: true
}

const PFSelect = ({ fieldPath, ...rest }: PFSelectProps) => {
  const confidenceStyle = useGetConfidenceStyle(fieldPath);

  const disabledContext = useProductFormDisabled();
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  const form = useProductFormContext();
  // value is seperated out because resetting the form works in peculiar ways depending on initial values of inputs
  const { value } = form.getInputProps(fieldPath);
  return (
    <>
      {(!hiddenFieldsContext.hiddenFields.includes(fieldPath) ||
        hiddenFieldsContext.showAllHiddenFields) && (
        <Select
          {...form.getInputProps(fieldPath)}
          value={value !== undefined ? value : null}
          clearable={true}
          searchable={true}
          maw={280}
          {...rest}
          styles={{
            label: {
              textDecoration: derivableFieldValues.includes(fieldPath)
                ? 'underline'
                : 'unset',
            },
            input: {
              cursor: 'pointer',
              ...confidenceStyle,
            },
          }}
          disabled={
            rest.disabled || isFieldDisabled(disabledContext, fieldPath)
          }
        ></Select>
      )}
    </>
  );
};

export default PFSelect;
