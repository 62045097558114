import React, { ForwardedRef, forwardRef } from 'react';
import { Divider } from '@mantine/core';
import {
  FloatInstruments,
  rateBarrierObservationFrequencies,
  ratePaymentTypes,
  UnderlierSources,
} from '../../../../product-schema/pdw-select-options';
import LeverageFactorsComponent from './CMS-details-expandables/Leverage-factors';
import RateCapsComponent from './CMS-details-expandables/Rate-caps';
import RateMinComponent from './CMS-details-expandables/Rate-min';
import RateBarrierLevelsComponent from './CMS-details-expandables/Rate-Barrier-Levels';
import {
  PFNumberInput,
  PFSelect,
  PFTextInput,
} from '../../reusable-form-component/BaseFormComponents';
import { cmsDetailsFields } from '../../../../product-schema/pdw-field-subsets';

const CMSDetailsFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <>
        <div className={'form-section'} ref={ref}>
          <div className={'form-subsection'}>
            <PFNumberInput
              decimalScale={6}
              label="Float Rate Fixing Offset Days"
              fieldPath={cmsDetailsFields.floatRateFixingOffsetDays}
            ></PFNumberInput>

            <PFSelect
              data={[...Object.values(FloatInstruments)]}
              fieldPath={cmsDetailsFields.floatInstrument}
              label={'Float Instrument'}
            ></PFSelect>

            <PFTextInput
              label={'Fixed Coupon Multiplier'}
              fieldPath={cmsDetailsFields.fixedCouponMultiplier}
            ></PFTextInput>
          </div>
          <div className={'form-subsection'}>
            <PFNumberInput
              decimalScale={6}
              label="Interest Multiplier Per Year"
              fieldPath={cmsDetailsFields.interestMultiplierPerYear}
            ></PFNumberInput>

            <PFNumberInput
              decimalScale={6}
              label="Float Spread"
              rightSection={<div>%</div>}
              fieldPath={'productYield.floatSpread'}
            ></PFNumberInput>

            <PFSelect
              label="Rate Payment Type"
              data={ratePaymentTypes}
              fieldPath={cmsDetailsFields.ratePaymentType}
            />

            <PFSelect
              label="Rate Barrier Observation Frequency"
              data={rateBarrierObservationFrequencies}
              fieldPath={cmsDetailsFields.rateBarrierObservationFrequency}
            />
            <PFSelect
              label="Floating Rate Observation Frequency"
              data={rateBarrierObservationFrequencies}
              fieldPath={cmsDetailsFields.floatingRateObservationFrequency}
            />
            <PFNumberInput
              label="Inflation Month Offset"
              fieldPath={cmsDetailsFields.inflationMonthOffset}
            />
          </div>
        </div>
        <div style={{ width: '90%', margin: '2em 5%' }}>
          <LeverageFactorsComponent></LeverageFactorsComponent>
          <Divider style={{ margin: '2em 1em', width: '90%' }}></Divider>
          <RateCapsComponent></RateCapsComponent>
          <Divider style={{ margin: '2em 1em', width: '90%' }}></Divider>
          <RateMinComponent></RateMinComponent>
          <Divider style={{ margin: '2em 1em', width: '90%' }}></Divider>
          <RateBarrierLevelsComponent></RateBarrierLevelsComponent>
          <Divider style={{ margin: '2em 1em', width: '90%' }}></Divider>
        </div>
        <h3 style={{ margin: '0 5%' }}>CMS Reference Index</h3>
        <div className={'form-section'}>
          <div className={'form-subsection'}>
            <h3 style={{ marginBottom: 0, marginTop: '1em' }}>Short Term</h3>
            <PFSelect
              label={'Ticker'}
              data={[...Object.values(FloatInstruments)]}
              fieldPath={cmsDetailsFields.shortTermRateUnderlierSymbol}
            ></PFSelect>

            <PFSelect
              label="Source"
              data={[...Object.values(UnderlierSources)]}
              fieldPath={cmsDetailsFields.shortTermRateUnderlierSource}
            />
          </div>
          <div className={'form-subsection'}>
            <h3 style={{ marginBottom: 0, marginTop: '1em' }}>Long Term</h3>

            <PFSelect
              label={'Ticker'}
              data={[...Object.values(FloatInstruments)]}
              fieldPath={cmsDetailsFields.longTermRateUnderlierSymbol}
            ></PFSelect>

            <PFSelect
              label="Source"
              data={[...Object.values(UnderlierSources)]}
              fieldPath={cmsDetailsFields.longTermRateUnderlierSource}
            />
          </div>
        </div>
      </>
    );
  },
);
CMSDetailsFormComponent.displayName = 'CMSDetailsFormComponent';

export default CMSDetailsFormComponent;
