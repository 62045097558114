import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { TemplateOptions } from './pdw-select-options';
import { formatDate, isWeekday } from '../utilities/utilities';

// We are overriding the BASE schema on our methods.
// This effectively causes subsequent yup.Chained().operations()... to be limited to operations exposed
// in the base schema, but is easier to maintain than the alternative (see bottom of file).
// Everything will work fine as long as no '[specific]Schema (*like StringSchema, BooleanSchema)' operations happen AFTER this operation
yup.addMethod(
  yup.mixed,
  'requiredForTemplates',
  function (templates: TemplateOptions[]) {
    return this.when('$data.templateSelection', {
      is: (val: any) => templates.includes(val),
      then: (schema) => schema.required('Required For This Template'),
    });
  },
);

yup.addMethod(yup.date, 'noHolidayDates', function () {
  return this.test(
    'isDateAHoliday',
    'This date falls on a holiday.',
    function (value, context) {
      return !context?.options?.context?.holidays?.includes(formatDate(value));
    },
  );
});

yup.addMethod(yup.date, 'noWeekendDates', function () {
  return this.test(
    'isDateAWeekend',
    'This date falls on a weekend',
    function (value) {
      if (!value) {
        return true;
      }
      return isWeekday(value);
    },
  );
});

yup.addMethod(yup.mixed, 'nullRequired', function (message: string) {
  return this.test('nullRequired', message, function (value) {
    return !value;
  });
});

// checks whether the field that this muthod is added to has a value (based on template) or any of the sub fields entered have values
yup.addMethod(
  yup.mixed,
  'requiredForTemplateRanges',
  function (
    templates: (TemplateOptions | 'ALL')[],
    otherPossibleFields: string[],
  ) {
    return this.test(
      'requiredForTemplate',
      'Template Requires at least 1 range value',
      function (value, context) {
        // console.log('that context', context);
        if (
          templates.includes(context.options.context?.data.templateSelection) ||
          templates.includes('ALL')
        ) {
          return (
            value || otherPossibleFields.some((field) => context.parent[field])
          );
        } else {
          return true;
        }
      },
    );
  },
);

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface BaseSchema<TCast = any, TContext = AnyObject, TOutput = any> {
    requiredForTemplates(templates: TemplateOptions[]): BaseSchema<TOutput>;
    nullRequired(message: string): BaseSchema<TOutput>;
    requiredForTemplateRanges(
      templates: (TemplateOptions | 'ALL')[],
      otherFields: string[],
    ): BaseSchema<TOutput>;
  }

  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends BaseSchema<TType, TContext, TOut> {
    noHolidayDates(): DateSchema;
    noWeekendDates(): DateSchema;
  }
}

// yup.DateSchema
export default yup;

// // If we want to be able to chain subsequent schema types AFTER our custom method we need to override interfaces explicitly.
// and there are a lot of schemas. Woof.
// // ex: StringSchema.
// yup.addMethod(yup.string, "stringRequiredForTemplates", function (templates: TemplateOptions[]) {
//   return this.when('$data.templateSelection', {
//     is: (val: any) => {
//       // console.log('val', val, templates, templates.includes(val));
//       return templates.includes(val)}, // value check
//     then: schema => schema.required('Required For This Template')
//   });
// });
// declare module "yup" {
//   interface StringSchema<
//     TType extends Maybe<string> = string | undefined,
//     TContext extends AnyObject = AnyObject,
//     TOut extends TType = TType
//     > extends yup.BaseSchema<TType, TContext, TOut> {
//     stringRequiredForTemplates(templates: TemplateOptions[]): StringSchema<TType, TContext>;
//   }
// }
//
// export default yup;
