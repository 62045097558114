import React, { useState } from 'react';
import { Grid, Button, Modal, Text, Card } from '@mantine/core';
import { Underlier } from '../../../product-schema/underlier.schema';
import UnderlierSearchFactset from './UnderlierSearchFactset';
import UnderlierItem from './UnderlierItem';
import UnderlierPageInstructions from './UnderlierPageInstructions';
import UnderlierForm from './UnderlierForm';
import UnderlierTypeahead from '../../product-entry-and-edit/form-sections/Underliers/UnderlierTypeahead';

const UnderlierPage: React.FC = () => {
  // const [factsetSearchTicker, setFactsetSearchTicker] = useState('');
  const [factsetSelectedUnderlier, setFactsetSelectedUnderlier] =
    useState<Underlier | null>(null);
  const [selectedUnderlier, setSelectedUnderlier] = useState<Underlier | null>(
    null,
  );
  const [showFactsetSearch, setShowFactsetSearch] = useState<boolean>(false);
  const [showAddUnderlierModal, setShowAddUnderlierModal] = useState(false);

  const handleFactsetUnderlierAddition = () => {
    setShowFactsetSearch(false);
    setSelectedUnderlier(factsetSelectedUnderlier);
  };

  const handleCloseFactsetModal = () => {
    setShowFactsetSearch(false);
  };

  const handleCloseUnderlieModal = () => {
    setShowAddUnderlierModal(false);
  };

  const handleFactsetOpen = () => {
    setShowFactsetSearch(true);
  };

  return (
    <>
      <Grid gutter="lg" style={{ width: 'calc(100% - 2rem)', margin: '1rem' }}>
        <Grid.Col span={12}>
          <UnderlierPageInstructions />

          <Card padding="lg" shadow="md" style={{ marginBottom: '20px' }}>
            <Text mb="1rem" style={{ color: 'red' }}>
              Reminder: Please check if the underlier exists in Luma or Factset
              database before manually adding it.
            </Text>
            <div style={{ display: 'flex', gap: '2rem' }}>
              <Button
                style={{ marginRight: '10px', width: 'fit-content' }}
                onClick={() => handleFactsetOpen()}
              >
                Add Underlier from Factset
              </Button>
              <Button
                onClick={() => setShowAddUnderlierModal(true)}
                style={{ marginRight: '10px', width: 'fit-content' }}
              >
                Add the Underlier Manually
              </Button>
            </div>
          </Card>
          <Modal
            opened={showAddUnderlierModal}
            onClose={handleCloseUnderlieModal}
            title="Add Underlier Manually"
          >
            <UnderlierForm />
          </Modal>
        </Grid.Col>
        <Grid.Col span={12}>
          <Card padding="lg" shadow="md">
            <UnderlierTypeahead
              underlierSelected={setSelectedUnderlier}
              selectedUnderliers={selectedUnderlier}
              width={'100%'}
            />
            <h4>{selectedUnderlier?.bloombergTicker}</h4>
          </Card>
        </Grid.Col>
        <Grid.Col span={12}>
          {selectedUnderlier && (
            <UnderlierItem
              setSelectedUnderlier={setSelectedUnderlier}
              underlier={selectedUnderlier}
            />
          )}
        </Grid.Col>
      </Grid>
      {showFactsetSearch && (
        <Modal
          opened={showFactsetSearch}
          size={'lg'}
          onClose={handleCloseFactsetModal}
          title={<h3>Search Factset Underlier</h3>}
          centered={true}
        >
          <UnderlierSearchFactset
            setFactsetSelectedUnderlier={setFactsetSelectedUnderlier}
            onUnderlierAdded={handleFactsetUnderlierAddition}
          />
        </Modal>
      )}
    </>
  );
};

export default UnderlierPage;
