import classNames from 'classnames';
import classes from './product-sections.module.scss';
import React, { cloneElement, memo, useContext, useMemo, useRef } from 'react';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconGripVertical,
  IconZoomExclamation,
} from '@tabler/icons-react';
import { Button, Tooltip } from '@mantine/core';

import { EditModeContext } from '../entry-and-edit-contexts/edit-mode-context';
import {
  formSectionHasInvalidValues,
  formSectionHasValues,
} from '../../../utilities/utilities';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  FormSectionProperties,
  PDWFormSectionIds,
} from '../form-section.types';

export interface ProductSectionComponentProps {
  onCollapseOthersClicked: () => any;
  onSectionClicked: () => any;
  sectionProperties: FormSectionProperties;
  holidayDates: string[];
  index: number;
}

const ProductSectionComponent = memo(
  ({
    onCollapseOthersClicked,
    holidayDates,
    onSectionClicked,
    sectionProperties,
    index,
  }: ProductSectionComponentProps) => {
    const form = useProductFormContext();
    const invalidFields = useMemo(
      () =>
        formSectionHasInvalidValues({
          formErrors: form.errors,
          sectionFormFields: sectionProperties.formFields,
        }),
      [form.errors, sectionProperties.formFields],
    );

    const hasValues = useMemo(
      () =>
        formSectionHasValues({
          formValues: form.values,
          sectionFormFields: sectionProperties.formFields,
        }),
      [form.values, sectionProperties.formFields],
    );
    const editMode = useContext(EditModeContext);
    const ref = useRef<any>(null);
    const headerClassEditMode = editMode
      ? classes.formSectionHeaderEditMode
      : classes.formSectionHeaderNonEditMode;
    const headerClassHasValues = hasValues
      ? editMode
        ? classes.formSectionHeaderHasValues
        : classes.formHeaderWithValuesEditMode
      : undefined;
    return (
      <div id={`${sectionProperties.id}`}>
        <Draggable
          draggableId={sectionProperties.id}
          index={index}
          key={sectionProperties.id}
        >
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            <div
              className={snapshot.isDragging ? classes.itemDragging : undefined}
              {...provided.draggableProps}
              ref={provided.innerRef}
            >
              <h2
                onClick={onSectionClicked}
                className={classNames(
                  classes.formSectionHeader,
                  headerClassEditMode,
                  headerClassHasValues,
                )}
              >
                <div
                  style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
                >
                  <div
                    {...provided.dragHandleProps}
                    className={classes.dragHandle}
                  >
                    <IconGripVertical size="1.05rem" stroke={1.5} />
                  </div>
                  {invalidFields.length > 0 ? (
                    <Tooltip
                      style={{ whiteSpace: 'pre' }}
                      multiline
                      label={invalidFields.join('\r\n')}
                      withinPortal={true}
                    >
                      <div style={{ position: 'relative', top: '2px' }}>
                        <IconZoomExclamation></IconZoomExclamation>
                      </div>
                    </Tooltip>
                  ) : (
                    <div style={{ position: 'relative', top: '2px' }}>
                      <IconCheck></IconCheck>
                    </div>
                  )}
                  <div>{sectionProperties.label}</div>
                  <Button
                    variant="primary"
                    type={'button'}
                    style={{
                      position: 'absolute',
                      left: '400px',
                      padding: '.4em 1.25em',
                      fontSize: '.75em',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onCollapseOthersClicked();
                      setTimeout(() =>
                        ref?.current?.scrollIntoView({
                          block:
                            sectionProperties.id ===
                            PDWFormSectionIds.coreDetails
                              ? 'end'
                              : 'center',
                        }),
                      );
                    }}
                  >
                    Focus
                  </Button>
                </div>
                <div
                  style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
                >
                  {sectionProperties.expanded ? (
                    <IconChevronUp></IconChevronUp>
                  ) : (
                    <IconChevronDown></IconChevronDown>
                  )}
                </div>
              </h2>
              {sectionProperties.expanded && (
                <>
                  {sectionProperties.id === PDWFormSectionIds.calendarDates
                    ? cloneElement(sectionProperties.component, {
                        ref,
                        holidayDates,
                      })
                    : cloneElement(sectionProperties.component, { ref })}
                </>
              )}
            </div>
          )}
        </Draggable>
      </div>
    );
  },
);
ProductSectionComponent.displayName = 'ProductSectionComponent';

export default ProductSectionComponent;
