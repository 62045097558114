import React, { useContext, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { PFTextInput } from '../../reusable-form-component/BaseFormComponents';
import { getPdwProductByCusipOrIsin } from '../../../../API/product-data-warehouse.service';
import { EditModeContext } from '../../entry-and-edit-contexts/edit-mode-context';
import { useAuth } from '../../../../components/Authentication/AuthProvider';

const IsinEntry = () => {
  const form = useProductFormContext();
  const editMode = useContext(EditModeContext);

  // needed to ensure token before we make the endpoint call
  const { token } = useAuth();

  useEffect(() => {
    if (
      token &&
      !editMode &&
      form.values.productGeneral.isin &&
      form.values.productGeneral.isin.length === 12
    ) {
      setTimeout(() => {
        getProductByCusipOrIsin.mutate(form.values.productGeneral.isin);
      });
    }
    form.validateField('productGeneral.cusip');
  }, [form.values.productGeneral.isin, token]); // eslint-disable-line react-hooks/exhaustive-deps

  const getProductByCusipOrIsin: any = useMutation(
    (isin: string) => getPdwProductByCusipOrIsin(isin),
    {
      onSuccess: (res) => {
        console.log('set them errors');
        form.setFieldError('productGeneral.isin', 'ISIN Already Taken');
      },
      onError: (err) => {
        // isin wasn't found if 404
        // todo: we can handle 500 errors by offering a retry potentially, might not be worth getting into though.
      },
    },
  );

  const checkISIN = async (event: any) => {
    if (event.target?.value && event.target.value.length === 9 && !editMode) {
      getProductByCusipOrIsin.mutate(event.target.value);
    } else {
      return;
    }
  };

  const { onBlur: formOnBlur } = form.getInputProps('productGeneral.isin');
  const onISINBlur = async (event: any) => {
    await formOnBlur(event);
    await checkISIN(event);
  };

  return (
    <PFTextInput
      label="ISIN"
      fieldPath={'productGeneral.isin'}
      onBlur={onISINBlur}
    />
  );
};

export default IsinEntry;
