import axios from 'axios';
import { AUTH_SERVICE_ROOT } from './api-consts';

export const removeRMT = () => axios.get(`${AUTH_SERVICE_ROOT}/rmt/logout`);
export const logout = () => axios.delete(`${AUTH_SERVICE_ROOT}/tokens/current`);

export const rmtApiCall = () => {
  axios.put(
    `${AUTH_SERVICE_ROOT}/rmt/user/rememberMe?rememberMeIndicator=false`,
    {},
    { withCredentials: true },
  );
};
