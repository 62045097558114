import { CustomCellRendererProps } from 'ag-grid-react';
import {
  EventConfirmation,
  EventConfirmationGridData,
} from '../../../API/event-service';

import { MSWMEventConfirmationForm } from './MSWMEventConfirmationForm';
import { useMemo } from 'react';
import { Group } from '@mantine/core';
import { MSWMAuditLogTable } from './MSWMAuditLogTable';

export const EventConfirmationDetailCellRenderer = (
  props: CustomCellRendererProps<EventConfirmationGridData>,
) => {
  const updateGrid = (event: EventConfirmation) => {
    props.api.applyTransaction({
      update: [{ ...event, modifiedInThisSession: true }],
    });
  };

  const removeEvent = (event: EventConfirmation | undefined) => {
    if (!event) {
      return;
    }
    props.api.applyTransaction({
      remove: [{ ...event }],
    });
  };

  const event = useMemo(() => {
    if (!props.data) {
      return undefined;
    }
    const { modifiedInThisSession, ...eventData } = props.data || {};
    return eventData;
  }, [props.data]);
  // todo: add in physical form

  // todo: this can be added in if we want to keep the rows expandable but show something different
  // if (dayjs(props.data?.eventDate).isAfter(dayjs())) {
  //   return (
  //     <Title order={1} p={'xl'}>
  //       Event is in the future.
  //     </Title>
  //   );
  // }

  return (
    <Group
      align="stretch"
      style={{
        padding: '1rem 1rem 2rem 3rem',
      }}
      gap="xl"
    >
      <MSWMEventConfirmationForm
        event={event}
        updateGrid={updateGrid}
        removeEvent={removeEvent}
      />
      <MSWMAuditLogTable
        eventDate={event?.eventDate}
        eventType={event?.eventType}
        productIdentifier={event?.productIdentifier}
        flex={1}
        miw={250}
      />
    </Group>
  );
};
