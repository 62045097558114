import React, { useState } from 'react';
import {
  Container,
  Text,
  Space,
  Divider,
  Autocomplete,
  Affix,
  rem,
  Transition,
  Button,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import MissingDataTable from './MissingDataTable';
import UnderlierModal from './UnderlierModal';
import PricingCSVUploadButton from './MissingMarketDataUpload';
import { findMissingPricing } from '../../../API/market-data-maintenance.service';
import { IconArrowUp } from '@tabler/icons-react';
import { useWindowScroll } from '@mantine/hooks';

type MissingPriceData = {
  underlier: {
    bloombergTicker: string;
    ticker: string;
    securityName: string;
  };
  missingPricesDates?: string[];
};

const MissingMarketData: React.FC = () => {
  const [selectedUnderlier, setSelectedUnderlier] = useState<null | any>(null);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const {
    data: responseData,
    isLoading,
    error,
  } = useQuery(['missingPricing'], findMissingPricing);
  const [scroll, scrollTo] = useWindowScroll();
  const handleTickerClick = (underlier: any) => {
    setSelectedUnderlier(underlier);
    setIsModalOpened(true);
  };

  const filteredData = (responseData?.data.filter(
    (item: MissingPriceData) =>
      item.underlier.bloombergTicker
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      item.underlier.ticker.toLowerCase().includes(search.toLowerCase()) ||
      item.underlier.securityName.toLowerCase().includes(search.toLowerCase()),
  ) || []) as MissingPriceData[];

  return (
    <Container size="md">
      <h2>Missing Market Data Page</h2>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {(error as Error).message}</p>}
      {responseData?.data && (
        <>
          <Text ta="center" size="xl" style={{ marginBottom: '1rem' }}>
            Missing Price Date:{' '}
            {responseData.data[0]?.missingPricesDates?.join(', ')}
          </Text>

          <PricingCSVUploadButton />

          <Space h={32} />
          <Divider />
          <Space h={32} />

          <Autocomplete
            styles={{
              dropdown: { overflowY: 'auto', maxHeight: '20em' },
            }}
            limit={10}
            data={
              responseData.data?.map(
                (item: any) => item.underlier.bloombergTicker,
              ) || []
            }
            placeholder="Search Bloomberg Ticker..."
            value={search}
            onChange={setSearch}
          />

          <MissingDataTable
            data={filteredData}
            handleTickerClick={handleTickerClick}
          />
          <UnderlierModal
            isModalOpened={isModalOpened}
            onClose={() => setIsModalOpened(false)}
            underlier={selectedUnderlier}
          />
        </>
      )}
      <Affix position={{ bottom: rem(20), right: rem(20) }}>
        <Transition transition="slide-up" mounted={scroll.y > 600}>
          {(transitionStyles) => (
            <Button
              leftSection={<IconArrowUp size="1rem" />}
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
            >
              Scroll to top
            </Button>
          )}
        </Transition>
      </Affix>
    </Container>
  );
};

export default MissingMarketData;
