import React, { ForwardedRef, forwardRef } from 'react';

import { PFNumberInput } from '../reusable-form-component/BaseFormComponents';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { ArrayNumberItem } from '../../../components/ArrayNumberItem';
import { isFieldDisabled } from '../../../utilities/utilities';
import { useProductFormDisabled } from '../entry-and-edit-contexts/disabled-fields-context';
import { SplitButton } from '../../../components/splitButton/SplitButton';
import { IconTrash } from '@tabler/icons-react';
import AutoAnimateChildren from '../../../components/AutoAnimateChildren';
import { equityBuilderFields } from '../../../product-schema/pdw-field-subsets';
import { Button } from '@mantine/core';

const EquityBuildersFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    const disabledContext = useProductFormDisabled();
    const disableCashPoolKnockInVolume = isFieldDisabled(
      disabledContext,
      equityBuilderFields.cashPoolKnockInVolume,
    );
    const disableCashPoolKnockInLevel = isFieldDisabled(
      disabledContext,
      equityBuilderFields.cashPoolKnockInLevel,
    );

    const handleAddRow = () => {
      handleAddCashPoolKnockInVolume();
      handleAddCashPoolKnockInLevel();
    };

    const handleAddCashPoolKnockInVolume = () => {
      form.insertListItem(equityBuilderFields.cashPoolKnockInVolume, undefined);
    };

    const handleAddCashPoolKnockInLevel = () => {
      form.insertListItem(equityBuilderFields.cashPoolKnockInLevel, undefined);
    };

    const handleRemoveCashPoolKnockInLevel = (index: number) => {
      form.removeListItem(equityBuilderFields.cashPoolKnockInLevel, index);
    };
    const handleRemoveCashPoolKnockInVolume = (index: number) => {
      form.removeListItem(equityBuilderFields.cashPoolKnockInVolume, index);
    };
    return (
      <div style={{ margin: '0 5%', containerType: 'inline-size' }} ref={ref}>
        <div className={'form-subsection'}>
          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="Equity Pool"
            fieldPath={equityBuilderFields.equityPool}
          ></PFNumberInput>

          <PFNumberInput
            decimalScale={6}
            label="Trigger Participation Rate"
            rightSection={<div>%</div>}
            fieldPath={equityBuilderFields.triggerParticipationRate}
          ></PFNumberInput>
        </div>

        <div style={{ marginTop: '2em' }}>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              gap: '1em',
              alignItems: 'center',
            }}
          >
            <h3 style={{ margin: 0 }}>Cash Pool</h3>
            <SplitButton
              primaryButton={{
                label: 'Add Row',
                fn: handleAddRow,
                disabled:
                  disableCashPoolKnockInVolume || disableCashPoolKnockInLevel,
              }}
              secondaryButtons={[
                {
                  label: 'Add Level',
                  fn: handleAddCashPoolKnockInLevel,
                  disabled: disableCashPoolKnockInLevel,
                },
                {
                  label: 'Add Volume',
                  fn: handleAddCashPoolKnockInVolume,
                  disabled: disableCashPoolKnockInVolume,
                },
              ]}
            ></SplitButton>
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              gap: '2em',
              alignItems: 'flex-start',
            }}
          >
            {/*<AutoAnimateChildren style={{maxWidth: '20em', width: 'auto'}}>*/}
            <AutoAnimateChildren style={{ width: '20rem' }}>
              {form.values.productYield.cashPoolKnockInVolume?.map(
                (value, index) => (
                  <div className={'array-entry-section-container'} key={index}>
                    <ArrayNumberItem style={{ marginRight: '1rem' }}>
                      {index + 1}.
                    </ArrayNumberItem>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PFNumberInput
                        decimalScale={6}
                        rightSection={<div>%</div>}
                        label={'Knock in Volume'}
                        fieldPath={`productYield.cashPoolKnockInVolume.${index}`}
                      ></PFNumberInput>
                    </div>
                    {(form.values.productYield.cashPoolKnockInVolume?.length ||
                      1) >= 1 && (
                      <Button
                        variant={'secondary-small'}
                        style={{ margin: '0 1em' }}
                        type={'button'}
                        disabled={disableCashPoolKnockInVolume}
                        onClick={() => handleRemoveCashPoolKnockInVolume(index)}
                      >
                        <IconTrash size={18}></IconTrash>
                      </Button>
                    )}
                  </div>
                ),
              )}
            </AutoAnimateChildren>
            <AutoAnimateChildren style={{ width: '20rem' }}>
              {form.values.productYield.cashPoolKnockInLevel?.map(
                (value, index) => (
                  <div className={'array-entry-section-container'} key={index}>
                    <ArrayNumberItem style={{ marginRight: '1rem' }}>
                      {index + 1}.
                    </ArrayNumberItem>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PFNumberInput
                        decimalScale={6}
                        rightSection={<div>%</div>}
                        label={'Knock in Level'}
                        fieldPath={`productYield.cashPoolKnockInLevel.${index}`}
                      ></PFNumberInput>
                    </div>

                    <Button
                      variant={'secondary-small'}
                      style={{ margin: '0 1em' }}
                      type={'button'}
                      disabled={disableCashPoolKnockInLevel}
                      onClick={() => handleRemoveCashPoolKnockInLevel(index)}
                    >
                      <IconTrash size={18}></IconTrash>
                    </Button>
                  </div>
                ),
              )}
            </AutoAnimateChildren>
          </div>
          {/*<PFTextInput*/}
          {/*  label={'Cash Pool Knock In Volume'}*/}
          {/*  fieldPath={equityBuilderFields.cashPoolKnockInVolume}>*/}
          {/*</PFTextInput>*/}

          {/*<PFNumberInput*/}
          {/*  decimalScale={6}*/}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  rightSection={<div>%</div>}*/}
          {/*  label="Cash Pool Knock In Level"*/}
          {/*  fieldPath={equityBuilderFields.cashPoolKnockInLevel}>*/}
          {/*</PFNumberInput>*/}
        </div>
      </div>
    );
  },
);
EquityBuildersFormComponent.displayName = 'EquityBuildersFormComponent';
export default EquityBuildersFormComponent;
