import classes from '../product-entry-and-edit/editModeEntry/editModeEntry.module.scss';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ProductForm from './Product-form';
import CoreProductDetails from './form-sections/Core-product-details/Core-product-details';
import UnderlierFormComponent from './form-sections/Underliers/Underlier-form';
import ProductDatesComponent from './form-sections/Product-dates-form';
import CallDetailsFormComponent from './form-sections/Call-details-form';
import CouponDetailsFormComponent from './form-sections/Coupon-details-form';
import ProductGrowthFormComponent from './form-sections/ProductGrowthFormComponent';
import ProtectionDetailsFormComponent from './form-sections/Protection-details-form';
import ProductPreferencesComponent from './form-sections/Product-preferences-form';
import FeeDetailsFormComponent from './form-sections/Fee-details-form';
import CLNDetailsFormComponent from './form-sections/CLN-details-form';
import CMSDetailsFormComponent from './form-sections/CMS-details-form/CMS-details-form';
import EquityBuildersFormComponent from './form-sections/Equity-builders-form';
import NonCoreDetailsFormComponent from './form-sections/Non-core-details-form';
import AncillaryFeaturesFormComponent from './form-sections/Ancillary-features/Ancillary-features-form';
import ClientSpecifiesFormComponent from './form-sections/Client-specifies-form';
import OptionDetailsFormComponent from './form-sections/Option-details-form';
import { useProductForm } from './entry-and-edit-contexts/product-form-context';
import { PDWProduct, productSchema } from '../../product-schema/product.schema';
import { yupResolverWithContext } from '../../product-schema/yup-resolver-custom';
import { Button, Modal, TextInput, Tooltip } from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  mapProductFormForStorage,
  mapProductFormFROMStorage,
} from './product-form-mappings';
import {
  getCalculationAgents,
  getIssuers,
  getProduct,
} from '../../API/product-maintenance.service';
import {
  filterEmptyObjects,
  jsonReplacerKeepUndefined,
} from '../../utilities/utilities';
import { notifications } from '@mantine/notifications';
import { initialPDWProductValue } from '../../product-schema/initial-product-value';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  HiddenFieldsContextProvider,
  ProductFormHiddenFieldsContext,
} from './entry-and-edit-contexts/HiddenFields-context';
import { usePrompt } from '../../hooks/use-prompt';
import {
  getUnderlierHolidays,
  UnderlierHolidayPayload,
} from '../../API/product-metric.service';
import { get, set, unionBy } from 'lodash';
import { EditModeContext } from './entry-and-edit-contexts/edit-mode-context';
import { PRODUCT_EDIT_ROUTE, PRODUCT_ENTRY_ROUTE } from '../../Routes';
import { UnderlierSources } from '../../product-schema/pdw-select-options';
import JsonComparePicker from '../../product-schema/object-compare/JsonComparePicker';
import { flushSync } from 'react-dom';
import {
  ancillaryFeaturesFields,
  CalendarDatesFields,
  callDetailsFields,
  clientSpecificFields,
  clnDetailsFields,
  cmsDetailsFields,
  CoreDetailsFields,
  couponDetailsFields,
  equityBuilderFields,
  feeDetailsFields,
  nonCoreDetailsFields,
  optionDetailsFields,
  preferenceFields,
  productDatesFields,
  productGrowthFields,
  protectionFields,
  underlierFields,
} from '../../product-schema/pdw-field-subsets';
import CalendarDates from './form-sections/CalendarDates/CalendarDates';
import { useListState } from '@mantine/hooks';
import { FormSectionProperties, PDWFormSectionIds } from './form-section.types';
import { COMPARE_PRODUCT_SEARCH_PARAM } from '../../API/api-consts';
import { getStagedPDWProduct } from '../../API/pdw.service';
import {
  ProductCompareFieldsBlackList,
  ProductCopyFieldsBlackList,
} from '../../product-schema/object-compare/JsonCompareBlackLists';
import { JsonViewAndEdit } from '../../product-schema/object-compare/JsonViewAndEdit';
import { useIsProductCalled } from './useIsProductCalled';

export const LOCAL_STORAGE_PDW_ENTRY = 'product-entry-form';

// The formFields within each section are used to reference errors when they exist within the section. They should
// effectively be 1:1 with the properties used within the form section.
export const DefaultFormSections: FormSectionProperties[] = [
  {
    expanded: true,
    id: PDWFormSectionIds.coreDetails,
    label: 'Core Product Details',
    component: <CoreProductDetails></CoreProductDetails>,
    formFields: [...Object.values(CoreDetailsFields)],
  },
  {
    id: PDWFormSectionIds.underliers,
    expanded: false,
    label: 'Underliers',
    component: <UnderlierFormComponent />,
    formFields: [...Object.values(underlierFields)],
  },
  {
    id: PDWFormSectionIds.productDates,
    expanded: false,
    label: 'Dates',
    component: <ProductDatesComponent />,
    formFields: [...Object.values(productDatesFields)],
  },
  {
    id: PDWFormSectionIds.calendarDates,
    expanded: false,
    label: 'Calendar Dates',
    formFields: [...Object.values(CalendarDatesFields)],
    component: <CalendarDates />,
  },
  {
    id: PDWFormSectionIds.callDetails,
    expanded: false,
    label: 'Call Details',
    component: <CallDetailsFormComponent />,
    formFields: [...Object.values(callDetailsFields)],
  },
  {
    id: PDWFormSectionIds.couponDetails,
    expanded: false,
    label: 'Coupon Details',
    component: <CouponDetailsFormComponent />,
    formFields: [...Object.values(couponDetailsFields)],
  },
  {
    id: PDWFormSectionIds.productGrowth,
    expanded: false,
    label: 'Growth Details',
    component: <ProductGrowthFormComponent />,
    formFields: [...Object.values(productGrowthFields)],
  },
  {
    id: PDWFormSectionIds.protectionDetails,
    expanded: false,
    label: 'Protection Details',
    component: <ProtectionDetailsFormComponent />,
    formFields: [...Object.values(protectionFields)],
  },
  {
    id: PDWFormSectionIds.productPreferences,
    expanded: false,
    label: 'Product Preferences',
    component: <ProductPreferencesComponent />,
    formFields: [...Object.values(preferenceFields)],
  },
  {
    id: PDWFormSectionIds.feeDetails,
    expanded: false,
    label: 'Fee Details',
    component: <FeeDetailsFormComponent />,
    formFields: [...Object.values(feeDetailsFields)],
  },
  {
    id: PDWFormSectionIds.cLNDetails,
    expanded: false,
    label: 'CLN Details',
    component: <CLNDetailsFormComponent />,
    formFields: [...Object.values(clnDetailsFields)],
  },
  {
    id: PDWFormSectionIds.cMSDetails,
    expanded: false,
    label: 'CMS Details',
    component: <CMSDetailsFormComponent />,
    formFields: [...Object.values(cmsDetailsFields)],
  },
  {
    id: PDWFormSectionIds.optionDetails,
    expanded: false,
    label: 'Option Details',
    component: <OptionDetailsFormComponent />,
    formFields: [...Object.values(optionDetailsFields)],
  },
  {
    id: PDWFormSectionIds.equityBuilders,
    expanded: false,
    label: 'Equity Builders',
    component: <EquityBuildersFormComponent />,
    formFields: [...Object.values(equityBuilderFields)],
  },
  {
    id: PDWFormSectionIds.nonCoreDetails,
    expanded: false,
    label: 'Non Core Details',
    component: <NonCoreDetailsFormComponent />,
    formFields: [...Object.values(nonCoreDetailsFields)],
  },
  {
    id: PDWFormSectionIds.ancillaryFeatures,
    expanded: false,
    label: 'Ancillary Features',
    component: <AncillaryFeaturesFormComponent />,
    formFields: [...Object.values(ancillaryFeaturesFields)],
  },
  {
    id: PDWFormSectionIds.clientSpecifies,
    expanded: false,
    label: 'Client Specifies',
    component: <ClientSpecifiesFormComponent />,
    formFields: [...Object.values(clientSpecificFields)],
  },
];

const ProductEntryAndEditPage = () => {
  const { navigator } = useContext(NavigationContext);
  const [searchParams] = useSearchParams();
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  const editMode = useContext(EditModeContext);

  const [formSectionAttributes, formSectionHandlers] =
    useListState<FormSectionProperties>(DefaultFormSections);
  const [holidayDates, setHolidayDates] = useState([]);
  const [productEditFormInitialValue, setProductEditFormInitialValue] =
    useState<PDWProduct | null>(null);
  // const [currentEditProduct, setCurrentEditProduct] = useState(null);
  const [initialProductFormValue, setInitialProductFormValue] =
    useState<any>(null);
  const [stagedProductForComparison, setStagedProductForComparison] =
    useState(null);

  const [copyProductSearchId, setCopyProductSearchId] = useState<string>('');
  const [showConfirmProductChangeModal, setShowConfirmProductChangeModal] =
    useState<boolean>(false);
  const [showEditChanges, setShowEditChanges] = useState<boolean>(false);
  const [showProductMergeCompare, setShowProductMergeCompare] = useState(false);
  const [showProductJsonEdit, setShowProductJsonEdit] = useState(false);
  // const [showConfirmResetModal, setShowConfirmResetModal] = useState<boolean>(false); // todo: confirm reset dialog stuff.

  // these queries used further in the form.
  // We load them here with their query keys so they'll be available when the component loads.
  useQuery(['getCalculationAgents'], getCalculationAgents);
  useQuery(['getIssuers'], getIssuers);

  // clear notifications
  useEffect(() => {
    return () => {
      notifications.clean();
    };
  }, []);

  // If the url loads with a product then add it to the search input and show edit mode.
  const urlParams = useParams();
  const urlProductId = urlParams?.productId
    ? decodeURIComponent(urlParams?.productId)
    : undefined;
  const productIsCalled = useIsProductCalled(urlProductId);
  useEffect(() => {
    if (urlProductId) {
      fetchProduct();
    }
  }, [urlProductId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!editMode && !urlProductId) {
      getFormValueFromLocalStorage();
    }

    const originalTitle = 'Luma Admin';
    if (urlProductId && editMode) {
      document.title = urlProductId;
    } else {
      document.title = originalTitle;
    }
    return () => {
      document.title = originalTitle;
    };
  }, [editMode, urlProductId]); // eslint-disable-line react-hooks/exhaustive-deps

  const productForm = useProductForm({
    initialValues: initialPDWProductValue,
    // we replaced this with our own custom resolver to gain access to full data in .when() and .test() clauses
    validate: yupResolverWithContext(
      productSchema,
      holidayDates,
      productIsCalled,
    ),
    validateInputOnBlur: true,
  });

  /* Start
   * Getting Holidays from underliers
   * */

  const getHolidayPayload = (
    form: PDWProduct,
  ): UnderlierHolidayPayload | undefined => {
    if ((form.productGeneral?.underlierList?.length || 0) === 0) {
      return undefined;
    }
    return form.productGeneral?.underlierList
      ?.filter((x) => x.underlierSymbol)
      ?.reduce<UnderlierHolidayPayload>(
        (payload: any, underlier: any) => {
          if (underlier.underlierSource === UnderlierSources.Bloomberg) {
            return {
              ...payload,
              bloombergTickers: [
                ...payload.bloombergTickers,
                underlier.underlierSymbol,
              ],
            };
          } else if (underlier.underlierSource === UnderlierSources.RIC) {
            return {
              ...payload,
              rics: [...payload.rics, underlier.underlierSymbol],
            };
          }
          return payload;
        },
        {
          bloombergTickers: [],
          rics: [],
        },
      );
  };

  const reduceHolidaysIntoSingleList = (codeToHolidayMappings: any) => {
    return Object.values(codeToHolidayMappings)
      .reduce<any>((holidaydateList: any, currentHolidayDateList: any) => {
        return unionBy(holidaydateList, currentHolidayDateList, 'date');
      }, [])
      .map((x: any) => x.date);
  };

  const getHolidays = useMutation(getUnderlierHolidays, {
    onSuccess: (res) => {
      const unmappedUnderlierCodes = []; // = res.data.values().filter((x: any) => x?.length == 0 || x == null);
      for (const [key, value] of Object.entries(res?.data)) {
        if ((value as any[])?.length === 0 || value == null) {
          unmappedUnderlierCodes.push(key);
        }
      }
      if (unmappedUnderlierCodes.length > 0) {
        notifications.show({
          id: 'underlier-holidays',
          title: `Underliers are not mapped to holidays:`,
          message: unmappedUnderlierCodes.toString(),
          autoClose: 20000,
          withCloseButton: true,
          color: 'red',
        });
      }
      setHolidayDates(reduceHolidaysIntoSingleList(res.data));
    },
    onError: () => {
      setHolidayDates([]);
      notifications.show({
        id: 'underlier-holidays-error',
        title: `Unable to get holidays dates for validation:`,
        message: 'Holiday date validation will not work.',
        autoClose: false,
        withCloseButton: true,
        color: 'red',
      });
    },
    cacheTime: 10000,
  });

  useEffect(() => {
    const holidayPayload = getHolidayPayload(productForm.values);
    if (holidayPayload) {
      getHolidays.mutate(holidayPayload);
    } else {
      setHolidayDates([]);
      if (
        (productForm?.values?.productGeneral?.underlierList?.length || 0) > 0
      ) {
        notifications.show({
          id: 'underlier-holidays-error',
          title: `Unable to get holidays dates for validation:`,
          message:
            'No date validation will not account for holidays because of this.',
          autoClose: false,
          withCloseButton: true,
          color: 'red',
        });
      }
    }
  }, [productForm.values.productGeneral.underlierList?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  /* End
   * Getting Holidays from underliers
   * */

  // using a ref to do a proper unmount save to local storage:
  const formValueRef = useRef<any>();
  useEffect(() => {
    return () => {
      if (!editMode) {
        saveEntryFormToLocalStorage(formValueRef.current); // save form to storage when the component is destroyed
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // updating the ref when values change. Also validating on change.
  useEffect(() => {
    if (!editMode) {
      formValueRef.current = productForm.values;
    }
    // productForm.validate(); // we validate here so it handles undo's as well
  }, [productForm.values]); // eslint-disable-line react-hooks/exhaustive-deps
  // not including all deps to avoid infinite loop: productForm.validate changes productForm

  /** Start
   * Local storage for entry form
   */
  const getFormValueFromLocalStorage = () => {
    const storedValue = window.localStorage.getItem(LOCAL_STORAGE_PDW_ENTRY);
    if (storedValue) {
      try {
        // const fromStorageForm = mapProductFormFROMStorage(JSON.parse(storedValue, jsonReviverNullToUndefined)); // check if we need to convert nulls
        const fromStorageForm = mapProductFormFROMStorage(
          JSON.parse(storedValue),
        );
        productForm.setValues(fromStorageForm);
        setInitialProductFormValue(fromStorageForm);
        notifications.show({
          title: 'Welcome back!',
          id: 'welcomeBackProductForm',
          message: 'The form is where you left it! 😃',
          autoClose: true,
          withCloseButton: true,
        });
      } catch (e) {
        notifications.show({
          title: 'Welcome back!',
          id: 'welcomeBackProductFormNoData',
          message: `We aren't able to load in your form from local storage, sorry about that one! 🥲`,
          autoClose: true,
          withCloseButton: true,
        });
        console.log('Failed to parse stored value');
      }
    }
  };

  const saveEntryFormToLocalStorage = (productFormValue: any) => {
    window.localStorage.setItem(
      LOCAL_STORAGE_PDW_ENTRY,
      JSON.stringify(
        mapProductFormForStorage(productFormValue),
        jsonReplacerKeepUndefined,
      ),
    );
  };

  const clearFormFromStorage = () => {
    console.log('clearing form from local storage');
    window.localStorage.removeItem(LOCAL_STORAGE_PDW_ENTRY);
  };

  // We use a custom onbeforeunload because we needed to use onload for page related saving as well as prompting the user
  // otherwise the use-prompt hook would have been sufficient
  window.onbeforeunload = () => {
    if (
      !(
        window.location.pathname.includes(PRODUCT_EDIT_ROUTE) ||
        window.location.pathname.includes(PRODUCT_ENTRY_ROUTE)
      )
    ) {
      return;
    }
    if (!editMode) {
      saveEntryFormToLocalStorage(productForm.values); // may not be necessary if we do it on change
      return;
    } else {
      if (!productForm?.isDirty()) {
        return;
      }
    }
    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const confirmExit = window.confirm(
        'Leaving will result in the loss of unsaved changes',
      );
      if (confirmExit) {
        push(...args);
      }
    };
    return () => {
      navigator.push = push;
    };
  };

  /** End
   * Local storage for entry form
   */

  useEffect(() => {
    setProductEditFormInitialValue(productForm.values);
  }, [productForm.values.productId, productForm.values.revision]); // eslint-disable-line react-hooks/exhaustive-deps

  // reset dirty state for unload events when initial form value is set
  useEffect(() => {
    productForm.resetDirty();
  }, [productEditFormInitialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStagedProductForComparisonById: any = useMutation(
    (productId: string) => getStagedPDWProduct(productId?.toUpperCase()),
    {
      onSuccess: (res) => {
        const mappedForm = mapProductFormFROMStorage(res.data);
        setStagedProductForComparison(mappedForm);
      },
      onError: () => {
        setStagedProductForComparison(null);
        // todo: notify that we couldn't get the staged product
      },
    },
  );

  const getProductById: any = useMutation(
    (productId: string) => getProduct(productId?.toUpperCase()),
    {
      onSuccess: (res, variables) => {
        const mappedForm = mapProductFormFROMStorage(res.data);
        if (!editMode) {
          // entry mode: copying an existing product
          // set all blacklisted properties to null, they aren't allowed to be copied to an entry product
          ProductCopyFieldsBlackList.forEach((field) => {
            set(mappedForm, field, null);
          });
          productForm.setValues({
            ...mappedForm,
          });
          notifications.show({
            id: `product-entry-copy-${variables}`,
            title: `Copied ${variables}`,
            message: 'Copied data from an existing product for new entry',
            autoClose: 20000,
            withCloseButton: true,
            color: 'green',
          });
        } else {
          productForm.setValues({
            ...mappedForm,
            previousStage: mappedForm.productGeneral.stage || null,
            previousStatus: mappedForm.productGeneral.status || null,
          });
        }
      },
      onError: (err, variables) => {
        if (editMode) {
          // reset form if we are in edit mode
          productForm.reset();
          setProductEditFormInitialValue(null);
        }
        if (!editMode) {
          notifications.show({
            id: `product-entry-copy-${variables}`,
            title: `Copy Failed for ${variables}`,
            message:
              'Unable to copy data from an existing product for new entry',
            withCloseButton: true,
            autoClose: 20000,
            color: 'red',
          });
        }
      },
    },
  );

  useEffect(() => {
    if (urlProductId && searchParams.get(COMPARE_PRODUCT_SEARCH_PARAM)) {
      getStagedProductForComparisonById.mutate(urlProductId.toString());
    } else {
      setStagedProductForComparison(null);
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps
  // eslint disabled to avoid infinite loop, a useEffect seemed to be the only reasonable way to deal with this

  const fetchProduct = () => {
    if (urlProductId) {
      getProductById.mutate(urlProductId);
    }
  };

  const onSectionHeaderClicked = useCallback(
    (sectionId: string) => {
      formSectionHandlers.setState(
        formSectionAttributes.map((section: any) => {
          if (section.id === sectionId) {
            return {
              ...section,
              expanded: !section.expanded,
            };
          }
          return section;
        }),
      );
    },
    [formSectionAttributes, formSectionHandlers],
  );

  const onCollapseOthersClicked = useCallback(
    (sectionId: string) => {
      formSectionHandlers.setState(
        formSectionAttributes.map((section: any) => {
          return { ...section, expanded: section.id === sectionId };
        }),
      );
    },
    [formSectionAttributes, formSectionHandlers],
  );

  const handleMoveActiveSectionsToTop = () => {
    const valueSections: FormSectionProperties[] = [];
    const emptySections: FormSectionProperties[] = [];
    formSectionAttributes.forEach((section: any) => {
      const hasValues = section.formFields.some((x: string) => {
        const val = get(productForm?.values, x);
        return (
          val !== undefined &&
          val !== false &&
          val !== null &&
          val !== '' &&
          (!Array.isArray(val) || val.filter(filterEmptyObjects).length !== 0)
        );
      });
      if (hasValues) {
        valueSections.push(section);
      } else {
        emptySections.push(section);
      }
    });
    formSectionHandlers.setState([...valueSections, ...emptySections]);
  };

  const allExpanded = useMemo(
    () =>
      formSectionAttributes.every(
        (x) => x.expanded || x.id === PDWFormSectionIds.calendarDates,
      ),
    [formSectionAttributes],
  );
  const onExpandButtonClick = () => {
    if (allExpanded) {
      formSectionHandlers.setState(
        formSectionAttributes.map((section: any) => {
          return { ...section, expanded: false };
        }),
      );
    } else {
      formSectionHandlers.setState(
        formSectionAttributes.map((section: any) => {
          if (section.id === PDWFormSectionIds.calendarDates) {
            // we don't want to expand it on expand all in case there are loads of dates
            return section;
          }
          return { ...section, expanded: true };
        }),
      );
    }
  };

  const handleShowChanges = () => {
    setShowEditChanges(true);
  };

  const handleShowJsonEdit = () => {
    setShowProductJsonEdit(true);
  };

  const handleShowProductMergeCompare = () => {
    setShowProductMergeCompare(true);
  };

  const handleSoftResetProductForm = () => {
    flushSync(() => {
      productForm.setValues(initialProductFormValue || initialPDWProductValue);
    });
    setTimeout(() => productForm.validate());
  };

  const handleUpdateProductFormValues = (newValue: any) => {
    productForm.setValues(mapProductFormFROMStorage(newValue));
  };

  const handleNewEntryForm = () => {
    productForm.reset();
    productForm.setValues(initialPDWProductValue);
    hiddenFieldsContext.setHiddenFields([]);
    if (hiddenFieldsContext.showAllHiddenFields) {
      hiddenFieldsContext.toggleShowAll();
    }
    clearFormFromStorage();
  };

  const handleSoftResetProductEditForm = () => {
    // todo: confirm edit mode form reset if changes exist
    if (productEditFormInitialValue) {
      productForm.setValues(productEditFormInitialValue);
    }
  };

  // prompt the user if edits exist on the product edit form, since we don't store that in local storage.
  usePrompt(
    'Leaving will result in unsaved changes for the "Edit Product" section.',
    productForm.isDirty() && editMode,
    false,
  );
  return (
    <>
      {!editMode && (
        <div className={classes.entryAndEditHeader}>
          <h2 className={classes.title}>New Product Entry</h2>
          {!editMode && (
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}
            >
              <TextInput
                value={copyProductSearchId}
                placeholder={'Enter CUSIP/ISIN to copy from'}
                onChange={(e) => setCopyProductSearchId(e.target.value)}
                // onKeyPress={(e) => e.key === 'Enter' }
              ></TextInput>

              <Tooltip
                position={'right'}
                zIndex={1000}
                label={'Copy Product Fields from an existing product'}
                openDelay={500}
                withArrow
              >
                <Button
                  variant="primary"
                  disabled={
                    copyProductSearchId == null ||
                    (copyProductSearchId?.trim().length !== 9 &&
                      copyProductSearchId?.trim().length !== 12)
                  }
                  type={'button'}
                  onClick={() =>
                    getProductById.mutate(copyProductSearchId?.trim())
                  }
                >
                  Copy Product
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      <HiddenFieldsContextProvider>
        {((editMode && (getProductById.data || productForm.values.productId)) ||
          !editMode) && (
          <ProductForm
            {...{
              handleMoveActiveSectionsToTop,
              fetchProduct,
              handleShowChanges,
              handleShowJsonEdit,
              handleNewEntryForm,
              handleSoftResetProductEditForm,
              handleSoftResetProductForm,
              onCollapseOthersClicked,
              onSectionHeaderClicked,
              formSectionAttributes,
              onExpandButtonClick,
              allExpanded,
              formSectionHandlers,
            }}
            handleShowProductMergeCompare={
              stagedProductForComparison
                ? handleShowProductMergeCompare
                : undefined
            }
            holidayDates={holidayDates}
            productForm={productForm}
          ></ProductForm>
        )}
        {((getProductById.isLoading && !productForm.values.productId) ||
          getProductById.error) && (
          <div
            style={{ textAlign: 'center', margin: 'auto', marginTop: '2em' }}
          >
            {getProductById.isLoading && <h3>Loading Product...</h3>}
            {getProductById.error && (
              <>
                <h3>Error Loading Product</h3>
                {getProductById.error.code === 'ERR_BAD_REQUEST' && (
                  <h4>Invalid Product Entered</h4>
                )}
              </>
            )}
          </div>
        )}
      </HiddenFieldsContextProvider>

      <Modal
        style={{ marginTop: '5em' }}
        opened={showConfirmProductChangeModal}
        onClose={() => setShowConfirmProductChangeModal(false)}
        title={'Changing Products will erase your unsaved changes'}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: '1em',
          }}
        >
          <Button
            variant={'secondary'}
            type={'button'}
            onClick={() => setShowConfirmProductChangeModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type={'button'}
            onClick={() => {
              setShowConfirmProductChangeModal(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>

      <Modal
        styles={{
          header: {
            padding: '1.25em 2.5em',
          },
        }}
        title={
          productForm.values.productGeneral?.cusip ||
          productForm.values.productGeneral?.isin
        }
        opened={showEditChanges}
        onClose={() => setShowEditChanges(false)}
        transitionProps={{ duration: 0 }}
        fullScreen
      >
        {showEditChanges && productEditFormInitialValue && (
          <>
            <JsonComparePicker
              readonly={true}
              base={mapProductFormForStorage(productEditFormInitialValue)}
              other={mapProductFormForStorage(productForm.values)}
            />
          </>
        )}
      </Modal>

      {stagedProductForComparison && (
        <Modal
          styles={{
            header: {
              padding: '1.25em 2.5em',
            },
          }}
          // todo: compare products should have a useful title/header
          title={
            productForm.values.productGeneral?.cusip ||
            productForm.values.productGeneral?.isin
          }
          opened={showProductMergeCompare}
          onClose={() => setShowProductMergeCompare(false)}
          transitionProps={{ duration: 0 }}
          fullScreen
        >
          {showProductMergeCompare && productEditFormInitialValue && (
            <>
              <JsonComparePicker
                blackListProps={ProductCompareFieldsBlackList}
                base={mapProductFormForStorage(productForm.values)}
                other={mapProductFormForStorage(stagedProductForComparison)}
                applyChangesCallback={handleUpdateProductFormValues}
              />
            </>
          )}
        </Modal>
      )}

      {showProductJsonEdit && (
        <Modal
          title={'Manually Edit Product JSON'}
          opened={showProductJsonEdit}
          onClose={() => setShowProductJsonEdit(false)}
          transitionProps={{ duration: 0 }}
          fullScreen
        >
          <JsonViewAndEdit
            productJson={mapProductFormForStorage(productForm.values)}
            applyChangesCallback={handleUpdateProductFormValues}
          />
        </Modal>
      )}
    </>
  );
};

export default ProductEntryAndEditPage;
