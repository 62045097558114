import React, { useContext } from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { Checkbox, CheckboxProps } from '@mantine/core';
import { isFieldDisabled } from '../../../../utilities/utilities';
import { FormFieldPath } from './FieldInput.interface';
import { ProductFormHiddenFieldsContext } from '../../entry-and-edit-contexts/HiddenFields-context';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import { derivableFieldValues } from '../../../../product-schema/pdw-field-subsets';

export interface PFCheckboxProps extends CheckboxProps, FormFieldPath {}

const PFCheckbox = ({ fieldPath, ...rest }: PFCheckboxProps) => {
  const disabledContext = useProductFormDisabled();
  const form = useProductFormContext();
  const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
  return (
    <>
      {(!hiddenFieldsContext.hiddenFields.includes(fieldPath) ||
        hiddenFieldsContext.showAllHiddenFields) && (
        <Checkbox
          {...form.getInputProps(fieldPath, { type: 'checkbox' })}
          {...rest}
          styles={{
            label: {
              textDecoration: derivableFieldValues.includes(fieldPath)
                ? 'underline'
                : 'unset',
            },
          }}
          disabled={
            rest.disabled || isFieldDisabled(disabledContext, fieldPath)
          }
        ></Checkbox>
      )}
    </>
  );
};

export default PFCheckbox;
