import React from 'react';
import { useProductFormContext } from '../../../entry-and-edit-contexts/product-form-context';
import { isFieldDisabled, isWeekend } from '../../../../../utilities/utilities';
import { useProductFormDisabled } from '../../../entry-and-edit-contexts/disabled-fields-context';
import { ArrayNumberItem } from '../../../../../components/ArrayNumberItem';
import {
  PFDatePicker,
  PFNumberInput,
} from '../../../reusable-form-component/BaseFormComponents';
import { IconTrash } from '@tabler/icons-react';
import AutoAnimateChildren from '../../../../../components/AutoAnimateChildren';
import { Button } from '@mantine/core';

const RateMinComponent = () => {
  const form = useProductFormContext();
  const disabledContext = useProductFormDisabled();
  const disableFields = isFieldDisabled(disabledContext, 'productCln.rateMin');
  const addRateMin = () => {
    if (disableFields) {
      return;
    }
    form.insertListItem(
      'productCln.rateMin',
      {
        rateMin: undefined,
        rateMinStartDate: undefined,
        rateMinEndDate: undefined,
      },
      0,
    );
  };
  const removeRateMin = (index: number) => {
    if (disableFields) {
      return;
    }
    form.removeListItem('productCln.rateMin', index);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: '1em',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: 0 }}>Rate Mins</h3>
        <Button
          variant="primary"
          type={'button'}
          disabled={disableFields}
          onClick={addRateMin}
        >
          Add Rate Min
        </Button>
      </div>
      <AutoAnimateChildren>
        {form.values.productCln.rateMin?.map((rateMin, index) => (
          <div className={'array-entry-section-container'} key={index}>
            <div className={'array-entry-section'}>
              <ArrayNumberItem>{index + 1}.</ArrayNumberItem>

              <PFNumberInput
                decimalScale={6}
                rightSection={<div>%</div>}
                label="Rate Min"
                disabled={disableFields}
                fieldPath={`productCln.rateMin.${index}.rateMin`}
              ></PFNumberInput>

              <PFDatePicker
                label="Start Date"
                disabled={disableFields}
                excludeDate={isWeekend}
                fieldPath={`productCln.rateMin.${index}.rateMinStartDate`}
              />

              <PFDatePicker
                label="End Date"
                disabled={disableFields}
                excludeDate={isWeekend}
                fieldPath={`productCln.rateMin.${index}.rateMinEndDate`}
              />
              <Button
                variant={'secondary-small'}
                type={'button'}
                disabled={disableFields}
                onClick={() => removeRateMin(index)}
              >
                <IconTrash size={18}></IconTrash>
              </Button>
            </div>
          </div>
        ))}
      </AutoAnimateChildren>
    </>
  );
};

export default RateMinComponent;
