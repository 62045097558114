import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ConfirmationStatus,
  deleteEventCoreRecord,
  DeleteEventCoreRecordRequest,
  EventConfirmation,
  EventCoreRecord,
  getEventConfirmationsAuditLogKey,
  getEventCoreRecord,
  GetEventCoreRecordRequest,
  getIssuerConfirmation,
  GetIssuerConfirmationRequest,
  IssuerConfirmation,
  IssuerConfirmationStatus,
  putIssuerConfirmation,
} from '../../../API/event-service';
import {
  Button,
  Group,
  List,
  Loader,
  Modal,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { useMemo, useState } from 'react';
import { notifications } from '@mantine/notifications';

interface UpdateStatusButtonProps {
  event: EventConfirmation | undefined;
  updateGrid?: (event: EventConfirmation) => void;
  removeEvent?: (event: EventConfirmation | undefined) => void;
  action: 'resetToPending' | 'delete';
}
export const UpdateStatusButton = ({
  event,
  updateGrid,
  removeEvent,
  action,
}: UpdateStatusButtonProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    mutate: getEventCoreRecordMutate,
    isLoading: isGetEventCoreRecordLoading,
    data: eventCoreRecord,
    isError: isGetEventCoreRecordError,
  } = useMutation(
    (payload: GetEventCoreRecordRequest) => getEventCoreRecord(payload),
    {
      onSuccess: () => {
        if (event?.eventDate && event?.eventType && event?.productIdentifier)
          queryClient.invalidateQueries(
            getEventConfirmationsAuditLogKey({
              eventDate: event?.eventDate,
              eventType: event?.eventType,
              productIdentifier: event?.productIdentifier,
            }),
          );
      },
    },
  );

  const {
    mutate: deleteEventCoreRecordMutate,
    isLoading: isDeleteEventCoreRecordLoading,
  } = useMutation(
    (payload: DeleteEventCoreRecordRequest) => deleteEventCoreRecord(payload),
    {
      onSuccess: () => {
        if (event?.eventDate && event?.eventType && event?.productIdentifier)
          queryClient.invalidateQueries(
            getEventConfirmationsAuditLogKey({
              eventDate: event?.eventDate,
              eventType: event?.eventType,
              productIdentifier: event?.productIdentifier,
            }),
          );
      },
      onError: () => {
        notifications.show({
          id: 'delete-event-error',
          title: 'Error Deleting Event',
          message: `Failed to delete event.
          Please contact support. \n
           sourceId: ${eventCoreRecord?.data?.content?.[0]?.sourceId}, sourceId: ${eventCoreRecord?.data?.content?.[0]?.source}`,
          color: 'red',
          withCloseButton: true,
          autoClose: false,
        });
      },
    },
  );

  const {
    mutate: getIssuerConfirmationMutate,
    isLoading: isGetIssuerConfirmationLoading,
    data: issuerConfirmation,
    isError: isGetIssuerConfirmationError,
  } = useMutation((payload: GetIssuerConfirmationRequest) =>
    getIssuerConfirmation(payload),
  );

  const {
    mutate: updateIssuerConfirmationMutate,
    isLoading: updateIssuerConfirmationLoading,
    isError: isUpdateIssuerConfirmationError,
    error: updateIssuerConfirmationError,
  } = useMutation(
    (payload: IssuerConfirmation) =>
      putIssuerConfirmation({
        ...payload,
        status:
          action === 'resetToPending'
            ? IssuerConfirmationStatus.PENDING
            : IssuerConfirmationStatus.IGNORED,
        issuerMarkedAsMissed: false,
        lumaMarkedAsMissed: false,
      }),
    {
      onSuccess: (res, payload) => {
        if (res.data) {
          if (action === 'delete') {
            removeEvent?.(event);
          } else {
            const newEvent: EventConfirmation = {
              ...event,
              issuerMarkedAsMissed: false,
              lumaMarkedAsMissed: false,
              confirmationStatus: ConfirmationStatus.PENDING,
            } as EventConfirmation;
            updateGrid?.(newEvent);
          }
        }
        setIsOpened(false);
      },
      onError: (err) => {
        console.log('err', err);
      },
    },
  );

  const handleResetToPending = () => {
    const issuerConfirmationEvent = issuerConfirmation?.data?.[0];
    const eventCoreRecordEvent = eventCoreRecord?.data?.content?.[0];
    if (issuerConfirmationEvent) {
      updateIssuerConfirmationMutate(issuerConfirmationEvent);
    }
    if (eventCoreRecordEvent) {
      deleteEventCoreRecordMutate(eventCoreRecordEvent);
    }
  };
  const handleDeleteEventClick = () => {
    const cusipOrIsin = event?.cusip || event?.isin;
    const confirmationTypes = event?.eventType;
    const observationDate = event?.eventDate;
    if (!cusipOrIsin || !confirmationTypes || !observationDate) {
      return; // todo: show error or show that you can't get the ignore event. ideally hide button based on this
    }
    const getIssuerConfirmationPayload: GetIssuerConfirmationRequest = {
      cusipOrIsin,
      confirmationTypes,
      observationDate,
    } as GetIssuerConfirmationRequest;
    getIssuerConfirmationMutate(getIssuerConfirmationPayload);

    const getEventCoreRecordPayload: GetEventCoreRecordRequest = {
      cusipOrIsin,
      eventTypes: confirmationTypes,
      observationDate,
    };
    getEventCoreRecordMutate(getEventCoreRecordPayload);

    setIsOpened(true);
  };

  const loading = useMemo(() => {
    return (
      isDeleteEventCoreRecordLoading ||
      isGetIssuerConfirmationLoading ||
      updateIssuerConfirmationLoading ||
      isGetEventCoreRecordLoading
    );
  }, [
    isDeleteEventCoreRecordLoading,
    isGetEventCoreRecordLoading,
    isGetIssuerConfirmationLoading,
    updateIssuerConfirmationLoading,
  ]);

  const noEventsFound: boolean = useMemo(
    () =>
      (eventCoreRecord &&
        issuerConfirmation &&
        (eventCoreRecord?.data?.content?.length || 0) === 0 &&
        (issuerConfirmation?.data?.length || 0) === 0) ||
      false,
    [eventCoreRecord, issuerConfirmation],
  );

  const title = useMemo(() => {
    if (isGetIssuerConfirmationLoading) {
      return 'Loading Issuer Confirmation...';
    }
    if (isGetEventCoreRecordLoading) {
      return 'Loading Event Core Record...';
    }
    if (isDeleteEventCoreRecordLoading) {
      return 'Deleting Event Core Record...';
    }

    if (updateIssuerConfirmationLoading) {
      return `Submitting: ${action === 'resetToPending' ? 'Reset to pending' : 'Deleting Event'}`;
    }
    if (isUpdateIssuerConfirmationError) {
      return `Error ${action === 'resetToPending' ? 'Resetting to pending' : 'Deleting Event'}`;
    }
    if (noEventsFound) {
      return 'Event not found';
    } else {
      if (action === 'resetToPending') {
        return `Are you sure you want to reset this event to PENDING?`;
      } else {
        return `Are you sure you want to DELETE this event?`;
      }
    }
  }, [
    noEventsFound,
    isGetIssuerConfirmationLoading,
    isGetEventCoreRecordLoading,
    isDeleteEventCoreRecordLoading,
    updateIssuerConfirmationLoading,
    isUpdateIssuerConfirmationError,
    action,
  ]);

  const buttonLabel =
    action === 'resetToPending' ? 'Reset To Pending' : 'Delete';

  const infoList = useMemo(() => {
    if (action === 'resetToPending') {
      return (
        <Stack>
          <Text>
            Resetting an event to pending results in <strong>deleting</strong>{' '}
            the event from event core and updating the event in issuer
            confirmations to status = <strong>Pending</strong>
          </Text>
          <Text fw={500}>Use cases for Resetting to Pending:</Text>
          <List>
            <List.Item>The event is fully confirmed but incorrect </List.Item>
          </List>
        </Stack>
      );
    } else {
      return (
        <Stack>
          <Text>
            Deleting events results in <strong>deleting</strong> the event from
            event core and updating the event in issuer confirmations to status
            = <strong>Ignored</strong>
          </Text>
          <Text fw={500}>Use cases for Deleting Events:</Text>
          <List>
            <List.Item>Cliquets</List.Item>
            <List.Item>Not actually callable events</List.Item>
            <List.Item>
              When Incorrect Dates are entered and you need to remove an event
            </List.Item>
          </List>
        </Stack>
      );
    }
  }, [action]);

  return (
    <>
      <Button
        color={action === 'resetToPending' ? 'yellow.8' : 'red.8'}
        onClick={handleDeleteEventClick}
      >
        {buttonLabel}
      </Button>

      <Modal
        styles={{
          title: { fontSize: '1.25rem', fontWeight: 'bold' },
          ...(isUpdateIssuerConfirmationError
            ? {
                inner: {
                  border: '5px solid red',
                },
                content: {
                  border: '5px solid red',
                },
              }
            : {}),
        }}
        size={'lg'}
        opened={isOpened}
        onClose={() => setIsOpened(false)}
        title={title}
      >
        {loading && (
          <Group p={'3rem'} justify={'center'} align={'center'}>
            <Loader size={50} type={'dots'} />
          </Group>
        )}
        {noEventsFound ? (
          <>
            <Title order={3}>
              Event not found in issuer confirmations or Event core
            </Title>
            <Title order={4}>Try refreshing Events.</Title>
          </>
        ) : (
          <>
            {(updateIssuerConfirmationError as any)?.response?.data?.message}
            {issuerConfirmation && (
              <Stack>
                {infoList}
                {(issuerConfirmation?.data?.length || 0) > 0 ? (
                  <Stack gap={2}>
                    <Title order={5}>Issuer Confirmation Records</Title>
                    <Table withRowBorders={true} withTableBorder={true}>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>Product ID</Table.Th>
                          <Table.Th>Event Date</Table.Th>
                          <Table.Th>Type</Table.Th>
                          <Table.Th>Status</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {issuerConfirmation?.data?.map(
                          (row: IssuerConfirmation) => (
                            <Table.Tr key={row.id}>
                              <Table.Td>{row.productId}</Table.Td>
                              <Table.Td>{row.observationDate}</Table.Td>
                              <Table.Td>{row.confirmationType}</Table.Td>
                              <Table.Td>{row.status}</Table.Td>
                            </Table.Tr>
                          ),
                        )}
                      </Table.Tbody>
                    </Table>
                  </Stack>
                ) : isGetIssuerConfirmationError ? (
                  <Title order={4}>Error Loading Issuer Confirmation</Title>
                ) : (
                  <Title order={4}>No Issuer Confirmation Records Found</Title>
                )}

                {(eventCoreRecord?.data?.content?.length || 0) > 0 ? (
                  <Stack gap={2}>
                    <Title order={5}>Event Core Records</Title>
                    <Table withRowBorders={true} withTableBorder={true}>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>Product ID</Table.Th>
                          <Table.Th>Source</Table.Th>
                          <Table.Th>Type</Table.Th>
                          <Table.Th>Status</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {eventCoreRecord?.data?.content?.map(
                          (row: EventCoreRecord) => (
                            <Table.Tr key={row.productId + row.eventDate}>
                              <Table.Td>{row.productId}</Table.Td>
                              <Table.Td>{row.source}</Table.Td>
                              <Table.Td>{row.eventType}</Table.Td>
                              <Table.Td>{row.eventStatus}</Table.Td>
                            </Table.Tr>
                          ),
                        )}
                      </Table.Tbody>
                    </Table>
                  </Stack>
                ) : isGetEventCoreRecordError ? (
                  <Title order={4}>Error Loading Event Core Record</Title>
                ) : (
                  <Title order={4}>No Event Core Records Found</Title>
                )}

                <Group justify={'center'} gap={'3rem'}>
                  <Button
                    variant={'outline'}
                    onClick={() => setIsOpened(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={updateIssuerConfirmationLoading}
                    color={action === 'resetToPending' ? 'yellow.8' : 'red.8'}
                    onClick={handleResetToPending}
                  >
                    {buttonLabel}
                  </Button>
                </Group>
              </Stack>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
