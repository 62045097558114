export const ArrayNumberItem = ({ children }: any) => {
  return (
    <div
      style={{
        fontWeight: '700',
        fontSize: '1.3em',
      }}
    >
      {children}
    </div>
  );
};
