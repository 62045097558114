export class NumberFormatters {
  public static truncateDecimals = (
    number: number | null | undefined,
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
    locale: string | undefined = undefined,
    extraLocaleStringOptions?: Intl.NumberFormatOptions, // arguments to pass to toLocaleString
  ): string => {
    if (number == null) return '';
    if (isNaN(+number)) {
      return number + '';
    }
    if (
      extraLocaleStringOptions?.style === 'percent' &&
      maximumFractionDigits
    ) {
      maximumFractionDigits += 2;
    }
    const multiplier = Math.pow(10, maximumFractionDigits),
      adjustedNum = Math.round(number * multiplier * 1e10) / 1e10,
      truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    const truncatedValue = truncatedNum / multiplier;
    return minimumFractionDigits
      ? truncatedValue.toLocaleString(locale, {
          minimumFractionDigits: minimumFractionDigits,
          maximumFractionDigits: maximumFractionDigits,
          ...extraLocaleStringOptions,
        })
      : truncatedValue.toLocaleString(locale, {
          maximumFractionDigits: maximumFractionDigits,
          ...extraLocaleStringOptions,
        });
  };
}
