import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import axios from 'axios';
import { openDocumentBlob } from '../utilities';

export const DocumentLinkRenderer = (props: ICellRendererParams) => {
  const documents = props.value;

  const fetchDocument = (fullDocumentUrl: string) => {
    axios.get(fullDocumentUrl).then((doc) => {
      openDocumentBlob(doc.data);
    });
  };

  return (
    <div>
      {documents.map((doc: any) => (
        <div
          key={doc.documentUrl}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => fetchDocument(doc.documentUrl)}
        >
          {doc.documentName}
        </div>
      ))}
      {/*todo: find a way to make this a menu, currently the grid cell renderer encloses the scope*/}
      {/*{documents?.length > 0 &&*/}
      {/*<Menu>*/}
      {/*    <Menu.Target>*/}
      {/*      /!*<IconFiles onClick={() => setShowFiles(!showFiles)}></IconFiles>*!/*/}
      {/*        <IconFiles/>*/}
      {/*    </Menu.Target>*/}

      {/*    <Menu.Dropdown>*/}
      {/*      {documents.map((doc: any) => (*/}
      {/*        <Menu.Item onClick={() => openDocumentBlob(doc.documentUrl)}>{doc.documentName}</Menu.Item>*/}
      {/*      ))}*/}
      {/*    </Menu.Dropdown>*/}
      {/*</Menu>*/}
      {/*}*/}
    </div>
  );
};

export default DocumentLinkRenderer;
