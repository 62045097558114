import React, { useEffect, useRef, useState } from 'react';
import { PDWProduct } from '../../product-schema/product.schema';
import { TextInput, useMantineColorScheme } from '@mantine/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { PRODUCT_EDIT_ROUTE } from '../../Routes';
import { IconSearch } from '@tabler/icons-react';
import { AgGridReact } from 'ag-grid-react';

interface RecentProductsGridProps {
  data: PDWProduct[];
}

const RecentProductsGrid = ({ data }: RecentProductsGridProps) => {
  const gridRef = useRef<any>();
  const { colorScheme } = useMantineColorScheme();
  const [quickFilterText, setQuickFilterText] = useState<string>('');
  const [rowCount, setRowCount] = useState();
  const defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true,
  };

  const handleQuickFilterTextChange = (value: string) => {
    setQuickFilterText(value);
  };

  const displayRows = gridRef?.current?.api?.getDisplayedRowCount();
  useEffect(() => {
    updateRowCount();
  }, [quickFilterText, displayRows]);

  const updateRowCount = () => {
    const count = gridRef?.current?.api?.getDisplayedRowCount();
    if (count != null) {
      setRowCount(gridRef?.current?.api?.getDisplayedRowCount());
    }
  };

  const columnDefs: ColDef[] = [
    {
      colId: 'cusip',
      headerName: 'CUSIP/ISIN',
      width: 150,
      valueGetter: (params) =>
        params.data.productGeneral.cusip || params.data.productGeneral.isin,
      cellRenderer: function (params: ICellRendererParams) {
        return (
          <Link to={`/${PRODUCT_EDIT_ROUTE}/${params.value}`}>
            {params.value}
          </Link>
        );
      },
    },

    {
      colId: 'createTimestamp',
      field: 'createTimestamp',
      headerName: 'Created',
      width: 220,
    },

    {
      colId: 'structureType',
      field: 'productGeneral.structureNameInternal',
      headerName: 'Structure Type',
      minWidth: 200,
      flex: 1,
    },

    {
      colId: 'issuer',
      field: 'productGeneral.issuer',
      headerName: 'Issuer',
      width: 220,
    },

    {
      colId: 'structureShortNameInternal',
      field: 'productGeneral.structureShortNameInternal',
      headerName: 'Internal Short Name',
      minWidth: 200,
      flex: 1,
    },

    {
      colId: 'updateTimestamp',
      field: 'updateTimestamp',
      headerName: 'Last Updated',
      width: 220,
    },
    {
      colId: 'opsReviewTimestamp',
      field: 'opsReviewTimestamp',
      headerName: 'Ops reviewed at:',
      width: 220,
    },

    {
      colId: 'opsReviewUserId',
      field: 'opsReviewUserId',
      headerName: 'Reviewer',
      width: 250,
    },
  ];

  return (
    <div>
      <h1 style={{ marginLeft: '1em' }}>Your Recent Products</h1>
      <TextInput
        style={{ marginLeft: '3em', width: '20em' }}
        placeholder="Search"
        value={quickFilterText}
        leftSection={<IconSearch size={14} />}
        onChange={(e) => handleQuickFilterTextChange(e.target.value)}
      />

      {rowCount != null && (
        <div style={{ margin: '.5em .5em .5em 3em' }}>{rowCount} results</div>
      )}

      <div
        className={
          colorScheme === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz'
        }
        style={{ height: '75vh', width: '95%', margin: '1em auto' }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'right',
              },
            ],
          }}
          quickFilterText={quickFilterText}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default RecentProductsGrid;
