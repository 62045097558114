import {
  EventConfirmation,
  EventCoreRow,
  AuditLogComponent,
  PhysicalSettlementType,
  IssuerConfirmationRow,
  EventTypeDisplay,
  IssuerConfirmationStatusRow,
  EventEmailRow,
  OperationSource,
  PhysicalSettlementRow,
} from '../../../API/event-service';
import { NumberFormatters } from '../../../utilities/parcers-and-formatters/number.formatters';

export enum AuditLogType {
  EMAIL = 'email',
  PAYOUT_CHANGE = 'payout change',
  STATE_CHANGE = 'state change',
}

export interface AuditLogRow {
  user: string | null;
  date: string;
  description: string;
  type: 'email' | 'state change' | 'payout change';
  adminOnBehalfOfIssuer: boolean;
  emailData?: EventEmailRow;
  audit: string | null;
}

interface PotentialCashRate {
  rateField: keyof EventCoreRow;
  label: string;
  auditField: keyof EventCoreRow;
}

const potentialCashRates: PotentialCashRate[] = [
  {
    rateField: 'luma_rate',
    label: 'Luma',
    auditField: 'luma_audit_note',
  },
  {
    rateField: 'issuer_rate',
    label: 'Issuer',
    auditField: 'luma_issuer_audit_note',
  },
  {
    rateField: 'rate',
    label: 'Confirmed',
    auditField: 'luma_audit_note',
  },
];

export const isLumaUser = (email: string | null): boolean =>
  email == null ? true : !!email?.split('@')[1]?.includes('luma');

const isAdminActingOnBehalfOfIssuer = (
  auditLogComponent: AuditLogComponent,
): boolean => {
  const { oldRow, newRow } = auditLogComponent;
  // newRow is of type issuer_confirmation and issuer marked as missed
  if (
    newRow &&
    'issuer_marked_as_missed' in newRow &&
    newRow.issuer_marked_as_missed
  ) {
    return isLumaUser(newRow.issuer_marked_as_missed_by);
  }
  // newRow is of type event_core and issuer rate changed
  else if (
    newRow &&
    'issuer_rate_last_modified_by' in newRow &&
    newRow.issuer_rate_last_modified_time
  ) {
    // confirming this was not a luma change
    if (
      (oldRow == null && newRow.issuer_rate_last_modified_time) ||
      (oldRow &&
        'issuer_rate_last_modified_by' in oldRow &&
        oldRow.issuer_rate_last_modified_time !==
          newRow.issuer_rate_last_modified_time)
    ) {
      return isLumaUser(newRow.issuer_rate_last_modified_by);
    }
  }
  return false;
};

interface PotentialPhysicalRate {
  valueField: keyof PhysicalSettlementRow;
  label: string;
}

const potentialPhysicalRates: PotentialPhysicalRate[] = [
  {
    valueField: 'luma_value',
    label: 'Luma',
  },
  {
    valueField: 'issuer_value',
    label: 'Issuer',
  },
  {
    valueField: 'value',
    label: 'Confirmed',
  },
];

export class EventConfirmationUtils {
  static getPhysicalSharesValue = (event: EventConfirmation | undefined) => {
    return (
      event?.physicalSettlements?.find(
        (x) => x.physicalSettlementType === PhysicalSettlementType.SHARES,
      ) || null
    );
  };

  static getPhysicalCashValue = (event: EventConfirmation | undefined) => {
    return (
      event?.physicalSettlements?.find(
        (x) => x.physicalSettlementType === PhysicalSettlementType.CASH_IN_LIEU,
      ) || null
    );
  };

  static formatAuditLogComponents = (
    auditLogComponents: AuditLogComponent[],
    isCoupon: boolean,
  ) => {
    const auditLogRows: AuditLogRow[] = [];
    auditLogComponents.forEach((auditLogComponent: AuditLogComponent) => {
      switch (auditLogComponent.operationSource) {
        case OperationSource.EVENT_CORE:
          this.formatEventCore(auditLogRows, auditLogComponent);
          break;
        case OperationSource.PHYSICAL_SETTLEMENT_CORE:
          this.formatPhysicalSettlements(auditLogRows, auditLogComponent);
          break;
        case OperationSource.ISSUER_CONFIRMATION:
          this.formatIssuerConfirmations(auditLogRows, auditLogComponent);
          break;
        case OperationSource.EVENT_CONFIRMATIONS_EMAIL:
          this.formatEmails(auditLogRows, auditLogComponent, isCoupon);
          break;
        default:
          break;
      }
    });

    return auditLogRows
      .reverse()
      .sort(
        (dateA, dateB) =>
          new Date(dateB.date).valueOf() - new Date(dateA.date).valueOf(),
      );
  };

  static pushEventToArray = (
    auditLogRows: AuditLogRow[],
    description: string,
    auditLogComponent: AuditLogComponent,
    type: AuditLogRow['type'],
    emailData: EventEmailRow | null = null,
    audit: string | null = null,
  ) => {
    const isAdminOnBehalfOfIssuer =
      isLumaUser(auditLogComponent.executedByUser) &&
      isAdminActingOnBehalfOfIssuer(auditLogComponent);
    const newRow: AuditLogRow = {
      user: auditLogComponent.executedByUser,
      description,
      date: auditLogComponent.executedTimestamp,
      type,
      adminOnBehalfOfIssuer: isAdminOnBehalfOfIssuer || false,
      ...(emailData && { emailData }),
      audit,
    };
    auditLogRows.push(newRow);
  };

  static formatEventCore = (
    auditLogRows: AuditLogRow[],
    auditLogComponent: AuditLogComponent,
  ): void => {
    const oldRow: EventCoreRow | null =
      auditLogComponent.oldRow as EventCoreRow | null;
    const newRow: EventCoreRow | null =
      auditLogComponent.newRow as EventCoreRow | null;
    potentialCashRates.forEach((potentialRate) => {
      const isCallAndConfirmed =
        (newRow?.event_type === 'AUTO_CALL' ||
          newRow?.event_type === 'ISSUER_CALL') &&
        newRow?.event_status === 'CONFIRMED';
      if (newRow?.[potentialRate.rateField] == null) return;
      if (oldRow?.[potentialRate.rateField] == null) {
        this.pushEventToArray(
          auditLogRows,
          `${potentialRate.label} Rate: ${
            isCallAndConfirmed ? 'called' : ''
          } ${NumberFormatters.truncateDecimals(
            newRow[potentialRate.rateField] as number,
            15,
            4,
          )}%`,
          auditLogComponent,
          AuditLogType.PAYOUT_CHANGE,
          null,
          (newRow?.[potentialRate.auditField] as string) || '',
        );
      } else if (
        oldRow?.[potentialRate.rateField] &&
        oldRow?.[potentialRate.rateField] !== newRow[potentialRate.rateField]
      ) {
        this.pushEventToArray(
          auditLogRows,
          `${potentialRate.label} Rate Changed: ${NumberFormatters.truncateDecimals(
            oldRow[potentialRate.rateField] as number,
            15,
            4,
          )}% → ${NumberFormatters.truncateDecimals(
            newRow[potentialRate.rateField] as number,
            15,
            4,
          )}%`,
          auditLogComponent,
          AuditLogType.PAYOUT_CHANGE,
          null,
          (newRow?.[potentialRate.auditField] as string) || '',
        );
      }
    });
  };

  static formatPhysicalSettlements = (
    auditLogRows: AuditLogRow[],
    auditLogComponent: AuditLogComponent,
  ) => {
    const oldRow: PhysicalSettlementRow | null =
      auditLogComponent.oldRow as PhysicalSettlementRow | null;
    const newRow: PhysicalSettlementRow | null =
      auditLogComponent.newRow as PhysicalSettlementRow | null;
    potentialPhysicalRates.forEach((potentialRate: PotentialPhysicalRate) => {
      if (newRow?.[potentialRate.valueField] == null) return;
      if (oldRow?.[potentialRate.valueField] == null) {
        this.pushEventToArray(
          auditLogRows,
          this.formatPhysicalSettlementLabel(
            newRow,
            potentialRate.label,
            newRow[potentialRate.valueField],
          ),
          auditLogComponent,
          AuditLogType.PAYOUT_CHANGE,
        );
      } else if (
        oldRow?.[potentialRate.valueField] &&
        oldRow?.[potentialRate.valueField] !== newRow[potentialRate.valueField]
      ) {
        this.pushEventToArray(
          auditLogRows,
          this.formatPhysicalSettlementLabel(
            newRow,
            potentialRate.label,
            newRow[potentialRate.valueField],
            oldRow[potentialRate.valueField],
          ),
          auditLogComponent,
          AuditLogType.PAYOUT_CHANGE,
        );
      }
    });
  };

  static formatPhysicalSettlementLabel = (
    newRow: PhysicalSettlementRow,
    label: string,
    newValue: string | number | null,
    oldValue: string | number | null = null,
  ): string => {
    if (newRow.physical_settlement_type === 'CASH_IN_LIEU') {
      if (oldValue) {
        return `${label} Cash in Lieu: $${oldValue} -> $${newValue}`;
      }
      return `${label} Cash in Lieu: $${newValue}`;
    } else {
      if (oldValue) {
        return `${label} Shares: ${oldValue} ${newRow.security_id} -> ${newValue} ${newRow.security_id}`;
      }
      return `${label} Shares: ${newValue} ${newRow.security_id}`;
    }
  };

  static formatIssuerConfirmations = (
    auditLogRows: AuditLogRow[],
    auditLogComponent: AuditLogComponent,
  ): void => {
    const oldRow: IssuerConfirmationRow | null =
      auditLogComponent.oldRow as IssuerConfirmationRow | null;
    const newRow: IssuerConfirmationRow | null =
      auditLogComponent.newRow as IssuerConfirmationRow | null;

    if (newRow?.status == null) return;

    if (!oldRow?.status) {
      // When the issuer confirmation is first generated (no oldRow), display the status of it
      this.pushEventToArray(
        auditLogRows,
        `Status Change: ${newRow.status}`,
        auditLogComponent,
        AuditLogType.STATE_CHANGE,
      );
    } else {
      // Display an issuer note change
      if (newRow.issuer_note && !oldRow.issuer_note) {
        this.pushEventToArray(
          auditLogRows,
          `Issuer Note: ${newRow.issuer_note}`,
          auditLogComponent,
          AuditLogType.STATE_CHANGE,
        );
      }

      // Add missed payout changes, if any
      this.addMissedPayout(auditLogRows, auditLogComponent, oldRow, newRow);

      // Display a status change
      if (oldRow.status !== newRow.status) {
        this.pushEventToArray(
          auditLogRows,
          `Status Change: ${this.formatEventStatus(oldRow.status)} → ${this.formatEventStatus(newRow.status)}`,
          auditLogComponent,
          AuditLogType.STATE_CHANGE,
        );
      }
    }
  };

  static addMissedPayout = (
    auditLogRows: AuditLogRow[],
    auditLogComponent: AuditLogComponent,
    oldRow: IssuerConfirmationRow,
    newRow: IssuerConfirmationRow,
  ) => {
    let missedText;
    if (
      [EventTypeDisplay.AUTO_CALL, EventTypeDisplay.ISSUER_CALL].includes(
        auditLogComponent.eventType,
      )
    ) {
      missedText = 'Not Called';
    } else if (auditLogComponent.eventType === EventTypeDisplay.COUPON) {
      missedText = 'Missed';
    } else {
      return;
    }

    // If status changed to Not Confirmed, event was confirmed as missed
    if (
      oldRow.status !== newRow.status &&
      newRow.status === IssuerConfirmationStatusRow.NOT_CONFIRMED
    ) {
      this.pushEventToArray(
        auditLogRows,
        `Confirmed: ${missedText}`,
        auditLogComponent,
        AuditLogType.STATE_CHANGE,
      );
    } else if (
      !oldRow.issuer_marked_as_missed &&
      newRow.issuer_marked_as_missed
    ) {
      this.pushEventToArray(
        auditLogRows,
        `Issuer: ` + missedText,
        auditLogComponent,
        AuditLogType.STATE_CHANGE,
      );
    } else if (!oldRow.luma_marked_as_missed && newRow.luma_marked_as_missed) {
      this.pushEventToArray(
        auditLogRows,
        'Luma: ' + missedText,
        auditLogComponent,
        AuditLogType.STATE_CHANGE,
      );
    }
  };

  static formatEventStatus = (
    status: IssuerConfirmationRow['status'],
  ): string => {
    switch (status) {
      case 'CALCULATED':
        return 'Calculated';
      case 'PENDING':
        return 'Pending';
      case 'CONFIRMED':
      case 'NOT_CONFIRMED': // Overriding 'Not Confirmed' status to 'Confirmed' - the former is only useful in the DB, not in the UI
        return 'Confirmed';
      case 'REQUIRES_RESOLUTION':
        return 'Review';
      default:
        return status;
    }
  };

  static formatEmails = (
    auditLogRows: AuditLogRow[],
    auditLogComponent: AuditLogComponent,
    isCoupon: boolean,
  ): void => {
    const newRow: EventEmailRow | null =
      auditLogComponent.newRow as EventEmailRow | null;
    if (newRow?.model?.table) {
      if (isCoupon) {
        let couponPayout: string | undefined = newRow.model.table.find(
          (row) => row.label === 'Contingent Coupon',
        )?.value;
        if (couponPayout) {
          couponPayout = couponPayout.split(' ')[0];
        }
        this.pushEventToArray(
          auditLogRows,
          `EmailSent${couponPayout ? `: ${couponPayout}` : ''}`,
          auditLogComponent,
          AuditLogType.EMAIL,
          newRow,
        );
      } else {
        const callPayout: string | undefined = newRow.model.table.find(
          (row) =>
            row.label === 'Principal Return' || row.label === 'Call Premium',
        )?.value;
        this.pushEventToArray(
          auditLogRows,
          `EmailSent${callPayout ? `: ${callPayout}` : ''}`,
          auditLogComponent,
          AuditLogType.EMAIL,
          newRow,
        );
      }
    }
  };
}
