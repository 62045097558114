import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Group, Stack, TextInput, useMantineColorScheme } from '@mantine/core';
import { ColDef, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { PRODUCT_EDIT_ROUTE } from '../../Routes';
import { IconSearch } from '@tabler/icons-react';
import { AgGridReact } from 'ag-grid-react';
import { getUnmergedStagedPDWProducts } from '../../API/pdw.service';
import { COMPARE_PRODUCT_SEARCH_PARAM } from '../../API/api-consts';

export const StagedReviewQueue = () => {
  const gridRef = useRef<any>();
  const { data: stagedProducts, isError: errorLoadingProducts } = useQuery(
    ['getStagedPDWProducts'],
    getUnmergedStagedPDWProducts,
  );
  const { colorScheme } = useMantineColorScheme();
  const [quickFilterText, setQuickFilterText] = useState<string>('');
  const [rowCount, setRowCount] = useState();
  const rowData = stagedProducts ? stagedProducts.data : null;
  const defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
  };

  const handleQuickFilterTextChange = (value: string) => {
    setQuickFilterText(value);
  };

  const displayRows = gridRef?.current?.api?.getDisplayedRowCount();

  const updateRowCount = useCallback(() => {
    const count = gridRef?.current?.api?.getDisplayedRowCount();
    if (count != null) {
      setRowCount(gridRef?.current?.api?.getDisplayedRowCount());
    }
  }, []);

  useEffect(() => {
    updateRowCount();
  }, [quickFilterText, displayRows, updateRowCount]);

  const columnDefs: ColDef[] = [
    {
      colId: 'cusip',
      headerName: 'CUSIP/ISIN',
      width: 150,
      valueGetter: (params) =>
        params.data.productGeneral.cusip || params.data.productGeneral.isin,
      cellRenderer: function (params: ICellRendererParams) {
        return (
          <Link
            to={`/${PRODUCT_EDIT_ROUTE}/${params.value}?${COMPARE_PRODUCT_SEARCH_PARAM}=true`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      colId: 'structureType',
      field: 'productGeneral.structureNameInternal',
      headerName: 'Structure Type',
      flex: 1,
    },
    {
      colId: 'issueDate',
      field: 'productGeneral.issueDate',
      headerName: 'Issue Date',
    },
    {
      colId: 'createTimestamp',
      field: 'createTimestamp',
      headerName: 'Create Timestamp',
      width: 240,
    },
    {
      colId: 'tradeDate',
      field: 'productGeneral.tradeDate',
      headerName: 'Trade Date',
    },
    {
      colId: 'productDataSource',
      field: 'productDataSource',
      headerName: 'Data Source',
    },
    // {
    //   colId: 'productDocuments',
    //   cellStyle: {overflow: 'visible'},
    //   autoHeight: true,
    //   field: 'productDocuments',
    //   sortable: false,
    //   headerName: 'Documents',
    //   cellRenderer: DocumentLinkRenderer
    // },
  ];
  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      updateRowCount();
    },
    [updateRowCount],
  );
  return (
    //   --global-header-height == 50px
    <Stack gap={0} style={{ height: 'calc(100vh - 50px)', padding: '1rem' }}>
      <h1>Staged Products For Comparison</h1>
      <Group align={'end'}>
        {rowCount != null && (
          <div style={{ marginLeft: '1em' }}>{rowCount} results</div>
        )}
        <TextInput
          style={{ marginLeft: '3em', width: '20em' }}
          placeholder="Search"
          value={quickFilterText}
          leftSection={<IconSearch size={14} />}
          onChange={(e) => handleQuickFilterTextChange(e.target.value)}
        />
      </Group>

      {errorLoadingProducts ? (
        <div> Error loading data</div>
      ) : (
        <div
          className={
            colorScheme === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz'
          }
          style={{
            minHeight: '50vh',
            flexGrow: 1,
            width: '100%',
            margin: '1em auto',
          }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'right',
                },
              ],
            }}
            quickFilterText={quickFilterText}
          ></AgGridReact>
        </div>
      )}
    </Stack>
  );
};
