import React from 'react';
import { minMax } from '../../../../product-schema/pdw-select-options';
import { isWeekend } from '../../../../utilities/utilities';
import {
  PFDatePicker,
  PFSelect,
} from '../../reusable-form-component/BaseFormComponents';
import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';

const WithLookbackFinalComponent = () => {
  return (
    <div>
      <h3>Lookback Final</h3>
      {/*todo: make this a hint + style these components*/}
      {/*Final valuation date is assumed as the end of the lookback period*/}

      <PFDatePicker
        fieldPath={`productGeneral.lookbackFinalDate`}
        label="Start Date of Lookback Period"
        excludeDate={isWeekend}
      />

      <PFSelect
        label="Valuation Type:"
        fieldPath={ancillaryFeaturesFields.lookbackFinalValuationType}
        data={minMax}
      />
    </div>
  );
};

export default WithLookbackFinalComponent;
