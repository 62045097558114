import axios from 'axios';
import { DOCUMENT_SERVICE_ROOT } from './api-consts';
import { DocumentObject } from '../components/documentsWidget/DocumentsWidget';

export const mapDocuments = (productDocuments: any): DocumentObject[] => {
  const documents = [];
  if (!productDocuments) {
    return [];
  }
  for (const [key, url] of Object.entries(productDocuments)) {
    if (typeof url !== 'string') continue;
    documents.push({ name: key, url: url as string });
  }
  return documents;
};

interface DocumentRetrievalObject {
  cusip: string;
  documents: { [key: string]: string }; // where the value is a document url
}

export const getDocumentByCusip = (cusip: string) =>
  axios.get<DocumentRetrievalObject[]>(
    `${DOCUMENT_SERVICE_ROOT}/document/product?cusip=${encodeURIComponent(cusip)}`,
  );
export const getDocumentByUrl = (documentURl: string) =>
  axios.get(documentURl, { responseType: 'blob' });
