import { Button } from '@mantine/core';

const SampleCSVTemplate: React.FC = () => {
  const currentDate = new Date();

  // Format the date as 'MM/DD/YY'
  const formattedDate =
    currentDate.getMonth() +
    1 +
    '/' +
    currentDate.getDate() +
    '/' +
    (currentDate.getFullYear() % 100);

  const csvContent = `
BLOOMBERG_TICKER,DATE,PRICE
SAMPLE TICKER,${formattedDate},1000.00
`;

  // Convert the content into a Blob
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const blobUrl = window.URL.createObjectURL(blob);

  return (
    <div>
      <a
        href={blobUrl}
        download="sample_template.csv"
        style={{ textDecoration: 'none' }}
      >
        <Button variant={'secondary'}>Download Sample CSV</Button>
      </a>
    </div>
  );
};

export default SampleCSVTemplate;
