import React from 'react';
import { Paper, Text } from '@mantine/core';

const AccessDenied = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15%',
      }}
    >
      <Paper shadow="md" p="xl" withBorder style={{ textAlign: 'center' }}>
        <Text>You don't have access to this page</Text>
        <Text>For assistance, please contact luma administration.</Text>
      </Paper>
    </div>
  );
};

export default AccessDenied;
