import { read, utils, writeFile } from 'xlsx';
import {
  Container,
  Button,
  rem,
  Stack,
  Table,
  Text,
  Title,
  useMantineColorScheme,
  Space,
  Switch,
  Group,
  CopyButton,
} from '@mantine/core';
import { useState } from 'react';
import { Dropzone, FileWithPath, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone';
import {
  EventConfirmationGeneral,
  updateEventsGeneral,
} from '../../../API/event-service';
import { IconFileText, IconUpload, IconX } from '@tabler/icons-react';
import { formatDate, getJsDateFromExcel } from '../../../utilities/utilities';
import { useMutation } from '@tanstack/react-query';

type ExcelFileHeaders =
  | 'CUSIP'
  | 'Event Date'
  | 'Event Type'
  | 'Settlement Type'
  | 'Rate'
  | 'Issuer Rate'
  | 'Luma Rate'
  | 'missed';

export const EventConfirmationsGeneral = () => {
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [asIssuer, setAsIssuer] = useState<boolean>(false);
  const [isEscalationOverride, setIsEscalationOverride] =
    useState<boolean>(false);
  const [eventsToUpdate, setEventsToUpdate] = useState<
    EventConfirmationGeneral[] | undefined
  >();
  const { colorScheme } = useMantineColorScheme();
  const handelDownloadTemplate = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.book_append_sheet(wb, ws, 'Calendar Dates');
    const headers: string[] = [
      'CUSIP',
      'Event Date',
      'Event Type',
      'Settlement Type',
      'Rate',
      'Issuer Rate',
      'Luma Rate',
      'missed',
    ];
    utils.sheet_add_json(ws, [], {
      // utils.sheet_add_json(ws, [{...headers}], {
      header: headers,
      skipHeader: false,
    });
    ws['!cols'] = [
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];
    writeFile(wb, 'EventConfirmationsGeneralTemplate.xlsx');
  };

  const handleFileSubmit = async () => {
    if (file) {
      try {
        const fileBytes = await file.arrayBuffer();
        const wb = read(fileBytes);
        const data: Record<ExcelFileHeaders, string>[] = utils.sheet_to_json(
          wb.Sheets[wb.SheetNames[0]],
        );
        const eventList: EventConfirmationGeneral[] = [];
        for (const row of data) {
          const cusip = row['CUSIP'];
          const eventDate = getJsDateFromExcel(+row['Event Date']);
          const eventType = row['Event Type'];
          const settlementType = row['Settlement Type'];
          const rate = row['Rate'];
          const issuerRate = row['Issuer Rate'];
          const lumaRate = row['Luma Rate'];
          const missed = row['missed'];

          const EventObject: EventConfirmationGeneral = {
            eventIdentifier: {
              productIdentifier: cusip,
              eventDate: formatDate(eventDate), // eventDate,
              eventType: eventType,
            },
            cashSettlementRates:
              rate || lumaRate || issuerRate
                ? {
                    rate: rate,
                    lumaRate: lumaRate,
                    issuerRate: issuerRate,
                  }
                : undefined,
            settlementType: settlementType as 'Cash' | 'Physical',
            missed: missed as any,
          };
          eventList.push(EventObject);
        }
        setEventsToUpdate(eventList);
      } catch (e) {
        console.error('error reading file', e);
      }
    }
  };

  const handleFileDrop = (acceptedFile: FileWithPath[]) => {
    setFile(acceptedFile[0]);
  };

  const {
    mutate: mutateSubmitEvents,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(() =>
    updateEventsGeneral(eventsToUpdate, { asIssuer, escalationOverride: true }),
  );

  const submitEvents = () => {
    mutateSubmitEvents();
  };

  return (
    <Container size={'xl'}>
      <Title pt={'xl'} order={2}>
        Bulk Event Confirmations Update
      </Title>

      <Button pl={0} onClick={handelDownloadTemplate} variant={'transparent'}>
        Download Template
      </Button>

      <Text>
        To generate multiple event records, using CUSIP/Product Identifier,
        Event Type, YYYY-MM-DD, Rates, Settlement type, or mark as called or
        missed.
      </Text>

      <Dropzone
        onDrop={handleFileDrop}
        multiple={false}
        style={{ cursor: 'pointer' }}
        radius="md"
        bg={colorScheme === 'dark' ? 'gray.8' : 'gray.1'}
        accept={MS_EXCEL_MIME_TYPE}
      >
        <Stack
          justify={'center'}
          align="center"
          gap="xl"
          mih={220}
          style={{ pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <IconUpload
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-blue-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-red-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <>
              {file ? (
                <>
                  <Title order={3}>{file.name}</Title>
                  <Text>
                    Last Modified:{' '}
                    {new Date(file.lastModified)?.toLocaleDateString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Text>
                  <Text>Size: {file.size} bytes</Text>
                </>
              ) : (
                <IconFileText
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: 'var(--mantine-color-dimmed)',
                  }}
                  stroke={1.5}
                />
              )}
            </>
          </Dropzone.Idle>

          {!file && (
            <div>
              <Text size="md" inline>
                Drag Excel Date File or Click To Select File
              </Text>
            </div>
          )}
        </Stack>
      </Dropzone>

      <Button
        fullWidth={true}
        mt={'0px'}
        disabled={!file}
        onClick={handleFileSubmit}
        size={'sm'}
      >
        Process File
      </Button>

      <Space h={'xl'} />

      {(eventsToUpdate?.length || 0) > 0 && (
        <>
          <Table striped withTableBorder>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>CUSIP</Table.Th>
                <Table.Th>Event Date</Table.Th>
                <Table.Th>Event Type</Table.Th>
                <Table.Th>Settlement Type</Table.Th>
                <Table.Th>Rate</Table.Th>
                <Table.Th>Issuer Rate</Table.Th>
                <Table.Th>Luma Rate</Table.Th>
                <Table.Th>missed</Table.Th>
                <Table.Th>Processed</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {eventsToUpdate?.map((row: EventConfirmationGeneral) => (
                <Table.Tr
                  key={
                    row.eventIdentifier.eventDate +
                    row.eventIdentifier.eventType +
                    row.eventIdentifier.productIdentifier
                  }
                >
                  <Table.Td>{row.eventIdentifier.productIdentifier}</Table.Td>
                  <Table.Td>{row.eventIdentifier.eventDate}</Table.Td>
                  <Table.Td>{row.eventIdentifier.eventType}</Table.Td>
                  <Table.Td>{row.settlementType}</Table.Td>
                  <Table.Td>{row.cashSettlementRates?.rate}</Table.Td>
                  <Table.Td>{row.cashSettlementRates?.issuerRate}</Table.Td>
                  <Table.Td>{row.cashSettlementRates?.lumaRate}</Table.Td>
                  <Table.Td>{row.missed.toString()}</Table.Td>
                  <Table.Td>todo: process</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>

          <Group mt={'sm'} justify={'space-between'}>
            <Group>
              <Switch
                label={'As Issuer'}
                checked={asIssuer}
                onChange={(event) => setAsIssuer(event.currentTarget.checked)}
              />
              <Switch
                label={'Escalation Override'}
                checked={isEscalationOverride}
                onChange={(event) =>
                  setIsEscalationOverride(event.currentTarget.checked)
                }
              />

              <CopyButton value={JSON.stringify(eventsToUpdate)}>
                {({ copied, copy }) => (
                  <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                    {copied ? 'Copied JSON to clipboard' : 'Copy JSON'}
                  </Button>
                )}
              </CopyButton>
            </Group>
          </Group>

          <Button
            mt={'sm'}
            fullWidth={true}
            onClick={submitEvents}
            loading={isLoading}
          >
            Submit Events
          </Button>
          {isSuccess && (
            <Text c={'green.7'}>Events submitted successfully.</Text>
          )}
          {isError && (
            <Text c={'red.7'}>
              Error submitting events. Please try again or contact support.
            </Text>
          )}
        </>
      )}
    </Container>
  );
};
