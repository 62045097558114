// export const isLocal = process.env['REACT_APP_LOCAL'];
export const isLocal = process.env['REACT_APP_LOCAL'];
export const apiUrl = process.env['REACT_APP_API_URL'];
export const envName = process.env['REACT_APP_ENVIRONMENT_NAME'];
export const isProd = JSON.parse(JSON.stringify(envName)) === 'prod';
export const goDomain = process.env['REACT_APP_GO_DOMAIN'];
export const angularDomain = process.env['REACT_APP_ANGULAR_DOMAIN'];
export const cfDomain = process.env['REACT_APP_CF_DOMAIN'];
export const deploymentVersion =
  process.env['REACT_APP_CIRCLECI_DEPLOYMENT_VERSION'];
