import React, { useState } from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { WithAverageComponentProps } from './Ancillary-features-form';
import { Button } from '@mantine/core';
import { highLow } from '../../../../product-schema/pdw-select-options';
import { isFieldDisabled, isWeekend } from '../../../../utilities/utilities';
import {
  PFDatePicker,
  PFSelect,
} from '../../reusable-form-component/BaseFormComponents';
import { useProductFormDisabled } from '../../entry-and-edit-contexts/disabled-fields-context';
import { ArrayNumberItem } from '../../../../components/ArrayNumberItem';
import { IconTrash } from '@tabler/icons-react';
import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';
import { get } from 'lodash';
import { DatePickerInput } from '@mantine/dates';

const WithAverageOutComponent = ({
  showExclusion = false,
}: WithAverageComponentProps) => {
  const form = useProductFormContext();
  const disabledContext = useProductFormDisabled();
  const disableFields = isFieldDisabled(
    disabledContext,
    ancillaryFeaturesFields.averagingOutDates,
  );
  const addDate = () => {
    form.insertListItem(
      ancillaryFeaturesFields.averagingOutDates,
      undefined,
      0,
    );
  };
  const removeDate = (index: number) => {
    form.removeListItem(ancillaryFeaturesFields.averagingOutDates, index);
  };

  const [multiSelectDatesSelected, setMultiSelectDatesSelected] = useState<
    Date[]
  >([]);
  const handleAddMultipleDates = () => {
    const currentDates = get(
      form.values,
      ancillaryFeaturesFields.averagingOutDates,
    );
    const newDates = [...currentDates, ...multiSelectDatesSelected];
    form.setFieldValue(ancillaryFeaturesFields.averagingOutDates, newDates);
    setMultiSelectDatesSelected([]);
  };

  const handleSortDates = () => {
    form.setFieldValue(
      ancillaryFeaturesFields.averagingOutDates,
      get(form.values, ancillaryFeaturesFields.averagingOutDates)
        .filter((x: any) => x)
        .sort(function compare(a: Date, b: Date) {
          return a.getTime() - b.getTime();
        }),
    );
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: '1em',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: 0 }}>Averaging Out Dates</h3>
        <Button
          disabled={disableFields}
          type={'button'}
          className={'basic-button'}
          onClick={addDate}
        >
          Add Date
        </Button>
        <Button onClick={handleSortDates} type={'button'}>
          Sort Dates
        </Button>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <DatePickerInput
            firstDayOfWeek={0}
            type="multiple"
            label="Select Multiple Dates"
            placeholder="Pick dates"
            value={multiSelectDatesSelected}
            onChange={setMultiSelectDatesSelected}
            mx="auto"
            clearable={true}
            valueFormat={'MM-DD-YYYY'}
            popoverProps={{ position: 'left-start' }}
            w={130}
          />
          <Button
            disabled={multiSelectDatesSelected?.length === 0}
            type={'button'}
            className={'basic-button'}
            onClick={handleAddMultipleDates}
          >
            Add Multiple Dates
          </Button>
        </div>
      </div>
      {showExclusion && (
        <PFSelect
          label="Average Out Exclusion"
          fieldPath={ancillaryFeaturesFields.averagingExclusion}
          data={highLow}
        />
      )}

      {form.values.productGeneral.averagingOutDates?.map((date, index) => (
        <div className={'array-entry-section-container'} key={index}>
          <div className={'array-entry-section'}>
            <ArrayNumberItem>{index + 1}.</ArrayNumberItem>

            <PFDatePicker
              label="Averaging Out Date"
              excludeDate={isWeekend}
              fieldPath={`productGeneral.averagingOutDates.${index}`}
            />

            <Button
              variant={'secondary-small'}
              type={'button'}
              disabled={disableFields}
              onClick={() => removeDate(index)}
            >
              <IconTrash size={18}></IconTrash>
            </Button>
          </div>
        </div>
      ))}
    </>
  );
};

export default WithAverageOutComponent;
