import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  MarketDataErrorResponse,
  uploadPrices,
} from '../../../API/market-data-maintenance.service';
import ResponseDataDisplay from './ResponseDataDisplay';
import SampleCSVTemplate from './SampleCSVTemplate';
import {
  Alert,
  Button,
  Checkbox,
  FileInput,
  LoadingOverlay,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import UploadConfirmation from './UploadConfirmation';
import { AxiosError } from 'axios';

function MissingMarketDataUpload() {
  const [isHistorical, setIsHistorical] = useState<boolean>(false);
  const [isIndicitive, setIsIndicitive] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [showResponseData, setShowResponseData] = useState(true);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // State for controlling the confirmation modal

  const onFileChange = (file: File | null) => {
    setFile(file);
  };

  const priceUpload = useMutation(uploadPrices, {
    onSuccess: (res) => {
      setFile(null);
      if (Array.isArray(res.data) && res.data.length === 0) {
        notifications.show({
          id: 'marketDataUpload',
          title: 'Warning!',
          message:
            'This might indicate duplicate prices or an issue with the identifiers in the upload, please check again or reach out to support.',
          color: 'yellow',
          autoClose: 30000,
        });
      } else {
        notifications.show({
          id: 'marketDataUpload',
          title: 'Success',
          message:
            'Market Data Uploaded Successfully! ' +
            res.data.length +
            ' prices uploaded.',
          color: 'green',
          withCloseButton: true,
          autoClose: 30000,
        });
      }
    },
    onError: (error: AxiosError<MarketDataErrorResponse>) => {
      setFile(null);
      // Generate a unique id for each error notification
      const notificationId = `${new Date().getTime()}`;

      const errorTitle = error.response?.data.error || 'Error';

      const errorMessage =
        error.response?.data.message +
          '. Please review the csv to ensure all rows are valid.' ||
        'An error occurred. Please contact support.';

      notifications.show({
        id: 'marketDataUpload-' + notificationId,
        title: errorTitle,
        message: errorMessage,
        color: 'red',
        withCloseButton: true,
        autoClose: 20000,
      });
    },
  });

  const handleUpload = () => {
    if (file) {
      priceUpload.mutate({ file, isIndicitive, isHistorical });
      setIsConfirmationOpen(false);
    }
  };

  const openConfirmationDialog = () => {
    if (file) {
      setIsConfirmationOpen(true); // Open the confirmation modal when the user clicks Upload
    }
  };

  const cancelUpload = () => {
    setIsConfirmationOpen(false); // Close the confirmation modal
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '2rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '1rem',
        }}
      >
        <FileInput
          style={{ width: '20rem' }}
          value={file}
          onChange={onFileChange}
          clearable={true}
          styles={{
            error: {
              position: 'absolute',
            },
          }}
          error={priceUpload.isError ? 'Error uploading file' : undefined}
          placeholder={'Select File For upload'}
        />
        <Button
          variant="primary"
          onClick={openConfirmationDialog}
          disabled={priceUpload.isLoading || !file}
        >
          Upload
        </Button>

        <UploadConfirmation
          isOpen={isConfirmationOpen}
          isIndicative={isIndicitive}
          onCancel={cancelUpload}
          onConfirm={handleUpload}
        />

        <SampleCSVTemplate />
        <Tooltip
          style={{ marginLeft: 'auto' }}
          label={
            'Only check this box if you are uploading close to EOD (4pm EST) prices'
          }
        >
          <Checkbox
            onClick={(event) => setIsIndicitive(event.currentTarget.checked)}
            checked={isIndicitive}
            label={'Indicative Pricing'}
            style={{ marginBottom: '0.5rem', marginLeft: 'auto' }}
            size={'md'}
          />
        </Tooltip>
      </div>
      <div style={{ display: 'flex', gap: '2rem', justifyContent: 'flex-end' }}>
        <Tooltip
          label={
            'Only check this box if you are uploading price dates prior to the current day (in EST)'
          }
        >
          <Checkbox
            onClick={(event) => setIsHistorical(event.currentTarget.checked)}
            checked={isHistorical}
            label={'Historical Pricing'}
            style={{ marginBottom: '0.5rem' }}
            size={'md'}
          />
        </Tooltip>
      </div>

      {isHistorical && (
        <Alert
          variant="light"
          color="blue"
          title="Historical Upload Checked"
          style={{ marginTop: '1rem' }}
        >
          This upload has been marked as a historical upload. This means there
          are price dates present prior to the current date. These prices must
          be reviewed to make sure they are correct and valid. Prices should not
          be submitted without validating.
        </Alert>
      )}

      <div>
        {priceUpload.isLoading ? (
          // Display your Spinner component when loading
          <LoadingOverlay visible={priceUpload.isLoading} />
        ) : (
          // Render your main content here
          priceUpload.data?.data.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
                marginTop: '2rem',
              }}
            >
              <Button
                variant={'secondary'}
                onClick={() => setShowResponseData(!showResponseData)}
              >
                {showResponseData ? 'Hide Response Data' : 'Show Response Data'}
              </Button>
              {showResponseData && (
                <ResponseDataDisplay data={priceUpload.data?.data} />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default MissingMarketDataUpload;
