import React from 'react';
import ExpandableNumberFieldset from '../../reusable-form-component/BaseFormComponents/ExpandableNumberFieldset';
import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';

const WithKnockOutComponent = () => {
  return (
    <div>
      <h3>With Knock Out</h3>
      <ExpandableNumberFieldset
        primaryField={{
          property: ancillaryFeaturesFields.knockOutBarrierFinal,
          label: 'Knockout Barrier',
        }}
        subFields={[
          {
            property: ancillaryFeaturesFields.knockOutBarrierHigh,
            label: 'High Knockout Barrier',
          },
          {
            property: ancillaryFeaturesFields.knockOutBarrierLow,
            label: 'Low Knockout Barrier',
          },
          {
            property: ancillaryFeaturesFields.knockOutBarrierCurrent,
            label: 'Current Knockout Barrier',
          },
        ]}
        percentFormat={true}
        decimalScale={6}
      />

      <ExpandableNumberFieldset
        primaryField={{
          property: ancillaryFeaturesFields.knockOutRebateFinal,
          label: 'Knockout Rebate',
        }}
        subFields={[
          {
            property: ancillaryFeaturesFields.knockOutRebateHigh,
            label: 'High Knockout Rebate',
          },
          {
            property: ancillaryFeaturesFields.knockOutRebateLow,
            label: 'Low Knockout Rebate',
          },
          {
            property: ancillaryFeaturesFields.knockOutRebateCurrent,
            label: 'Current Knockout Rebate',
          },
        ]}
        percentFormat={true}
        decimalScale={6}
      />
    </div>
  );
};

export default WithKnockOutComponent;
