import React, { useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useAuth } from '../../components/Authentication/AuthProvider';
import { Button, Container, PasswordInput, TextInput } from '@mantine/core';
import { deploymentVersion, envName, isLocal } from '../../enviroments/.env';
import { datadogLogs } from '@datadog/browser-logs';

const LoginComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onLogin } = useAuth();
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState('');

  const login = () => {
    onLogin(userName, password);
  };

  const isBotAgent = () => {
    const botPattern =
      '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'; // eslint-disable-line no-useless-escape
    const isSyntheticUserAgent = new RegExp(/CloudWatchSynthetics/, 'i').test(
      window.navigator.userAgent,
    );
    const isBotUserAgent = new RegExp(botPattern, 'i').test(
      window.navigator.userAgent,
    );

    return isSyntheticUserAgent || isBotUserAgent;
  };

  if (!isBotAgent() && isLocal !== 'true') {
    datadogLogs.init({
      clientToken: 'pub91d219764c03b7d9e8bbfe24a7a09636',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: `${envName}`,
      version: `${deploymentVersion}`,
      service: `luma-admin-app`,
      forwardConsoleLogs: 'all',
      useSecureSessionCookie: true,
      silentMultipleInit: true,
    });

    datadogRum.init({
      applicationId: 'f637ae31-2cd5-47b8-9827-e420fddcf57e',
      clientToken: 'pub91d219764c03b7d9e8bbfe24a7a09636',
      site: 'datadoghq.com',
      service: `luma-admin-app`,
      env: `${envName}`,
      version: `${deploymentVersion}`,
      allowedTracingUrls: [/https:\/\/.*\.lumafintech\.com/],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      useSecureSessionCookie: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
      beforeSend: (event) => {
        if (
          event.type === 'resource' &&
          event.resource.status_code !== undefined &&
          event.resource.status_code >= 500
        ) {
          datadogRum.addError(
            new Error(
              `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`,
            ),
          );
        }
        return true;
      },
    });
  }

  return (
    <Container style={{ marginTop: '15vh' }} size={400}>
      <form
        onSubmit={(e) => {
          login();
          e.preventDefault();
        }}
      >
        <TextInput
          label={'Username'}
          aria-label={'username'}
          value={userName}
          onChange={(event) => setUserName(event.currentTarget.value)}
        ></TextInput>
        <PasswordInput
          label={'Password'}
          aria-label={'password'}
          value={password}
          onChange={(event) => setPassword(event.currentTarget.value)}
        />
        <Button
          type="submit"
          variant="primary-full"
          disabled={!userName || !password}
          style={{ marginTop: '2em' }}
        >
          Login
        </Button>
      </form>
    </Container>
  );
};

export default LoginComponent;
