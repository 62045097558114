import React, { ForwardedRef, forwardRef } from 'react';
import { Checkbox } from '@mantine/core';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { AncillaryFeature } from '../../../../product-schema/pdw-select-options';
import AncillaryFeatureSpecificsComponent from './Ancillary-feature-specifics';
import PFCheckbox from '../../reusable-form-component/BaseFormComponents/PFCheckbox';
import AutoAnimateChildren from '../../../../components/AutoAnimateChildren';
import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';

export interface WithAverageComponentProps {
  showExclusion: boolean;
}

const AncillaryFeaturesFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    //todo: do we want to null out values when ancillary features change, or do we just want to hide them from the ui??
    // current system nulls, so it seems appropriate
    // const onAcillaryFeaturesChange = (value: string[]) => {

    // form.setFieldValue(`productGeneral.ancillaryFeatures`, value);
    // }

    const isFeatureDisabled = (feature: string): boolean => {
      // console.log('feature', AncillaryFeature, form.values?.productGeneral?.ancillaryFeatures);
      switch (feature) {
        case AncillaryFeature.With_Modified_Averaging_In: // fallthrough
        case AncillaryFeature.With_Modified_Averaging_Out:
          if (
            (form.values?.productGeneral?.ancillaryFeatures || [])?.some(
              (x) =>
                x === AncillaryFeature.With_Averaging_In ||
                x === AncillaryFeature.With_Averaging_Out,
            )
          ) {
            return true;
          }
          break;
        case AncillaryFeature.With_Averaging_Out: // fallthrough
        case AncillaryFeature.With_Averaging_In:
          if (
            (form.values?.productGeneral?.ancillaryFeatures || [])?.some(
              (x) =>
                x === AncillaryFeature.With_Modified_Averaging_In ||
                x === AncillaryFeature.With_Modified_Averaging_Out,
            )
          ) {
            return true;
          }
          break;
        default:
          return false;
      }
      return false;
    };

    //question: when Ancillary options become hidden do we reset the form values on their fields? or do we do it on a submit? or persist them

    // todo: 'with minimum coupon' option is based on this in the old app? do we keep this???
    //   if(this.isValidValue(value.minPaymentRatePerAnnumFinal) ||
    //     this.isValidValue(value.minPaymentRatePerAnnumHigh) ||
    //     this.isValidValue(value.minPaymentRatePerAnnumLow) ||
    //     this.isValidValue(value.minPaymentRatePerAnnumCurrent) ||
    //     this.isValidValue(value.minPaymentRatePerPeriodFinal) ||
    //     this.isValidValue(value.minPaymentRatePerPeriodHigh) ||
    //     this.isValidValue(value.minPaymentRatePerPeriodLow) ||
    //     this.isValidValue(value.minPaymentRatePerPeriodCurrent)) {
    //     result.push('With Minimum Coupon');
    //   }

    const handleAncillaryFeaturesChange = (value: string) => {
      const currentAncillaryFeatures =
        form.values?.productGeneral?.ancillaryFeatures || [];
      if (currentAncillaryFeatures.includes(value)) {
        form.setFieldValue(
          `productGeneral.ancillaryFeatures`,
          currentAncillaryFeatures.filter((x) => x !== value),
        );
      } else {
        form.setFieldValue(`productGeneral.ancillaryFeatures`, [
          ...currentAncillaryFeatures,
          value,
        ]);
      }
    };
    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <Checkbox.Group
            size="md"
            {...form.getInputProps(ancillaryFeaturesFields.ancillaryFeatures)}
          >
            {[...Object.values(AncillaryFeature)].map((feature) => (
              <PFCheckbox
                fieldPath={ancillaryFeaturesFields.ancillaryFeatures}
                style={{ marginTop: '.5em', marginBottom: '.5em' }}
                key={feature}
                value={feature}
                label={feature}
                onChange={(e: any) =>
                  handleAncillaryFeaturesChange(e.target.value)
                }
                disabled={isFeatureDisabled(feature)}
              ></PFCheckbox>
            ))}
          </Checkbox.Group>
        </div>
        <AutoAnimateChildren style={{ flex: 1 }}>
          <AncillaryFeatureSpecificsComponent></AncillaryFeatureSpecificsComponent>
        </AutoAnimateChildren>
      </div>
    );
  },
);
AncillaryFeaturesFormComponent.displayName = 'AncillaryFeaturesFormComponent';
export default AncillaryFeaturesFormComponent;
