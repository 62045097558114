import { useMutation } from '@tanstack/react-query';
import { getProduct } from '../../../../API/product-maintenance.service';
import { useState } from 'react';
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import {
  CallObservationDateListItem,
  PaymentDateListItem,
} from '../../../../product-schema/product.schema';
import { emptyCallDateObj, emptyCouponDateObj } from './CalendarDates';
import { IconCopy } from '@tabler/icons-react';

export const CalendarDateCopy = () => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const backgroundColor =
    colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0];

  const form = useProductFormContext();
  const [cusipInputText, setCusipInputText] = useState<string>('');
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const {
    mutate: getProductById,
    isError,
    isLoading,
    data: copiedProduct,
  }: any = useMutation(
    (productId: string) => getProduct(productId?.toUpperCase()),
    {
      onSuccess: () => {
        setIsModalOpened(true);
      },
    },
  );

  const handleLookupProduct = () => {
    getProductById(cusipInputText);
  };
  const paymentDateList: PaymentDateListItem[] =
    copiedProduct?.data?.productYield?.paymentDateList?.map(
      (row: PaymentDateListItem) => {
        return {
          ...emptyCouponDateObj,
          paymentObservationDate: row.paymentObservationDate,
          paymentSettlementDate: row.paymentSettlementDate,
        };
      },
    );
  const callObservationDateList: CallObservationDateListItem[] =
    copiedProduct?.data?.productCall?.callObservationDateList?.map(
      (row: CallObservationDateListItem) => {
        return {
          ...emptyCallDateObj,
          callObservationDate: row.callObservationDate,
          callSettlementDate: row.callSettlementDate,
        };
      },
    );
  const handleCopyProduct = () => {
    form.setFieldValue('productYield.paymentDateList', paymentDateList);
    form.setFieldValue(
      'productCall.callObservationDateList',
      callObservationDateList,
    );
    setIsModalOpened(false);
  };
  return (
    <div>
      <Tooltip
        label={
          'view and copy dates from another product. They will replace the current dates.'
        }
      >
        <TextInput
          value={cusipInputText}
          onChange={(e) => setCusipInputText(e.target.value)}
          placeholder={'copy: CUSIP/ISIN'}
          styles={{
            input: {
              borderColor: isError ? 'red' : 'inherit',
            },
            description: {
              color: isError ? 'red' : 'inherit',
            },
          }}
          description={isError ? 'Error Retrieving Product' : ''}
          rightSection={
            <ActionIcon loading={isLoading} onClick={handleLookupProduct}>
              <IconCopy />
            </ActionIcon>
          }
        />
      </Tooltip>
      <Modal
        size={'fit-content'}
        opened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        title={'Copy Dates'}
      >
        <Group gap={'xl'} m={'lg'} align={'start'}>
          <Stack style={{ position: 'relative' }}>
            <Title
              pb={'md'}
              order={4}
              style={{
                position: 'sticky',
                top: 60,
                zIndex: 1000,
                backgroundColor,
              }}
            >
              Call Dates
            </Title>
            {callObservationDateList?.map(
              (row: CallObservationDateListItem, index: number) => {
                return (
                  <Group key={index}>
                    <Text ml={'sm'} style={{ width: '1.25rem' }}>
                      {index + 1}.
                    </Text>
                    <Group>
                      <Text>Obs.</Text>
                      <Text fw={600}>
                        <>{row.callObservationDate}</>
                      </Text>
                    </Group>
                    <Group>
                      <Text>Sett.</Text>
                      <Text fw={600}>
                        <>{row.callSettlementDate}</>
                      </Text>
                    </Group>
                  </Group>
                );
              },
            )}
          </Stack>
          <Stack style={{ position: 'relative' }}>
            <Title
              pb={'md'}
              order={4}
              style={{
                position: 'sticky',
                top: 60,
                zIndex: 1000,
                backgroundColor,
              }}
            >
              Coupon Dates
            </Title>
            {paymentDateList?.map((row: PaymentDateListItem, index: number) => {
              return (
                <Group key={index}>
                  <Text ml={'sm'} style={{ width: '1.25rem' }}>
                    {index + 1}.
                  </Text>
                  <Group>
                    <Text>Obs.</Text>
                    <Text fw={600}>
                      <>{row.paymentObservationDate}</>
                    </Text>
                  </Group>
                  <Group>
                    <Text>Sett.</Text>
                    <Text fw={600}>
                      <>{row.paymentSettlementDate}</>
                    </Text>
                  </Group>
                </Group>
              );
            })}
          </Stack>
        </Group>
        <Group justify={'center'} mb={'10rem'} mt={'2rem'}>
          <Button onClick={handleCopyProduct}>
            Copy Dates. This will overwrite any existing dates.
          </Button>
        </Group>
      </Modal>
    </div>
  );
};
