export interface Underlier {
  activeListing: boolean | null;
  assetType: string | null;
  bloombergId: string | null;
  bloombergTicker: string;
  cdsCurveName: string | null;
  clientConfiguration: string | null;
  companyDescription: string | null;
  companyName: string | null;
  country: string | null;
  createdByUserId: string | null;
  createdTimestamp: string | null;
  currency: string | null;
  currentDividendYield: number | null;
  cusip: string | null;
  earningsSchedule: string[] | null;
  endTimestamp: string | null;
  esgDesc: string | null;
  esgFlag: string | null;
  exDividendDate: string | null;
  exchangeCode: string | null;
  exchangeName: string | null;
  factsetData?: {
    fsymRegionalId: string | null;
    fsymSecurityId: string | null;
    factsetEntityId: string | null;
  } | null;
  figi: string | null;
  firstPriceDate: string | null;
  fsymId: string | null;
  futureDividendYield: number | null;
  gwimType: string | null;
  industry: string | null;
  isin: string | null;
  isoCountry: string | null;
  issuerConfiguration: string | null;
  localJurisdiction: string | null;
  mic: string | null;
  newBloombergTicker: string | null;
  onProduct: boolean | null;
  region: string | null;
  revision: number;
  ric: string | null;
  sector: string | null;
  sectorJICX: string | null;
  sectorTRBC: string | null;
  securityName: string | null;
  sponsor: string | null;
  ticker: string | null;
  tickerExchange: string | null;
  underlierId: string;
  universeType: string | null;
  indexReplication: string | null;
  expanded?: boolean;
  expandedRowKeys: string[] | null;
  pricesUploadedSuccessfully: boolean | null;
}

export const assetTypeOptions = [
  { value: 'ETF', label: 'ETF' },
  { value: 'Stock', label: 'Stock' },
  { value: 'Index', label: 'Index' },
  { value: 'FICC', label: 'FICC' },
  { value: 'Crypto', label: 'Crypto' },
  { value: 'MLP', label: 'MLP' },
  { value: 'ADR', label: 'ADR' },
  { value: 'Rates', label: 'Rates' },
  { value: 'Commodity', label: 'Commodity' },
  { value: 'Cross Asset', label: 'Cross Asset' },
  { value: 'Currency', label: 'Currency' },
  { value: 'Unknown', label: 'Unknown' },
];

export const underlierCurrencyCodes = [
  'USD',
  'USX',
  'AED',
  'ARS',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EUR',
  'EUX',
  'GBP',
  'GBX',
  'HKD',
  'HUF',
  'IDR',
  'INR',
  'ISK',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PEN',
  'PHP',
  'PLN',
  'RUB',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'ZAR',
];

export const uneditableFields = [
  'underlierId',
  'createdByUserId',
  'createdTimestamp',
  'fsymId',
  'endTimestamp',
  'revision',
  'factsetData',
  'bloombergTicker',
];

export interface CardProps {
  entries: [string, any][];
  style: React.CSSProperties;
}

export const specialFields = [
  'bloombergTicker',
  'ticker',
  'companyName',
  'securityName',
];
