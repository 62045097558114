import { Button, Modal, Text } from '@mantine/core';

type UploadConfirmationProps = {
  isOpen: boolean;
  isIndicative: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const UploadConfirmation = ({
  isOpen,
  isIndicative,
  onCancel,
  onConfirm,
}: UploadConfirmationProps) => (
  <Modal opened={isOpen} onClose={onCancel} size="md">
    <Text size="lg" ta="center" style={{ marginBottom: '20px', weight: 700 }}>
      Confirm {isIndicative ? 'INDICATIVE' : 'FINAL'} Pricing Upload
    </Text>
    <Text size="sm" ta="center" style={{ marginBottom: '20px' }}>
      {isIndicative
        ? 'These prices will be uploaded as INDICATIVE prices. This means these prices are preliminary and will be updated to official final prices later. Please confirm if this is correct, or cancel and submit without the indicative checkbox marked.'
        : 'These prices will be uploaded as FINAL prices. This means that these prices have been validated as official closing prices. If this is not the case please check the Indicative Pricing checkbox to upload preliminary prices.'}
    </Text>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        variant="primary"
        onClick={onConfirm}
        style={{ marginRight: '40px', padding: '8px 24px' }}
      >
        Confirm {isIndicative ? 'INDICATIVE Upload' : 'FINAL Upload'}
      </Button>
      <Button
        variant={'secondary'}
        onClick={onCancel}
        color="red"
        style={{ padding: '8px 24px' }}
      >
        Cancel
      </Button>
    </div>
  </Modal>
);

export default UploadConfirmation;
