import { CSSProperties } from 'react';

// SVG Imports
// Full Logos (Flame and Name)
import SVG_LumaDark from '../../../assets/lumaDark.svg';
import SVG_LumaNeutral from '../../../assets/lumaLogo.svg';
// Flame Logos (Flame Only and No Name)
import SVG_FlameDark from '../../../assets/lumaFlame.svg';
import SVG_FlameNeutral from '../../../assets/lumaFlameNeutral.svg';
import { Image, ImageProps } from '@mantine/core';

// Luma includes the word Luma in the file - Flame is only our Flame Icon
export enum LUMA_LOGOS {
  SVG_LUMA_DARK = 'SVG_LumaDark',
  SVG_LUMA_NEUTRAL = 'SVG_LumaNeutral',
  SVG_FLAME_DARK = 'SVG_FlameDark',
  SVG_FLAME_NEUTRAL = 'SVG_FlameNeutral',
}

export interface LumaLogoProps extends ImageProps {
  logoType: LUMA_LOGOS;
  className?: string;
  style?: CSSProperties;
}

const LOGO_MAP = new Map<LUMA_LOGOS, string>([
  [LUMA_LOGOS.SVG_LUMA_DARK, SVG_LumaDark],
  [LUMA_LOGOS.SVG_LUMA_NEUTRAL, SVG_LumaNeutral],
  [LUMA_LOGOS.SVG_FLAME_DARK, SVG_FlameDark],
  [LUMA_LOGOS.SVG_FLAME_NEUTRAL, SVG_FlameNeutral],
]);

export const LumaLogo = ({
  logoType = LUMA_LOGOS.SVG_LUMA_DARK,
  className = '',
  ...rest
}: LumaLogoProps) => {
  const imageSource = LOGO_MAP.get(logoType) ?? SVG_LumaDark;
  return (
    <Image
      h={22}
      w="auto"
      className={className}
      src={imageSource}
      alt="Luma Logo"
      {...rest}
    />
  );
};
