import {
  IconCopy,
  IconFileDescription,
  IconLoader,
  IconPencil,
  IconSearch,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getDocumentByCusip, mapDocuments } from '../../API/document.service';
import { Group, Modal, Text } from '@mantine/core';
import DocumentsWidget from '../documentsWidget/DocumentsWidget';
import { spotlight, Spotlight } from '@mantine/spotlight';
import { SpotlightActionData } from '@mantine/spotlight/lib/Spotlight';

import '@mantine/spotlight/styles.css';
const DOCUMENT_QUERY_KEY = 'fetch-cusip-documents';

enum SPOTLIGHT_ACTIONS {
  VIEW_DOCUMENTS = 'View Documents',
  EDIT_PRODUCT = 'Edit Product',
  COPY_TO_ENTRY = 'Copy existing product to entry',
}

const SpotLightWrapper = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const {
    data: productDocuments,
    isFetching: loadingDocuments,
    isError,
    refetch: fetchDocuments,
  } = useQuery([DOCUMENT_QUERY_KEY], () => getDocumentByCusip(query), {
    enabled: false,
    onSettled: () => {
      spotlight.close();
      setDocumentsModalOpened(true);
    },
  });
  const mappedProductDocuments = useMemo(() => {
    return mapDocuments(productDocuments?.data?.[0]?.documents);
  }, [productDocuments]);

  const [documentsModalOpened, setDocumentsModalOpened] = useState(false);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const actions: SpotlightActionData[] = [
    {
      id: 'open-product-documents',
      title: SPOTLIGHT_ACTIONS.VIEW_DOCUMENTS,
      // closeOnTrigger: false,
      leftSection: loadingDocuments ? (
        <IconLoader size={20} />
      ) : (
        <IconFileDescription size={20} />
      ),
      onClick: () => {
        fetchDocuments();
      },
    },
    {
      id: 'edit-product',
      title: SPOTLIGHT_ACTIONS.EDIT_PRODUCT,
      leftSection: <IconPencil size={20} />,
      onClick: () => navigate(`/product-edit/${encodeURIComponent(query)}`),
    },
    {
      id: 'copy-to-entry',
      title: SPOTLIGHT_ACTIONS.COPY_TO_ENTRY,
      leftSection: <IconCopy size={20} />,
      onClick: () =>
        navigate(`/product-entry/copy/${encodeURIComponent(query)}`),
    },
  ];

  const filteredActions = actions
    .filter((action: SpotlightActionData) => {
      // matches cusip or isin
      if (
        action.title === SPOTLIGHT_ACTIONS.EDIT_PRODUCT ||
        action.title === SPOTLIGHT_ACTIONS.VIEW_DOCUMENTS ||
        action.title === SPOTLIGHT_ACTIONS.COPY_TO_ENTRY
      ) {
        return /^[A-Za-z0-9#]{9}$|^[A-Za-z0-9#]{12}$/.test(query?.trim());
      }
      return true;
    })
    .map((action: SpotlightActionData) => {
      return (
        <Spotlight.Action
          {...action}
          key={action.id}
          style={{ padding: '1rem', width: '100%' }}
        >
          <Group wrap="nowrap" w="100%">
            {action.leftSection ?? null}
            <div style={{ flex: 1 }}>
              <Text>{action.title}</Text>

              {action.description && (
                <Text opacity={0.6} size="xs">
                  {action.description}
                </Text>
              )}
            </div>
          </Group>
        </Spotlight.Action>
      );
    });

  return (
    <>
      <Spotlight.Root shortcut={['/']} query={query} onQueryChange={setQuery}>
        <Spotlight.Search
          leftSection={<IconSearch size="1.2rem" />}
          placeholder={'Search...'}
          style={{
            actions: loadingDocuments
              ? { pointerEvents: 'none', opacity: '.7' }
              : {},
          }}
        />

        <Spotlight.ActionsList>
          {filteredActions.length > 0 ? (
            filteredActions
          ) : query?.length > 0 ? (
            <Spotlight.Empty style={{ padding: '1rem' }}>
              No Results. Try searching a CUSIP or ISIN
            </Spotlight.Empty>
          ) : null}
        </Spotlight.ActionsList>
      </Spotlight.Root>

      {children}
      <Modal
        styles={{ title: { fontSize: '1.25rem', fontWeight: 'bold' } }}
        yOffset={300}
        opened={documentsModalOpened}
        title={`${query} Documents`}
        onClose={() => {
          queryClient.invalidateQueries({ queryKey: [DOCUMENT_QUERY_KEY] });
          setDocumentsModalOpened(false);
        }}
      >
        {isError ? (
          <ErrorGettingDocuments />
        ) : (
          <DocumentsWidget documents={mappedProductDocuments} />
        )}
      </Modal>

      {/*<Spotlight*/}
      {/*  shortcut={['/']}*/}
      {/*  // styles={() => ({*/}
      {/*  //   actions: loadingDocuments ? { pointerEvents: 'none', opacity: '.7'} : {}*/}
      {/*  // })}*/}
      {/*  actions={actions}*/}
      {/*  query={query}*/}
      {/*  onQueryChange={setQuery}*/}
      {/*  clearQueryOnClose={false}*/}
      {/*  yOffset={300}*/}
      {/*  searchProps={{*/}
      {/*    leftSection: <IconSearch size="1.2rem"/>,*/}
      {/*    placeholder: 'Search...',*/}
      {/*    style: {actions: loadingDocuments ? {pointerEvents: 'none', opacity: '.7'} : {}}*/}
      {/*  }}*/}
      {/*  filter={(query, actions: SpotlightActions[]) =>*/}
      {/*    actions.filter((action) => {*/}
      {/*      // matches cusip or isin*/}
      {/*      if (action.title === SPOTLIGHT_ACTIONS.EDIT_PRODUCT || action.title === SPOTLIGHT_ACTIONS.VIEW_DOCUMENTS || action.title === SPOTLIGHT_ACTIONS.COPY_TO_ENTRY) {*/}
      {/*        return (/^[A-Za-z0-9]{9}$|^[A-Za-z0-9]{12}$/).test(query?.trim());*/}
      {/*      }*/}
      {/*      return true;*/}
      {/*    })*/}
      {/*  }*/}
      {/*>*/}
      {/*  {children}*/}
      {/*  <Modal*/}
      {/*    styles={{title: {fontSize: '1.25rem', fontWeight: 'bold'}}}*/}
      {/*    yOffset={300} opened={documentsModalOpened} title={`${query} Documents`} onClose={() => {*/}
      {/*    queryClient.invalidateQueries({queryKey: [DOCUMENT_QUERY_KEY]});*/}
      {/*    setDocumentsModalOpened(false);*/}
      {/*  }}>*/}
      {/*    {isError ? (<ErrorGettingDocuments/>) :*/}
      {/*      (<DocumentsWidget documents={mappedProductDocuments}/>)*/}
      {/*    }*/}

      {/*  </Modal>*/}
      {/*</Spotlight>*/}
    </>
  );
};

export default SpotLightWrapper;

const ErrorGettingDocuments = () => {
  return (
    <div style={{ padding: '1rem', fontSize: '1rem', textAlign: 'center' }}>
      Error getting documents
    </div>
  );
};
