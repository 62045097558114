import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProductFormProvider } from './entry-and-edit-contexts/product-form-context';
import ProductSectionComponent from './reusable-form-component/ProductSection';
import ProductFormControls from './Product-form-controls/ProductFormControls';
import {
  mapProductBeforeSubmit,
  mapProductFormForStorage,
} from './product-form-mappings';
import { DisabledFieldsContextProvider } from './entry-and-edit-contexts/disabled-fields-context';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import {
  PDWRuleValidationError,
  PDWRuleValidationErrorResponse,
  savePDWProduct,
  setStagedProductAsMerged,
  updatePDWProduct,
  validatePDWProduct,
} from '../../API/pdw.service';
import { PDWProduct } from '../../product-schema/product.schema';
import ValidationErrors from './validationErrors/ValidationErrors';
import { AxiosError } from 'axios';
import { EditModeContext } from './entry-and-edit-contexts/edit-mode-context';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormSectionProperties } from './form-section.types';
import { useParams, useSearchParams } from 'react-router-dom';
import { COMPARE_PRODUCT_SEARCH_PARAM } from '../../API/api-consts';
import { modals } from '@mantine/modals';
import { Affix, Button, Group, rem, Text, Transition } from '@mantine/core';
import { IconArrowUp } from '@tabler/icons-react';
import { useWindowScroll } from '@mantine/hooks';
import { saveProductEditTimePost } from '../../API/product-data-warehouse.service';
import { useStopwatch } from './useStopwatch';

export interface ProductFormProps {
  fetchProduct: () => void;
  onSectionHeaderClicked: (key: any) => void;
  onCollapseOthersClicked: (key: any) => void;
  formSectionAttributes: FormSectionProperties[];
  onExpandButtonClick: () => void;
  productForm: any;
  handleSoftResetProductEditForm?: () => void;
  handleNewEntryForm: () => void;
  handleShowChanges?: () => void;
  handleShowJsonEdit: () => void;
  handleShowProductMergeCompare?: () => void;
  handleSoftResetProductForm?: () => void;
  handleMoveActiveSectionsToTop: () => void;
  holidayDates: string[];
  allExpanded: boolean;
  formSectionHandlers: any;
}

const ProductForm = ({
  fetchProduct,
  onSectionHeaderClicked,
  onCollapseOthersClicked,
  formSectionAttributes,
  onExpandButtonClick,
  productForm,
  handleSoftResetProductEditForm,
  handleNewEntryForm,
  handleSoftResetProductForm,
  handleShowChanges,
  handleShowJsonEdit,
  handleShowProductMergeCompare,
  handleMoveActiveSectionsToTop,
  holidayDates,
  allExpanded,
  formSectionHandlers,
}: ProductFormProps) => {
  const [scroll, scrollTo] = useWindowScroll();
  const editMode = useContext(EditModeContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    timeFormatted,
    timeInSeconds,
    startTimer,
    stopTimer,
    resetTimer,
    isRunning,
  } = useStopwatch();
  useEffect(() => {
    startTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [validationErrors, setValidationErrors] = useState<
    PDWRuleValidationError[] | null
  >(null);

  const urlParams = useParams();
  const productFromUrl = urlParams?.productId
    ? decodeURIComponent(urlParams?.productId)
    : undefined;

  useEffect(() => {
    if (productFromUrl) {
      notifications.hide('validate-product');
      handleValidateProduct();
    } else {
      setValidationErrors(null);
    }
  }, [productFromUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValidationErrors = (violations: any) => {
    setValidationErrors(violations);
    const errors = { ...productForm.errors };
    if (violations?.length > 0) {
      for (const errorMessage of violations) {
        errors[errorMessage.fieldName] = errorMessage.message;
      }
    }
    productForm.setErrors(errors);
  };

  const validateProduct: any = useMutation(
    (productForm: PDWProduct) =>
      validatePDWProduct(mapProductBeforeSubmit(productForm)),
    {
      onSuccess: () => {
        notifications.update({
          id: 'validate-product',
          loading: false,
          title: 'Validation Complete',
          message: 'Data in the form passed validation checks.',
          autoClose: true,
          withCloseButton: true,
          color: 'green',
        });
        setValidationErrors(null);
      },
      onError: (err: AxiosError<PDWRuleValidationErrorResponse>) => {
        handleValidationErrors(err.response?.data?.violations);
        notifications.update({
          id: 'validate-product',
          title: 'Validation Errors!',
          message: 'Product validation failed.',
          autoClose: true,
          withCloseButton: true,
          color: 'red',
          loading: false,
        });
        console.log('productForm values:', productForm.values);
      },
    },
  );

  const handleValidateProduct = () => {
    notifications.show({
      id: 'validate-product',
      loading: true,
      title: 'Validating Product',
      message: 'Validating... ',
      autoClose: false,
      withCloseButton: false,
      color: 'blue',
    });
    productForm.validate();
    console.log('form errors', productForm.errors);
    validateProduct.mutate(mapProductFormForStorage(productForm.values));
  };

  const postProductForm: any = useMutation(
    (pf: PDWProduct) =>
      savePDWProduct(
        mapProductFormForStorage({
          ...pf,
          productDataSource: 'Manual',
        }),
      ),
    {
      onSuccess: (res) => {
        notifications.update({
          id: 'submit-product',
          title:
            productForm.values?.productGeneral?.cusip ??
            productForm.values?.productGeneral?.isin,
          message: 'Product Submitted Successfully.',
          loading: false,
          autoClose: false,
          withCloseButton: true,
          color: 'green',
        });
        setValidationErrors(null);
        handleNewEntryForm();
      },
      onError: (err: AxiosError<PDWRuleValidationErrorResponse>) => {
        handleValidationErrors(err.response?.data?.violations);
        notifications.update({
          id: 'submit-product',
          title: 'Error Submitting Product',
          loading: false,
          autoClose: true,
          withCloseButton: true,
          message: err.response?.data?.violations
            ? 'Product failed validation checks.'
            : 'Please try again or contact support.',
          color: 'red',
        });
      },
    },
  );

  const saveProductEditTime = useMutation(
    (payload: { productId: string; version: number }) =>
      saveProductEditTimePost({
        productId: payload.productId,
        version: payload.version,
        timeSpentSeconds: timeInSeconds,
      }),
    {
      onSettled: () => {
        resetTimer();
      },
    },
  );

  const handleSaveEditTime = (props: {
    productId: string;
    version: number;
  }) => {
    saveProductEditTime.mutate(props);
  };

  const updateProductForm: any = useMutation(
    (updatePayload: { payload: PDWProduct; resolveAsMerged?: boolean }) =>
      updatePDWProduct(mapProductFormForStorage(updatePayload.payload)),
    {
      onSuccess: (res, variables) => {
        handleSaveEditTime({
          productId: productForm.values?.productId,
          version: productForm.values?.version,
        });
        if (variables.resolveAsMerged) {
          searchParams.delete(COMPARE_PRODUCT_SEARCH_PARAM);
          putStagedProductAsMerged.mutate(productForm.values?.productId);
        }
        notifications.update({
          id: 'update-product',
          title:
            productForm.values?.productGeneral?.cusip ??
            productForm.values?.productGeneral?.isin,
          loading: false,
          autoClose: true,
          withCloseButton: true,
          message: 'Product Updated Successfully.',
          color: 'green',
        });
        setValidationErrors(null);
        fetchProduct();
      },
      onError: (err: AxiosError<PDWRuleValidationErrorResponse>) => {
        handleValidationErrors(err.response?.data?.violations);
        notifications.update({
          id: 'update-product',
          title: 'Error Updating Product',
          loading: false,
          autoClose: true,
          withCloseButton: true,
          message: err.response?.data?.violations
            ? 'Product failed validation checks.'
            : 'Please try again or contact support.',
          color: 'red',
        });
      },
    },
  );

  const putStagedProductAsMerged = useMutation(
    (productId: string) => setStagedProductAsMerged(productId),
    {
      onSuccess: () => {
        productForm.resetDirty();
        searchParams.delete(COMPARE_PRODUCT_SEARCH_PARAM);
        setTimeout(() => setSearchParams(searchParams));
        // do we need a success notification or just show for errors?
        notifications.show({
          id: 'merge-product',
          title: 'Product Merge Resolved',
          message: 'Product has been Successfully set to merged.',
          loading: false,
          withCloseButton: true,
          autoClose: true,
          color: 'green',
        });
      },
      onError: () => {
        notifications.show({
          id: 'merge-product',
          title: 'Unable to set product state to merged',
          message: 'Failed to set the product as merged.',
          loading: false,
          autoClose: false,
          withCloseButton: true,
          color: 'red',
        });
      },
      onSettled: () => {
        setTimeout(() => {
          fetchProduct();
        });
      },
    },
  );

  const handleProductFormSubmit = () => {
    if (!editMode) {
      // entry mode save
      notifications.show({
        id: editMode ? 'update-product' : 'submit-product',
        title: 'Submitting',
        message:
          'submitting product ' + productForm.values?.productGeneral?.cusip ??
          productForm.values?.productGeneral?.isin,
        loading: true,
        autoClose: false,
        withCloseButton: false,
        color: 'blue',
      });
      postProductForm.mutate(mapProductBeforeSubmit(productForm.values));
      return;
    }
    if (searchParams.get(COMPARE_PRODUCT_SEARCH_PARAM)) {
      modals.openConfirmModal({
        centered: true,
        title: 'Submitting: Resolve Staged Product As Merged?',
        children: (
          <Text size={'small'}>
            <p>
              Do you want to save the product, marking it as merged and{' '}
              <strong>Removing it from the staged queue</strong>?
            </p>
          </Text>
        ),
        labels: { confirm: 'Submit & Merge', cancel: 'Cancel' },
        onConfirm: () => {
          notifications.show({
            id: editMode ? 'update-product' : 'submit-product',
            title: 'Submitting',
            message:
              'submitting product ' +
                productForm.values?.productGeneral?.cusip ??
              productForm.values?.productGeneral?.isin,
            loading: true,
            autoClose: false,
            withCloseButton: false,
            color: 'blue',
          });
          updateProductForm.mutate({
            payload: mapProductBeforeSubmit(productForm.values),
            resolveAsMerged: true,
          });
        },
      });
    } else {
      notifications.show({
        id: editMode ? 'update-product' : 'submit-product',
        title: 'Submitting',
        message:
          'submitting product ' + productForm.values?.productGeneral?.cusip ??
          productForm.values?.productGeneral?.isin,
        loading: true,
        autoClose: false,
        withCloseButton: false,
        color: 'blue',
      });
      updateProductForm.mutate({
        payload: mapProductBeforeSubmit(productForm.values),
      });
    }
  };

  const formSections = useMemo(
    () =>
      formSectionAttributes.map((section, index) => (
        <ProductSectionComponent
          sectionProperties={section}
          key={section.id}
          index={index}
          holidayDates={holidayDates}
          onCollapseOthersClicked={() => onCollapseOthersClicked(section.id)}
          onSectionClicked={() => onSectionHeaderClicked(section.id)}
        ></ProductSectionComponent>
      )),
    [
      formSectionAttributes,
      holidayDates,
      onCollapseOthersClicked,
      onSectionHeaderClicked,
    ],
  );

  const TimerComponent = useMemo(() => {
    return (
      <Group>
        <Text size="sm" fw={500}>
          Time Spent:
        </Text>
        <Text size="sm">{timeFormatted}</Text>
        <Button
          size="xs"
          variant="light"
          onClick={() => {
            if (isRunning) {
              stopTimer();
            } else {
              startTimer();
            }
          }}
        >
          {isRunning ? 'Stop' : 'Start'}
        </Button>
      </Group>
    );
  }, [timeFormatted, isRunning, startTimer, stopTimer]);
  return (
    <ProductFormProvider form={productForm}>
      <Affix position={{ bottom: rem(20), right: rem(20) }}>
        <Transition transition="slide-up" mounted={scroll.y > 600}>
          {(transitionStyles) => (
            <Button
              leftSection={<IconArrowUp size="1rem" />}
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
            >
              Scroll to top
            </Button>
          )}
        </Transition>
      </Affix>

      <div style={{ display: 'flex' }}>
        <div
          style={{
            opacity:
              postProductForm.isLoading || updateProductForm.isLoading
                ? 0.7
                : 1,
            pointerEvents:
              postProductForm.isLoading || updateProductForm.isLoading
                ? 'none'
                : 'auto',
          }}
        >
          <ProductFormControls
            {...{
              handleShowJsonEdit,
              handleMoveActiveSectionsToTop,
              handleProductFormSubmit,
              handleSoftResetProductEditForm,
              handleNewEntryForm,
              handleSoftResetProductForm,
              handleValidateProduct,
              handleShowChanges,
              handleShowProductMergeCompare,
              onExpandButtonClick,
              allExpanded,
              formSectionHandlers,
              formSectionAttributes,
              TimerComponent,
            }}
          ></ProductFormControls>
        </div>
        <form style={{ width: '100%' }}>
          <DisabledFieldsContextProvider>
            <div style={{ padding: '0 1em' }}>
              <DragDropContext
                onDragEnd={({ destination, source }) => {
                  if (destination) {
                    formSectionHandlers.reorder({
                      from: source.index,
                      to: destination?.index,
                    });
                  }
                }}
              >
                <Droppable droppableId="dnd-list" direction="vertical">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {formSections}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div style={{ height: '300px' }}></div>
            </div>
            <ValidationErrors errors={validationErrors} />
          </DisabledFieldsContextProvider>
        </form>
      </div>
    </ProductFormProvider>
  );
};
export default ProductForm;
