import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  EventConfirmation,
  EventConfirmationGridData,
  getEventConfirmationsById,
  getEventConfirmationsForDateRange,
  PhysicalSettlement,
} from '../../../API/event-service';
import {
  camelCaseToWords,
  createAccurateDateWithoutTime,
  formatDate,
  quickFilterCommaParser,
} from '../../../utilities/utilities';
import { DatePickerInput } from '@mantine/dates';
import {
  ActionIcon,
  Button,
  Container,
  Group,
  Kbd,
  SegmentedControl,
  Stack,
  TextInput,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  IDateFilterParams,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import dayjs from 'dayjs';
import { EventConfirmationDetailCellRenderer } from './EventConfirmationDetailCellRenderer';
import { UnderlierPillsCellRenderer } from '../../../components/UnderlierPillsCellRenderer';
import { EventConfirmationUtils } from './EventConfirmationUtils';
import { HotkeyItem, useDidUpdate, useHotkeys } from '@mantine/hooks';
import { IconFilterOff } from '@tabler/icons-react';

const statusBarConfig = {
  statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent' }],
};

const isRowEditable = (params: EventConfirmation) => {
  // return false;
  return !(!params.eventDate || dayjs(params?.eventDate).isAfter(dayjs()));
};

export const getEventConfirmationRowId = (
  params: GetRowIdParams<EventConfirmation>,
) => {
  return `${params.data?.cusip}${params.data.isin}${params.data.productSymbol}${params.data.eventDate}${params.data.settlementType}${params.data.eventId}${params.data.eventType}`;
};

const SIDE_BAR_CONFIG = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',

      toolPanelParams: {
        suppressPivotMode: true,
        suppressValues: true,
        suppressRowGroups: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
};

const percentFormatter = (params: ValueFormatterParams) => {
  return params.value ? params.value + '%' : '';
};

const dateFilterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (cellValue == null) return -1;
    const cellDate = createAccurateDateWithoutTime(cellValue);
    if (cellDate == null) return -1;
    if (
      !cellDate ||
      filterLocalDateAtMidnight.getTime() === cellDate?.getTime()
    ) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

const dateColDef: Partial<ColDef<EventConfirmation>> = {
  valueFormatter: (params: any) =>
    dayjs(params.value)?.format('MM/DD/YYYY') || '',
  filter: 'agDateColumnFilter',
  filterParams: dateFilterParams,
};

const defaultColDef: ColDef = {
  enableRowGroup: false,
  sortable: true,
  resizable: true,
  floatingFilter: true,
  filter: 'agSetColumnFilter',
  filterParams: {
    // can be 'windows' or 'mac'
    excelMode: 'mac',
  },
};

const EventConfirmationsColDefs: ColDef<EventConfirmation>[] = [
  // {
  //   colId: 'expand-for-master-detail',
  //   pinned: 'left',
  //   suppressHeaderMenuButton: true,
  //   suppressHeaderContextMenu: true,
  //   suppressMovable: true,
  //   suppressColumnsToolPanel: true,
  //   floatingFilter: false,
  //   filter: false,
  //   cellRenderer: "agGroupCellRenderer",
  //   width: 30,
  // },
  {
    colId: 'count',
    valueGetter: (params: ValueGetterParams) => {
      return params.node?.rowIndex != null ? params.node.rowIndex + 1 : null;
    },

    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    floatingFilter: false,
    filter: false,
    headerName: 'rowNumber',
    pinned: 'left',
    width: 30,
  },
  {
    field: 'productIdentifier',
    headerName: 'productIdentifier',
    colId: 'productIdentifier',
    pinned: 'left',
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    field: 'eventDate',
    headerName: 'eventDate',
    colId: 'eventDate',
    pinned: 'left',
    ...dateColDef,
  },
  {
    field: 'eventType',
    headerName: 'eventType',
    colId: 'eventType',
    pinned: 'left',
  },
  { field: 'issuer', headerName: 'issuer', colId: 'issuer' },
  {
    field: 'structureNameInternal',
    headerName: 'structureNameInternal',
    colId: 'structureNameInternal',
  },
  {
    field: 'confirmationStatus',
    headerName: 'confirmationStatus',
    colId: 'confirmationStatus',
  },
  {
    field: 'cashSettlementRates.lumaRate',
    headerName: 'lumaRate',
    colId: 'lumaRate',
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === 'Missed') {
        return params.value;
      }
      return params.value ? params.value + '%' : '';
    },
    valueGetter: (params: ValueGetterParams) => {
      if (params.data?.cashSettlementRates?.lumaRate == null) {
        if (params.data?.lumaMarkedAsMissed) {
          return 'Missed';
        }
      }
      return params.data?.cashSettlementRates?.lumaRate;
    },
  },
  {
    field: 'cashSettlementRates.issuerRate',
    headerName: 'issuerRate',
    colId: 'issuerRate',
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === 'Missed') {
        return params.value;
      }
      return params.value ? params.value + '%' : '';
    },
    valueGetter: (params: ValueGetterParams) => {
      if (params.data?.cashSettlementRates?.lumaRate == null) {
        if (params.data?.issuerMarkedAsMissed) {
          return 'Missed';
        }
      }
      return params.data?.cashSettlementRates?.issuerRate;
    },
  },
  {
    field: 'settlementType',
    headerName: 'settlementType',
    colId: 'settlementType',
  },
  {
    field: 'holdingClients',
    headerName: 'holdingClients',
    colId: 'holdingClients',
  },
  { field: 'originator', headerName: 'originator', colId: 'originator' },
  {
    field: 'activeUnderlier',
    hide: true,
    headerName: 'activeUnderlier',
    colId: 'activeUnderlier',
  },
  {
    field: 'underlierList',
    cellRenderer: UnderlierPillsCellRenderer,
    cellRendererParams: (params: ICellRendererParams) => {
      return {
        tickers: params.data?.underlierList?.map((x: any) => x.ticker),
        activeTicker: params.data?.activeUnderlier,
      };
    },
    headerName: 'underlierList',
    colId: 'underlierList',
  },
  { field: 'basketType', headerName: 'basketType', colId: 'basketType' },
  {
    field: 'underlierPerformance',
    headerName: 'underlierPerformance',
    colId: 'underlierPerformance',
  },
  {
    field: 'markToMarketDifference',
    headerName: 'markToMarketDifference',
    colId: 'markToMarketDifference',
  },
  {
    field: 'cashSettlementRates.rate',
    headerName: 'rate',
    colId: 'rate',
    valueFormatter: percentFormatter,
  },
  { field: 'fixedCoupon', headerName: 'fixedCoupon', colId: 'fixedCoupon' },
  { field: 'rateLinked', headerName: 'rateLinked', colId: 'rateLinked' },
  {
    field: 'likelihoodOfCall',
    headerName: 'likelihoodOfCall',
    colId: 'likelihoodOfCall',
  },
  {
    field: 'settlementDate',
    headerName: 'settlementDate',
    colId: 'settlementDate',
    ...dateColDef,
  },

  {
    colId: 'physicalSharesUnderlier',
    headerName: 'Physical Shares (underlier)',
    valueGetter: (params: ValueGetterParams<EventConfirmation>) => {
      const settlement: PhysicalSettlement | null =
        EventConfirmationUtils.getPhysicalSharesValue(params.data);
      return settlement?.securityId ?? '';
    },
  },
  {
    colId: 'physicalSharesLuma',
    headerName: 'Physical Shares (luma)',
    valueGetter: (params: ValueGetterParams<EventConfirmation>) => {
      const settlement: PhysicalSettlement | null =
        EventConfirmationUtils.getPhysicalSharesValue(params.data);
      return settlement?.lumaValue ?? '';
    },
  },
  {
    colId: 'physicalSharesIssuer',
    headerName: 'Physical Shares (issuer)',
    valueGetter: (params: ValueGetterParams<EventConfirmation>) => {
      const settlement: PhysicalSettlement | null =
        EventConfirmationUtils.getPhysicalSharesValue(params.data);
      return settlement?.issuerValue ?? '';
    },
  },
  {
    colId: 'physicalCashLuma',
    headerName: 'Physical Cash (luma)',
    valueGetter: (params: ValueGetterParams<EventConfirmation>) => {
      const settlement: PhysicalSettlement | null =
        EventConfirmationUtils.getPhysicalCashValue(params.data);
      return settlement?.lumaValue ?? '';
    },
  },
  {
    colId: 'physicalCashIssuer',
    headerName: 'Physical Cash (issuer)',
    valueGetter: (params: ValueGetterParams<EventConfirmation>) => {
      const settlement: PhysicalSettlement | null =
        EventConfirmationUtils.getPhysicalCashValue(params.data);
      return settlement?.issuerValue ?? '';
    },
  },
  {
    colId: 'physicalValuesMatch',
    headerName: 'Physical Values Match',
    valueGetter: (params: ValueGetterParams<EventConfirmation>) => {
      const shares = EventConfirmationUtils.getPhysicalSharesValue(params.data);
      const cash = EventConfirmationUtils.getPhysicalCashValue(params.data);

      const cashMatch: boolean =
        (cash?.lumaValue &&
          cash?.issuerValue &&
          cash?.lumaValue === cash?.issuerValue) ||
        false;

      const sharesMatch: boolean =
        (shares?.lumaValue &&
          shares?.issuerValue &&
          shares?.lumaValue === shares?.issuerValue) ||
        false;
      if (cashMatch && sharesMatch) {
        return true;
      }
      if (cashMatch || sharesMatch) {
        return null;
      }
      return false;
    },
    cellDataType: 'boolean',
  },

  {
    field: 'issuerRateLastModifiedBy',
    headerName: 'issuerRateLastModifiedBy',
    colId: 'issuerRateLastModifiedBy',
  },
  {
    field: 'issuerRateLastModifiedTime',
    headerName: 'issuerRateLastModifiedTime',
    colId: 'issuerRateLastModifiedTime',
  },
  {
    field: 'lumaRateLastModifiedBy',
    headerName: 'lumaRateLastModifiedBy',
    colId: 'lumaRateLastModifiedBy',
  },
  {
    field: 'lumaRateLastModifiedTime',
    headerName: 'lumaRateLastModifiedTime',
    colId: 'lumaRateLastModifiedTime',
  },
  {
    field: 'lastSentEmails',
    headerName: 'lastSentEmails',
    colId: 'lastSentEmails',
  },
  {
    field: 'confirmationMethod',
    headerName: 'confirmationMethod',
    colId: 'confirmationMethod',
  },
  { field: 'issuerNote', headerName: 'issuerNote', colId: 'issuerNote' },
  {
    field: 'markToMarketValue',
    headerName: 'markToMarketValue',
    colId: 'markToMarketValue',
  },
  { field: 'ACAT', headerName: 'ACAT', colId: 'ACAT' },
  { field: 'eventId', headerName: 'eventId', colId: 'eventId', hide: true },
  { field: 'cusip', headerName: 'cusip', colId: 'cusip', hide: true },
  { field: 'isin', headerName: 'isin', colId: 'isin', hide: true },
  {
    field: 'productSymbol',
    headerName: 'productSymbol',
    colId: 'productSymbol',
    hide: true,
  },

  {
    field: 'productStatus',
    headerName: 'productStatus',
    colId: 'productStatus',
    hide: true,
  },
  {
    field: 'structureShortNameInternal',
    headerName: 'structureShortNameInternal',
    colId: 'structureShortNameInternal',
    hide: true,
  },
  {
    field: 'principalProximityDistance',
    headerName: 'principalProximityDistance',
    colId: 'principalProximityDistance',
    hide: true,
  },
  {
    field: 'principalProximityThreshold',
    headerName: 'principalProximityThreshold',
    colId: 'principalProximityThreshold',
    hide: true,
  },

  {
    field: 'registrationType',
    headerName: 'registrationType',
    colId: 'registrationType',
    hide: true,
  },
  {
    field: 'tradeDate',
    headerName: 'tradeDate',
    colId: 'tradeDate',
    ...dateColDef,
    hide: true,
  },
  {
    field: 'strikeDate',
    headerName: 'strikeDate',
    colId: 'strikeDate',
    ...dateColDef,
    hide: true,
  },
  {
    field: 'tenorFinal',
    headerName: 'tenorFinal',
    colId: 'tenorFinal',

    hide: true,
  },
  {
    field: 'tenorUnit',
    headerName: 'tenorUnit',
    colId: 'tenorUnit',

    hide: true,
  },
  {
    field: 'downsideType',
    headerName: 'downsideType',
    colId: 'downsideType',

    hide: true,
  },
  {
    field: 'productMarkToMarketData',
    headerName: 'productMarkToMarketData',
    colId: 'productMarkToMarketData',
    hide: true,
  },
  {
    field: 'issueSize',
    headerName: 'issueSize',
    colId: 'issueSize',

    hide: true,
  },
  {
    field: 'paymentFrequency',
    headerName: 'paymentFrequency',
    colId: 'paymentFrequency',
    hide: true,
  },
  {
    field: 'executed_by_user',
    headerName: 'executed_by_user',
    colId: 'executed_by_user',
    hide: true,
  },
  {
    field: 'executed_ts',
    headerName: 'executed_ts',
    colId: 'executed_ts',

    hide: true,
  },

  {
    field: 'confirmedRateLastModifiedBy',
    headerName: 'confirmedRateLastModifiedBy',
    colId: 'confirmedRateLastModifiedBy',
    hide: true,
  },
  {
    field: 'confirmedRateLastModifiedTime',
    headerName: 'confirmedRateLastModifiedTime',
    colId: 'confirmedRateLastModifiedTime',
    hide: true,
  },
  {
    field: 'issuerMarkedAsMissed',
    headerName: 'issuerMarkedAsMissed',
    colId: 'issuerMarkedAsMissed',
    hide: true,
  },
  {
    field: 'lumaMarkedAsMissed',
    headerName: 'lumaMarkedAsMissed',
    colId: 'lumaMarkedAsMissed',
    hide: true,
  },
  {
    field: 'lumaAuditNote',
    headerName: 'lumaAuditNote',
    colId: 'lumaAuditNote',
    hide: true,
  },
  {
    field: 'lumaIssuerAuditNote',
    headerName: 'lumaIssuerAuditNote',
    colId: 'lumaIssuerAuditNote',
    hide: true,
  },
  {
    field: 'escalationRequired',
    headerName: 'escalationRequired',
    colId: 'escalationRequired',
    hide: true,
  },
  {
    field: 'initialLumaRate',
    headerName: 'initialLumaRate',
    colId: 'initialLumaRate',
    hide: true,
  },
  {
    field: 'formattedLumaOutcome',
    headerName: 'formattedLumaOutcome',
    colId: 'formattedLumaOutcome',
    hide: true,
  },
  {
    field: 'initialLumaRateDifference',
    headerName: 'initialLumaRateDifference',
    colId: 'initialLumaRateDifference',
    hide: true,
  },
  {
    field: 'modifiedInThisSession',
    headerName: 'Modified',
    colId: 'modifiedInThisSession',
    hide: true,
  },
].map((x) => ({ ...x, headerName: camelCaseToWords(x.headerName) }) as ColDef);

const quickSearchFocusHotKey = 'mod+k';
const today = new Date();
const yesterday = dayjs(today).subtract(1, 'day').toDate();
const threeMonthsAgo = dayjs(today).subtract(3, 'month').toDate();

const todayFilter = {
  eventDate: {
    dateFrom: today.toISOString(),
    dateTo: null,
    filterType: 'date',
    type: 'equals',
  },
};

const yesterdayFilter = {
  eventDate: {
    dateFrom: yesterday.toISOString(),
    dateTo: null,
    filterType: 'date',
    type: 'equals',
  },
};
// const tomorrow = dayjs(today).add(1, 'day').toDate();
export const MSWMEventConfirmations = () => {
  const gridRef = useRef<AgGridReact>(null);
  const quickSearchRef = useRef<HTMLInputElement>(null);
  const hotKeys: HotkeyItem[] = quickSearchFocusHotKey
    ? [
        [
          quickSearchFocusHotKey,
          () => {
            if (quickSearchRef.current) {
              quickSearchRef.current?.focus();
            }
          },
        ],
      ]
    : [];
  useHotkeys(hotKeys);
  const { colorScheme } = useMantineColorScheme();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    threeMonthsAgo,
    today,
  ]);
  const [cusipOrIsinTextInput, setCusipOrIsinTextInput] = useState<string>('');
  const [queryTypeToggle, setQueryTypeToggle] = useState<'date' | 'cusip'>(
    'date',
  );
  const theme = useMantineTheme();
  const getRowStyle = useCallback(
    (params: any) => {
      if (params.data?.modifiedInThisSession) {
        return {
          borderLeft: `6px solid ${
            colorScheme === 'dark'
              ? theme.colors.green[9]
              : theme.colors.green[4]
          }`,
        };
      }
    },
    [theme, colorScheme],
  );

  useDidUpdate(() => {
    gridRef?.current?.api?.setFilterModel(null);
  }, [queryTypeToggle]);

  const formattedStartDate = useMemo(() => {
    // return '2024-01-30';
    if (dateRange[0]) {
      return formatDate(dateRange[0]);
    }
    return null;
  }, [dateRange]);

  const formattedEndDate: string | null | undefined = useMemo(() => {
    // return '2024-10-30';
    if (dateRange[1]) {
      return formatDate(dateRange[1]);
    }
    return null;
  }, [dateRange]);
  const {
    data: eventsFromDateRange,
    isFetching: eventsFromDateRangeFetching,
    isError: isErrorFromDateRange,
    refetch: refreshEvents,
  } = useQuery(
    ['get-event-confirmations' + formattedStartDate + formattedEndDate],
    () =>
      getEventConfirmationsForDateRange({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }),
    {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  );

  const {
    mutate: getEventsByCusip,
    isLoading: getEventsByCusipLoading,
    data: eventsFromCusip,
    isError: isErrorFromCusip,
  } = useMutation(() => getEventConfirmationsById([cusipOrIsinTextInput]));

  const refetch = useCallback(() => {
    if (queryTypeToggle === 'date') {
      refreshEvents();
    } else {
      getEventsByCusip();
    }
  }, [getEventsByCusip, queryTypeToggle, refreshEvents]);

  const isError = useMemo(() => {
    if (queryTypeToggle === 'date') {
      return isErrorFromDateRange;
    } else {
      return isErrorFromCusip;
    }
  }, [isErrorFromCusip, isErrorFromDateRange, queryTypeToggle]);

  const handleSearch = () => {
    if (cusipOrIsinTextInput !== '') {
      getEventsByCusip();
    }
  };

  const isFetchingEvents = useMemo(() => {
    if (queryTypeToggle === 'date') {
      return eventsFromDateRangeFetching;
    } else {
      return getEventsByCusipLoading;
    }
  }, [eventsFromDateRangeFetching, getEventsByCusipLoading, queryTypeToggle]);

  const events = useMemo(() => {
    if (queryTypeToggle === 'date') {
      return eventsFromDateRange;
    } else {
      return eventsFromCusip;
    }
  }, [eventsFromCusip, eventsFromDateRange, queryTypeToggle]);

  // const initialGridState: GridState = useMemo(() => {
  //   return {
  //     ...quickEventFilter,
  //   };
  // }, []);
  //

  const handleTodayFilter = () => {
    gridRef?.current?.api?.setFilterModel(todayFilter);
  };
  const handleYesterdayFilter = () => {
    gridRef?.current?.api?.setFilterModel(yesterdayFilter);
  };

  const onSortChanged = useCallback((event: SortChangedEvent) => {
    event.api.refreshCells();
  }, []);
  const handleRefreshEvents = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleSearchInputChange = useCallback((e: any) => {
    gridRef?.current?.api?.setGridOption('quickFilterText', e.target.value);
  }, []);

  const resetGridFilters = useCallback(() => {
    gridRef?.current?.api?.setFilterModel(null);
  }, []);
  return (
    //   --global-header-height == 50px
    <Stack style={{ height: 'calc(100vh - 50px)', padding: '1rem' }}>
      <Group justify={'space-between'} align={'end'}>
        <Group>
          <SegmentedControl
            data={[
              { label: 'Date', value: 'date' },
              { label: 'Cusip', value: 'cusip' },
            ]}
            value={queryTypeToggle}
            onChange={(e) => setQueryTypeToggle(e as 'date' | 'cusip')}
          />

          {queryTypeToggle === 'date' && (
            <DatePickerInput
              style={{ width: '15rem' }}
              placeholder="MMM-DD-YYYY"
              valueFormat={'MMM-DD-YYYY'}
              firstDayOfWeek={0}
              type={'range'}
              value={dateRange}
              onChange={(value) => setDateRange(value)}
            />
          )}

          {queryTypeToggle === 'cusip' && (
            <Group>
              <TextInput
                placeholder={'Enter cusip or isin'}
                value={cusipOrIsinTextInput}
                onChange={(e) => setCusipOrIsinTextInput(e.target.value)}
              />
              <Button onClick={handleSearch}>Search</Button>
            </Group>
          )}
          <Button variant={'light'} onClick={handleYesterdayFilter}>
            Yesterday
          </Button>
          <Button onClick={handleTodayFilter}>Today</Button>
          <TextInput
            onChange={handleSearchInputChange}
            ref={quickSearchRef}
            rightSectionWidth={80}
            rightSection={
              <Kbd
                style={{
                  opacity: 0.8,
                }}
                size={'sm'}
              >
                {`ctrl + k`}
              </Kbd>
            }
            placeholder={'Search Comma Separated Values'}
            style={{ width: '25rem' }}
          />
        </Group>

        <Group>
          {(events?.data?.length || 0) > 0 && (
            <Button onClick={handleRefreshEvents} variant={'outline'}>
              Refresh Events
            </Button>
          )}

          <Tooltip label={'Reset Filters within the grid'}>
            <ActionIcon onClick={resetGridFilters} variant={'transparent'}>
              <IconFilterOff size={22} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
      <div
        style={{
          flexGrow: 1,
          // height: 'calc(100vh - 11rem)',
          minHeight: '40rem',
          width: '100%',
          margin: '0 auto',
        }}
      >
        {dateRange[0] && dateRange[1] ? (
          isError ? (
            <Title order={2}>Error Loading Events</Title>
          ) : (
            <div
              className={
                colorScheme === 'dark'
                  ? 'ag-theme-quartz-dark'
                  : 'ag-theme-quartz'
              }
              style={{ height: '100%' }}
            >
              {/*// style={{height: 'calc(100vh - 100px)', minHeight: '40rem', width: '100%', margin: '0 auto'}}>*/}
              <AgGridReact
                getRowId={getEventConfirmationRowId}
                masterDetail={true}
                isRowMaster={isRowEditable}
                detailCellRenderer={EventConfirmationDetailCellRenderer}
                // detailRowHeight={400}
                detailRowAutoHeight={true}
                loading={isFetchingEvents}
                ref={gridRef}
                rowData={(events?.data as EventConfirmationGridData[]) || []}
                columnDefs={EventConfirmationsColDefs}
                defaultColDef={defaultColDef}
                sideBar={SIDE_BAR_CONFIG}
                onSortChanged={onSortChanged}
                getRowStyle={getRowStyle}
                statusBar={statusBarConfig}
                quickFilterParser={quickFilterCommaParser}
              />
            </div>
          )
        ) : (
          <Container size={'md'}>
            <Title order={2}>Enter A Date Range To View Events</Title>
          </Container>
        )}
      </div>
    </Stack>
  );
};
