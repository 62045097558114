import React, { createContext, useMemo } from 'react';
export const EditModeContext = createContext<boolean>(false);
export const EditModeContextProvider = ({ children, editMode }: any) => {
  const editModeValue = useMemo(() => editMode, [editMode]);
  return (
    <EditModeContext.Provider value={editModeValue}>
      {children}
    </EditModeContext.Provider>
  );
};
