import classes from './jsonComparePicker.module.scss';
import { useCallback, useMemo, useState } from 'react';
import * as jsonpatch from 'fast-json-patch';
import { IconChevronLeft } from '@tabler/icons-react';
import { Button, Indicator, useMantineColorScheme } from '@mantine/core';
import { Operation } from 'fast-json-patch';
import { colors } from '../../Styles/colors';
import { allFormFields } from '../pdw-field-subsets';

interface OperationApply {
  op: string;
  value?: any;
  path: string;
  shouldApply?: null | boolean;
}

interface JsonReturnNodeRowProps {
  children: React.ReactNode;
  depth: number;
}

interface JsonReturnNodePatchRowProps {
  children: React.ReactNode;
  depth: number;
  patch?: OperationApply;
  updatePatchCallback?: ((patch: OperationApply) => void) | null;
}

interface createJsonDisplayForObjectProps {
  obj: any;
  patches: OperationApply[];
  depth?: number;
  currentPath?: string;
  updatePatchCallback: ((patch: OperationApply) => void) | null;
  showUnchanged: boolean;
}

interface JsonCompareDiffsProps {
  base: any;
  other: any;
  applyChangesCallback?: (value: any) => void;
  readonly?: boolean;
  blackListProps?: string[];
}

const patchFieldExistsInForm = (patch: OperationApply) => {
  return allFormFields.some(
    (x) =>
      x.replaceAll('.', '')?.trim() ===
      patch?.path?.replaceAll('/', '')?.trim(),
  );
};

const JsonComparePicker = ({
  base,
  other,
  applyChangesCallback,
  blackListProps,
  readonly,
}: JsonCompareDiffsProps) => {
  const { colorScheme } = useMantineColorScheme();
  const [showUnchanged, setShowUnchanged] = useState(true);
  const [patchesToBeApplied, setPatchesToBeApplied] = useState(
    [] as OperationApply[],
  );

  const handleUpdatePatch = useCallback(
    (patch: OperationApply) => {
      setPatchesToBeApplied([
        ...patchesToBeApplied.filter((x) => x.path !== patch.path),
        patch,
      ]);
    },
    [patchesToBeApplied],
  );

  const handleToggleHideUnchanged = () => {
    setShowUnchanged(!showUnchanged);
  };

  const handleApplyPatches = () => {
    const newBase = jsonpatch.applyPatch(
      base,
      patchesToBeApplied.filter((x) => x.shouldApply) as Operation[],
      true,
      false,
    ).newDocument;
    setPatchesToBeApplied([]);
    if (!!applyChangesCallback) {
      applyChangesCallback(newBase);
    }
  };

  /*
  Helper function to row with a given depth
 */
  const JsonReturnNodeRow = ({ children, depth }: JsonReturnNodeRowProps) => {
    return <div style={{ paddingLeft: `${depth * 20}px` }}>{children}</div>;
  };

  /*
    Helper function to show a change exists in the data, and allow the user to accept or reject the change
   */
  const JsonReturnNodePatchRow = useCallback(
    ({
      children,
      depth,
      patch,
      updatePatchCallback,
    }: JsonReturnNodePatchRowProps) => {
      const bc = patch
        ? patch.op === 'add'
          ? colorScheme === 'light'
            ? colors.lightThemeAddition
            : colors.darkThemeAddition
          : patch.op === 'remove'
            ? colorScheme === 'light'
              ? colors.lightThemeDeletion
              : colors.darkThemeDeletion
            : colorScheme === 'light'
              ? colors.lightThemeModification
              : colors.darkThemeModification
        : 'inherit';
      const patchIsInForm = patch ? patchFieldExistsInForm(patch) : null;
      return (
        <div
          style={{
            paddingLeft: `${depth * 20}px`,
            backgroundColor: bc,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>{children}</div>
          {patch && (
            <>
              <div
                style={{
                  paddingRight: '1em',
                  display: 'flex',
                  alignItems: 'center',
                }}
                data-testid={patch.op}
              >
                {patch.op === 'remove' ? (
                  ''
                ) : (
                  <>
                    {patch.op !== 'add' && (
                      <IconChevronLeft width={50} size={20} />
                    )}
                    <span
                      style={{
                        backgroundColor:
                          patch.op === 'add' || patch.op === 'remove'
                            ? 'inherit'
                            : colorScheme === 'dark'
                              ? colors.darkThemeNewValue
                              : colors.lightThemeNewValue,
                        padding: '0 1em',
                      }}
                    >
                      {patch?.value
                        ? typeof patch.value === 'object'
                          ? Array.isArray(patch.value)
                            ? showArrayAsJson(
                                patch.value,
                                depth - 2,
                                patch.path,
                              )
                            : showObjectAsJson(
                                patch.value,
                                depth - 2,
                                patch.path,
                              )
                          : patch.value + ''
                        : 'null'}
                    </span>
                  </>
                )}
              </div>
              {!!updatePatchCallback && !readonly && (
                <button
                  data-testid={patch.path + '-button'}
                  type="button"
                  disabled={patchIsInForm ? false : true}
                  style={{
                    width: '7em',
                    pointerEvents: patchIsInForm ? 'auto' : 'none',
                    cursor: patchIsInForm ? 'pointer' : 'none',
                    backgroundColor: patch.shouldApply ? 'green' : 'gray',
                    opacity: patchIsInForm ? 1 : 0.5,
                    color: 'white',
                    borderRadius: '4px',
                  }}
                  onClick={() =>
                    patchIsInForm
                      ? updatePatchCallback({
                          ...patch,
                          shouldApply: !patch?.shouldApply,
                        })
                      : () => {}
                  }
                >
                  {patchIsInForm
                    ? patch.shouldApply
                      ? 'Apply'
                      : "Don't Apply"
                    : 'Not in Form'}
                </button>
              )}
            </>
          )}
        </div>
      );
    },
    [colorScheme], // eslint-disable-line react-hooks/exhaustive-deps
  );

  /*
    This function recursively iterates to create a series of react nodes for a given json object and its set of patches
    patches are derives when comparing the base object (obj) to another object via jsonpatch.compare (json-fast-patch library)
   */
  const createJSONDisplayForObject = useCallback(
    ({
      obj,
      patches,
      depth = 0,
      currentPath,
      updatePatchCallback,
      showUnchanged,
    }: createJsonDisplayForObjectProps): any => {
      const returnNode = [];
      // handle additions:
      const applicableChildPatches = patches.filter((x) => {
        return (
          new RegExp(`^${currentPath}/[0-9A-Za-z]*$`).test(x.path) &&
          x.op === 'add'
        );
      });
      if (applicableChildPatches?.length > 0) {
        // @ts-ignore
        for (const [index, p] of applicableChildPatches.entries()) {
          const key = p.path.split('/').pop();
          returnNode.push(
            <JsonReturnNodePatchRow
              key={(currentPath || 'initialPath') + index}
              depth={depth}
              updatePatchCallback={updatePatchCallback} // todo: do we want additions?
              // updatePatchCallback={null} // todo: do we want additions?
              patch={p as OperationApply}
            >
              {' '}
              {key}:
            </JsonReturnNodePatchRow>,
          );
        }
        // returnNode.push(<JsonReturnNodeRow key={pathToKey + ']'} depth={depth}>{']'}</JsonReturnNodeRow>)
      }

      for (const [key, value] of Object.entries(obj)) {
        const pathToKey = currentPath + '/' + key;
        const applicablePatch = patches.find((x) => x.path === pathToKey);

        // null value, will be a replace patch
        if (value == null) {
          if (applicablePatch && applicablePatch.op !== 'remove') {
            returnNode.push(
              <JsonReturnNodePatchRow
                key={pathToKey}
                depth={depth}
                updatePatchCallback={updatePatchCallback}
                patch={applicablePatch as OperationApply}
              >{`${key}: ${value}`}</JsonReturnNodePatchRow>,
            );
          } else if (showUnchanged) {
            returnNode.push(
              <JsonReturnNodeRow
                key={pathToKey}
                depth={depth}
              >{`${key}: null`}</JsonReturnNodeRow>,
            );
          }
          continue;
          // empty array value, will be an add patch
        } else {
          if (typeof value === 'object') {
            if (Array.isArray(value)) {
              returnNode.push(
                <JsonReturnNodeRow
                  key={pathToKey}
                  depth={depth}
                >{`${key}: [`}</JsonReturnNodeRow>,
              );
              returnNode.push(
                createJsonDisplayForArray(
                  value,
                  patches,
                  depth + 1,
                  pathToKey,
                  updatePatchCallback,
                  showUnchanged,
                ),
              );
              returnNode.push(
                <JsonReturnNodeRow key={pathToKey + ']'} depth={depth}>
                  {']'}
                </JsonReturnNodeRow>,
              );
            } else {
              returnNode.push(
                <JsonReturnNodeRow
                  key={pathToKey + '{'}
                  depth={depth}
                >{`${key}: {`}</JsonReturnNodeRow>,
              );
              returnNode.push(
                createJSONDisplayForObject({
                  obj: value,
                  patches,
                  depth: depth + 1,
                  currentPath: pathToKey,
                  updatePatchCallback,
                  showUnchanged,
                }),
              );
              returnNode.push(
                <JsonReturnNodeRow key={pathToKey + '}'} depth={depth}>
                  {'}'}
                </JsonReturnNodeRow>,
              );
            }
          } else {
            // value is a primitive
            if (applicablePatch && applicablePatch.op !== 'remove') {
              returnNode.push(
                <JsonReturnNodePatchRow
                  key={pathToKey}
                  depth={depth}
                  updatePatchCallback={updatePatchCallback}
                  patch={applicablePatch as OperationApply}
                >{`${key}: ${value}`}</JsonReturnNodePatchRow>,
              );
            } else if (showUnchanged) {
              returnNode.push(
                <JsonReturnNodeRow
                  key={pathToKey}
                  depth={depth}
                >{`${key}: ${value}`}</JsonReturnNodeRow>,
              );
            }
          }
        }
      }
      return returnNode;
    },
    [JsonReturnNodePatchRow], // eslint-disable-line react-hooks/exhaustive-deps
  );

  /*
    Helper function that effectively prints the same thing as JSON.stringify(obj, null, 2), but without quotes around keys/values
 */
  const showObjectAsJson = useCallback(
    (obj: any, depth: number, path: string) => {
      const returnNodes: React.ReactNode[] = [];
      returnNodes.push(
        <JsonReturnNodeRow key={path + '{'} depth={depth}>
          {'{'}
        </JsonReturnNodeRow>,
      );
      Object.keys(obj).forEach((key: string) => {
        returnNodes.push(
          <JsonReturnNodeRow
            key={path + key}
            depth={depth + 1}
          >{`${key}: ${obj[key]}`}</JsonReturnNodeRow>,
        );
      });
      returnNodes.push(
        <JsonReturnNodeRow key={path + '}'} depth={depth}>
          {'}'}
        </JsonReturnNodeRow>,
      );
      return returnNodes;
    },
    [],
  );

  const showArrayAsJson = (arr: any[], depth: number, path: string) => {
    const returnNodes = [];
    if (arr.length === 0) {
      returnNodes.push(
        <JsonReturnNodeRow key={path + '[]'} depth={depth}>
          {'[]'}
        </JsonReturnNodeRow>,
      );
    } else {
      returnNodes.push(
        <JsonReturnNodeRow key={path + '['} depth={depth}>
          {'['}
        </JsonReturnNodeRow>,
      );
      arr.forEach((item: any, index: number) => {
        if (typeof item === 'object') {
          if (Array.isArray(item)) {
            returnNodes.push(
              showArrayAsJson(item, depth + 2, path + '/' + index),
            );
          } else {
            returnNodes.push(
              showObjectAsJson(item, depth + 2, path + '/' + index),
            );
          }
        } else {
          returnNodes.push(
            <JsonReturnNodeRow
              key={path + index}
              depth={depth + 1}
            >{`${item}`}</JsonReturnNodeRow>,
          );
        }
      });
      returnNodes.push(
        <JsonReturnNodeRow key={path + ']'} depth={depth}>
          {']'}
        </JsonReturnNodeRow>,
      );
    }
    return returnNodes;
  };

  /*
  Helper function for the recursive createJSONDisplayForObject function to handle arrays
   */
  const createJsonDisplayForArray = useCallback(
    (
      arr: any[],
      patches: OperationApply[],
      depth: number,
      currentPath: string,
      updatePatchCallback: any,
      showUnchanged: boolean,
    ) => {
      const returnNode = [];

      // initially, we loop through all the values that exist in the array from the base object
      // @ts-ignore needed for arr.entries() to not yell
      for (const [index, item] of arr.entries()) {
        const pathToItem = currentPath + '/' + index;
        const applicablePatch = patches.find((x) => x.path === pathToItem);
        const applicableChildPatch = patches.find((x) =>
          x.path.startsWith(pathToItem),
        );
        if (typeof item === 'object') {
          if (applicablePatch) {
            returnNode.push(
              <JsonReturnNodePatchRow
                key={pathToItem}
                depth={depth}
                updatePatchCallback={null}
                patch={applicablePatch as OperationApply}
              >
                {showObjectAsJson(item, depth - 2, pathToItem)}
              </JsonReturnNodePatchRow>,
            );
          } else if (applicableChildPatch) {
            returnNode.push(
              <JsonReturnNodeRow
                key={pathToItem + '{'}
                depth={depth}
              >{`{`}</JsonReturnNodeRow>,
            );
            // includePatchAdditions(item, patches, pathToItem);
            returnNode.push(
              createJSONDisplayForObject({
                obj: item,
                patches,
                depth: depth + 1,
                currentPath: pathToItem,
                updatePatchCallback: null,
                showUnchanged,
              }),
            );
            returnNode.push(
              <JsonReturnNodeRow
                key={pathToItem + '}'}
                depth={depth}
              >{`}`}</JsonReturnNodeRow>,
            );
          } else {
            if (showUnchanged) {
              returnNode.push(showObjectAsJson(item, depth, pathToItem));
            }
          }
        } else {
          // item is a primitive
          if (applicablePatch) {
            // question: do we allow primitives to be added to arrays?
            returnNode.push(
              <JsonReturnNodePatchRow
                key={pathToItem}
                depth={depth}
                updatePatchCallback={null}
                patch={applicablePatch as OperationApply}
              >{`${item}`}</JsonReturnNodePatchRow>,
            );
          } else if (showUnchanged) {
            returnNode.push(
              <JsonReturnNodeRow
                key={pathToItem}
                depth={depth}
              >{`${item}`}</JsonReturnNodeRow>,
            );
          }
        }
      }
      // then, we handle additions to array
      const applicableChildPatches = patches.filter((x) => {
        return (
          new RegExp(`^${currentPath}/([0-9]$|^[1-9][0-9]$|^(100))$`).test(
            x.path,
          ) &&
          x.path.startsWith(currentPath) &&
          x.op === 'add'
        );
      });
      if (applicableChildPatches?.length > 0) {
        // @ts-ignore
        for (const [index, p] of applicableChildPatches.entries()) {
          returnNode.push(
            <JsonReturnNodePatchRow
              key={currentPath + index}
              depth={depth}
              updatePatchCallback={updatePatchCallback}
              patch={p as OperationApply}
            >
              {' '}
              New Entry:
            </JsonReturnNodePatchRow>,
          );
        }
      }

      return returnNode;
    },
    [JsonReturnNodePatchRow, createJSONDisplayForObject, showObjectAsJson],
  );

  const patchesSelected = useMemo(() => {
    return patchesToBeApplied.filter((x) => x.shouldApply);
  }, [patchesToBeApplied]);

  const patches = useMemo(() => {
    return jsonpatch.compare(base, other).filter((patch) => {
      // We filter out the blacklisted props from the patch set.
      return !blackListProps?.includes(patch.path.replace('/', ''));
    });
  }, [base, other, blackListProps]);

  const diffs = useMemo(() => {
    const currentPatches: OperationApply[] = patches.map((x) => ({
      ...x,
      shouldApply:
        patchesToBeApplied.find((y) => y.path === x.path)?.shouldApply ?? false,
    }));

    return createJSONDisplayForObject({
      obj: base,
      patches: currentPatches,
      depth: 0,
      currentPath: '',
      updatePatchCallback: handleUpdatePatch,
      showUnchanged: showUnchanged,
    });
  }, [
    base,
    patchesToBeApplied,
    showUnchanged,
    handleUpdatePatch,
    createJSONDisplayForObject,
    patches,
  ]);
  return (
    <>
      {patches?.length === 0 ? (
        <h3>No Differences Found</h3>
      ) : (
        <div className={classes.actionHeader}>
          <Button variant="primary" onClick={handleToggleHideUnchanged}>
            {showUnchanged ? 'Hide Unchanged' : 'Show All'}
          </Button>
          <Indicator
            label={patchesSelected?.length}
            size={20}
            disabled={patchesSelected?.length === 0}
          >
            {!readonly && (
              <Button
                variant="primary"
                onClick={handleApplyPatches}
                disabled={patchesSelected?.length === 0}
              >
                Apply Updates
              </Button>
            )}
          </Indicator>
        </div>
      )}
      <pre style={{ padding: '1em 2em' }}>{diffs}</pre>
    </>
  );
};

export default JsonComparePicker;

// notes: on incoming objects we intentionally don't show 'removal' patches because they don't make sense, since we don't
// necessarily count on the incoming data to have all of our data, it would just clutter the ui and confuse the user
