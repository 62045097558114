import { useNavigate } from 'react-router-dom';

export const useGoToCopyProductEntry = () => {
  const navigate = useNavigate();

  const goToCopyProductEntry = (productId: string | null | undefined) => {
    if (productId) {
      navigate(`/product-entry/copy/${productId}`);
    }
  };

  return { goToCopyProductEntry };
};
