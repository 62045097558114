import axios from 'axios';
import { MARKET_DATA_MAINTENANCE_SERVICE } from './api-consts';
import { Underlier } from '../product-schema/underlier.schema';

export interface MarketDataErrorResponse {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
}

export const insertUnderlier = (underlier: Partial<Underlier>) =>
  axios.post(`${MARKET_DATA_MAINTENANCE_SERVICE}/underlier`, underlier);

export const insertFactsetUnderlier = (underlier: Partial<Underlier>) =>
  axios.post(`${MARKET_DATA_MAINTENANCE_SERVICE}/underlier/factset`, underlier);

export const updateUnderlier = (underlier: Underlier) =>
  axios.put(`${MARKET_DATA_MAINTENANCE_SERVICE}/underlier`, underlier);

export const searchUnderliersByPrefix = (
  prefix: string,
  searchActive: boolean,
) =>
  axios.get<Underlier[]>(
    `${MARKET_DATA_MAINTENANCE_SERVICE}/underlier/search`,
    {
      params: {
        prefix,
        searchActive,
      },
    },
  );

export const searchFactsetUnderliers = (bloombergTickers: string[]) =>
  axios.post(
    `${MARKET_DATA_MAINTENANCE_SERVICE}/underlier/factset/search`,
    bloombergTickers,
  );

// todo: may be needed one day?
// export const getUnderliersByBloombergOrRicTicker = (ticker: {
//   type: 'rics' | 'bloombergTickers',
//   ticker: string
// }) => axios.post<Underlier>(`${MARKET_DATA_MAINTENANCE_SERVICE}/underlier/getunderliers`, {
//   [ticker.type]: [ticker.ticker]
// });
export const findMissingPricing = () =>
  axios.post(
    `${MARKET_DATA_MAINTENANCE_SERVICE}/market-data/missingprices/default`,
  );

export interface UploadPricesPayload {
  file: File;
  isIndicitive: boolean;
  isHistorical: boolean;
}

export const uploadPrices = (payload: UploadPricesPayload) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  return axios.post(
    `${MARKET_DATA_MAINTENANCE_SERVICE}/market-data/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        indicativePricing: payload.isIndicitive,
        isHistoricalUpload: payload.isHistorical,
      },
    },
  );
};

export const getAllExchanges = () =>
  axios.get(`${MARKET_DATA_MAINTENANCE_SERVICE}/exchange/all`);
