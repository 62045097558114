import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getCoursesViaPost,
  saveCourses,
  TrainingCourse,
} from '../../API/user.service';
import { useForm, yupResolver } from '@mantine/form';
import {
  Autocomplete,
  Button,
  LoadingOverlay,
  Paper,
  Switch,
  Text,
  TextInput,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { ArrayNumberItem } from '../../components/ArrayNumberItem';
import { DateInput } from '@mantine/dates';
import { quickInputDateParser } from '../../utilities/parcers-and-formatters/quick-input-date-parser';
import { colors } from '../../Styles/colors';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import {
  createAccurateDateWithoutTime,
  formatDate,
  removeNestedNulls,
} from '../../utilities/utilities';
import { notifications } from '@mantine/notifications';
import { useMemo } from 'react';
import { usePrompt } from '../../hooks/use-prompt';
import { courseListSchema } from './CourseListSchema';

interface props {
  publishersSelected: string[];
  publishers: string[] | undefined;
}

export const ANLearningCenterCourseList = ({
  publishersSelected,
  publishers,
}: props) => {
  const queryClient = useQueryClient();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  const {
    data: trainingCourses,
    isLoading: coursesLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['getANLearningCenterCourses', publishersSelected.join(',')],
    queryFn: () => getCoursesViaPost(publishersSelected),
    cacheTime: 0,
    onSuccess: (res) => {
      const newFormValue = {
        courses: res.data.map((x) => ({
          ...x,
          status: x.status === 'ACTIVE',
          startDate: x.startDate
            ? createAccurateDateWithoutTime(x.startDate as string)
            : '',
          endDate: x.endDate
            ? createAccurateDateWithoutTime(x.endDate as string)
            : '',
        })),
      };
      trainingCoursesForm.setValues(newFormValue);
      trainingCoursesForm.resetDirty(newFormValue);
    },
  });

  const currentCourseCount = useMemo(
    () => trainingCourses?.data?.length,
    [trainingCourses],
  );

  const trainingCoursesForm = useForm({
    initialValues: {
      courses: [] as TrainingCourse[],
    },
    validate: yupResolver(courseListSchema),
    validateInputOnBlur: true,
  });
  console.log('check', trainingCoursesForm, trainingCoursesForm.isValid());
  console.log(trainingCoursesForm.getInputProps(`courses.${0}.courseName`));
  const handleAddCourse = () => {
    trainingCoursesForm.insertListItem('courses', {
      id: '',
      coursePackage: '',
      courseId: '',
      courseName: '',
      status: '',
      trainingType: '',
      publisher: '',
      carrierCode: '',
      startDate: '',
      endDate: '',
      createDate: '',
      alias: '',
    });
  };

  const {
    mutate: mutateCourseList,
    isLoading: savingCourseList,
    isError: courseListSaveError,
  }: any = useMutation((config: TrainingCourse[]) => saveCourses(config), {
    onSuccess: () => {
      notifications.update({
        id: 'save-course-list',
        title: 'Save Successful',
        message: 'Course List Saved Successfully.',
        loading: false,
        autoClose: false,
        withCloseButton: true,
        color: 'green',
      });
      queryClient.invalidateQueries([
        'getANLearningCenterCourses',
        publishersSelected.join(','),
      ]);
    },
    onError: () => {
      notifications.update({
        id: 'save-course-list',
        title: 'Error Saving Courses',
        message: 'Failed to save courses at this time.',
        loading: false,
        withCloseButton: true,
        color: 'red',
        autoClose: 30000,
      });
    },
  });

  const handleSaveCourses = () => {
    const payload = trainingCoursesForm.values.courses?.map((x) => ({
      ...x,
      status: x.status ? 'ACTIVE' : 'INACTIVE',
      startDate: formatDate(x.startDate),
      endDate: formatDate(x.endDate),
    }));

    notifications.show({
      id: 'save-course-list',
      title: 'Submitting',
      message: 'Saving Course List...',
      loading: true,
      autoClose: false,
      withCloseButton: false,
      color: 'blue',
    });
    mutateCourseList(removeNestedNulls(payload));
  };

  const handleRemoveRow = (index: number) => {
    trainingCoursesForm.removeListItem('courses', index);
  };

  // prompt the user if edits exist on the product edit form, since we don't store that in local storage.
  usePrompt(
    'Leaving will result in unsaved changes for the "Edit Product" section.',
    trainingCoursesForm.isDirty(),
    true,
  );
  const formIsValid = trainingCoursesForm.isValid();

  return (
    <section style={{ width: 'fit-content' }}>
      <Paper
        shadow="sm"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: courseListSaveError ? '4px solid red' : '1px solid gray',
        }}
      >
        <LoadingOverlay
          visible={coursesLoading || savingCourseList}
          overlayProps={{ blur: 1 }}
        />
        {isError ? (
          <>
            <Text ta={'center'} style={{ color: 'red' }}>
              Something went wrong
            </Text>
            <Text>Unable To load Courses</Text>
            <Button
              variant="primary-full"
              disabled={isRefetching}
              onClick={() => refetch()}
            >
              Retry
            </Button>
          </>
        ) : (
          <>
            {trainingCoursesForm.values.courses.length > 0 ? (
              <>
                {trainingCoursesForm.values.courses.map((course, index) => (
                  <div
                    key={course.id ?? index}
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      flexWrap: 'wrap',
                      alignItems: 'end',
                      padding: '1rem 2rem',
                      backgroundColor:
                        index % 2 === 0
                          ? colorScheme === 'dark'
                            ? theme.colors.dark[7]
                            : colors.neutral20
                          : colorScheme === 'dark'
                            ? colors.neutral80
                            : 'white',
                    }}
                  >
                    <ArrayNumberItem>{index + 1}.</ArrayNumberItem>
                    <TextInput
                      label={'Course Id'}
                      style={{ width: '7rem' }}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.courseId`,
                      )}
                    />

                    <TextInput
                      label={'Course Name'}
                      styles={{
                        wrapper: {
                          marginBottom: '0px',
                        },
                        error: {
                          position: 'absolute',
                          paddingTop: '0.25rem',
                        },
                      }}
                      style={{ width: '24rem' }}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.courseName`,
                      )}
                    />
                    <TextInput
                      label={'Alias'}
                      style={{ width: '16rem' }}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.alias`,
                      )}
                    />

                    <TextInput
                      label={'Training Type'}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.trainingType`,
                      )}
                    />

                    <TextInput
                      label={'Carrier Code'}
                      style={{ width: '8rem' }}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.carrierCode`,
                      )}
                    />

                    <Autocomplete
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.publisher`,
                      )}
                      label="Publisher"
                      styles={{
                        wrapper: {
                          marginBottom: '0px',
                        },
                        error: {
                          position: 'absolute',
                          paddingTop: '0.25rem',
                        },
                      }}
                      placeholder="Publisher"
                      data={publishers ?? []}
                    />

                    <DateInput
                      label={'Start Date'}
                      style={{ width: '8rem' }}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.startDate`,
                      )}
                      dateParser={quickInputDateParser}
                      allowDeselect={true}
                      valueFormat={'MM/DD/YYYY'}
                      autoComplete={'off'}
                      placeholder="MM/DD/YYYY"
                      firstDayOfWeek={0}
                      hideOutsideDates={true}
                      maw={280}
                    />

                    <DateInput
                      label={'End Date'}
                      style={{ width: '8rem' }}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.endDate`,
                      )}
                      dateParser={quickInputDateParser}
                      allowDeselect={true}
                      valueFormat={'MM/DD/YYYY'}
                      autoComplete={'off'}
                      placeholder="MM/DD/YYYY"
                      firstDayOfWeek={0}
                      hideOutsideDates={true}
                      maw={280}
                    />

                    <Switch
                      style={{ marginBottom: '0.5rem' }}
                      label={`Status: ${trainingCoursesForm.values.courses[index].status ? 'Active' : 'Inactive'}`}
                      {...trainingCoursesForm.getInputProps(
                        `courses.${index}.status`,
                        { type: 'checkbox' },
                      )}
                    />

                    {currentCourseCount && index + 1 > currentCourseCount && (
                      <IconTrash
                        style={{ marginBottom: '0.5rem' }}
                        onClick={() => handleRemoveRow(index)}
                      />
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div style={{ padding: '5rem 10rem' }}>
                <Text ta={'center'}>No Courses Found</Text>
              </div>
            )}

            <Button
              variant="primary-full"
              disabled={savingCourseList}
              onClick={handleAddCourse}
            >
              <IconPlus size={16} style={{ marginRight: '0.5rem' }} /> Add
              Course
            </Button>
          </>
        )}
      </Paper>

      <div
        style={{
          padding: '2rem 1rem',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="primary"
          onClick={handleSaveCourses}
          disabled={savingCourseList || !formIsValid}
        >
          Save Courses
        </Button>
      </div>
    </section>
  );
};
