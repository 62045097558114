import React from 'react';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { Badge, Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const ProductMetaData = () => {
  const form = useProductFormContext();
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <div>
      {
        form.values.productId && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h4 style={{ margin: '0.5rem' }}>Product Meta Data</h4>

            <Badge
              style={{ cursor: 'pointer', marginRight: '1rem' }}
              variant={'outline'}
              radius={'sm'}
              onClick={toggle}
            >
              {opened ? 'Hide' : 'Show'}
            </Badge>
          </div>
        )
        // <Tooltip label={'Show Product Meta Data'} position={'bottom'}>
        //     <div style={{cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={toggle}>
        //
        //         <div>Id: <strong>{form.values.productId}</strong></div>
        //         <Burger size={'xs'} opened={opened} style={{paddingRight: '2em'}}/>
        //     </div>
        //
        // </Tooltip>
      }

      <Collapse in={opened}>
        <div style={{ display: 'flex', padding: '0', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              Id: <strong>{form.values.productId}</strong>
            </div>
            {form.values.productDataSource && (
              <div>
                {' '}
                Data Source: <strong>{form.values.productDataSource}</strong>
              </div>
            )}
            {form.values.productGeneral.structureNameInternal && (
              <div>
                {' '}
                Internal Name:{' '}
                <strong>
                  {form.values.productGeneral.structureNameInternal}
                </strong>
              </div>
            )}
            {form.values.previousStage && (
              <div>
                {' '}
                Previous Stage: <strong>{form.values.previousStage}</strong>
              </div>
            )}
            {form.values.previousStatus && (
              <div>
                {' '}
                Previous Status: <strong>{form.values.previousStatus}</strong>
              </div>
            )}
            {form.values.createTimestamp && (
              <div>
                Created On:{' '}
                <strong>
                  {new Date(form.values.createTimestamp).toLocaleString(
                    'en-US',
                    {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    },
                  )}
                </strong>
              </div>
            )}
            {form.values.createdByUserId && (
              <div>
                {' '}
                Created By: <strong>{form.values.createdByUserId}</strong>
              </div>
            )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {form.values.productGeneral.opsReviewStatus && (
              <div>
                {' '}
                Ops Review Status:{' '}
                <strong>{form.values.productGeneral.opsReviewStatus}</strong>
              </div>
            )}
            {form.values.opsReviewUserId && (
              <div>
                {' '}
                Ops Review User: <strong>{form.values.opsReviewUserId}</strong>
              </div>
            )}
            {form.values.opsReviewTimestamp && (
              <div>
                {' '}
                Ops Review Timestamp:{' '}
                <strong>
                  {new Date(form.values.opsReviewTimestamp).toLocaleString(
                    'en-US',
                    {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    },
                  )}
                </strong>
              </div>
            )}
            {form.values.updatedByUserId && (
              <div>
                {' '}
                Updated By: <strong>{form.values.updatedByUserId}</strong>
              </div>
            )}
            {form.values.updateTimestamp && (
              <div>
                {' '}
                Last Updated:{' '}
                <strong>
                  {new Date(form.values.updateTimestamp).toLocaleString(
                    'en-US',
                    {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    },
                  )}
                </strong>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default ProductMetaData;
