import { emptyCallDateObj, emptyCouponDateObj } from './CalendarDates';
import { convertTenorToMonths } from '../../../../utilities/utilities';
import {
  StepTypes,
  TenorUnits,
} from '../../../../product-schema/pdw-select-options';
import Big from 'big.js';

export const processDateCalculationPayload = (data: any, form: any) => {
  if (data?.OBSERVATIONSETTLEMENTDATES?.QDATES?.DATA) {
    const dates = data.OBSERVATIONSETTLEMENTDATES.QDATES.DATA;
    let datesFromCalc: any[] = [];
    const callObservationDates: any[] = [];
    const couponDates: any[] = [];
    dates.forEach((datePair: any, index: number) => {
      datesFromCalc.push({
        observationDate: datePair[1]?.replaceAll('/', '-'),
        settlementDate: datePair[2]?.replaceAll('/', '-'),
        type: null,
      });
    });

    if (
      form.values.productYield.paymentType &&
      form.values.productCall.callType
    ) {
      datesFromCalc = updateType([...datesFromCalc], form);
    } else {
      if (form.values.productCall.callType) {
        datesFromCalc = updateNoCallPeriodRows([...datesFromCalc], form);
      }
      let type: string = '';
      if (
        form.values.productYield.paymentType &&
        !form.values.productCall.callType
      ) {
        type = 'coupon';
      } else if (
        !form.values.productYield.paymentType &&
        form.values.productCall.callType
      ) {
        type = 'call';
      }
      datesFromCalc = datesFromCalc.map((x) => {
        return { ...x, type: type };
      });
    }
    datesFromCalc.forEach((dateObj: any) => {
      if (dateObj.type === 'both') {
        couponDates.push({
          ...emptyCouponDateObj,
          paymentObservationDate: dateObj.observationDate,
          paymentSettlementDate: dateObj.settlementDate,
          // dateType: DateRowRadioOptions.CALL
        });
        callObservationDates.push({
          ...emptyCallDateObj,
          callObservationDate: dateObj.observationDate,
          callSettlementDate: dateObj.settlementDate,
          // dateType: DateRowRadioOptions.COUPON,
        });
      } else if (dateObj.type === 'call') {
        callObservationDates.push({
          ...emptyCallDateObj,
          callObservationDate: dateObj.observationDate,
          callSettlementDate: dateObj.settlementDate,
          // dateType: DateRowRadioOptions.COUPON,
        });
      } else if (dateObj.type === 'coupon') {
        couponDates.push({
          ...emptyCouponDateObj,
          paymentObservationDate: dateObj.observationDate,
          paymentSettlementDate: dateObj.settlementDate,
          // dateType: DateRowRadioOptions.CALL
        });
      } else {
        console.log('error, no type', dateObj);
      }
    });
    form.setFieldValue(
      'productCall.callObservationDateList',
      callObservationDates,
    );
    form.setFieldValue('productYield.paymentDateList', couponDates);
  }
};

const updateType = (dateCalcDates: any[], form: any): any[] => {
  const noCallRows = form.values.productCall.numberNoCallPeriods;
  const freqs = calculateCallCouponFrequency(form);
  const callFreq = freqs.callFreq;
  const couponFreq = freqs.couponFreq;

  let rowNum = 1;
  let callPeriods = 0;
  const newRows = [];
  for (let i = 0; i < dateCalcDates.length; i++) {
    const row: any = { ...dateCalcDates[i] };

    if (
      dateCalcDates.length === i + 1 ||
      (callPeriods < noCallRows && rowNum % couponFreq === 0)
    ) {
      row.type = 'both';
    } else if (
      callPeriods < noCallRows &&
      rowNum % callFreq === 0 &&
      rowNum % couponFreq !== 0
    ) {
      dateCalcDates.splice(i, 1);
      i--;
    } else if (callPeriods >= noCallRows) {
      row.type =
        rowNum % callFreq === 0
          ? rowNum % couponFreq === 0
            ? 'both'
            : 'call'
          : 'coupon';
    }
    if (rowNum % callFreq === 0) {
      callPeriods++;
    }
    newRows.push(row);
    rowNum++;
  }
  return newRows;
};

const calculateCallCouponFrequency = (form: any) => {
  const freqArray: any = {
    Monthly: 1,
    Quarterly: 3,
    'Semi-Annually': 6,
    Annually: 12,
  };
  let callFreq =
    freqArray[form.values.productCall.callObservationFrequency] || 0;
  let couponFreq =
    freqArray[form.values.productYield.paymentEvaluationFrequencyFinal] || 0;

  if (callFreq === 0 || couponFreq === 0) {
    return { callFreq, couponFreq };
  }

  let maxTemp;
  let minTemp;
  let callIsMax;
  if (callFreq > couponFreq) {
    maxTemp = callFreq;
    minTemp = couponFreq;
    callIsMax = true;
  } else if (couponFreq > callFreq) {
    maxTemp = couponFreq;
    minTemp = callFreq;
    callIsMax = false;
  } else {
    callFreq = 1;
    couponFreq = 1;
  }

  if (maxTemp && maxTemp % minTemp === 0) {
    callFreq = callIsMax ? maxTemp / minTemp : minTemp / minTemp;
    couponFreq = callIsMax ? minTemp / minTemp : maxTemp / minTemp;
  }

  return { callFreq, couponFreq };
};

const updateNoCallPeriodRows = (dateCalcDates: any[], form: any): any[] => {
  const newRows = [];
  const noCallRows = form.values.productCall.numberNoCallPeriods;
  let callPeriods = 0;

  for (let i = 0; i < dateCalcDates.length; i++) {
    if (callPeriods >= noCallRows) {
      newRows.push({ ...dateCalcDates[i] });
    }
    callPeriods++;
  }
  return newRows;
};

export const calculateBarrierPremium = (form: any) => {
  const noCallRows = form.values.productCall.numberNoCallPeriods;
  const freqs = calculateCallCouponFrequency(form);
  let callFreq = freqs.callFreq;
  let couponFreq = freqs.couponFreq;
  const tenorInMonths: number | null = convertTenorToMonths(
    form.values.productGeneral.tenorFinal,
    form.values.productGeneral.tenorUnit as TenorUnits,
  );
  const indexArray: any[] = [];

  if (!tenorInMonths) {
    alert('Please enter a valid tenor and tenor unit.');
    return;
  }
  if (callFreq === 0) {
    indexArray.length = tenorInMonths / couponFreq;
    couponFreq = 1;
  } else if (couponFreq === 0) {
    indexArray.length = tenorInMonths / callFreq;
    callFreq = 1;
  } else {
    indexArray.length =
      tenorInMonths / (callFreq > couponFreq ? couponFreq : callFreq);
  }

  let rowNum = 1;
  let callPeriods = 0;

  for (let i = 0; i < indexArray.length; i++) {
    if (indexArray.length === i + 1) {
      indexArray[i] = levelStepObject(form, rowNum);
    } else if (callPeriods < noCallRows && rowNum % couponFreq === 0) {
      indexArray[i] = levelStepObject(form, rowNum);
    } else if (
      callPeriods < noCallRows &&
      rowNum % callFreq === 0 &&
      rowNum % couponFreq !== 0
    ) {
      i--;
    } else if (callPeriods >= noCallRows) {
      indexArray[i] = levelStepObject(form, rowNum);
    }
    if (rowNum % callFreq === 0) {
      callPeriods++;
    }
    rowNum++;
  }
  return indexArray;
};

const levelStepObject = (form: any, index: number) => {
  const stepDirection = form.values.productCall.stepType;
  const multiplier = stepDirection === StepTypes.UP ? 1 : -1;
  const callBarrierLevelFinal = form.values.productCall.callBarrierLevelFinal;
  const callBarrierStepIncrement =
    form.values.productCall.callBarrierStepIncrement;
  const callPremiumFinal = form.values.productCall.callPremiumFinal;
  const callPremiumStepIncrement =
    form.values.productCall.callPremiumStepIncrement;
  return {
    barrier: calculateLevel(
      callBarrierLevelFinal,
      callBarrierStepIncrement,
      index,
      multiplier,
    ),
    premium: calculateLevel(
      callPremiumFinal,
      callPremiumStepIncrement,
      index,
      1,
    ),
  };
};

const calculateLevel = (
  call: number,
  increment: number,
  index: number,
  multiplier: number,
) => {
  // return call + (+increment * index * multiplier);
  const inc: number = +Big(increment).times(Big(index)).times(Big(multiplier));
  return +Big(call).plus(inc);
};

// versions that directly call form from form hook:
// const processDateCalculationPayload = (data: any) => {
//   console.log('check', data);
//   // todo: test/debug
//   if (data?.OBSERVATIONSETTLEMENTDATES?.QDATES?.DATA) {
//     const dates = data.OBSERVATIONSETTLEMENTDATES.QDATES.DATA;
//
//     // this.rowArray.clear(); // todo: clear rows
//     let datesFromCalc: any[] = [];
//     const callObservationDates: any[] = [];
//     const couponDates: any[] = [];
//     dates.forEach((datePair: any, index: number) => {
//       datesFromCalc.push({
//         observationDate: datePair[1]?.replaceAll('/', '-'),
//         settlementDate: datePair[2]?.replaceAll('/', '-'),
//         type: null
//       });
//     });
//
//     if (form.values.productYield.paymentType && form.values.productCall.callType) {
//       datesFromCalc = updateType([...datesFromCalc]);
//     } else {
//       if (form.values.productCall.callType) {
//         datesFromCalc = updateNoCallPeriodRows([...datesFromCalc]);
//       }
//       let type: string = '';
//       if(form.values.productYield.paymentType && !form.values.productCall.callType) {
//         type = 'coupon'
//       } else if(!form.values.productYield.paymentType && form.values.productCall.callType) {
//         type = 'call';
//       }
//       datesFromCalc = datesFromCalc.map(x => {
//         return {...x, type: type}
//       })
//     }
//     console.log('dates', datesFromCalc);
//     datesFromCalc.map((dateObj: any) => {
//       if(dateObj.type === 'both') {
//         couponDates.push({
//           ...emptyCouponDateObj,
//           paymentObservationDate: dateObj.observationDate,
//           paymentSettlementDate: dateObj.settlementDate,
//           // dateType: DateRowRadioOptions.CALL
//         });
//         callObservationDates.push({
//           ...emptyCallDateObj,
//           callObservationDate: dateObj.observationDate,
//           callSettlementDate: dateObj.settlementDate,
//           // dateType: DateRowRadioOptions.COUPON,
//         })
//       }
//       else if(dateObj.type === 'call') {
//         callObservationDates.push({
//           ...emptyCallDateObj,
//           callObservationDate: dateObj.observationDate,
//           callSettlementDate: dateObj.settlementDate,
//           // dateType: DateRowRadioOptions.COUPON,
//         })
//       } else if(dateObj.type === 'coupon') {
//         couponDates.push({
//           ...emptyCouponDateObj,
//           paymentObservationDate: dateObj.observationDate,
//           paymentSettlementDate: dateObj.settlementDate,
//           // dateType: DateRowRadioOptions.CALL
//         });
//       } else {
//         console.log('error, no type', dateObj)
//       }
//     })
//     console.log('check', callObservationDates, couponDates)
//     form.setFieldValue('productCall.callObservationDateList', callObservationDates);
//     form.setFieldValue('productYield.paymentDateList', couponDates);
//   }
// }

// const updateNoCallPeriodRows = (dateCalcDates: any[]): any[] => {
//   const newRows = [];
//   const noCallRows = form.values.productCall.numberNoCallPeriods;
//
//   let callPeriods = 0;
//
//   for (let i = 0; i < dateCalcDates.length; i++) {
//     if (callPeriods >= noCallRows) {
//       newRows.push({...dateCalcDates[i]})
//     }
//     // if(callPeriods < noCallRows) {
//     //   this.rowArray.removeAt(i);
//     //   i--;
//     // }
//     callPeriods++;
//   }
//   return newRows;
// }

// const updateType = (dateCalcDates: any[]): any[] => {
//   const noCallRows = form.values.productCall.numberNoCallPeriods;
//   const freqs = calculateCallCouponFrequency();
//   const callFreq = freqs.callFreq;
//   const couponFreq = freqs.couponFreq;
//
//   let rowNum = 1;
//   let callPeriods = 0;
//   const newRows = [];
//   for (let i = 0; i < dateCalcDates.length; i++) {
//     const row: any = {...dateCalcDates[i]};
//
//     if ((dateCalcDates.length === i + 1) ||
//       callPeriods < noCallRows && (rowNum % couponFreq === 0)
//     ) {
//       row.type = 'both';
//     } else if (callPeriods < noCallRows && (rowNum % callFreq === 0) && (rowNum % couponFreq !== 0)) {
//       dateCalcDates.splice(i, 1);
//       i--;
//     } else if (callPeriods >= noCallRows) {
//       row.type = (rowNum % callFreq === 0) ? ((rowNum % couponFreq === 0) ? 'both' : 'call') : 'coupon';
//     }
//     if (rowNum % callFreq === 0) {
//       callPeriods++;
//     }
//     newRows.push(row);
//     rowNum++;
//   }
//   return newRows;
// }

// const calculateCallCouponFrequency = () => {
//   const freqArray: any = {Monthly: 1, Quarterly: 3, 'Semi-Annually': 6, Annually: 12};
//   let callFreq = freqArray[form.values.productCall.callObservationFrequency] || 0;
//   let couponFreq = freqArray[form.values.productYield.paymentEvaluationFrequencyFinal] || 0;
//
//   if (callFreq === 0 || couponFreq === 0) {
//     return {callFreq, couponFreq};
//   }
//
//   let maxTemp;
//   let minTemp;
//   let callIsMax;
//   if (callFreq > couponFreq) {
//     maxTemp = callFreq;
//     minTemp = couponFreq;
//     callIsMax = true;
//   } else if (couponFreq > callFreq) {
//     maxTemp = couponFreq;
//     minTemp = callFreq;
//     callIsMax = false;
//   } else {
//     callFreq = 1;
//     couponFreq = 1;
//   }
//
//   if (maxTemp && maxTemp % minTemp === 0) {
//     callFreq = (callIsMax) ? (maxTemp / minTemp) : (minTemp / minTemp);
//     couponFreq = (callIsMax) ? (minTemp / minTemp) : (maxTemp / minTemp);
//   }
//
//   return {callFreq, couponFreq};
// }
