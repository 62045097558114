import { useState, useRef, useMemo } from 'react';

export const useStopwatch = () => {
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTimeInSeconds((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setIsRunning(false);
  };

  const resetTimer = () => {
    setTimeInSeconds(0);
  };

  //   // Format time into HH:MM:SS
  const timeFormatted = useMemo(() => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const secs = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }, [timeInSeconds]);

  //   // Format time into HH:MM:SS
  //   const getTimeFormatted = (timeInSeconds: number) => {
  //     const hours = Math.floor(timeInSeconds / 3600);
  //     const minutes = Math.floor((timeInSeconds % 3600) / 60);
  //     const secs = timeInSeconds % 60;
  //     return `${hours.toString().padStart(2, '0')}:${minutes
  //       .toString()
  //       .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  //   };

  return {
    stopTimer,
    startTimer,
    resetTimer,
    timeFormatted,
    timeInSeconds,
    isRunning,
  };
};
