type FormErrors = Record<string, React.ReactNode>;
interface YupError {
  path: string;
  message: string;
}

interface YupValidationResult {
  inner: YupError[];
}

interface YupSchema {
  validateSync(
    values: Record<string, any>,
    options: { abortEarly: boolean; context: any },
  ): void;
}

export function yupResolverWithContext(
  schema: any,
  dates: any,
  isProductCalled: boolean = false,
) {
  const _schema: YupSchema = schema;
  return (values: Record<string, any>): FormErrors => {
    try {
      _schema.validateSync(values, {
        abortEarly: false,
        context: { data: values, holidays: dates, isProductCalled },
      });
      return {};
    } catch (_yupError: any) {
      const yupError: YupValidationResult = _yupError;
      const results: any = {};
      yupError.inner.forEach((error: YupError) => {
        results[error.path.replaceAll('[', '.').replaceAll(']', '')] =
          error.message;
      });
      return results;
    }
  };
}
