import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.min.css';
import './Styles/index.scss';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import BasicError from './components/error-boundries/BasicError';
import ProductEntryAndEditPage from './pages/product-entry-and-edit/product-entry-and-edit.page';
import HomeComponent from './pages/home/Home';
import RootComponent from './components/core/Root';
import LoginComponent from './pages/login/login';
import ProtectedRouterOutlet from './components/Authentication/ProtectedRouterOutlet';
import { EditModeContextProvider } from './pages/product-entry-and-edit/entry-and-edit-contexts/edit-mode-context';
import {
  getFullJWTResponse,
  LOGIN_PATH,
  refreshAccessToken,
} from './components/Authentication/AuthProvider';
import { isLocal } from './enviroments/.env';
import Cookies from 'js-cookie';
import {
  DESK_CONFIG_ROUTE,
  FINAL_REVIEW_QUEUE_ROUTE,
  PRODUCT_EDIT_ROUTE,
  PRODUCT_ENTRY_ROUTE,
  REVIEW_QUEUE_ROUTE,
  SPOTTY_REVIEW_QUEUE_ROUTE,
  STAGED_PRODUCTS_ROUTE,
  UPCOMING_HOLIDAYS_ROUTE,
  UNDERLIERS_ROUTE,
  MISSING_MARKET_DATA_ROUTE,
  LEARNING_CENTER_COURSE_CONFIG,
  MSWM_EVENTS_ROUTE,
  EVENT_CONFIRMATION_GENERAL,
} from './Routes';
import FinalReviewQueue from './pages/reviewQueues/FinalEvaluationReviewQueue';
import EditModeEntry from './pages/product-entry-and-edit/editModeEntry/EditModeEntry';
import ReviewQueue from './pages/reviewQueues/ReviewQueue';
import PermissionGuard from './components/Authentication/PermissionGuard';
import UpcomingHolidays from './pages/market-data/holiday-page/UpcomingHolidays';
import UnderlierPage from './pages/market-data/underliers/UnderliersPage';
import MissingMarketData from './pages/market-data/missing-market-data/MissingMarketData';
import { WidgetPermissions } from './enviroments/WidgetPermissions';
import axios from 'axios';
import { NothingFoundBackground } from './components/error-boundries/404';
import { StagedReviewQueue } from './pages/reviewQueues/StagedReviewQueue';
import { DeskConfig } from './pages/deskConfig/DeskConfig';
import { LearningCenterCourseConfig } from './pages/learningCenterCourseConfig/LearningCenterCourseConfig';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { PrimaryTheme } from './components/core/MantineTheme';
import { MSWMEventConfirmations } from './pages/EventConfirmations/MSWMEventConfirmations/MSWMEventConfirmations';
import { EventConfirmationsGeneral } from './pages/EventConfirmations/EventConfirmationsGeneral/EventConfirmationsGeneral';

const INIT_TOKEN_KEY = 'user_token';

// todo: use the cookie version when non-local

let initAccessToken: string | null | undefined;
if (isLocal) {
  initAccessToken = window.localStorage.devAccessToken
    ? JSON.parse(window.localStorage.devAccessToken)
    : null;
} else {
  initAccessToken = Cookies.get(INIT_TOKEN_KEY);
  Cookies.remove(INIT_TOKEN_KEY); // remove after storing it locally
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootComponent />,
    id: 'root',
    loader: () => {
      if (initAccessToken) {
        return getFullJWTResponse(initAccessToken)
          ?.then((token: any) => {
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + token.data.access_token;
            initAccessToken = undefined;
            return token;
          })
          ?.catch((err) => {
            initAccessToken = undefined;
            if (
              isLocal &&
              (err.response?.status === 400 || err.response?.status === 401) &&
              window.localStorage.devRefreshToken
            ) {
              return refreshAccessToken(
                JSON.parse(window.localStorage.devRefreshToken),
              ).catch(() => null);
            } else {
              return null;
            }
          });
      } else {
        return null;
      }
    },
    children: [
      {
        errorElement: <BasicError />,
        children: [
          {
            path: LOGIN_PATH,
            element: <LoginComponent></LoginComponent>,
          },
          {
            element: <ProtectedRouterOutlet></ProtectedRouterOutlet>,
            children: [
              {
                index: true,
                element: <HomeComponent />,
              },
              {
                path: EVENT_CONFIRMATION_GENERAL,
                element: (
                  <PermissionGuard perms={[WidgetPermissions.MenuAdmin]}>
                    <EventConfirmationsGeneral />
                  </PermissionGuard>
                ),
              },
              {
                path: MSWM_EVENTS_ROUTE,
                element: (
                  <PermissionGuard perms={[WidgetPermissions.MenuAdmin]}>
                    <MSWMEventConfirmations />
                  </PermissionGuard>
                ),
              },
              {
                path: DESK_CONFIG_ROUTE,
                element: (
                  <PermissionGuard perms={[WidgetPermissions.MenuAdminUsers]}>
                    <DeskConfig />
                  </PermissionGuard>
                ),
              },
              {
                path: PRODUCT_ENTRY_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[
                      WidgetPermissions.ProductMaintenanceProductEntry,
                      WidgetPermissions.ProductMaintenanceProductEntrySuper,
                    ]}
                  >
                    <EditModeContextProvider editMode={false}>
                      <ProductEntryAndEditPage key={'product-entry-route'} />
                    </EditModeContextProvider>
                  </PermissionGuard>
                ),
                children: [
                  {
                    path: `copy/:productId`,
                    element: (
                      <ProductEntryAndEditPage
                        key={'product-entry-copy-route'}
                      />
                    ),
                    index: true,
                  },
                ],
              },
              {
                path: PRODUCT_EDIT_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[WidgetPermissions.ProductMaintenanceProductEdit]}
                  >
                    <EditModeContextProvider editMode={true}>
                      <EditModeEntry />
                    </EditModeContextProvider>
                  </PermissionGuard>
                ),
                children: [
                  {
                    path: `:productId`,
                    element: (
                      <ProductEntryAndEditPage key={'product-edit-route'} />
                    ),
                  },
                ],
              },
              {
                path: FINAL_REVIEW_QUEUE_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[
                      WidgetPermissions.RangeToFinalAccess,
                      WidgetPermissions.RangeToFinalSuperAccess,
                    ]}
                  >
                    <FinalReviewQueue />
                  </PermissionGuard>
                ),
              },
              {
                path: STAGED_PRODUCTS_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[
                      WidgetPermissions.RangeToFinalAccess,
                      WidgetPermissions.RangeToFinalSuperAccess,
                      WidgetPermissions.ProductMaintenanceProductEdit,
                    ]}
                  >
                    <StagedReviewQueue />
                  </PermissionGuard>
                ),
              },

              {
                path: REVIEW_QUEUE_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[WidgetPermissions.ReviewQueueAccess]}
                  >
                    <ReviewQueue key={'reviewQueue'} isSpottyReview={false} />
                  </PermissionGuard>
                ),
              },
              {
                path: SPOTTY_REVIEW_QUEUE_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[WidgetPermissions.ReviewQueueAccess]}
                  >
                    <ReviewQueue
                      key={'spottyReviewQueue'}
                      isSpottyReview={true}
                    />
                  </PermissionGuard>
                ),
              },
              {
                path: UPCOMING_HOLIDAYS_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[WidgetPermissions.ProductMaintenanceProductEdit]}
                  >
                    <UpcomingHolidays />
                  </PermissionGuard>
                ),
              },
              {
                path: UNDERLIERS_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[WidgetPermissions.ProductMaintenanceProductEdit]}
                  >
                    <UnderlierPage />
                  </PermissionGuard>
                ),
              },
              {
                path: MISSING_MARKET_DATA_ROUTE,
                element: (
                  <PermissionGuard
                    perms={[WidgetPermissions.ProductMaintenanceProductEdit]}
                  >
                    <MissingMarketData />
                  </PermissionGuard>
                ),
              },
              {
                path: LEARNING_CENTER_COURSE_CONFIG,
                element: (
                  <PermissionGuard perms={[WidgetPermissions.MenuAdmin]}>
                    <LearningCenterCourseConfig />
                  </PermissionGuard>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NothingFoundBackground />,
  },
]);

createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <ColorSchemeScript defaultColorScheme="auto" />
    <MantineProvider defaultColorScheme={'light'} theme={{ ...PrimaryTheme }}>
      <RouterProvider router={router} />
    </MantineProvider>
  </>,
);
