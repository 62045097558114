import { Table } from '@mantine/core';
interface Metadata {
  [key: string]: string;

  fsymId: string;
  bloombergTicker: string;
}

interface ResponseData {
  [key: string]: any;

  metadata: Metadata;
  marketDateTimestamp: string;
  priceType: string;
  price: number;
  open?: number | null;
  high?: number | null;
  low?: number | null;
  vwap?: number | null;
  marketCap?: number | null;
  dailyVolume?: number | null;
  turnover?: number | null;
  tradeCount?: number | null;
  source: string;
}

interface Props {
  data: ResponseData[];
}

const ResponseDataDisplay = ({ data }: Props) => {
  const fieldHasValues = (fieldName: keyof ResponseData | keyof Metadata) => {
    for (let item of data) {
      if (fieldName in item.metadata) {
        if (item.metadata[fieldName]) {
          return true;
        }
      } else if (item[fieldName]) {
        return true;
      }
    }
    return false;
  };

  const displayableFields = [
    'bloombergTicker',
    'marketDateTimestamp',
    'priceType',
    'price',
    'fsymId',
    'open',
    'high',
    'low',
    'vwap',
    'marketCap',
    'dailyVolume',
    'turnover',
    'tradeCount',
    'source',
    // 'underlierId', 'fsymId', 'bloombergTicker', 'marketDateTimestamp', 'priceType', 'price', 'open', 'high', 'low', 'vwap', 'marketCap', 'dailyVolume', 'turnover', 'tradeCount', 'source'
  ].filter(fieldHasValues);

  const mapDataFields = (field: string, value: any) => {
    if (field === 'marketDateTimestamp') {
      return new Date(value).toLocaleDateString();
    }
    return value;
  };

  return (
    <Table>
      <thead>
        <tr>
          {displayableFields.map((field) => (
            <th key={field} align={'left'}>
              {field.replace(/([A-Z])/g, ' $1').trim()}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {displayableFields.map((field) => (
              <td key={field}>
                {mapDataFields(
                  field,
                  field in item.metadata ? item.metadata[field] : item[field],
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ResponseDataDisplay;
