import * as Yup from 'yup';

const courseSchema = Yup.object({
  id: Yup.string().nullable(),
  coursePackage: Yup.string().nullable(),
  courseId: Yup.string().nullable(),
  courseName: Yup.string().required(),
  status: Yup.string().nullable(),
  trainingType: Yup.string().nullable(),
  publisher: Yup.string().required(),
  carrierCode: Yup.string().nullable(),
  startDate: Yup.string().nullable(),
  endDate: Yup.string().nullable(),
  createDate: Yup.string().nullable(),
  alias: Yup.string().nullable(),
});

export const courseListSchema = Yup.object({
  courses: Yup.array(courseSchema),
});
