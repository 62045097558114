import React, { ForwardedRef, forwardRef } from 'react';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { Button, Divider } from '@mantine/core';
import {
  PFNumberInput,
  PFSelect,
  PFTextInput,
} from '../reusable-form-component/BaseFormComponents';
import { useProductFormDisabled } from '../entry-and-edit-contexts/disabled-fields-context';
import { isFieldDisabled } from '../../../utilities/utilities';
import { IconTrash } from '@tabler/icons-react';
import AutoAnimateChildren from '../../../components/AutoAnimateChildren';
import { clnDetailsFields } from '../../../product-schema/pdw-field-subsets';
import {
  CDSNames,
  AccrualFieldReference,
  CollateralOptions,
} from '../../../product-schema/pdw-select-options';
import { ArrayNumberItem } from '../../../components/ArrayNumberItem';

const CLNDetailsFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    const disabledContext = useProductFormDisabled();
    const disableFields = isFieldDisabled(
      disabledContext,
      clnDetailsFields.debtIsinList,
    );

    const addDebtIsin = () => {
      if (disableFields) {
        return;
      }
      form.insertListItem(
        clnDetailsFields.debtIsinList,
        {
          isin: undefined,
          weight: undefined,
        },
        0,
      );
    };
    const removeDebtIsin = (index: number) => {
      if (disableFields) {
        return;
      }
      form.removeListItem(clnDetailsFields.debtIsinList, index);
    };

    const debtIsinSection = form.values.productCln.debtIsinList?.map(
      (debtIsin, index) => (
        <div className={'array-entry-section-container'} key={index}>
          <div className={'array-entry-section'}>
            <ArrayNumberItem>{index + 1}.</ArrayNumberItem>
            <PFTextInput
              label={'Isin'}
              disabled={disableFields}
              fieldPath={`${clnDetailsFields.debtIsinList}.${index}`}
            ></PFTextInput>
            <PFNumberInput
              decimalScale={6}
              label="Weight"
              disabled={disableFields}
              fieldPath={`${clnDetailsFields.debtWeightList}.${index}`}
            ></PFNumberInput>
            <Button
              variant={'secondary-small'}
              type={'button'}
              disabled={disableFields}
              onClick={() => removeDebtIsin(index)}
            >
              <IconTrash size={18}></IconTrash>
            </Button>
          </div>
        </div>
      ),
    );

    return (
      <div className={'form-section'} ref={ref}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            gap: '1em',
            width: '100%',
          }}
        >
          <h3 style={{ margin: 0 }}>Debt ISIN List </h3>
          <Button
            variant="primary"
            style={{ width: 'fit-content' }}
            type={'button'}
            disabled={disableFields}
            onClick={addDebtIsin}
          >
            Add Debt ISIN
          </Button>
          <AutoAnimateChildren>{debtIsinSection}</AutoAnimateChildren>
        </div>

        <Divider style={{ margin: '2em 1em', width: '90%' }}></Divider>
        <div className={'form-subsection'}>
          <PFSelect
            label="Accrual Field Reference"
            data={[...Object.values(AccrualFieldReference)]}
            fieldPath={clnDetailsFields.accrualFieldReference}
          ></PFSelect>{' '}
          <PFSelect
            label="CDS Name"
            data={[...Object.values(CDSNames)]}
            fieldPath={clnDetailsFields.cdsName}
          ></PFSelect>
          <PFNumberInput
            decimalScale={6}
            label="CDS Tenor"
            fieldPath={clnDetailsFields.cdsTenor}
          />
          <PFSelect
            label={'Collateral'}
            data={[...Object.values(CollateralOptions)]}
            fieldPath={clnDetailsFields.collateral}
          />
        </div>
      </div>
    );
  },
);
CLNDetailsFormComponent.displayName = 'CLNDetailsFormComponent';
export default CLNDetailsFormComponent;
