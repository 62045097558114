import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useMergedRef } from '@mantine/hooks';

interface props extends ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
  disable?: boolean;
}

const AutoAnimateChildren = forwardRef(
  ({ children, disable = false, ...rest }: props, ref) => {
    const [animate] = useAutoAnimate<any>();
    const mergedRef = useMergedRef(ref, animate);
    const refs: any = disable ? { ref: ref } : { ref: mergedRef };
    return (
      <div {...refs} {...rest}>
        {children}
      </div>
    );
  },
);
AutoAnimateChildren.displayName = 'AutoAnimateChildren';

export default AutoAnimateChildren;
