import React from 'react';
import ExpandableNumberFieldset from '../../reusable-form-component/BaseFormComponents/ExpandableNumberFieldset';
import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';

const WithMinimumReturnComponent = () => {
  return (
    <div>
      <h3>Minimum Return</h3>
      <ExpandableNumberFieldset
        primaryField={{
          property: ancillaryFeaturesFields.minimumReturnFinal,
          label: 'Minimum Return',
        }}
        subFields={[
          {
            property: ancillaryFeaturesFields.minimumReturnHigh,
            label: 'High Minimum Return',
          },
          {
            property: ancillaryFeaturesFields.minimumReturnLow,
            label: 'Low Minimum Return',
          },
          {
            property: ancillaryFeaturesFields.minimumReturnCurrent,
            label: 'Current Minimum Return',
          },
        ]}
        percentFormat={true}
        decimalScale={6}
      />
    </div>
  );
};

export default WithMinimumReturnComponent;
