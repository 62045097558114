import React from 'react';
import { PFNumberInput } from '../../reusable-form-component/BaseFormComponents';

import { ancillaryFeaturesFields } from '../../../../product-schema/pdw-field-subsets';

const WithSupplementalComponent = () => {
  return (
    <div>
      <h3>Supplemental Percentage</h3>

      <PFNumberInput
        decimalScale={6}
        rightSection={<div>%</div>}
        label="Supplemental Percent"
        fieldPath={ancillaryFeaturesFields.supplementalPercentage}
      ></PFNumberInput>
    </div>
  );
};

export default WithSupplementalComponent;
