import React, { useState } from 'react';

export interface ProductFormHiddenFields {
  showAllHiddenFields: boolean;
  hiddenFields: string[];
  toggleShowAll: any;
  setHiddenFields: any;
}

export const ProductFormHiddenFieldsContext =
  React.createContext<ProductFormHiddenFields>({
    showAllHiddenFields: false,
    hiddenFields: [],
    toggleShowAll: () => {},
    setHiddenFields: () => {},
  });
export const HiddenFieldsContextProvider = (props: any) => {
  const [showAllHiddenFields, setShowAllHiddenFields] =
    useState<boolean>(false);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);

  const toggleShowAll = () => {
    setShowAllHiddenFields(!showAllHiddenFields);
  };
  const providerValue = React.useMemo(
    () => ({
      showAllHiddenFields,
      toggleShowAll,
      hiddenFields,
      setHiddenFields,
    }),
    [hiddenFields, showAllHiddenFields], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ProductFormHiddenFieldsContext.Provider value={providerValue}>
      {props.children}
    </ProductFormHiddenFieldsContext.Provider>
  );
};
