export const quickInputDateParser = (input: string): Date => {
  //if 4 digits, assume MDYY in the year 20xx
  if (/^([0-9]{4})$/.test(input)) {
    return new Date(
      '0' +
        input.slice(0, 1) +
        '/0' +
        input.slice(1, 2) +
        '/20' +
        input.slice(2, 4),
    );
  }

  // if 6 digits, assume MMDDYY
  if (/^([0-9]{6})$/.test(input)) {
    return new Date(
      input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 6),
    );
  }
  // if 8 digits, assume MMDDYYYY
  else if (/^([0-9]{8})$/.test(input)) {
    return new Date(
      input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 8),
    );
  } else {
    const date = new Date(new Date(input).toDateString());
    if (date.toString() === 'Invalid Date') {
      return new Date(new Date().toDateString());
    }
    return date;
  }
};
