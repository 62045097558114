import axios, { AxiosResponse } from 'axios';
import { COMPANY_SERVICE_ROOT } from './api-consts';

export interface Company {
  id: number; // 140,
  fullName: string; // "CREDICORP CAPITAL SERVICIOS FINANCIEROS",
  shortName: string; // "Credicorp",
  timezone: string; // "EST",
  streetAddress1: string; // null,
  streetAddress2: string; // null,
  city: string; // null,
  state: string; // null,
  zipCode: number; // null,
  pointOfContact: string; // null,
  signedClientAgreementDate: string; // null,
  termsOfUse: any; // null,
  activeEffectiveDate: string; // 1,
  tombstoneDate: string; // null,
  contacts: any[]; // [],
  country: string; // 'USA
  roleAssignments: [
    {
      assignmentId: number; // 78,
      status: string; // "ACTIVE",
      createDate: string; // "2021-07-20T12:01:33Z",
      updateDate: string; // null,
      updatedBy: string; // null
    },
  ];
}

export const getCompanies = (): Promise<AxiosResponse<Company[]>> => {
  return axios.get<Company[]>(`${COMPANY_SERVICE_ROOT}/companies`);
};
