// UnderlierPageInstructions.tsx
import { Card, Text, List } from '@mantine/core';

const UnderlierPageInstructions = () => (
  <Card padding="lg" shadow="md" style={{ marginBottom: '20px' }}>
    <h2>Welcome to the Underlier Page!</h2>
    <Text size="sm" style={{ marginTop: '10px' }}>
      Here's how to use this page:
    </Text>
    <List spacing="xs" size="sm" center style={{ marginTop: '10px' }}>
      <List.Item>
        Type a prefix of at least 3 characters in the search bar. The relevant
        underlier will be displayed if found in the Luma database.
      </List.Item>
      <List.Item>
        If the underlier is not found in the Luma database, you should search in
        the FactSet database. If found in FactSet, you can add it to the Luma
        database.
      </List.Item>
      <List.Item>
        If the underlier is not found in both the Luma and FactSet databases,
        you have the option to add it manually.
      </List.Item>
    </List>
  </Card>
);

export default UnderlierPageInstructions;
