import { useMutation } from '@tanstack/react-query';
import { Underlier } from '../../../product-schema/underlier.schema';
import { Text, Button, TextInput, Card } from '@mantine/core';
import { insertFactsetUnderlier } from '../../../API/market-data-maintenance.service';
import KeyValueCard from '../../../components/KeyValueCard';
import { AxiosResponse } from 'axios';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

interface FactsetUnderlierItemProps {
  underlier: Underlier;
  onUnderlierAdded: () => void;
  setSelectedUnderlier: (underlier: Underlier | null) => void; // New prop
}

const FactsetUnderlierItem = ({
  underlier,
  onUnderlierAdded,
  setSelectedUnderlier,
}: FactsetUnderlierItemProps) => {
  const [ric, setRic] = useState('');

  const downloadUnderlier = useMutation(
    (newUnderlier: Underlier) => insertFactsetUnderlier(newUnderlier),
    {
      onSuccess: (response: AxiosResponse<Underlier>) => {
        const data = response.data;
        onUnderlierAdded();
        setSelectedUnderlier(data); // Display the addedUnderlier with UnderlierItem
        notifications.show({
          id: 'factset-add-underlier',
          title: `${underlier.ticker} added to database.`,
          message: 'Successfully added underlier to the database.',
          color: 'green',
          withCloseButton: true,
          autoClose: 10000,
        });
        if (data.pricesUploadedSuccessfully) {
          notifications.show({
            id: 'price-upload-success',
            title: 'Success',
            message: 'Price is uploaded successfully.',
            color: 'green',
            withCloseButton: true,
            autoClose: 10000,
          });
        }
      },
      onError: () => {
        notifications.show({
          id: 'factset-add-underlier',
          title: 'Error',
          message:
            'An error occurred while adding the underlier to the database.',
          color: 'red',
          withCloseButton: true,
          autoClose: 10000,
        });
      },
    },
  );

  const handleAddToDatabase = () => {
    const enhancedUnderlier = {
      ...underlier,
      ric: ric,
    };
    downloadUnderlier.mutate(enhancedUnderlier);
  };

  const entries = Object.entries(underlier).filter(
    ([key, value]) => value !== null && value !== undefined,
  );

  return (
    <KeyValueCard
      entries={entries}
      style={{ marginTop: '20px', marginBottom: '1.5rem' }}
      footerComponent={
        <>
          {downloadUnderlier.isError && (
            <Text ta={'center'} style={{ color: 'red', weight: 600 }}>
              Error occurred when adding underlier.
            </Text>
          )}

          <Card padding="md">
            <TextInput
              label="Enter RIC code to submit"
              description={
                <span>
                  For help with lookup see{' '}
                  <a
                    href="https://coda.io/d/Shared-Services_dXGkf6axil9/Finding-a-RIC-code-for-an-underlier_suZB-#_lu185"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    documentation
                  </a>
                  .
                </span>
              }
              value={ric}
              placeholder={'Enter RIC code'}
              onChange={(event) => setRic(event.currentTarget.value)}
              style={{ padding: '15px', paddingBottom: '30px' }}
            />
          </Card>

          <Button
            variant={'secondary'}
            onClick={handleAddToDatabase}
            disabled={downloadUnderlier.isLoading || !ric}
            style={{ width: '100%' }}
          >
            Add to Database
          </Button>
        </>
      }
    />
  );
};

export default FactsetUnderlierItem;
