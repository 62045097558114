import React, { useEffect, useState } from 'react';
import { PDWRuleValidationError } from '../../../API/pdw.service';
import classes from './validationErrors.module.scss';
import { IconAlertTriangleFilled, IconX } from '@tabler/icons-react';
import Draggable from 'react-draggable';

export interface ValidationErrorsProps {
  errors: PDWRuleValidationError[] | null;
}

const ValidationErrors = ({ errors }: ValidationErrorsProps) => {
  const [showErrors, setShowErrors] = useState(false);
  const handleShowErrorsToggle = () => setShowErrors(!showErrors);
  useEffect(() => {
    if (errors) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
    }
  }, [errors]);
  return errors ? (
    <>
      <div
        className={classes.errorIndicator}
        onClick={handleShowErrorsToggle}
        title={'Validation Errors'}
      >
        <IconAlertTriangleFilled size={40} />
      </div>
      {showErrors && (
        <Draggable handle={'.handle'} bounds={'body'}>
          <div className={classes.errorContainer}>
            <div
              className={'handle'}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'grab',
                padding: '1em',
              }}
            >
              <div>Errors from last validation run:</div>
              <IconX
                size={20}
                style={{ cursor: 'pointer' }}
                onClick={handleShowErrorsToggle}
              />
            </div>
            {errors.map((err) => (
              <div
                key={err.fieldName + err.message}
                style={{ color: 'red', padding: '.2em 1em' }}
              >
                {err.fieldName}: {err.message}
                {Array.isArray(err.rejectedValue) ? (
                  <>
                    {err.rejectedValue
                      .filter((x) => x)
                      .map((val, index) => (
                        <div key={index}>
                          <span className={classes.errorRejectedValue}>
                            {JSON.stringify(val)}
                          </span>
                        </div>
                      ))}
                  </>
                ) : (
                  <span className={classes.errorRejectedValue}>
                    {err.rejectedValue}
                  </span>
                )}
              </div>
            ))}
          </div>
        </Draggable>
      )}
    </>
  ) : null;
};

export default ValidationErrors;
