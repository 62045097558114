import axios from 'axios';
import { PRODUCT_MAINTENANCE_ROOT } from './api-consts';

export const getIssuers = () =>
  axios.get(`${PRODUCT_MAINTENANCE_ROOT}/issuers`);
export const getCalculationAgents = () =>
  axios.get(`${PRODUCT_MAINTENANCE_ROOT}/calculation-agents`);
export const getProduct = (productId: string) =>
  axios.get(
    `${PRODUCT_MAINTENANCE_ROOT}/v2/get-product/${encodeURIComponent(productId)}`,
  );
export const getFinalReviewQueue = () =>
  axios.get(`${PRODUCT_MAINTENANCE_ROOT}/products/rangeToFinal`);

export interface DateCalculationPayload {
  registrationType: string | undefined;
  dateShift: string | undefined;
  rollForward: boolean | undefined;
  useModifiedFollowing: boolean | undefined;
  LTCGTreatment: boolean | undefined;
  callObservationFrequency: string | undefined;
  // couponTypeFixed: boolean | undefined,
  tradeDate: string | undefined;
  tenor: number | undefined;
  observationFrequency: string | undefined;
  tickers: string | undefined;
}

export const calculateDates = (payload: DateCalculationPayload) =>
  axios.post(`${PRODUCT_MAINTENANCE_ROOT}/products/dates`, payload);
