import yup from './yup-extended.product-schema';
import {
  ProductCompletionStatus,
  ProductStatuses,
  ProtectionTypes,
  TemplateOptions,
} from './pdw-select-options';
import { setLocale } from 'yup';

export type PDWProduct = yup.InferType<typeof productSchema>;
export type UnderlierFormSchema = yup.InferType<typeof underlierSchema>;
export type CallObservationDateListItem = yup.InferType<
  typeof callObservationSchema
>;
export type PaymentDateListItem = yup.InferType<typeof paymentDateItemSchema>;
// helper function to cover cases where empty strings need to be nulls
const emptyStringToNull = (value: any, originalValue: any) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

setLocale({
  mixed: {
    required: '*Required',
  },
});

const underlierSchema = yup.object({
  underlierName: yup.string().nullable(),
  currency: yup.string().nullable(),
  exchangeCode: yup.string().nullable(),
  underlierWeight: yup.string().nullable(),
  underlierSource: yup.string().nullable(),
  underlierLevel: yup.string().nullable(),
  underlierSymbol: yup.string().nullable(),
  dividendYield: yup.string().nullable(),
  underlierStrikeDate: yup.date().nullable().noHolidayDates(),
  initialFixing: yup.string().nullable(),
});

const callObservationSchema = yup.object({
  callObservationDate: yup.date().nullable().noHolidayDates().noWeekendDates(),
  callSettlementDate: yup.date().nullable().noHolidayDates().noWeekendDates(),
  callPremiumStepValue: yup.number().transform(emptyStringToNull).nullable(), // percentage
  callBarrierLevelStepValue: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(), // percentage
  callScheduleUnderlierList: yup.array(underlierSchema).nullable(),
});

const paymentDateItemSchema = yup.object({
  paymentObservationDate: yup
    .date()
    .nullable()
    .noHolidayDates()
    .noWeekendDates(),
  paymentSettlementDate: yup
    .date()
    .nullable()
    .noHolidayDates()
    .noWeekendDates(),
  accrualEndDate: yup.date().nullable().noHolidayDates().noWeekendDates(),
  paymentBarrierLevel: yup.number().transform(emptyStringToNull).nullable(),
  paymentRate: yup
    .number()
    .transform(emptyStringToNull)
    .min(0)
    .max(100)
    .nullable(),
  bifurcationOfInterest: yup.boolean().nullable(),
  interest: yup
    .number()
    .transform(emptyStringToNull)
    .min(0)
    .max(100)
    .nullable(),
  optionPremium: yup
    .number()
    .transform(emptyStringToNull)
    .min(0)
    .max(100)
    .nullable(),
  paymentResetDate: yup.date().nullable().noHolidayDates().noWeekendDates(),
});

export const productSchema = yup.object({
  // these fields get stripped out on submit
  templateSelection: yup.string().nullable(),
  qcVerified: yup.boolean().nullable(),
  //

  createTimestamp: yup.string().nullable(),
  id: yup.string().nullable(),
  version: yup.number().transform(emptyStringToNull).nullable(),
  programs: yup.string().nullable(),
  previousStage: yup.string().nullable(),
  previousStatus: yup.string().nullable(),
  quotes: yup.mixed().nullable(),
  productId: yup.string().nullable(),
  productScenarios: yup
    .object({
      scenarioOne: yup.string().nullable(),
      scenarioTwo: yup.string().nullable(),
    })
    .nullable(),
  updateTimestamp: yup.string().nullable(),
  createdByUserId: yup.string().nullable(),
  updatedByUserId: yup.string().nullable(),
  productDataSource: yup.string().nullable(),
  revision: yup.string().nullable(),
  opsReviewTimestamp: yup.string().nullable(),
  opsReviewUserId: yup.string().nullable(),
  productGeneral: yup.object({
    structureNameExternal: yup.string().nullable(),
    structureShortNameExternal: yup.string().nullable(),
    structureLongNameExternal: yup.string().nullable(),
    structureNameInternal: yup.string().nullable(),
    productName: yup.string().nullable(),
    externalProductId: yup.string().nullable(),
    cusip: yup
      .string()
      .matches(/.{9,}/, {
        excludeEmptyString: true,
        message: 'CUSIP must be 9 characters long',
      })
      .nullable()
      .test(
        'isinOrCusip',
        'Must have an Identifier',
        function (value, context) {
          return !(!context.parent.isin && !value);
        },
      ),
    // yup.string().length(9, 'CUSIP must be 9 characters long').nullable().notRequired(),
    isin: yup
      .string()
      .matches(/.{12,}/, {
        excludeEmptyString: true,
        message: 'ISIN must be 12 characters long',
      })
      .nullable()
      .test(
        'isinOrCusip',
        'Must have an Identifier',
        function (value, context) {
          return !(!context.parent.cusip && !value);
        },
      ),
    // yup.string().length(12, 'ISIN must be 12 characters long').nullable(),
    requestId: yup.string().max(36).nullable(),
    issuer: yup.string().nullable().required(),
    tenorCurrent: yup.number().transform(emptyStringToNull).nullable(),
    tenorFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        ['ALL'],
        ['tenorLow', 'tenorHigh', 'tenorCurrent'],
      ),
    tenorLow: yup.number().transform(emptyStringToNull).nullable(),
    tenorHigh: yup.number().transform(emptyStringToNull).nullable(),
    tenorUnit: yup.string().nullable().required(),
    productNotionalFinal: yup.number().transform(emptyStringToNull).nullable(),
    productNotionalCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    productNotionalLow: yup.number().transform(emptyStringToNull).nullable(),
    productNotionalHigh: yup.number().transform(emptyStringToNull).nullable(),
    productNotionalCap: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(9999999999),
    accountType: yup.string().nullable(),
    hedgeType: yup.string().nullable(),
    currency: yup.string().nullable(),
    productFaceValue: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .required(),
    estimatedInitialValue: yup.string().nullable(),
    registrationType: yup.string().nullable(),
    tradeDate: yup
      .date()
      .nullable()
      .test(
        'tradeDate must be before issueDate',
        'Trade Date must be equal to or prior to Issue date',
        function (value, context) {
          if (context.parent.issueDate && value) {
            return (
              dateWithoutTime(context.parent.issueDate) >=
              dateWithoutTime(value)
            );
          }
          return true;
        },
      )
      .required()
      .noHolidayDates()
      .noWeekendDates(),
    strikeDate: yup
      .date()
      .nullable()
      .test(
        'strikeDate must be before finalValuationDate',
        'Strike date must be prior to final valuation date',
        function (value, context) {
          if (context.parent.finalValuationDate && value) {
            return (
              dateWithoutTime(context.parent.finalValuationDate) >
              dateWithoutTime(value)
            );
          }
          return true;
        },
      )
      .noHolidayDates()
      .noWeekendDates(),
    issueDate: yup
      .date()
      .nullable()
      .test(
        'issueDate must be before finalValuationDate',
        'Issue Date must be prior to Final Valuation Date',
        function (value, context) {
          if (context.parent.finalValuationDate && value) {
            return (
              dateWithoutTime(context.parent.finalValuationDate) >
              dateWithoutTime(value)
            );
          }
          return true;
        },
      )
      .required()
      .noHolidayDates()
      .noWeekendDates(),
    finalValuationDate: yup
      .date()
      .nullable()
      .test(
        'finalValuationDate must be before maturityDate',
        'Final Valuation date must be prior to Maturity Date',
        function (value, context) {
          if (context.parent.maturityDate && value) {
            return (
              dateWithoutTime(context.parent.maturityDate) >
              dateWithoutTime(value)
            );
          }
          return true;
        },
      )
      .required()
      .noHolidayDates()
      .noWeekendDates(),
    averagingInDates: yup
      .array(yup.date().noHolidayDates().noWeekendDates())
      .nullable(),
    averagingOutDates: yup
      .array(yup.date().noHolidayDates().noWeekendDates())
      .nullable(),
    aggregatingDates: yup
      .array(yup.date().noHolidayDates().noWeekendDates())
      .nullable(),
    maturityDate: yup
      .date()
      .nullable()
      .required()
      .noHolidayDates()
      .noWeekendDates(),
    settlementType: yup.string().nullable().required(),
    basketType: yup
      .string()
      .when('underlierList', {
        is: (val: any[]) => val?.length >= 2,
        then: (schema) => schema.required(),
      })
      .nullable(),
    externalBasketName: yup.string().nullable(),
    lookbackInitialDate: yup
      .date()
      .nullable()
      .noHolidayDates()
      .noWeekendDates(),
    reofferClientList: yup
      .array(
        yup.object({
          reofferClientName: yup.string().nullable(),
          reofferClientRateFinal: yup
            .number()
            .transform(emptyStringToNull)
            .min(0)
            .max(100)
            .nullable(),
          reofferClientRateCurrent: yup
            .number()
            .transform(emptyStringToNull)
            .min(0)
            .max(100)
            .nullable(),
          reofferClientRateLow: yup
            .number()
            .transform(emptyStringToNull)
            .min(0)
            .max(100)
            .nullable(),
          reofferClientRateHigh: yup
            .number()
            .transform(emptyStringToNull)
            .min(0)
            .max(100)
            .nullable(),
        }),
      )
      .nullable(),
    productDeadlineTimestamp: yup
      .date()
      .nullable()
      .noHolidayDates()
      .noWeekendDates(),
    productLaunchTimestamp: yup
      .date()
      .nullable()
      .noHolidayDates()
      .noWeekendDates(),
    productNoteList: yup.array(yup.string()).nullable(),
    bidDeadlineTimestamp: yup.string().nullable(),
    fdicInsured: yup.boolean().nullable(),
    calculationAgent: yup.string().nullable().required(),
    stage: yup.string().nullable().required(),
    status: yup
      .string()
      .nullable()
      .test(
        'mustBeCalledWhenProductIsCalled',
        'The status of this product must be called, since there is a call event confirmed for this product.',
        function (value, context) {
          return context?.options?.context?.isProductCalled
            ? value === ProductStatuses.CALLED ||
                value === ProductStatuses.RETIRED
            : true;
        },
      )
      .required(),
    highWaterMarkDate: yup.string().nullable(),
    salesCreditCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    salesCreditFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    salesCreditHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    salesCreditLow: yup.number().transform(emptyStringToNull).nullable().min(0),
    fairPriceFinal: yup.number().transform(emptyStringToNull).nullable(),
    lookbackInitialValuationType: yup.string().nullable(),
    lookbackFinalDate: yup.string().nullable(),
    lookbackFinalValuationType: yup.string().nullable(),
    averagingModified: yup.boolean().nullable(),
    averagingExclusion: yup.string().nullable(),
    supplementalPercentage: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    totalPnl: yup.string().nullable(),
    fundingSpread: yup.mixed().nullable(),
    oisDfPercentage: yup.number().transform(emptyStringToNull).nullable(),
    structureFee: yup.number().transform(emptyStringToNull).nullable(), // question: is this a percentage based decimal
    riskFreeRate: yup.string().nullable(),
    swapMaturity: yup.string().nullable(),
    upfrontDeferred: yup.string().nullable(),
    indicativeDeadlineTimestamp: yup.string().nullable(),
    externalDocumentProvider: yup
      .object({
        legalReview: yup.string().nullable(),
        taxReview: yup.string().nullable(),
        secLink: yup.string().nullable(),
        externalDocumentProviderId: yup.string().nullable(),
      })
      .nullable(),
    matchInformation: yup.mixed().nullable(),
    wrapperType: yup.string().nullable().required(),
    structureShortNameInternal: yup.string().nullable(),
    structureLongNameInternal: yup.string().nullable(),
    lumaProductIdentifier: yup.string().nullable(),
    assetClass: yup.string().nullable(),
    ancillaryFeatures: yup.array(yup.string()).nullable(),
    dayCountFraction: yup
      .string()
      .nullable()
      .when('$data.productCln.ratePaymentType', {
        is: (val: string) => !!val,
        then: (schema) =>
          schema.required('Required when rate payment type exists'),
      }),
    minimumTradingLot: yup.number().transform(emptyStringToNull).nullable(),
    longTermCapitalGain: yup.boolean().nullable(),
    returnType: yup.string().nullable(),
    originator: yup.string().nullable(),
    standardPrice: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    productRiskRanking: yup.number().transform(emptyStringToNull).nullable(),
    businessDayConvention: yup.string().nullable(),
    performanceStrike: yup.number().transform(emptyStringToNull).nullable(),
    basketReturnAdjustment: yup
      .number()
      .transform(emptyStringToNull)
      .min(-100, 'Below Min length (-100)')
      .max(100, 'Above max length (100)')
      .nullable(),
    issuePriceCurrent: yup.string().nullable(),
    issuePriceFinal: yup.string().nullable().required(),
    issuePriceLow: yup.string().nullable(),
    issuePriceHigh: yup.string().nullable(),
    sspaStructureTypeName: yup.string().nullable(),
    sspaStructureTypeId: yup.string().nullable(),
    completionStatus: yup.string().nullable(),
    opsReviewStatus: yup.string().nullable(),
    incompleteCategory: yup
      .string()
      .nullable()
      .when('$data.productGeneral.completionStatus', {
        is: (val: ProductCompletionStatus) =>
          val === ProductCompletionStatus.INCOMPLETE,
        then: (schema) =>
          schema.required('Required when completion status is incomplete'),
      }),
    secondaryMarketQuotingType: yup.string().nullable(),
    offeringType: yup.string().nullable(),
    productType: yup.string().nullable(),
    productCategory: yup.string().nullable(),
    dateOffset: yup.string().nullable(),
    fundservID: yup.string().max(10).nullable(),
    underlierList: yup
      .array(underlierSchema)
      .required()
      .min(1, '* At least one underlier is required'),
    underlierRankWeightList: yup.array(yup.string()).nullable(),
  }),

  productYield: yup.object({
    paymentType: yup
      .string()
      .nullable()
      .requiredForTemplates([
        TemplateOptions.ALL_UP,
        TemplateOptions.AUTO_CAP,
        TemplateOptions.CLIQUET,
        TemplateOptions.CONTINGENT,
        TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
        TemplateOptions.P2P_WITH_FIXED_COUPON,
        TemplateOptions.RANGE_ACCRUAL,
        TemplateOptions.RATE_BUILDER,
        TemplateOptions.TIME_SERIES,
        TemplateOptions.FIXED_TO_FLOATING,
        TemplateOptions.FIXED,
      ]),
    paymentStepType: yup.string().nullable(),
    paymentEvaluationFrequencyCurrent: yup.string().nullable(),
    paymentEvaluationFrequencyFinal: yup
      .string()
      .nullable()
      .requiredForTemplateRanges(
        [
          TemplateOptions.ALL_UP,
          TemplateOptions.AUTO_CAP,
          TemplateOptions.CONTINGENT,
          TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
          TemplateOptions.P2P_WITH_FIXED_COUPON,
          TemplateOptions.RANGE_ACCRUAL,
          TemplateOptions.RATE_BUILDER,
          TemplateOptions.TIME_SERIES,
          TemplateOptions.FIXED_TO_FLOATING,
          TemplateOptions.FIXED,
        ],
        [
          'paymentEvaluationFrequencyLow',
          'paymentEvaluationFrequencyHigh',
          'paymentEvaluationFrequencyCurrent',
        ],
      ),
    paymentBarrierObservationFrequency: yup
      .string()
      .nullable()
      .requiredForTemplates([
        TemplateOptions.ALL_UP,
        TemplateOptions.CONTINGENT,
        TemplateOptions.RANGE_ACCRUAL,
      ]),
    paymentDateList: yup.array(paymentDateItemSchema).nullable(),
    paymentFrequency: yup
      .string()
      .nullable()
      .requiredForTemplates([
        TemplateOptions.ALL_UP,
        TemplateOptions.AUTO_CAP,
        TemplateOptions.CONTINGENT,
        TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
        TemplateOptions.P2P_WITH_FIXED_COUPON,
        TemplateOptions.RANGE_ACCRUAL,
        TemplateOptions.RATE_BUILDER,
        TemplateOptions.TIME_SERIES,
        TemplateOptions.FIXED_TO_FLOATING,
        TemplateOptions.FIXED,
      ]),
    paymentRatePerPeriodCurrent: yup.string().nullable(),
    paymentRatePerPeriodFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [
          TemplateOptions.ALL_UP,
          TemplateOptions.FIXED_TO_FLOATING,
          TemplateOptions.CONTINGENT,
          TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
          TemplateOptions.RANGE_ACCRUAL,
          TemplateOptions.FIXED,
        ],
        [
          'paymentRatePerPeriodLow',
          'paymentRatePerPeriodHigh',
          'paymentRatePerPeriodCurrent',
        ],
      ),
    paymentRatePerPeriodLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    paymentRatePerPeriodHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    paymentBarrierCurrent: yup.number().transform(emptyStringToNull).nullable(),
    paymentBarrierFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [
          TemplateOptions.ALL_UP,
          TemplateOptions.CONTINGENT,
          TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
          TemplateOptions.RANGE_ACCRUAL,
          TemplateOptions.FIXED_TO_FLOATING,
        ],
        ['paymentBarrierLow', 'paymentBarrierHigh', 'paymentBarrierCurrent'],
      ),
    paymentBarrierLow: yup.number().transform(emptyStringToNull).nullable(),
    paymentBarrierHigh: yup.number().transform(emptyStringToNull).nullable(),
    paymentBarrierUnderlierList: yup.array(underlierSchema).nullable(),
    paymentMemory: yup.boolean().nullable(),
    minPaymentRatePerAnnumCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    minPaymentRatePerAnnumFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [
          TemplateOptions.ALL_UP,
          TemplateOptions.AUTO_CAP,
          TemplateOptions.CONTINGENT,
          TemplateOptions.RANGE_ACCRUAL,
          TemplateOptions.TIME_SERIES,
        ],
        [
          'minPaymentRatePerAnnumLow',
          'minPaymentRatePerAnnumHigh',
          'minPaymentRatePerAnnumCurrent',
        ],
      ),
    minPaymentRatePerAnnumHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    minPaymentRatePerAnnumLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    paymentRatePerAnnumCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    paymentRatePerAnnumFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [
          TemplateOptions.ALL_UP,
          TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
          TemplateOptions.P2P_WITH_FIXED_COUPON,
          TemplateOptions.FIXED_TO_FLOATING,
          TemplateOptions.FIXED,
        ],
        [
          'paymentRatePerAnnumLow',
          'paymentRatePerAnnumHigh',
          'paymentRatePerAnnumCurrent',
        ],
      ),
    paymentRatePerAnnumHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    paymentRatePerAnnumLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    minPaymentRatePerPeriodCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    minPaymentRatePerPeriodFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [
          TemplateOptions.ALL_UP,
          TemplateOptions.AUTO_CAP,
          TemplateOptions.TIME_SERIES,
          TemplateOptions.P2P_WITH_FIXED_COUPON,
        ],
        [
          'minPaymentRatePerPeriodLow',
          'minPaymentRatePerPeriodHigh',
          'minPaymentRatePerPeriodCurrent',
        ],
      ),
    minPaymentRatePerPeriodLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    minPaymentRatePerPeriodHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    stockReturnCapCurrent: yup.number().transform(emptyStringToNull).nullable(),
    stockReturnCapFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [TemplateOptions.CLIQUET],
        ['stockReturnCapLow', 'stockReturnCapHigh', 'stockReturnCapCurrent'],
      ),
    stockReturnCapLow: yup.number().transform(emptyStringToNull).nullable(),
    stockReturnCapHigh: yup.number().transform(emptyStringToNull).nullable(),
    stockReturnCapUnderlierList: yup.array(underlierSchema).nullable(),
    stockReturnFloorCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(-100),
    stockReturnFloorFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(-100)
      .requiredForTemplateRanges(
        [TemplateOptions.CLIQUET],
        [
          'stockReturnFloorLow',
          'stockReturnFloorHigh',
          'stockReturnFloorCurrent',
        ],
      ),
    stockReturnFloorLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(-100),
    stockReturnFloorHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(-100),
    stockReturnFloorUnderlierList: yup.array(underlierSchema).nullable(),
    variablePayInitialObservationDate: yup.string().nullable(),
    variablePayFinalObservationDate: yup.string().nullable(),
    minCouponPayPeriod: yup
      .string()
      .nullable()
      .requiredForTemplates([
        TemplateOptions.ALL_UP,
        TemplateOptions.AUTO_CAP,
        TemplateOptions.TIME_SERIES,
      ]),
    bifurcationOfInterest: yup.string().nullable(),
    interest: yup.string().nullable(),
    optionPremium: yup.string().nullable(),
    // Deprecated rate builder fields
    // rateBuilderValuesCurrent: yup.string().nullable(),
    // rateBuilderValuesFinal: yup.string().nullable(),
    // rateBuilderValuesLow: yup.string().nullable(),
    // rateBuilderValuesHigh: yup.string().nullable(),
    rateBuilderGrouping: yup
      .string()
      .nullable()
      .test(
        'rate-builder-grouping',
        'Rate builder grouping can only contain numbers and commas',
        (value) => {
          if (!value) {
            return true;
          }
          const pattern = /^[(0-9, )]*$/;
          return pattern.test(value);
        },
      )
      .requiredForTemplates([TemplateOptions.RATE_BUILDER]),
    rateBuilderReturns: yup.string().nullable(),
    leverageFactors: yup
      .array(
        yup.object({
          leverageFactor: yup.number().transform(emptyStringToNull).nullable(),
          leverageFactorStartDate: yup
            .date()
            .nullable()
            .noHolidayDates()
            .noWeekendDates(),
          leverageFactorEndDate: yup
            .date()
            .nullable()
            .noHolidayDates()
            .noWeekendDates(),
        }),
      )
      .nullable(),
    paymentTypeChangeDate: yup
      .string()
      .nullable()
      .requiredForTemplates([TemplateOptions.FIXED_TO_FLOATING]),
    legalPrintEstimate: yup.number().transform(emptyStringToNull).nullable(),
    upperBarrierLevelCurrent: yup.string().nullable(),
    upperBarrierLevelFinal: yup.string().nullable(),
    upperBarrierLevelLow: yup.string().nullable(),
    upperBarrierLevelHigh: yup.string().nullable(),
    couponBarrierCrossingType: yup
      .string()
      .nullable()
      .when('$data.productYield.paymentBarrierFinal', {
        is: (val: any) => val !== null && val !== undefined,
        then: (schema) =>
          schema.required('Required when barrier level is present'),
      }),
    couponBasketType: yup.string().nullable(),
    equityPool: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    cashPoolKnockInLevel: yup
      .array(yup.number().transform(emptyStringToNull).min(0).max(100))
      .nullable(),
    cashPoolKnockInVolume: yup
      .array(yup.number().transform(emptyStringToNull).min(0).max(100))
      .nullable(),
    triggerParticipationRate: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    yieldBasketType: yup.string().nullable(),
    yieldCrossing: yup.string().nullable(),
  }),
  productCall: yup.object({
    callType: yup
      .string()
      .nullable()
      .requiredForTemplates([TemplateOptions.SNOWBALL]),
    callObservationDateList: yup.array(callObservationSchema).nullable(),
    callBarrierLevelCurrent: yup.string().nullable(),
    callBarrierLevelFinal: yup.string().nullable(),
    callBarrierLevelLow: yup.string().nullable(),
    callBarrierLevelHigh: yup.string().nullable(),
    callPremiumCurrent: yup.string().nullable().max(1000),
    callPremiumFinal: yup.string().nullable().max(1000),
    callPremiumLow: yup.string().nullable().max(1000),
    callPremiumHigh: yup.string().nullable().max(1000),
    callObservationFrequency: yup
      .string()
      .nullable()
      .requiredForTemplates([TemplateOptions.SNOWBALL]),
    callBarrierLevelUnderlierList: yup.array(underlierSchema).nullable(),
    numberNoCallPeriods: yup
      .string()
      .nullable()
      .requiredForTemplates([TemplateOptions.SNOWBALL]),
    callPeriodObservationType: yup.string().nullable(),
    expectedMaturity: yup.date().nullable(),
    stepType: yup.string().nullable(),
    callBarrierStepIncrement: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    callPremiumStepIncrement: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    callBasketType: yup.string().nullable(),
    callCrossing: yup
      .string()
      .nullable()
      .when('$data.productCall.callBarrierLevelFinal', {
        is: (val: any) => val !== null && val !== undefined,
        then: (schema) =>
          schema.required('Required when call barrier level is present'),
      }),
    callMemory: yup.string().nullable(),
  }),
  productProtection: yup.object({
    downsideType: yup.string().nullable().required(),
    putStrikeCurrent: yup.number().transform(emptyStringToNull).nullable(),
    putStrikeFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .test(
        'requiredForProtectionType',
        'Put strike is required for this protection type',
        (value, context) => {
          const requiredProtectionTypeSet = [
            ProtectionTypes.BARRIER,
            ProtectionTypes.BUFFER,
            ProtectionTypes.GEARED_BARRIER,
            ProtectionTypes.GEARED_BUFFER,
          ];
          if (requiredProtectionTypeSet.includes(context.parent.downsideType)) {
            return !!value;
          }
          return true;
        },
      ),
    putStrikeLow: yup.number().transform(emptyStringToNull).nullable(),
    putStrikeHigh: yup.number().transform(emptyStringToNull).nullable(),
    putStrikeUnderlierList: yup.array(underlierSchema).nullable(),
    principalBarrierLevelUnderlierList: yup.array(underlierSchema).nullable(),
    principalBufferLevelUnderlierList: yup.array(underlierSchema).nullable(),
    principalBarrierLevelCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    principalBarrierLevelFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    principalBarrierLevelLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    principalBarrierLevelHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    principalBufferLevelCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    principalBufferLevelFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    principalBufferLevelLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    principalBufferLevelHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    protectionLevel: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplates([TemplateOptions.CLIQUET]),
    putType: yup.string().nullable(),
    putObservationFrequency: yup.array(yup.string()).nullable(),
    putObservationDateList: yup.array(yup.string()).nullable(),
    putLeverageCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    putLeverageFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .requiredForTemplateRanges(
        [TemplateOptions.BEARISH],
        ['putLeverageLow', 'putLeverageHigh', 'putLeverageCurrent'],
      ),
    putLeverageLow: yup.number().transform(emptyStringToNull).nullable().min(0),
    putLeverageHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    capitalProtectionLevelCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    capitalProtectionLevelFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    capitalProtectionLevelLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    capitalProtectionLevelHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100),
    downsideBasketType: yup.string().nullable(),
    KIbarrierCrossing: yup.string().nullable(),
    finalFixingCrossing: yup.string().nullable(),
    oneStarType: yup.string().nullable(),
    oneStarLevel: yup.number().transform(emptyStringToNull).nullable(), // question: do we need this field to be stored as a decimal or a number
  }),
  productGrowth: yup.object({
    digitalReturnCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    digitalReturnFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .requiredForTemplateRanges(
        [
          TemplateOptions.DIGITAL,
          TemplateOptions.DIGITAL_DUAL_DIRECTIONAL,
          TemplateOptions.DIGITAL_PLUS,
        ],
        ['digitalReturnLow', 'digitalReturnHigh', 'digitalReturnCurrent'],
      ),
    digitalReturnLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    digitalReturnHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideParticipationRateCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideParticipationRateFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .requiredForTemplateRanges(
        [
          TemplateOptions.UNCAPPED_POINT_TO_POINT,
          TemplateOptions.CAPPED_DUAL_DIRECTIONAL,
          TemplateOptions.CAPPED_POINT_TO_POINT,
          TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
          TemplateOptions.P2P_WITH_FIXED_COUPON,
          TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL,
        ],
        [
          'upsideParticipationRateLow',
          'upsideParticipationRateHigh',
          'upsideParticipationRateCurrent',
        ],
      ),
    upsideParticipationRateLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideParticipationRateHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideParticipationUnderlierList: yup.array(underlierSchema).nullable(),
    lowerCallStrikeUnderlierList: yup.array(underlierSchema).nullable(),
    underlierReturnCapLevelCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    underlierReturnCapLevelFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [TemplateOptions.CAPPED_POINT_TO_POINT],
        [
          'underlierReturnCapLevelLow',
          'underlierReturnCapLevelHigh',
          'underlierReturnCapLevelCurrent',
        ],
      ),
    underlierReturnCapLevelLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    underlierReturnCapLevelHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    digitalReturnBarrierCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    digitalReturnBarrierFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .requiredForTemplateRanges(
        [
          TemplateOptions.DIGITAL,
          TemplateOptions.DIGITAL_DUAL_DIRECTIONAL,
          TemplateOptions.DIGITAL_PLUS,
        ],
        [
          'digitalReturnBarrierLow',
          'digitalReturnBarrierHigh',
          'digitalReturnBarrierCurrent',
        ],
      ),
    digitalReturnBarrierLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    digitalReturnBarrierHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    digitalReturnBarrierUnderlierList: yup.array(underlierSchema).nullable(),
    digitalReturnBarrierObservationFrequency: yup
      .string()
      .nullable()
      .requiredForTemplates([
        TemplateOptions.DIGITAL,
        TemplateOptions.DIGITAL_DUAL_DIRECTIONAL,
        TemplateOptions.DIGITAL_PLUS,
      ]),
    digitalReturnBarrierObservationDateList: yup.array(yup.string()).nullable(),
    upsideAboveDigitalReturn: yup.boolean().nullable(),
    upsideParticipationAboveDigitalReturnCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideParticipationAboveDigitalReturnFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideParticipationAboveDigitalReturnLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideParticipationAboveDigitalReturnHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    absoluteReturnBarrierLevelCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    absoluteReturnBarrierLevelFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .requiredForTemplateRanges(
        [
          TemplateOptions.DIGITAL_DUAL_DIRECTIONAL,
          TemplateOptions.DIGITAL_PLUS,
          TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL,
        ],
        [
          'absoluteReturnBarrierLevelLow',
          'absoluteReturnBarrierLevelHigh',
          'absoluteReturnBarrierLevelCurrent',
        ],
      ),
    absoluteReturnBarrierLevelLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    absoluteReturnBarrierLevelHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    absoluteReturnBarrierUnderlierList: yup.array(underlierSchema).nullable(),
    absoluteReturnParticipationRateCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    absoluteReturnParticipationRateFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .requiredForTemplateRanges(
        [
          TemplateOptions.DIGITAL_DUAL_DIRECTIONAL,
          TemplateOptions.DIGITAL_PLUS,
          TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL,
        ],
        [
          'absoluteReturnParticipationRateLow',
          'absoluteReturnParticipationRateHigh',
          'absoluteReturnParticipationRateCurrent',
        ],
      ),
    absoluteReturnParticipationRateLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    absoluteReturnParticipationRateHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    maximumReturnCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    maximumReturnFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .requiredForTemplateRanges(
        [TemplateOptions.CAPPED_POINT_TO_POINT],
        ['maximumReturnLow', 'maximumReturnHigh', 'maximumReturnCurrent'],
      ),
    maximumReturnLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    maximumReturnHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    minimumReturnCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    minimumReturnFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    minimumReturnLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    minimumReturnHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    // question: ensure the backend has this coming as a string not an array
    growthType: yup
      .string()
      .nullable()
      .requiredForTemplates([
        TemplateOptions.UNCAPPED_POINT_TO_POINT,
        TemplateOptions.BEARISH,
        TemplateOptions.CAPPED_DUAL_DIRECTIONAL,
        TemplateOptions.CAPPED_POINT_TO_POINT,
        TemplateOptions.CLIQUET,
        TemplateOptions.DIGITAL,
        TemplateOptions.DIGITAL_DUAL_DIRECTIONAL,
        TemplateOptions.DIGITAL_PLUS,
        TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
        TemplateOptions.P2P_WITH_FIXED_COUPON,
        TemplateOptions.SNOWBALL,
        TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL,
      ]),
    bearish: yup
      .boolean()
      .nullable()
      .requiredForTemplates([
        TemplateOptions.UNCAPPED_POINT_TO_POINT,
        TemplateOptions.BEARISH,
        TemplateOptions.CAPPED_DUAL_DIRECTIONAL,
        TemplateOptions.CAPPED_POINT_TO_POINT,
        TemplateOptions.CLIQUET,
        TemplateOptions.DIGITAL,
        TemplateOptions.DIGITAL_DUAL_DIRECTIONAL,
        TemplateOptions.DIGITAL_PLUS,
        TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
        TemplateOptions.P2P_WITH_FIXED_COUPON,
        TemplateOptions.SNOWBALL,
        TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL,
      ]),
    lowerPutStrike: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0)
      .max(100)
      .requiredForTemplates([TemplateOptions.BEARISH]),
    knockOutBarrierObservationFrequency: yup.string().nullable(),
    knockOutBarrierCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(1),
    knockOutBarrierFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(1),
    knockOutBarrierLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(1),
    knockOutBarrierHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(1),
    knockOutRebateCurrent: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    knockOutRebateFinal: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    knockOutRebateLow: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    knockOutRebateHigh: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .min(0),
    upsideBasketType: yup.string().nullable(),
    lowerCallStrikeList: yup.array(
      yup.object({
        lowerCallStrikeCurrent: yup
          .number()
          .transform(emptyStringToNull)
          .nullable(),
        lowerCallStrikeFinal: yup
          .number()
          .transform(emptyStringToNull)
          .nullable()
          .requiredForTemplateRanges(
            // todo question: does the lowerCallStrikeList need to be > 0 for these templates??
            [
              TemplateOptions.UNCAPPED_POINT_TO_POINT,
              TemplateOptions.CAPPED_DUAL_DIRECTIONAL,
              TemplateOptions.CAPPED_POINT_TO_POINT,
              TemplateOptions.CLIQUET,
              TemplateOptions.P2P_WITH_CONTINGENT_COUPON,
              TemplateOptions.P2P_WITH_FIXED_COUPON,
              TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL,
            ],
            [
              'lowerCallStrikeLow',
              'lowerCallStrikeHigh',
              'lowerCallStrikeCurrent',
            ],
          ),
        lowerCallStrikeLow: yup
          .number()
          .transform(emptyStringToNull)
          .nullable(),
        lowerCallStrikeHigh: yup
          .number()
          .transform(emptyStringToNull)
          .nullable(),
        lowerCallStrikeStartDate: yup
          .date()
          .nullable()
          .when('$data.productGrowth.lowerCallStrikeList', {
            is: (val: any[]) => val?.length >= 2,
            then: (schema) =>
              schema.required('Required when multiple call strikes'),
          })
          .noHolidayDates()
          .noWeekendDates(),
        lowerCallStrikeEndDate: yup
          .date()
          .nullable()
          .when('$data.productGrowth.lowerCallStrikeList', {
            is: (val: any[]) => val?.length >= 2,
            then: (schema) =>
              schema.required('Required when multiple call strikes'),
          })
          .noHolidayDates()
          .noWeekendDates(),
      }),
    ),
  }),
  productCln: yup.object({
    collateral: yup.string().nullable(),
    debtIsinList: yup.array(yup.string().nullable()).nullable(),
    debtWeightList: yup
      .array(yup.number().transform(emptyStringToNull).nullable())
      .nullable(),
    cdsName: yup.string().nullable(),
    cdsTenor: yup.number().transform(emptyStringToNull).nullable(),
    interestMultiplierPerYear: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    creditEvent: yup.string().nullable(),
    floatRateFixingOffsetDays: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    floatRateFixingTime: yup.string().nullable(),
    floatRateFixingSchedule: yup
      .array(yup.date().nullable().noHolidayDates().noWeekendDates())
      .nullable(),
    floatInstrument: yup.string().nullable(),
    fixedCouponMultiplier: yup.string().nullable(),
    rateCap: yup
      .array(
        yup.object({
          rateCap: yup.number().transform(emptyStringToNull).nullable().min(0),
          rateCapStartDate: yup
            .date()
            .nullable()
            .noHolidayDates()
            .noWeekendDates(),
          rateCapEndDate: yup
            .date()
            .nullable()
            .noHolidayDates()
            .noWeekendDates(),
        }),
      )
      .nullable(),
    rateMin: yup
      .array(
        yup.object({
          rateMin: yup.number().transform(emptyStringToNull).nullable().min(0),
          rateMinStartDate: yup
            .date()
            .nullable()
            .noHolidayDates()
            .noWeekendDates(),
          rateMinEndDate: yup
            .date()
            .nullable()
            .noHolidayDates()
            .noWeekendDates(),
        }),
      )
      .nullable(),
    rateConditionalCoupon: yup
      .array(
        yup.object({
          rateConditionalCoupon: yup
            .number()
            .transform(emptyStringToNull)
            .nullable()
            .min(0),
          rateConditionalCouponStartDate: yup.date().nullable(),
          rateConditionalCouponEndDate: yup.date().nullable(),
        }),
      )
      .nullable(),
    rateBarrierObservationFrequency: yup.string().nullable(),
    rateBarrierLevel: yup
      .array(
        yup.object({
          rateBarrierLevel: yup
            .number()
            .transform(emptyStringToNull)
            .nullable()
            .min(0),
          rateBarrierLevelStartDate: yup.date().nullable(),
          rateBarrierLevelEndDate: yup.date().nullable(),
        }),
      )
      .nullable(),
    ratePaymentType: yup.string().nullable(),
  }),
  productCms: yup.object({
    longTermRateUnderlierSymbol: yup.string().nullable(),
    longTermRateUnderlierSource: yup.string().nullable(),
    shortTermRateUnderlierSymbol: yup.string().nullable(),
    shortTermRateUnderlierSource: yup.string().nullable(),
    inflationMonthOffset: yup.number().nullable(),
  }),
  clientSpecific: yup.object({
    clientProductId: yup.number().transform(emptyStringToNull).nullable(),
    splitIds: yup.array(yup.string()).nullable(),
    offeringScope: yup.string().nullable(),
    productCode: yup.string().nullable(),
    offshoreEligibility: yup.string().nullable(),
    locked: yup.boolean().nullable(),
    prosRequestId: yup.string().nullable(),
    offeringNotification: yup
      .object({
        uuid: yup.string().nullable(),
        dismissed: yup.boolean().nullable(),
      })
      .nullable(),
    proceedToOfferingStatus: yup
      .object({
        uuid: yup.string().nullable(),
        createOfferWithIB: yup.string().nullable(),
        createOfferWithOrderingSystem: yup.string().nullable(),
        uploadDocstoOrderingSystem: yup.string().nullable(),
        errors: yup.mixed().nullable(),
      })
      .nullable(),
    broadOffering: yup.boolean().nullable(),
    dealLead: yup.array(yup.string()).nullable(),
    pvFundingLessFeesPercentage: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(),
    productSymbol: yup.string().nullable(),
    splitHedgeFunding: yup.mixed().nullable(), // this is quite the object in pdw, likely not needed for the form?
    merrillLynchSecurityId: yup.string().nullable(),
    legalTeam: yup.string().nullable(),
    legalPrintEstimate: yup.number().transform(emptyStringToNull).nullable(),
    issuerCreditCode: yup.string().nullable(),
    securityId: yup.string().nullable(),
    dealBackup: yup.string().nullable(),
    ramCode: yup.string().nullable(),
    trader: yup.string().nullable(),
    breakpointShares: yup.string().nullable(),
    numBreakpointInvestors: yup.string().nullable(),
    coreApproval: yup.boolean().nullable(),
    internalTradingDesk: yup.string().nullable(),
    firstPaymentDate: yup.date().nullable().noHolidayDates().noWeekendDates(),
    interestPeriod: yup.string().nullable(),
  }),
  productOptionDetails: yup.object({
    optiontype: yup.string().nullable(),
    optionStrikeLevel: yup.number().nullable(),
    optionObservationType: yup.string().nullable(),
    optionNumberOfShares: yup.number().nullable(),
  }),
  productRegulatory: yup.object({
    countryDistribution: yup.array(yup.string()).nullable(),
    listing: yup.string().nullable(),
    supportingDocumentation: yup
      .array(
        yup
          .object({
            documentationType: yup.string().nullable(),
            documentLanguage: yup.array(yup.string()).nullable(),
          })
          .nullable(),
      )
      .nullable(),
    targetMarket: yup.string().nullable(),
    costsAndCharges: yup
      .object({
        entryCostProRata: yup.number().transform(emptyStringToNull).nullable(),
        exitCostProRataPrior: yup
          .number()
          .transform(emptyStringToNull)
          .nullable(),
        ongoingCostPerAnnum: yup
          .number()
          .transform(emptyStringToNull)
          .nullable(),
        managementFeePerAnnum: yup
          .number()
          .transform(emptyStringToNull)
          .nullable(),
      })
      .nullable(),
  }),
  accumulatorDecumulator: yup
    .object({
      settlementFrequency: yup.string().nullable(),
      leverage: yup.number().transform(emptyStringToNull).nullable(),
      strike: yup.number().transform(emptyStringToNull).nullable(),
      strikeObservationFrequency: yup.string().nullable(),
      strikeObservationFixingType: yup.string().nullable(),
      numberOfShares: yup.number().transform(emptyStringToNull).nullable(),
      totalNumberOfObservationDates: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(),
      accumulationDecumulationDateList: yup
        .array(
          yup.object({
            periodEndDate: yup
              .date()
              .nullable()
              .noHolidayDates()
              .noWeekendDates(),
            settlementDate: yup
              .date()
              .nullable()
              .noHolidayDates()
              .noWeekendDates(),
          }),
        )
        .nullable(),
    })
    .nullable(),
});

export const dateWithoutTime = function (date: Date | string) {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const d = new Date(dateObj);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const getTemplateSelector = (context: any) => {
  return context.options.context.data.templateSelection;
};
