import { useState } from 'react';
import { useForm } from '@mantine/form';
import {
  TextInput,
  Select,
  Checkbox,
  Grid,
  Flex,
  LoadingOverlay,
  Text,
  Button,
} from '@mantine/core';
import {
  Underlier,
  assetTypeOptions,
  underlierCurrencyCodes,
} from '../../../product-schema/underlier.schema';
import {
  insertUnderlier,
  getAllExchanges,
} from '../../../API/market-data-maintenance.service';
import ResponseCard from './ResponseCard';
import { useMutation, useQuery } from '@tanstack/react-query';

type Exchange = {
  exchangeId: string;
  exchangeCode: string;
  exchangeName: string;
  region: string;
  regionCode: string | null;
  countryCode: string;
};

const UnderlierForm = () => {
  const form = useForm({
    initialValues: {
      bloombergTicker: '',
      bloombergTickerSymbol: '',
      securityName: '',
      ticker: '',
      currency: '',
      assetType: '',
      exchangeCode: '',
      activeListing: true,
      onProduct: false,
      showOptionalFields: false,
    },
    validate: {
      bloombergTicker: (value) => (value === '' ? 'Required' : null),
      bloombergTickerSymbol: (value) => (value === '' ? 'Required' : null),
      securityName: (value) => (value === '' ? 'Required' : null),
      ticker: (value) => (value === '' ? 'Required' : null),
      currency: (value) => (value === '' ? 'Required' : null),
      assetType: (value) => (value === '' ? 'Required' : null),
      exchangeCode: (value) => (value === '' ? 'Required' : null),
    },
  });

  const { getInputProps } = form;
  const [responseData, setResponseData] = useState<Underlier | null>(null);

  const mapUnderlierForSubmit = () => {
    return {
      ...form.values,
      bloombergTicker: form.values.bloombergTicker.toUpperCase(),
      bloombergTickerSymbol: form.values.bloombergTickerSymbol,
      securityName: form.values.securityName,
      ticker: form.values.ticker?.toUpperCase(),
      currency: form.values.currency,
      assetType: form.values.assetType,
      exchangeCode: form.values.exchangeCode?.toUpperCase(),
    };
  };

  const submitUnderlier = useMutation(
    () => insertUnderlier(mapUnderlierForSubmit()),
    {
      onSuccess: (res) => {
        setResponseData(res.data);
      },
    },
  );

  const { data: exchangeCodes } = useQuery(['exchangeCodes'], getAllExchanges);
  const exchangeCodeOptions =
    exchangeCodes?.data.map((code: Exchange) => ({
      label: `${code.exchangeName} (${code.exchangeCode})`,
      value: code.exchangeCode,
    })) || [];
  const onFormSubmit = () => {
    console.log('form.values', form.values);
    const x = form.validate();
    if (!x.hasErrors) {
      submitUnderlier.mutate();
    }
  };

  return (
    <div style={{ margin: '0.75rem' }}>
      <LoadingOverlay
        visible={submitUnderlier.isLoading}
        overlayProps={{ blur: 2 }}
      />
      {!responseData ? (
        <form>
          <Grid gutter="lg" style={{ marginBottom: '2rem' }}>
            <Grid.Col span={6} className="ag-theme-alpine">
              <Flex direction={'column'} gap={'0.5rem'}>
                <TextInput
                  label="Bloomberg Ticker"
                  {...getInputProps('bloombergTicker')}
                />
                <TextInput
                  label="Bloomberg Ticker Symbol"
                  placeholder="ex. AAPL UW Equity or SPX Index"
                  {...getInputProps('bloombergTickerSymbol')}
                />
                <TextInput
                  label="Security Name"
                  {...getInputProps('securityName')}
                />
                <TextInput label="Ticker" {...getInputProps('ticker')} />
                <Select
                  searchable={true}
                  label="Currency"
                  {...getInputProps('currency')}
                  data={underlierCurrencyCodes}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={6} className="ag-theme-alpine">
              <Flex direction={'column'} gap={'0.5rem'}>
                <Select
                  label="Asset Type"
                  {...getInputProps('assetType')}
                  data={assetTypeOptions}
                />
                <Select
                  label="Exchange Code"
                  searchable={true}
                  clearable={true}
                  {...getInputProps('exchangeCode')}
                  data={exchangeCodeOptions}
                />
                <Checkbox
                  label="Active Listing"
                  {...getInputProps('activeListing', { type: 'checkbox' })}
                />
                <Checkbox
                  label="On Product"
                  {...getInputProps('onProduct', { type: 'checkbox' })}
                />
              </Flex>
            </Grid.Col>
          </Grid>
          {submitUnderlier.isError && (
            <Text ta={'center'} style={{ color: 'red', weight: 600 }}>
              Error Submitting Underlier!
            </Text>
          )}
          <Button variant="primary-full" type="button" onClick={onFormSubmit}>
            Submit
          </Button>
        </form>
      ) : (
        // Render the ResponseCard component if the form has been submitted and responseData is set
        responseData && <ResponseCard responseData={responseData} />
      )}
    </div>
  );
};

export default UnderlierForm;
