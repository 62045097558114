import React, { useEffect, useState } from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import {
  Group,
  Modal,
  NumberInput,
  Radio,
  TextInput,
  LoadingOverlay,
  Overlay,
  Chip,
  Button,
} from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import {
  convertTenorToMonths,
  getRegistrationType,
  mmddyyyyDate,
} from '../../../../utilities/utilities';
import {
  MODIFIED_FOLLOWING,
  TenorUnits,
} from '../../../../product-schema/pdw-select-options';
import { PFRadio } from '../../reusable-form-component/BaseFormComponents';
import { useMutation } from '@tanstack/react-query';
import {
  calculateDates,
  DateCalculationPayload,
} from '../../../../API/product-maintenance.service';
import { notifications } from '@mantine/notifications';
import { DatePickerInput } from '@mantine/dates';

const acceptableFrequencies = [
  'Annually',
  'Monthly',
  'Quarterly',
  'Semi-Annually',
];

const mapUnderliers = (underlierList: any[] | undefined | null) => {
  if (!underlierList) {
    return undefined;
  }
  return underlierList
    .filter((underlierData) => underlierData.underlierSymbol != null)
    .filter((underlierData) => underlierData.underlierSymbol != null)
    .map((underlierData) => ({
      ticker: underlierData.underlierSymbol?.toUpperCase(),
      enabled: true,
    }));
};

export interface DateCalculatorProps {
  processDatesCallback: any;
}

const DateCalculator = ({ processDatesCallback }: DateCalculatorProps) => {
  const form = useProductFormContext();
  const [opened, setOpened] = useState(false);
  const [dateShift, setDateShift] = useState<number | string>(0);
  const [dateStartSelector, setDateStartSelector] = useState<
    string | 'tradeDate' | 'strikeDate'
  >('tradeDate');
  const [tickerInputForCalc, setTickerInputForCalc] = useState<string>('');

  const [tradeDate, setTradeDate] = useState<Date | null | undefined>();
  const [strikeDate, setStrikeDate] = useState<Date | null | undefined>();

  useEffect(() => {
    setTradeDate(form.values.productGeneral.tradeDate);
    setStrikeDate(form.values.productGeneral.strikeDate);
  }, [
    form.values.productGeneral.tradeDate,
    form.values.productGeneral.strikeDate,
  ]);

  const [underliersForCalculation, setUnderliersForCalculation] = useState<
    | {
        ticker: string | null | undefined;
        enabled: boolean;
      }[]
    | undefined
  >(undefined);

  const [calculationComplete, setCalculationComplete] = useState(false);

  useEffect(() => {
    setCalculationComplete(false);
    setUnderliersForCalculation(
      mapUnderliers(form.values.productGeneral.underlierList),
    );
  }, [opened, form.values.productGeneral.underlierList]);

  const getStartDate = () => {
    if (dateStartSelector === 'tradeDate') {
      return mmddyyyyDate(tradeDate);
    } else if (dateStartSelector === 'strikeDate') {
      return mmddyyyyDate(strikeDate);
    } else {
      return null;
    }
  };

  const observationFrequency = acceptableFrequencies.includes(
    form.values.productYield.paymentEvaluationFrequencyFinal || '',
  )
    ? form.values.productYield.paymentEvaluationFrequencyFinal // todo: does this make sense? do we need a tip for the user?
    : form.values.productCall.callObservationFrequency;

  const tenorInMonths =
    convertTenorToMonths(
      form.values.productGeneral.tenorFinal,
      form.values.productGeneral.tenorUnit as TenorUnits,
    ) || undefined;

  const registrationType = getRegistrationType(form.values) || undefined;
  const callObservationFrequency = acceptableFrequencies.includes(
    form.values.productCall.callObservationFrequency || '',
  )
    ? form.values.productCall.callObservationFrequency
    : form.values.productYield.paymentEvaluationFrequencyFinal;

  const payloadForDatesCalculations: DateCalculationPayload = {
    tickers: underliersForCalculation
      ? underliersForCalculation
          .filter((x) => x.enabled)
          .map((x) => x.ticker)
          .join(',')
      : undefined,
    // couponTypeFixed: form.values.productYield.paymentType === CouponTypes.FIXED, // todo: not needed????????
    tradeDate: getStartDate() || undefined,
    tenor: tenorInMonths,
    observationFrequency: observationFrequency,
    callObservationFrequency: callObservationFrequency,
    registrationType: registrationType,
    dateShift: '' + dateShift, // settlementOffsetDays needs to be a form value somehow, but not tied to schema, just required for endpoint
    rollForward: false, // question: is this something that should be controlled by something? old app defaults to false
    useModifiedFollowing:
      form.values.productGeneral.businessDayConvention === MODIFIED_FOLLOWING,
    LTCGTreatment: form.values.productGeneral.longTermCapitalGain || undefined,
  };

  const postDateCalculation: any = useMutation(
    (payload: DateCalculationPayload) =>
      calculateDates(payloadForDatesCalculations),
    {
      onSuccess: (res) => {
        console.log('res', res);
        processDatesCallback(res.data);
        notifications.show({
          id: 'date-calculation',
          title: 'Dates Calculated!',
          message: 'Dated calculation completed Successfully.',
          autoClose: 10000,
          withCloseButton: true,
          color: 'green',
        });
        setCalculationComplete(true);
      },
      onError: (err) => {
        notifications.show({
          id: 'date-calculation',
          title: 'Error Calculating Dates',
          autoClose: 10000,
          withCloseButton: true,
          message: 'Please try again or contact support.',
          color: 'red',
        });
      },
    },
  );
  return (
    <>
      <Modal
        style={{ marginTop: 'min(10em, 10%)' }}
        opened={opened}
        onClose={() => setOpened(false)}
        title="Calendar Date Calculator"
      >
        <div>
          <LoadingOverlay
            visible={postDateCalculation.isLoading}
            overlayProps={{ blur: 1 }}
          />
          {calculationComplete && (
            <>
              <div
                style={{
                  textAlign: 'center',
                  position: 'absolute',
                  top: '40%',
                  left: 0,
                  right: 0,
                  fontSize: '2em',
                  fontWeight: 700,
                  zIndex: 100,
                }}
              >
                Dates Calculated <br /> Successfully
                <br />
                <IconCircleCheck color={'green'} size={90} />
              </div>
              <Overlay opacity={0.3} blur={2} zIndex={100} />
            </>
          )}
          <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
            <Radio.Group
              name="startDate"
              value={dateStartSelector}
              onChange={setDateStartSelector}
              label="Select the start date"
              withAsterisk
            >
              <Radio value="tradeDate" label="Trade Date" />
              <Radio value="strikeDate" label="Strike Date" />
            </Radio.Group>

            <NumberInput
              label={'Date Shift'}
              value={dateShift}
              onChange={(val) => setDateShift(val)}
            />

            <Radio.Group
              label={'Modified Following'}
              {...form.getInputProps('productGeneral.businessDayConvention')}
            >
              <PFRadio
                fieldPath={'productGeneral.businessDayConvention'}
                value={MODIFIED_FOLLOWING}
                label={'True'}
              ></PFRadio>
              <PFRadio
                fieldPath={'productGeneral.businessDayConvention'}
                value={''}
                label={'False'}
              ></PFRadio>
            </Radio.Group>

            <div style={{ display: 'flex', gap: '2em' }}>
              <DatePickerInput
                label="Trade Date"
                style={
                  dateStartSelector === 'strikeDate'
                    ? { opacity: '.3' }
                    : { opacity: 1 }
                }
                readOnly={dateStartSelector !== 'tradeDate'}
                allowDeselect={true}
                value={tradeDate}
                onChange={(val) => setTradeDate(val)}
              />

              <DatePickerInput
                label="Strike Date"
                style={
                  dateStartSelector === 'tradeDate'
                    ? { opacity: '.3' }
                    : { opacity: 1 }
                }
                allowDeselect={true}
                readOnly={dateStartSelector !== 'strikeDate'}
                value={strikeDate}
                onChange={(val) => setStrikeDate(val)}
              />
            </div>

            <TextInput
              label={'Observation Frequency'}
              value={observationFrequency}
              readOnly={true}
              placeholder={'Not provided'}
            />
            <TextInput
              label={'Call Observation Frequency'}
              value={callObservationFrequency}
              readOnly={true}
              placeholder={'Not provided'}
            />
            <TextInput
              label={'Registration Type'}
              value={registrationType}
              readOnly={true}
              placeholder={'Not provided'}
            />
            <NumberInput
              label={'Tenor (Months)'}
              value={tenorInMonths}
              readOnly={true}
              placeholder={'Not provided'}
            />
            <TextInput
              label={'Long Term Capital Gain'}
              value={
                (!!form.values.productGeneral.longTermCapitalGain)
                  .toString()
                  .toUpperCase() || undefined
              }
              readOnly={true}
              placeholder={'Not provided'}
            />

            <div
              style={{
                display: 'flex',
                gap: '.5em',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div>Underliers:</div>
              {underliersForCalculation?.map((underlier, index) => (
                <Chip
                  key={underlier?.ticker || index}
                  checked={underlier.enabled}
                  onChange={(checked) => {
                    setUnderliersForCalculation(
                      underliersForCalculation.map((x) =>
                        x.ticker === underlier.ticker
                          ? {
                              ticker: underlier.ticker,
                              enabled: !underlier.enabled,
                            }
                          : x,
                      ),
                    );
                  }}
                  color={'green'}
                >
                  {underlier.ticker}
                </Chip>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                gap: '.5em',
                alignItems: 'flex-end',
                justifyContent: 'space-around',
              }}
            >
              <TextInput
                label={'Add Ticker (For Date Calculation Only)'}
                value={tickerInputForCalc}
                onChange={(e) => setTickerInputForCalc(e.target.value)}
              />
              <Button
                type={'button'}
                onClick={() => {
                  if (
                    underliersForCalculation?.find(
                      (x) =>
                        x.ticker?.toUpperCase() ===
                        tickerInputForCalc.toUpperCase(),
                    )
                  )
                    return;
                  setUnderliersForCalculation([
                    ...(underliersForCalculation || []),
                    {
                      ticker: tickerInputForCalc.toUpperCase(),
                      enabled: true,
                    },
                  ]);
                  setTickerInputForCalc('');
                }}
              >
                Add Ticker
              </Button>
            </div>
          </div>
        </div>

        <Button
          variant="primary-full"
          type={'button'}
          style={{ marginTop: '2em' }}
          // todo: confirm reset??
          onClick={() => postDateCalculation.mutate()}
        >
          Calculate Dates
        </Button>
      </Modal>

      <Group justify="center" align="center">
        <Button
          variant={'primary'}
          type={'button'}
          onClick={() => setOpened(true)}
        >
          Calculate Dates
        </Button>
      </Group>
    </>
  );
};

export default DateCalculator;

// fields used for the endpoint call
//   /api/product-entry-and-edit-service/products/dates
// const payloadForDatesCalculations = {
//   tickers: form.values.productGeneral.underlierList?.filter(underlierData => underlierData.underlierSymbol != null)
//     .map(underlierData =>
//       underlierData.underlierSymbol?.toUpperCase
//     ),
//   couponTypeFixed: form.values.productYield.paymentType === CouponTypes.FIXED,
//   tradeDate: formatISOStyleDateTime(form.values.productGeneral.tradeDate),
//   tenor: convertTenorToMonths(form.values.productGeneral.tenorFinal, form.values.productGeneral.tenorUnit as TenorUnits),
//   observationFrequency: acceptableFrequencies.includes((form.values.productYield.paymentEvaluationFrequencyFinal || '')) ?
//     form.values.productYield.paymentEvaluationFrequencyFinal :
//     form.values.productCall.callObservationFrequency,
//   callObservationFrequency: acceptableFrequencies.includes((form.values.productCall.callObservationFrequency || '')) ?
//     form.values.productCall.callObservationFrequency:
//     form.values.productYield.paymentEvaluationFrequencyFinal,
//   registrationType: getRegistrationType(form.values),
//   dateShift: 0,// settlementOffsetDays needs to be a form value somehow, but not tied to schema, just required for endpoint
//   rollForward: false, // question: is this something that should be controlled by something? old app defaults to false
//   useModifiedFollowing: form.values.productGeneral.businessDayConvention === MODIFIED_FOLLOWING,
//   LTCGTreatment: form.values.productGeneral.longTermCapitalGain,
// }

// todo: intergration with the endpoint mentioned above, the old system did this from the payload response:
// {
//     if(data?.OBSERVATIONSETTLEMENTDATES?.QDATES?.DATA) {
//       const dates = data.OBSERVATIONSETTLEMENTDATES.QDATES.DATA;
//
//       this.rowArray.clear();
//       dates.forEach((datePair, index) => {
//         this.addRow();
//         const row = this.rowArray.controls[index];
//         row.get('observationDate')?.setValue(new Date(datePair[1]));
//         row.get('settlementDate')?.setValue(new Date(datePair[2]));
//       });
//
//       this.calculateResetDate();
//       if(this.callTypeSet && this.couponTypeSet) {
//         this.radioButtonAfterCalc();
//       } else {
//         if(this.callTypeSet) {
//           this.updateNoCallPeriodRows();
//         }
//         this.updateRadioButtonPrefill();
//       }
//     }
//   }, (error: any) => {
//     this.dateErrorPopup(error?.message);
//   });
// }
// }
