import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import LoginComponent from '../../pages/login/login';
import { useAuth } from '../Authentication/AuthProvider';
import { getUserPermissions } from '../../API/misc-services';
import { useQuery } from '@tanstack/react-query';
import { LoadingOverlay } from '@mantine/core';
import { colors } from '../../Styles/colors';
import axios from 'axios';

const TokenOrLogin = ({ children }: any) => {
  const { token } = useAuth();
  const { data: permissions, refetch } = useQuery({
    queryKey: ['getPermissions'],
    queryFn: getUserPermissions,
    enabled: false,
  });

  useEffect(() => {
    if (axios.defaults.headers.common['Authorization']) {
      setTimeout(() => {
        refetch();
      });
    }
  }, [axios.defaults.headers.common['Authorization']]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {token ? (
        <>
          {!permissions ? (
            <div
              style={{ width: '100%', height: '100%', position: 'absolute' }}
            >
              <LoadingOverlay
                visible={true}
                loaderProps={{
                  size: 'sm',
                  color: colors['luma-selective-yellow'],
                  variant: 'bars',
                }}
                overlayProps={{ opacity: 0.1 }}
              />
            </div>
          ) : (
            //   --global-header-height == 50px
            <div style={{ marginTop: '50px' }}>
              <Outlet />
            </div>
          )}
        </>
      ) : (
        <LoginComponent />
      )}
    </>
  );
};

export default TokenOrLogin;
