import React, { ForwardedRef, forwardRef } from 'react';
import { Divider } from '@mantine/core';
import {
  minCouponFrequencies,
  CouponTypes,
  CouponStepTypes,
  PaymentBarrierObservationFrequencies,
  basketTypeOptions,
  ObservationFrequencyOptions,
  BarrierCrossings,
} from '../../../product-schema/pdw-select-options';

import ExpandableNumberFieldset from '../reusable-form-component/BaseFormComponents/ExpandableNumberFieldset';
import { isWeekend } from '../../../utilities/utilities';
import {
  PFSelect,
  PFDatePicker,
  PFNumberInput,
  PFTextInput,
} from '../reusable-form-component/BaseFormComponents';
import { couponDetailsFields } from '../../../product-schema/pdw-field-subsets';

const CouponDetailsFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFSelect
            label="Coupon Type"
            data={[...Object.values(CouponTypes)]}
            fieldPath={couponDetailsFields.paymentType}
          />
          <PFSelect
            label="Coupon Step Type"
            data={[...Object.values(CouponStepTypes)]}
            fieldPath={couponDetailsFields.paymentStepType}
          />
          <PFSelect
            label="Coupon Basket Type"
            data={basketTypeOptions}
            fieldPath={couponDetailsFields.couponBasketType}
          />

          <PFSelect
            label="Payment Frequency"
            data={ObservationFrequencyOptions}
            fieldPath={couponDetailsFields.paymentFrequency}
          />

          <PFSelect
            label="(Final) Observation Frequency"
            data={ObservationFrequencyOptions}
            fieldPath={couponDetailsFields.paymentEvaluationFrequencyFinal}
          />

          <PFSelect
            label="Payment Barrier Observation Frequency"
            data={[...Object.values(PaymentBarrierObservationFrequencies)]}
            fieldPath={couponDetailsFields.paymentBarrierObservationFrequency}
          />

          <PFSelect
            label="Min Coupon Pay Period"
            data={minCouponFrequencies}
            fieldPath={couponDetailsFields.minCouponPayPeriod}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.paymentRatePerPeriodFinal,
              label: 'Rate Per Period',
            }}
            subFields={[
              {
                property: couponDetailsFields.paymentRatePerPeriodHigh,
                label: 'High Rate Per Period',
              },
              {
                property: couponDetailsFields.paymentRatePerPeriodLow,
                label: 'Low Rate Per Period',
              },
              {
                property: couponDetailsFields.paymentRatePerPeriodCurrent,
                label: 'Current Rate Per Period',
              },
            ]}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.paymentRatePerAnnumFinal,
              label: 'Coupon Rate Per Annum',
            }}
            subFields={[
              {
                property: couponDetailsFields.paymentRatePerAnnumHigh,
                label: 'High Coupon Rate Per Annum',
              },
              {
                property: couponDetailsFields.paymentRatePerAnnumLow,
                label: 'Low Coupon Rate Per Annum',
              },
              {
                property: couponDetailsFields.paymentRatePerAnnumCurrent,
                label: 'Current Coupon Rate Per Annum',
              },
            ]}
          />
        </div>

        <div className={'form-subsection'}>
          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.paymentBarrierFinal,
              label: 'Barrier Level',
            }}
            subFields={[
              {
                property: couponDetailsFields.paymentBarrierHigh,
                label: 'High Barrier Level',
              },
              {
                property: couponDetailsFields.paymentBarrierLow,
                label: 'Low Barrier Level',
              },
              {
                property: couponDetailsFields.paymentBarrierCurrent,
                label: 'Current Barrier Level',
              },
            ]}
          />

          <PFSelect
            label="Barrier Crossing Type"
            data={[...Object.values(BarrierCrossings)]}
            fieldPath={couponDetailsFields.couponBarrierCrossingType}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.upperBarrierLevelFinal,
              label: 'Upper Barrier Level',
            }}
            subFields={[
              {
                property: couponDetailsFields.upperBarrierLevelHigh,
                label: 'High Upper Barrier Level',
              },
              {
                property: couponDetailsFields.upperBarrierLevelLow,
                label: 'Low Upper Barrier Level',
              },
              {
                property: couponDetailsFields.upperBarrierLevelCurrent,
                label: 'Current Upper Barrier Level',
              },
            ]}
          />

          <PFDatePicker
            label="Variable Pay Initial Observation Date"
            excludeDate={isWeekend}
            fieldPath={couponDetailsFields.variablePayInitialObservationDate}
          />

          <PFDatePicker
            label="Variable Pay Final Observation Date"
            excludeDate={isWeekend}
            fieldPath={couponDetailsFields.variablePayFinalObservationDate}
          />

          <PFDatePicker
            label="Payment Type Change Date"
            excludeDate={isWeekend}
            fieldPath={couponDetailsFields.paymentTypeChangeDate}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.minPaymentRatePerPeriodFinal,
              label: 'Min Rate Per Period',
            }}
            subFields={[
              {
                property: couponDetailsFields.minPaymentRatePerPeriodHigh,
                label: 'High Min Rate Per Period',
              },
              {
                property: couponDetailsFields.minPaymentRatePerPeriodLow,
                label: 'Low Min Rate Per Period',
              },
              {
                property: couponDetailsFields.minPaymentRatePerPeriodCurrent,
                label: 'Current Min Rate Per Period',
              },
            ]}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.minPaymentRatePerAnnumFinal,
              label: 'Min Coupon Rate Per Annum',
            }}
            subFields={[
              {
                property: couponDetailsFields.minPaymentRatePerAnnumHigh,
                label: 'High Min Coupon Rate',
              },
              {
                property: couponDetailsFields.minPaymentRatePerAnnumLow,
                label: 'Low Min Coupon Rate',
              },
              {
                property: couponDetailsFields.minPaymentRatePerAnnumCurrent,
                label: 'Current Min Coupon Rate',
              },
            ]}
          />
        </div>

        <div className={'form-subsection'}>
          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.stockReturnCapFinal,
              label: 'Stock Return Cap',
            }}
            subFields={[
              {
                property: couponDetailsFields.stockReturnCapHigh,
                label: 'High Stock Return Cap',
              },
              {
                property: couponDetailsFields.stockReturnCapLow,
                label: 'Low Stock Return Cap',
              },
              {
                property: couponDetailsFields.stockReturnCapCurrent,
                label: 'Current Stock Return Cap',
              },
            ]}
          />

          <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.stockReturnFloorFinal,
              label: 'Stock Return Floor',
            }}
            subFields={[
              {
                property: couponDetailsFields.stockReturnFloorHigh,
                label: 'High Stock Return Floor',
              },
              {
                property: couponDetailsFields.stockReturnFloorLow,
                label: 'Low Stock Return Floor',
              },
              {
                property: couponDetailsFields.stockReturnFloorCurrent,
                label: 'Current Stock Return Floor',
              },
            ]}
          />

          <div>Rate Builder</div>
          <Divider></Divider>
          <PFTextInput
            label={'Grouping'}
            fieldPath={couponDetailsFields.rateBuilderGrouping}
          ></PFTextInput>

          <PFTextInput
            label={'Return'}
            fieldPath={couponDetailsFields.rateBuilderReturns}
          ></PFTextInput>

          {/* <ExpandableNumberFieldset
            primaryField={{
              property: couponDetailsFields.rateBuilderValuesFinal,
              label: 'Rate Builder Values',
            }}
            subFields={[
              {
                property: couponDetailsFields.rateBuilderValuesHigh,
                label: 'High Rate Builder Values',
              },
              {
                property: couponDetailsFields.rateBuilderValuesLow,
                label: 'Low Rate Builder Values',
              },
              {
                property: couponDetailsFields.rateBuilderValuesCurrent,
                label: 'Current Rate Builder Values',
              },
            ]}
          /> */}

          <Divider style={{ marginTop: '1em' }}></Divider>
        </div>
        <div className={'form-subsection'}>
          <div>Bifurcation Of Interest</div>
          <Divider></Divider>
          <PFNumberInput
            label={'Interest'}
            decimalScale={6}
            rightSection={<div>%</div>}
            fieldPath={couponDetailsFields.interest}
          ></PFNumberInput>

          <PFNumberInput
            label={'Option Premium'}
            decimalScale={6}
            rightSection={<div>%</div>}
            fieldPath={couponDetailsFields.optionPremium}
          ></PFNumberInput>

          <Divider style={{ marginTop: '1em' }}></Divider>
        </div>
      </div>
    );
  },
);
CouponDetailsFormComponent.displayName = 'CouponDetailsFormComponent';
export default CouponDetailsFormComponent;
