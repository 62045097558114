import React, { ForwardedRef, forwardRef, useContext } from 'react';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { Radio } from '@mantine/core';
import {
  // isFieldDisabled,
  isWeekend,
} from '../../../utilities/utilities';
import { MODIFIED_FOLLOWING } from '../../../product-schema/pdw-select-options';
import {
  PFDatePicker,
  PFRadio,
} from '../reusable-form-component/BaseFormComponents';
import { ProductFormHiddenFieldsContext } from '../entry-and-edit-contexts/HiddenFields-context';
import { isFieldHidden } from '../reusable-form-component/BaseFormComponents/PFNumberInput';
import { productDatesFields } from '../../../product-schema/pdw-field-subsets';

const ProductDatesComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    // const disableFields = isFieldDisabled(disabledContext, 'productYield.leverageFactors');
    const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);
    //todo: show warning when editting
    // form.values.id &&
    // '*WARNING: Updating Trade Date, Issue Date or Product Deadline Timestamp in the Offer Stage may impact order execution.',

    // {/*todo: make this based off of the 'dateShift' property in the fields array for this component.*/}
    // need to use disabled and hidden context explicitly for this one
    // {/*<NumberInput label={'Settlement Offset Days'}></NumberInput>*/}

    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFDatePicker
            label="Trade Date"
            excludeDate={isWeekend}
            fieldPath={productDatesFields.tradeDate}
          />

          <PFDatePicker
            label="Strike Date"
            excludeDate={isWeekend}
            fieldPath={productDatesFields.strikeDate}
          />

          <PFDatePicker
            label="Issue Date"
            excludeDate={isWeekend}
            fieldPath={productDatesFields.issueDate}
          />
        </div>
        <div className={'form-subsection'}>
          <PFDatePicker
            label="Final Valuation Date"
            excludeDate={isWeekend}
            fieldPath={productDatesFields.finalValuationDate}
          />

          <PFDatePicker
            label="Maturity Date"
            excludeDate={isWeekend}
            fieldPath={productDatesFields.maturityDate}
          />
        </div>
        {!isFieldHidden(
          hiddenFieldsContext,
          productDatesFields.businessDayConvention,
        ) && (
          <div className={'form-subsection'}>
            <Radio.Group
              label={'Modified Following'}
              {...form.getInputProps(productDatesFields.businessDayConvention)}
            >
              <PFRadio
                fieldPath={productDatesFields.businessDayConvention}
                value={MODIFIED_FOLLOWING}
                label={'True'}
              ></PFRadio>
              <PFRadio
                fieldPath={productDatesFields.businessDayConvention}
                value={''}
                label={'False'}
              ></PFRadio>
            </Radio.Group>
          </div>
        )}
      </div>
    );
  },
);

ProductDatesComponent.displayName = 'ProductDatesComponent';
export default ProductDatesComponent;

// trade date,
//   strike date,
//   final val date,
//   maturity date,
//   settlement offset,
//   modified following,
//   call observation frequency,
//   number no call, step increments,
//   coupon frequency,
//   and that whether to use trade or strike date button
