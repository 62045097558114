import { PDWProduct } from '../../product-schema/product.schema';
import {
  createAccurateDateWithoutTime,
  filterEmptyObjects,
  formatDate,
  formatISOStyleDateTime,
  removeWhiteSpaceFromString,
} from '../../utilities/utilities';
import produce, { setAutoFreeze } from 'immer';
import {
  AncillaryFeature,
  ProductStages,
  ProductOpsReviewStatus,
  RegistrationTypes,
} from '../../product-schema/pdw-select-options';
import { newLowerCallStrike } from './form-sections/ProductGrowthFormComponent';
import Big from 'big.js';

export const isFullProtection = (
  value: RegistrationTypes | null | undefined | string,
) => {
  if (!value) return false;
  return [
    RegistrationTypes.CD,
    RegistrationTypes.ML_GIC,
    RegistrationTypes.GIC,
    RegistrationTypes.PPN,
  ].includes(value as RegistrationTypes);
};

export const wholeNumberToDecimal = (x: number | null | undefined) => {
  if (!x) {
    return x;
  } else {
    return +new Big(x).div(100);
  }
};

export const decimalToWholeNumber = (x: number | null | undefined) => {
  if (!x) {
    return x;
  } else {
    return +new Big(x).times(100);
  }
};

export const incomingUnderlierList = (underlierList: any[]) => {
  return underlierList?.length > 0
    ? underlierList.map((x: any) => {
        return {
          ...x,
          underlierStrikeDate: x.underlierStrikeDate
            ? createAccurateDateWithoutTime(x.underlierStrikeDate)
            : null,
          underlierWeight: decimalToWholeNumber(x.underlierWeight),
        };
      })
    : [];
};

export const outgoingUnderlierList = (underlierList: any[]) => {
  if (!underlierList || underlierList?.length === 0) return null;
  return underlierList.filter(filterEmptyObjects)?.map((x: any) => {
    return {
      ...x,
      underlierStrikeDate: formatDate(x.underlierStrikeDate),
      underlierWeight: wholeNumberToDecimal(x.underlierWeight),
    };
  });
};

// mappings explicitly done on new product submit
export const mapProductBeforeSubmit = (formValue: PDWProduct): PDWProduct => {
  return produce(formValue, (draft: PDWProduct) => {
    // removing the fields
    draft.templateSelection = null;
    //

    if (draft.qcVerified) {
      draft.productGeneral.opsReviewStatus =
        draft.productGeneral.stage === ProductStages.POST_TRADE
          ? ProductOpsReviewStatus.QUALITY_REVIEWED_POST_TRADE
          : ProductOpsReviewStatus.QUALITY_REVIEWED_OFFER;
      draft.qcVerified = null;
    } else {
      draft.productGeneral.opsReviewStatus = null;
    }
    if (
      draft.productGrowth.upsideParticipationAboveDigitalReturnCurrent ||
      draft.productGrowth.upsideParticipationAboveDigitalReturnFinal ||
      draft.productGrowth.upsideParticipationAboveDigitalReturnLow ||
      draft.productGrowth.upsideParticipationAboveDigitalReturnHigh
    ) {
      draft.productGrowth.upsideAboveDigitalReturn = true;
    } else {
      draft.productGrowth.upsideAboveDigitalReturn = null;
    }
  });
};

export const mapProductFormForStorage = (formValue: PDWProduct | any): any => {
  // todo: clean up logs once we are happy with mapping 100%
  return produce(formValue, (draft: PDWProduct | any) => {
    draft.productGeneral.oisDfPercentage = wholeNumberToDecimal(
      draft.productGeneral?.oisDfPercentage,
    );
    draft.productGeneral.supplementalPercentage = wholeNumberToDecimal(
      draft.productGeneral.supplementalPercentage,
    );
    draft.productProtection.oneStarLevel = wholeNumberToDecimal(
      draft.productProtection.oneStarLevel,
    );
    draft.productProtection.protectionLevel = wholeNumberToDecimal(
      draft.productProtection.protectionLevel,
    );
    draft.productGeneral.standardPrice = wholeNumberToDecimal(
      draft.productGeneral.standardPrice,
    );
    draft.productGeneral.structureFee = wholeNumberToDecimal(
      draft.productGeneral.structureFee,
    );
    draft.productYield.triggerParticipationRate = wholeNumberToDecimal(
      draft.productYield.triggerParticipationRate,
    );
    draft.productYield.equityPool = wholeNumberToDecimal(
      draft.productYield.equityPool,
    );
    draft.productYield.optionPremium = wholeNumberToDecimal(
      draft.productYield.optionPremium,
    );
    draft.productYield.interest = wholeNumberToDecimal(
      draft.productYield.interest,
    );
    draft.clientSpecific.legalPrintEstimate = wholeNumberToDecimal(
      draft.clientSpecific.legalPrintEstimate,
    );
    draft.clientSpecific.pvFundingLessFeesPercentage = wholeNumberToDecimal(
      draft.clientSpecific.pvFundingLessFeesPercentage,
    );
    draft.productGrowth.minimumReturnHigh = wholeNumberToDecimal(
      draft.productGrowth.minimumReturnHigh,
    );
    draft.productGrowth.minimumReturnLow = wholeNumberToDecimal(
      draft.productGrowth.minimumReturnLow,
    );
    draft.productGrowth.minimumReturnCurrent = wholeNumberToDecimal(
      draft.productGrowth.minimumReturnCurrent,
    );
    draft.productGrowth.minimumReturnFinal = wholeNumberToDecimal(
      draft.productGrowth.minimumReturnFinal,
    );
    draft.productGrowth.knockOutBarrierHigh = wholeNumberToDecimal(
      draft.productGrowth.knockOutBarrierHigh,
    );
    draft.productGrowth.knockOutBarrierLow = wholeNumberToDecimal(
      draft.productGrowth.knockOutBarrierLow,
    );
    draft.productGrowth.knockOutBarrierCurrent = wholeNumberToDecimal(
      draft.productGrowth.knockOutBarrierCurrent,
    );
    draft.productGrowth.knockOutBarrierFinal = wholeNumberToDecimal(
      draft.productGrowth.knockOutBarrierFinal,
    );
    draft.productGrowth.knockOutRebateHigh = wholeNumberToDecimal(
      draft.productGrowth.knockOutRebateHigh,
    );
    draft.productGrowth.knockOutRebateLow = wholeNumberToDecimal(
      draft.productGrowth.knockOutRebateLow,
    );
    draft.productGrowth.knockOutRebateCurrent = wholeNumberToDecimal(
      draft.productGrowth.knockOutRebateCurrent,
    );
    draft.productGrowth.knockOutRebateFinal = wholeNumberToDecimal(
      draft.productGrowth.knockOutRebateFinal,
    );
    draft.productCall.callPremiumFinal = wholeNumberToDecimal(
      draft.productCall.callPremiumFinal,
    );
    draft.productCall.callPremiumHigh = wholeNumberToDecimal(
      draft.productCall.callPremiumHigh,
    );
    draft.productCall.callPremiumLow = wholeNumberToDecimal(
      draft.productCall.callPremiumLow,
    );
    draft.productCall.callPremiumCurrent = wholeNumberToDecimal(
      draft.productCall.callPremiumCurrent,
    );
    draft.productCall.callBarrierLevelFinal = wholeNumberToDecimal(
      draft.productCall.callBarrierLevelFinal,
    );
    draft.productCall.callBarrierLevelHigh = wholeNumberToDecimal(
      draft.productCall.callBarrierLevelHigh,
    );
    draft.productCall.callBarrierLevelLow = wholeNumberToDecimal(
      draft.productCall.callBarrierLevelLow,
    );
    draft.productCall.callBarrierLevelCurrent = wholeNumberToDecimal(
      draft.productCall.callBarrierLevelCurrent,
    );
    draft.productYield.paymentRatePerPeriodFinal = wholeNumberToDecimal(
      draft.productYield.paymentRatePerPeriodFinal,
    );
    draft.productYield.paymentRatePerPeriodHigh = wholeNumberToDecimal(
      draft.productYield.paymentRatePerPeriodHigh,
    );
    draft.productYield.paymentRatePerPeriodLow = wholeNumberToDecimal(
      draft.productYield.paymentRatePerPeriodLow,
    );
    draft.productYield.paymentRatePerPeriodCurrent = wholeNumberToDecimal(
      draft.productYield.paymentRatePerPeriodCurrent,
    );
    draft.productYield.paymentRatePerAnnumFinal = wholeNumberToDecimal(
      draft.productYield.paymentRatePerAnnumFinal,
    );
    draft.productYield.paymentRatePerAnnumHigh = wholeNumberToDecimal(
      draft.productYield.paymentRatePerAnnumHigh,
    );
    draft.productYield.paymentRatePerAnnumLow = wholeNumberToDecimal(
      draft.productYield.paymentRatePerAnnumLow,
    );
    draft.productYield.paymentRatePerAnnumCurrent = wholeNumberToDecimal(
      draft.productYield.paymentRatePerAnnumCurrent,
    );
    draft.productYield.paymentBarrierFinal = wholeNumberToDecimal(
      draft.productYield.paymentBarrierFinal,
    );
    draft.productYield.paymentBarrierHigh = wholeNumberToDecimal(
      draft.productYield.paymentBarrierHigh,
    );
    draft.productYield.paymentBarrierLow = wholeNumberToDecimal(
      draft.productYield.paymentBarrierLow,
    );
    draft.productYield.paymentBarrierCurrent = wholeNumberToDecimal(
      draft.productYield.paymentBarrierCurrent,
    );
    draft.productYield.upperBarrierLevelFinal = wholeNumberToDecimal(
      draft.productYield.upperBarrierLevelFinal,
    );
    draft.productYield.upperBarrierLevelHigh = wholeNumberToDecimal(
      draft.productYield.upperBarrierLevelHigh,
    );
    draft.productYield.upperBarrierLevelLow = wholeNumberToDecimal(
      draft.productYield.upperBarrierLevelLow,
    );
    draft.productYield.upperBarrierLevelCurrent = wholeNumberToDecimal(
      draft.productYield.upperBarrierLevelCurrent,
    );
    draft.productYield.minPaymentRatePerPeriodFinal = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerPeriodFinal,
    );
    draft.productYield.minPaymentRatePerPeriodHigh = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerPeriodHigh,
    );
    draft.productYield.minPaymentRatePerPeriodLow = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerPeriodLow,
    );
    draft.productYield.minPaymentRatePerPeriodCurrent = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerPeriodCurrent,
    );
    draft.productYield.minPaymentRatePerAnnumFinal = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerAnnumFinal,
    );
    draft.productYield.minPaymentRatePerAnnumHigh = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerAnnumHigh,
    );
    draft.productYield.minPaymentRatePerAnnumLow = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerAnnumLow,
    );
    draft.productYield.minPaymentRatePerAnnumCurrent = wholeNumberToDecimal(
      draft.productYield.minPaymentRatePerAnnumCurrent,
    );
    // draft.productYield.rateBuilderValuesFinal = wholeNumberToDecimal(
    //   draft.productYield.rateBuilderValuesFinal,
    // );
    // draft.productYield.rateBuilderValuesHigh = wholeNumberToDecimal(
    //   draft.productYield.rateBuilderValuesHigh,
    // );
    // draft.productYield.rateBuilderValuesLow = wholeNumberToDecimal(
    //   draft.productYield.rateBuilderValuesLow,
    // );
    // draft.productYield.rateBuilderValuesCurrent = wholeNumberToDecimal(
    //   draft.productYield.rateBuilderValuesCurrent,
    // );
    draft.productYield.stockReturnFloorFinal = wholeNumberToDecimal(
      draft.productYield.stockReturnFloorFinal,
    );
    draft.productYield.stockReturnFloorHigh = wholeNumberToDecimal(
      draft.productYield.stockReturnFloorHigh,
    );
    draft.productYield.stockReturnFloorLow = wholeNumberToDecimal(
      draft.productYield.stockReturnFloorLow,
    );
    draft.productYield.stockReturnFloorCurrent = wholeNumberToDecimal(
      draft.productYield.stockReturnFloorCurrent,
    );
    draft.productYield.stockReturnCapFinal = wholeNumberToDecimal(
      draft.productYield.stockReturnCapFinal,
    );
    draft.productYield.stockReturnCapHigh = wholeNumberToDecimal(
      draft.productYield.stockReturnCapHigh,
    );
    draft.productYield.stockReturnCapLow = wholeNumberToDecimal(
      draft.productYield.stockReturnCapLow,
    );
    draft.productYield.stockReturnCapCurrent = wholeNumberToDecimal(
      draft.productYield.stockReturnCapCurrent,
    );
    draft.productGrowth.upsideParticipationRateFinal = wholeNumberToDecimal(
      draft.productGrowth.upsideParticipationRateFinal,
    );
    draft.productGrowth.upsideParticipationRateHigh = wholeNumberToDecimal(
      draft.productGrowth.upsideParticipationRateHigh,
    );
    draft.productGrowth.upsideParticipationRateLow = wholeNumberToDecimal(
      draft.productGrowth.upsideParticipationRateLow,
    );
    draft.productGrowth.upsideParticipationRateCurrent = wholeNumberToDecimal(
      draft.productGrowth.upsideParticipationRateCurrent,
    );
    draft.productGrowth.underlierReturnCapLevelFinal = wholeNumberToDecimal(
      draft.productGrowth.underlierReturnCapLevelFinal,
    );
    draft.productGrowth.underlierReturnCapLevelHigh = wholeNumberToDecimal(
      draft.productGrowth.underlierReturnCapLevelHigh,
    );
    draft.productGrowth.underlierReturnCapLevelLow = wholeNumberToDecimal(
      draft.productGrowth.underlierReturnCapLevelLow,
    );
    draft.productGrowth.underlierReturnCapLevelCurrent = wholeNumberToDecimal(
      draft.productGrowth.underlierReturnCapLevelCurrent,
    );
    draft.productGrowth.maximumReturnFinal = wholeNumberToDecimal(
      draft.productGrowth.maximumReturnFinal,
    );
    draft.productGrowth.maximumReturnHigh = wholeNumberToDecimal(
      draft.productGrowth.maximumReturnHigh,
    );
    draft.productGrowth.maximumReturnLow = wholeNumberToDecimal(
      draft.productGrowth.maximumReturnLow,
    );
    draft.productGrowth.maximumReturnCurrent = wholeNumberToDecimal(
      draft.productGrowth.maximumReturnCurrent,
    );
    draft.productGrowth.digitalReturnBarrierFinal = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnBarrierFinal,
    );
    draft.productGrowth.digitalReturnBarrierHigh = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnBarrierHigh,
    );
    draft.productGrowth.digitalReturnBarrierLow = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnBarrierLow,
    );
    draft.productGrowth.digitalReturnBarrierCurrent = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnBarrierCurrent,
    );
    draft.productGrowth.digitalReturnFinal = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnFinal,
    );
    draft.productGrowth.digitalReturnHigh = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnHigh,
    );
    draft.productGrowth.digitalReturnLow = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnLow,
    );
    draft.productGrowth.digitalReturnCurrent = wholeNumberToDecimal(
      draft.productGrowth.digitalReturnCurrent,
    );
    draft.productGrowth.absoluteReturnBarrierLevelHigh = wholeNumberToDecimal(
      draft.productGrowth.absoluteReturnBarrierLevelHigh,
    );
    draft.productGrowth.absoluteReturnBarrierLevelLow = wholeNumberToDecimal(
      draft.productGrowth.absoluteReturnBarrierLevelLow,
    );
    draft.productGrowth.absoluteReturnBarrierLevelCurrent =
      wholeNumberToDecimal(
        draft.productGrowth.absoluteReturnBarrierLevelCurrent,
      );
    draft.productGrowth.absoluteReturnBarrierLevelFinal = wholeNumberToDecimal(
      draft.productGrowth.absoluteReturnBarrierLevelFinal,
    );
    draft.productGrowth.upsideParticipationAboveDigitalReturnFinal =
      wholeNumberToDecimal(
        draft.productGrowth.upsideParticipationAboveDigitalReturnFinal,
      );
    draft.productGrowth.upsideParticipationAboveDigitalReturnHigh =
      wholeNumberToDecimal(
        draft.productGrowth.upsideParticipationAboveDigitalReturnHigh,
      );
    draft.productGrowth.upsideParticipationAboveDigitalReturnLow =
      wholeNumberToDecimal(
        draft.productGrowth.upsideParticipationAboveDigitalReturnLow,
      );
    draft.productGrowth.upsideParticipationAboveDigitalReturnCurrent =
      wholeNumberToDecimal(
        draft.productGrowth.upsideParticipationAboveDigitalReturnCurrent,
      );
    draft.productGrowth.absoluteReturnParticipationRateFinal =
      wholeNumberToDecimal(
        draft.productGrowth.absoluteReturnParticipationRateFinal,
      );
    draft.productGrowth.absoluteReturnParticipationRateHigh =
      wholeNumberToDecimal(
        draft.productGrowth.absoluteReturnParticipationRateHigh,
      );
    draft.productGrowth.absoluteReturnParticipationRateLow =
      wholeNumberToDecimal(
        draft.productGrowth.absoluteReturnParticipationRateLow,
      );
    draft.productGrowth.absoluteReturnParticipationRateCurrent =
      wholeNumberToDecimal(
        draft.productGrowth.absoluteReturnParticipationRateCurrent,
      );
    draft.productProtection.putLeverageFinal = wholeNumberToDecimal(
      draft.productProtection.putLeverageFinal,
    );
    draft.productProtection.putLeverageHigh = wholeNumberToDecimal(
      draft.productProtection.putLeverageHigh,
    );
    draft.productProtection.putLeverageLow = wholeNumberToDecimal(
      draft.productProtection.putLeverageLow,
    );
    draft.productProtection.putLeverageCurrent = wholeNumberToDecimal(
      draft.productProtection.putLeverageCurrent,
    );
    draft.productProtection.capitalProtectionLevelHigh = wholeNumberToDecimal(
      draft.productProtection.capitalProtectionLevelHigh,
    );
    draft.productProtection.capitalProtectionLevelLow = wholeNumberToDecimal(
      draft.productProtection.capitalProtectionLevelLow,
    );
    draft.productProtection.capitalProtectionLevelCurrent =
      wholeNumberToDecimal(
        draft.productProtection.capitalProtectionLevelCurrent,
      );
    draft.productProtection.capitalProtectionLevelFinal = wholeNumberToDecimal(
      draft.productProtection.capitalProtectionLevelFinal,
    );
    draft.productProtection.principalBufferLevelFinal = wholeNumberToDecimal(
      draft.productProtection.principalBufferLevelFinal,
    );
    draft.productProtection.principalBufferLevelHigh = wholeNumberToDecimal(
      draft.productProtection.principalBufferLevelHigh,
    );
    draft.productProtection.principalBufferLevelLow = wholeNumberToDecimal(
      draft.productProtection.principalBufferLevelLow,
    );
    draft.productProtection.principalBufferLevelCurrent = wholeNumberToDecimal(
      draft.productProtection.principalBufferLevelCurrent,
    );
    draft.productProtection.principalBarrierLevelHigh = wholeNumberToDecimal(
      draft.productProtection.principalBarrierLevelHigh,
    );
    draft.productProtection.principalBarrierLevelLow = wholeNumberToDecimal(
      draft.productProtection.principalBarrierLevelLow,
    );
    draft.productProtection.principalBarrierLevelFinal = wholeNumberToDecimal(
      draft.productProtection.principalBarrierLevelFinal,
    );
    draft.productProtection.principalBarrierLevelCurrent = wholeNumberToDecimal(
      draft.productProtection.principalBarrierLevelCurrent,
    );
    draft.productProtection.putStrikeFinal = wholeNumberToDecimal(
      draft.productProtection.putStrikeFinal,
    );
    draft.productProtection.putStrikeCurrent = wholeNumberToDecimal(
      draft.productProtection.putStrikeCurrent,
    );
    draft.productProtection.putStrikeLow = wholeNumberToDecimal(
      draft.productProtection.putStrikeLow,
    );
    draft.productProtection.putStrikeHigh = wholeNumberToDecimal(
      draft.productProtection.putStrikeHigh,
    );

    if (draft.productGeneral.underlierRankWeightList?.length === 0) {
      draft.productGeneral.underlierRankWeightList = null;
    } else {
      draft.productGeneral.underlierRankWeightList =
        draft.productGeneral?.underlierRankWeightList?.map(
          (x: number | null | undefined) => wholeNumberToDecimal(x),
        );
    }

    // return produce(formValue, (draft: PDWProduct | any) => {
    draft.productYield.rateBuilderGrouping =
      draft.productYield.rateBuilderGrouping
        ?.split(',')
        .map((x: any) => x.trim());
    draft.productYield.rateBuilderReturns =
      draft.productYield.rateBuilderReturns
        ?.split(',')
        .map((x: any) => x.trim());
    draft.productGeneral.tradeDate = formatDate(draft.productGeneral.tradeDate);
    draft.productGeneral.strikeDate = formatDate(
      draft.productGeneral.strikeDate,
    );
    draft.productGeneral.issueDate = formatDate(draft.productGeneral.issueDate);
    draft.productGeneral.finalValuationDate = formatDate(
      draft.productGeneral.finalValuationDate,
    );
    draft.productGeneral.maturityDate = formatDate(
      draft.productGeneral.maturityDate,
    );
    draft.productGeneral.lookbackInitialDate = formatDate(
      draft.productGeneral.lookbackInitialDate,
    );
    draft.productGeneral.lookbackFinalDate = formatDate(
      draft.productGeneral.lookbackFinalDate,
    );
    draft.productGeneral.productDeadlineTimestamp = formatISOStyleDateTime(
      draft.productGeneral.productDeadlineTimestamp,
    );
    draft.productGeneral.productLaunchTimestamp = formatISOStyleDateTime(
      draft.productGeneral.productLaunchTimestamp,
    );
    draft.clientSpecific.firstPaymentDate = formatDate(
      draft.clientSpecific.firstPaymentDate,
    );
    draft.productCall.expectedMaturity = formatDate(
      draft.productCall.expectedMaturity,
    );
    draft.productGeneral.aggregatingDates =
      draft.productGeneral.aggregatingDates
        ?.filter((x: any) => x)
        ?.map((x: any) => formatDate(x));
    if (draft.productGeneral.aggregatingDates?.length === 0) {
      draft.productGeneral.aggregatingDates = null;
    }
    draft.productGeneral.averagingInDates =
      draft.productGeneral.averagingInDates
        ?.filter((x: any) => x)
        ?.map((x: any) => formatDate(x));
    if (draft.productGeneral.averagingInDates?.length === 0) {
      draft.productGeneral.averagingInDates = null;
    }

    draft.productGeneral.averagingOutDates =
      draft.productGeneral.averagingOutDates
        ?.filter((x: any) => x)
        ?.map((x: any) => formatDate(x));
    if (draft.productGeneral?.averagingOutDates?.length === 0) {
      draft.productGeneral.averagingOutDates = null;
    }
    if (draft.productCln?.debtIsinList?.length === 0) {
      draft.productCln.debtIsinList = null;
    }
    if (draft.productCln?.debtWeightList?.length === 0) {
      draft.productCln.debtWeightList = null;
    }
    if (draft.productGeneral.reofferClientList?.length === 0) {
      draft.productGeneral.reofferClientList = null;
    } else {
      draft.productGeneral.reofferClientList =
        draft.productGeneral.reofferClientList.map((row: any) => {
          return {
            ...row,
            reofferClientRateCurrent: wholeNumberToDecimal(
              row.reofferClientRateCurrent,
            ),
            reofferClientRateLow: wholeNumberToDecimal(
              row.reofferClientRateLow,
            ),
            reofferClientRateHigh: wholeNumberToDecimal(
              row.reofferClientRateHigh,
            ),
            reofferClientRateFinal: wholeNumberToDecimal(
              row.reofferClientRateFinal,
            ),
          };
        });
    }

    draft.productCln.floatRateFixingSchedule =
      draft.productCln?.floatRateFixingSchedule
        ?.filter((x: any) => x)
        ?.map((x: any) => formatDate(x));
    if (draft.productCln.floatRateFixingSchedule?.length === 0) {
      draft.productCln.floatRateFixingSchedule = null;
    }

    draft.productYield.variablePayFinalObservationDate = formatDate(
      draft.productYield.variablePayFinalObservationDate,
    );
    draft.productYield.paymentTypeChangeDate = formatDate(
      draft.productYield.paymentTypeChangeDate,
    );
    draft.productYield.variablePayInitialObservationDate = formatDate(
      draft.productYield.variablePayInitialObservationDate,
    );

    draft.productYield.leverageFactors = draft.productYield.leverageFactors
      ?.filter(filterEmptyObjects)
      ?.map((x: any) => {
        return {
          ...x,
          leverageFactorStartDate: formatDate(x.leverageFactorStartDate),
          leverageFactorEndDate: formatDate(x.leverageFactorEndDate),
          leverageFactor: wholeNumberToDecimal(x.leverageFactor),
        };
      });

    if (draft.productYield.leverageFactors?.length === 0) {
      draft.productYield.leverageFactors = null;
    }

    draft.productGrowth.lowerCallStrikeList =
      draft.productGrowth.lowerCallStrikeList
        ?.filter(filterEmptyObjects)
        ?.map((x: any) => {
          return {
            ...x,
            lowerCallStrikeStartDate: formatDate(x.lowerCallStrikeStartDate),
            lowerCallStrikeEndDate: formatDate(x.lowerCallStrikeEndDate),
            lowerCallStrikeLow: wholeNumberToDecimal(x.lowerCallStrikeLow),
            lowerCallStrikeHigh: wholeNumberToDecimal(x.lowerCallStrikeHigh),
            lowerCallStrikeFinal: wholeNumberToDecimal(x.lowerCallStrikeFinal),
          };
        });
    if (draft.productGrowth.lowerCallStrikeList?.length === 0) {
      draft.productGrowth.lowerCallStrikeList = null;
    }

    draft.productCln.rateCap = draft.productCln.rateCap
      ?.filter(filterEmptyObjects)
      ?.map((x: any) => {
        return {
          ...x,
          rateCapStartDate: formatDate(x.rateCapStartDate),
          rateCapEndDate: formatDate(x.rateCapEndDate),
          rateCap: wholeNumberToDecimal(x.rateCap),
        };
      });
    if (draft.productCln.rateCap?.length === 0) {
      draft.productCln.rateCap = null;
    }

    draft.productCln.rateMin = draft.productCln.rateMin
      ?.filter(filterEmptyObjects)
      ?.map((x: any) => {
        return {
          ...x,
          rateMinStartDate: formatDate(x.rateMinStartDate),
          rateMinEndDate: formatDate(x.rateMinEndDate),
          rateMin: wholeNumberToDecimal(x.rateMin),
        };
      });
    if (draft.productCln.rateMin?.length === 0) {
      draft.productCln.rateMin = null;
    }

    draft.productCln.rateConditionalCoupon =
      draft.productCln.rateConditionalCoupon
        ?.filter(filterEmptyObjects)
        ?.map((x: any) => {
          return {
            ...x,
            rateConditionalCouponStartDate: formatDate(
              x.rateConditionalCouponStartDate,
            ),
            rateConditionalCouponEndDate: formatDate(
              x.rateConditionalCouponEndDate,
            ),
            rateConditionalCoupon: wholeNumberToDecimal(
              x.rateConditionalCoupon,
            ),
          };
        });
    if (draft.productCln.rateConditionalCoupon?.length === 0) {
      draft.productCln.rateConditionalCoupon = null;
    }

    draft.productCln.rateBarrierLevel = draft.productCln.rateBarrierLevel
      ?.filter(filterEmptyObjects)
      ?.map((x: any) => {
        return {
          ...x,
          rateBarrierLevelStartDate: formatDate(x.rateBarrierLevelStartDate),
          rateBarrierLevelEndDate: formatDate(x.rateBarrierLevelEndDate),
          rateBarrierLevel: wholeNumberToDecimal(x.rateBarrierLevel),
        };
      });
    if (draft.productCln.rateBarrierLevel?.length === 0) {
      draft.productCln.rateBarrierLevel = null;
    }

    draft.productCall.callObservationDateList =
      draft.productCall.callObservationDateList?.length > 0
        ? draft.productCall.callObservationDateList
            .filter(filterEmptyObjects)
            ?.map((x: any) => {
              return {
                ...x,
                callSettlementDate: formatDate(x.callSettlementDate),
                callObservationDate: formatDate(x.callObservationDate),
                callScheduleUnderlierList: outgoingUnderlierList(
                  x.callScheduleUnderlierList,
                ),
              };
            })
        : null;

    draft.productYield.paymentDateList =
      draft.productYield.paymentDateList?.length > 0
        ? draft.productYield.paymentDateList
            .filter(filterEmptyObjects)
            ?.map((x: any) => {
              return {
                ...x,
                paymentObservationDate: formatDate(x.paymentObservationDate),
                paymentSettlementDate: formatDate(x.paymentSettlementDate),
                paymentResetDate: formatDate(x.paymentResetDate),
              };
            })
        : null;

    draft.productGeneral.underlierList = outgoingUnderlierList(
      draft.productGeneral.underlierList,
    );
    draft.productGrowth.upsideParticipationUnderlierList =
      outgoingUnderlierList(
        draft.productGrowth.upsideParticipationUnderlierList,
      );
    draft.productGrowth.lowerCallStrikeUnderlierList = outgoingUnderlierList(
      draft.productGrowth.lowerCallStrikeUnderlierList,
    );
    draft.productGrowth.digitalReturnBarrierUnderlierList =
      outgoingUnderlierList(
        draft.productGrowth.digitalReturnBarrierUnderlierList,
      );
    draft.productGrowth.absoluteReturnBarrierUnderlierList =
      outgoingUnderlierList(
        draft.productGrowth.absoluteReturnBarrierUnderlierList,
      );
    draft.productYield.paymentBarrierUnderlierList = outgoingUnderlierList(
      draft.productYield.paymentBarrierUnderlierList,
    );
    draft.productYield.stockReturnCapUnderlierList = outgoingUnderlierList(
      draft.productYield.stockReturnCapUnderlierList,
    );
    draft.productYield.stockReturnFloorUnderlierList = outgoingUnderlierList(
      draft.productYield.stockReturnFloorUnderlierList,
    );
    draft.productCall.callBarrierLevelUnderlierList = outgoingUnderlierList(
      draft.productCall.callBarrierLevelUnderlierList,
    );
    draft.productProtection.putStrikeUnderlierList = outgoingUnderlierList(
      draft.productProtection.putStrikeUnderlierList,
    );
    draft.productProtection.principalBarrierLevelUnderlierList =
      outgoingUnderlierList(
        draft.productProtection.principalBarrierLevelUnderlierList,
      );
    draft.productProtection.principalBufferLevelUnderlierList =
      outgoingUnderlierList(
        draft.productProtection.principalBufferLevelUnderlierList,
      );

    if (draft.productGeneral.ancillaryFeatures?.length === 0) {
      draft.productGeneral.ancillaryFeatures = null;
    }
    if (
      draft.productGeneral?.ancillaryFeatures?.includes(
        AncillaryFeature.With_Coupon_Memory,
      )
    ) {
      draft.productYield.paymentMemory = true;
    } else {
      draft.productYield.paymentMemory = null;
    }

    draft.productYield.cashPoolKnockInVolume =
      draft.productYield.cashPoolKnockInVolume?.length > 0
        ? draft.productYield.cashPoolKnockInVolume
            .map((x: any) => wholeNumberToDecimal(x))
            .join(',')
        : null;
    draft.productYield.cashPoolKnockInLevel =
      draft.productYield.cashPoolKnockInLevel?.length > 0
        ? draft.productYield.cashPoolKnockInLevel
            .map((x: any) => wholeNumberToDecimal(x))
            .join(',')
        : null;

    // computed mapped values for storage: will override a null with a false but that should be ok question adrian
    draft.productGeneral.averagingModified =
      draft.productGeneral.ancillaryFeatures?.includes(
        AncillaryFeature.With_Modified_Averaging_Out,
      ) ||
      draft.productGeneral.ancillaryFeatures?.includes(
        AncillaryFeature.With_Modified_Averaging_In,
      ) ||
      null;

    draft.productGrowth.growthType = draft.productGrowth.growthType
      ? [draft.productGrowth.growthType]
      : null; // weird but it's stored as an array

    //question: ancillary features mapping??? or keep them separate?
    // in the old app, ancillary features were mapped based on what had values in their associated fields, and not by ancillary features checkboxes themselves
    // ex:
    // in old app ('With Minimum Coupon') was added based on the existence of one of the following:
    // productYield.minPaymentRatePerAnnumFinal
    // productYield.minPaymentRatePerAnnumHigh
    // productYield.minPaymentRatePerAnnumLow
    // productYield.minPaymentRatePerAnnumCurrent
    // productYield.minPaymentRatePerPeriodFinal
    // productYield.minPaymentRatePerPeriodHigh
    // productYield.minPaymentRatePerPeriodLow
    // productYield.minPaymentRatePerPeriodCurrent
  });
};

export const mapProductFormFROMStorage = (value: PDWProduct | any): any => {
  // console.log('map from storage', value);
  setAutoFreeze(false);
  return produce(value, (draft: PDWProduct | any) => {
    try {
      if (!draft.clientSpecific) {
        draft.clientSpecific = {};
      }
      if (!draft.productCln) {
        draft.productCln = {};
      }
      if (!draft.productGeneral) {
        draft.productGeneral = {};
      }
      if (!draft.productGrowth) {
        draft.productGrowth = {};
      }
      if (!draft.productProtection) {
        draft.productProtection = {};
      }
      if (!draft.productYield) {
        draft.productYield = {};
      }
      if (!draft.productCall) {
        draft.productCall = {};
      }
      if (!draft.productCms) {
        draft.productCms = {};
      }
      if (!draft.productOptionDetails) {
        draft.productOptionDetails = {};
      }
      if (!draft.productRegulatory) {
        draft.productRegulatory = {
          countryDistribution: [],
          listing: null,
          supportingDocumentation: null,
          targetMarket: null,
        };
      }

      draft.productGeneral.oisDfPercentage = decimalToWholeNumber(
        draft.productGeneral?.oisDfPercentage,
      );
      draft.productGeneral.supplementalPercentage = decimalToWholeNumber(
        draft.productGeneral.supplementalPercentage,
      );
      draft.productProtection.oneStarLevel = decimalToWholeNumber(
        draft.productProtection.oneStarLevel,
      );
      draft.productProtection.protectionLevel = decimalToWholeNumber(
        draft.productProtection.protectionLevel,
      );
      draft.productGeneral.standardPrice = decimalToWholeNumber(
        draft.productGeneral.standardPrice,
      );
      draft.productGeneral.structureFee = decimalToWholeNumber(
        draft.productGeneral.structureFee,
      );
      draft.productYield.triggerParticipationRate = decimalToWholeNumber(
        draft.productYield.triggerParticipationRate,
      );
      draft.productYield.equityPool = decimalToWholeNumber(
        draft.productYield.equityPool,
      );
      draft.productYield.optionPremium = decimalToWholeNumber(
        draft.productYield.optionPremium,
      );
      draft.productYield.interest = decimalToWholeNumber(
        draft.productYield.interest,
      );
      draft.clientSpecific.legalPrintEstimate = decimalToWholeNumber(
        draft.clientSpecific.legalPrintEstimate,
      );
      draft.clientSpecific.pvFundingLessFeesPercentage = decimalToWholeNumber(
        draft.clientSpecific.pvFundingLessFeesPercentage,
      );
      draft.productGrowth.minimumReturnHigh = decimalToWholeNumber(
        draft.productGrowth.minimumReturnHigh,
      );
      draft.productGrowth.minimumReturnLow = decimalToWholeNumber(
        draft.productGrowth.minimumReturnLow,
      );
      draft.productGrowth.minimumReturnCurrent = decimalToWholeNumber(
        draft.productGrowth.minimumReturnCurrent,
      );
      draft.productGrowth.minimumReturnFinal = decimalToWholeNumber(
        draft.productGrowth.minimumReturnFinal,
      );
      draft.productGrowth.knockOutBarrierHigh = decimalToWholeNumber(
        draft.productGrowth.knockOutBarrierHigh,
      );
      draft.productGrowth.knockOutBarrierLow = decimalToWholeNumber(
        draft.productGrowth.knockOutBarrierLow,
      );
      draft.productGrowth.knockOutBarrierCurrent = decimalToWholeNumber(
        draft.productGrowth.knockOutBarrierCurrent,
      );
      draft.productGrowth.knockOutBarrierFinal = decimalToWholeNumber(
        draft.productGrowth.knockOutBarrierFinal,
      );
      draft.productGrowth.knockOutRebateHigh = decimalToWholeNumber(
        draft.productGrowth.knockOutRebateHigh,
      );
      draft.productGrowth.knockOutRebateLow = decimalToWholeNumber(
        draft.productGrowth.knockOutRebateLow,
      );
      draft.productGrowth.knockOutRebateCurrent = decimalToWholeNumber(
        draft.productGrowth.knockOutRebateCurrent,
      );
      draft.productGrowth.knockOutRebateFinal = decimalToWholeNumber(
        draft.productGrowth.knockOutRebateFinal,
      );
      draft.productCall.callPremiumFinal = decimalToWholeNumber(
        draft.productCall.callPremiumFinal,
      );
      draft.productCall.callPremiumHigh = decimalToWholeNumber(
        draft.productCall.callPremiumHigh,
      );
      draft.productCall.callPremiumLow = decimalToWholeNumber(
        draft.productCall.callPremiumLow,
      );
      draft.productCall.callPremiumCurrent = decimalToWholeNumber(
        draft.productCall.callPremiumCurrent,
      );
      draft.productCall.callBarrierLevelFinal = decimalToWholeNumber(
        draft.productCall.callBarrierLevelFinal,
      );
      draft.productCall.callBarrierLevelHigh = decimalToWholeNumber(
        draft.productCall.callBarrierLevelHigh,
      );
      draft.productCall.callBarrierLevelLow = decimalToWholeNumber(
        draft.productCall.callBarrierLevelLow,
      );
      draft.productCall.callBarrierLevelCurrent = decimalToWholeNumber(
        draft.productCall.callBarrierLevelCurrent,
      );
      draft.productYield.paymentRatePerPeriodFinal = decimalToWholeNumber(
        draft.productYield.paymentRatePerPeriodFinal,
      );
      draft.productYield.paymentRatePerPeriodHigh = decimalToWholeNumber(
        draft.productYield.paymentRatePerPeriodHigh,
      );
      draft.productYield.paymentRatePerPeriodLow = decimalToWholeNumber(
        draft.productYield.paymentRatePerPeriodLow,
      );
      draft.productYield.paymentRatePerPeriodCurrent = decimalToWholeNumber(
        draft.productYield.paymentRatePerPeriodCurrent,
      );
      draft.productYield.paymentRatePerAnnumFinal = decimalToWholeNumber(
        draft.productYield.paymentRatePerAnnumFinal,
      );
      draft.productYield.paymentRatePerAnnumHigh = decimalToWholeNumber(
        draft.productYield.paymentRatePerAnnumHigh,
      );
      draft.productYield.paymentRatePerAnnumLow = decimalToWholeNumber(
        draft.productYield.paymentRatePerAnnumLow,
      );
      draft.productYield.paymentRatePerAnnumCurrent = decimalToWholeNumber(
        draft.productYield.paymentRatePerAnnumCurrent,
      );
      draft.productYield.paymentBarrierFinal = decimalToWholeNumber(
        draft.productYield.paymentBarrierFinal,
      );
      draft.productYield.paymentBarrierHigh = decimalToWholeNumber(
        draft.productYield.paymentBarrierHigh,
      );
      draft.productYield.paymentBarrierLow = decimalToWholeNumber(
        draft.productYield.paymentBarrierLow,
      );
      draft.productYield.paymentBarrierCurrent = decimalToWholeNumber(
        draft.productYield.paymentBarrierCurrent,
      );
      draft.productYield.upperBarrierLevelFinal = decimalToWholeNumber(
        draft.productYield.upperBarrierLevelFinal,
      );
      draft.productYield.upperBarrierLevelHigh = decimalToWholeNumber(
        draft.productYield.upperBarrierLevelHigh,
      );
      draft.productYield.upperBarrierLevelLow = decimalToWholeNumber(
        draft.productYield.upperBarrierLevelLow,
      );
      draft.productYield.upperBarrierLevelCurrent = decimalToWholeNumber(
        draft.productYield.upperBarrierLevelCurrent,
      );
      draft.productYield.minPaymentRatePerPeriodFinal = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerPeriodFinal,
      );
      draft.productYield.minPaymentRatePerPeriodHigh = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerPeriodHigh,
      );
      draft.productYield.minPaymentRatePerPeriodLow = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerPeriodLow,
      );
      draft.productYield.minPaymentRatePerPeriodCurrent = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerPeriodCurrent,
      );
      draft.productYield.minPaymentRatePerAnnumFinal = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerAnnumFinal,
      );
      draft.productYield.minPaymentRatePerAnnumHigh = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerAnnumHigh,
      );
      draft.productYield.minPaymentRatePerAnnumLow = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerAnnumLow,
      );
      draft.productYield.minPaymentRatePerAnnumCurrent = decimalToWholeNumber(
        draft.productYield.minPaymentRatePerAnnumCurrent,
      );
      // draft.productYield.rateBuilderValuesFinal = decimalToWholeNumber(
      //   draft.productYield.rateBuilderValuesFinal,
      // );
      // draft.productYield.rateBuilderValuesHigh = decimalToWholeNumber(
      //   draft.productYield.rateBuilderValuesHigh,
      // );
      // draft.productYield.rateBuilderValuesLow = decimalToWholeNumber(
      //   draft.productYield.rateBuilderValuesLow,
      // );
      // draft.productYield.rateBuilderValuesCurrent = decimalToWholeNumber(
      //   draft.productYield.rateBuilderValuesCurrent,
      // );
      draft.productYield.stockReturnFloorFinal = decimalToWholeNumber(
        draft.productYield.stockReturnFloorFinal,
      );
      draft.productYield.stockReturnFloorHigh = decimalToWholeNumber(
        draft.productYield.stockReturnFloorHigh,
      );
      draft.productYield.stockReturnFloorLow = decimalToWholeNumber(
        draft.productYield.stockReturnFloorLow,
      );
      draft.productYield.stockReturnFloorCurrent = decimalToWholeNumber(
        draft.productYield.stockReturnFloorCurrent,
      );
      draft.productYield.stockReturnCapFinal = decimalToWholeNumber(
        draft.productYield.stockReturnCapFinal,
      );
      draft.productYield.stockReturnCapHigh = decimalToWholeNumber(
        draft.productYield.stockReturnCapHigh,
      );
      draft.productYield.stockReturnCapLow = decimalToWholeNumber(
        draft.productYield.stockReturnCapLow,
      );
      draft.productYield.stockReturnCapCurrent = decimalToWholeNumber(
        draft.productYield.stockReturnCapCurrent,
      );
      draft.productGrowth.upsideParticipationRateFinal = decimalToWholeNumber(
        draft.productGrowth.upsideParticipationRateFinal,
      );
      draft.productGrowth.upsideParticipationRateHigh = decimalToWholeNumber(
        draft.productGrowth.upsideParticipationRateHigh,
      );
      draft.productGrowth.upsideParticipationRateLow = decimalToWholeNumber(
        draft.productGrowth.upsideParticipationRateLow,
      );
      draft.productGrowth.upsideParticipationRateCurrent = decimalToWholeNumber(
        draft.productGrowth.upsideParticipationRateCurrent,
      );
      draft.productGrowth.underlierReturnCapLevelFinal = decimalToWholeNumber(
        draft.productGrowth.underlierReturnCapLevelFinal,
      );
      draft.productGrowth.underlierReturnCapLevelHigh = decimalToWholeNumber(
        draft.productGrowth.underlierReturnCapLevelHigh,
      );
      draft.productGrowth.underlierReturnCapLevelLow = decimalToWholeNumber(
        draft.productGrowth.underlierReturnCapLevelLow,
      );
      draft.productGrowth.underlierReturnCapLevelCurrent = decimalToWholeNumber(
        draft.productGrowth.underlierReturnCapLevelCurrent,
      );
      draft.productGrowth.maximumReturnFinal = decimalToWholeNumber(
        draft.productGrowth.maximumReturnFinal,
      );
      draft.productGrowth.maximumReturnHigh = decimalToWholeNumber(
        draft.productGrowth.maximumReturnHigh,
      );
      draft.productGrowth.maximumReturnLow = decimalToWholeNumber(
        draft.productGrowth.maximumReturnLow,
      );
      draft.productGrowth.maximumReturnCurrent = decimalToWholeNumber(
        draft.productGrowth.maximumReturnCurrent,
      );
      draft.productGrowth.digitalReturnBarrierFinal = decimalToWholeNumber(
        draft.productGrowth.digitalReturnBarrierFinal,
      );
      draft.productGrowth.digitalReturnBarrierHigh = decimalToWholeNumber(
        draft.productGrowth.digitalReturnBarrierHigh,
      );
      draft.productGrowth.digitalReturnBarrierLow = decimalToWholeNumber(
        draft.productGrowth.digitalReturnBarrierLow,
      );
      draft.productGrowth.digitalReturnBarrierCurrent = decimalToWholeNumber(
        draft.productGrowth.digitalReturnBarrierCurrent,
      );
      draft.productGrowth.digitalReturnFinal = decimalToWholeNumber(
        draft.productGrowth.digitalReturnFinal,
      );
      draft.productGrowth.digitalReturnHigh = decimalToWholeNumber(
        draft.productGrowth.digitalReturnHigh,
      );
      draft.productGrowth.digitalReturnLow = decimalToWholeNumber(
        draft.productGrowth.digitalReturnLow,
      );
      draft.productGrowth.digitalReturnCurrent = decimalToWholeNumber(
        draft.productGrowth.digitalReturnCurrent,
      );
      draft.productGrowth.absoluteReturnBarrierLevelHigh = decimalToWholeNumber(
        draft.productGrowth.absoluteReturnBarrierLevelHigh,
      );
      draft.productGrowth.absoluteReturnBarrierLevelLow = decimalToWholeNumber(
        draft.productGrowth.absoluteReturnBarrierLevelLow,
      );
      draft.productGrowth.absoluteReturnBarrierLevelCurrent =
        decimalToWholeNumber(
          draft.productGrowth.absoluteReturnBarrierLevelCurrent,
        );
      draft.productGrowth.absoluteReturnBarrierLevelFinal =
        decimalToWholeNumber(
          draft.productGrowth.absoluteReturnBarrierLevelFinal,
        );
      draft.productGrowth.upsideParticipationAboveDigitalReturnFinal =
        decimalToWholeNumber(
          draft.productGrowth.upsideParticipationAboveDigitalReturnFinal,
        );
      draft.productGrowth.upsideParticipationAboveDigitalReturnHigh =
        decimalToWholeNumber(
          draft.productGrowth.upsideParticipationAboveDigitalReturnHigh,
        );
      draft.productGrowth.upsideParticipationAboveDigitalReturnLow =
        decimalToWholeNumber(
          draft.productGrowth.upsideParticipationAboveDigitalReturnLow,
        );
      draft.productGrowth.upsideParticipationAboveDigitalReturnCurrent =
        decimalToWholeNumber(
          draft.productGrowth.upsideParticipationAboveDigitalReturnCurrent,
        );
      draft.productGrowth.absoluteReturnParticipationRateFinal =
        decimalToWholeNumber(
          draft.productGrowth.absoluteReturnParticipationRateFinal,
        );
      draft.productGrowth.absoluteReturnParticipationRateHigh =
        decimalToWholeNumber(
          draft.productGrowth.absoluteReturnParticipationRateHigh,
        );
      draft.productGrowth.absoluteReturnParticipationRateLow =
        decimalToWholeNumber(
          draft.productGrowth.absoluteReturnParticipationRateLow,
        );
      draft.productGrowth.absoluteReturnParticipationRateCurrent =
        decimalToWholeNumber(
          draft.productGrowth.absoluteReturnParticipationRateCurrent,
        );
      draft.productProtection.putLeverageFinal = decimalToWholeNumber(
        draft.productProtection.putLeverageFinal,
      );
      draft.productProtection.putLeverageHigh = decimalToWholeNumber(
        draft.productProtection.putLeverageHigh,
      );
      draft.productProtection.putLeverageLow = decimalToWholeNumber(
        draft.productProtection.putLeverageLow,
      );
      draft.productProtection.putLeverageCurrent = decimalToWholeNumber(
        draft.productProtection.putLeverageCurrent,
      );
      draft.productProtection.capitalProtectionLevelHigh = decimalToWholeNumber(
        draft.productProtection.capitalProtectionLevelHigh,
      );
      draft.productProtection.capitalProtectionLevelLow = decimalToWholeNumber(
        draft.productProtection.capitalProtectionLevelLow,
      );
      draft.productProtection.capitalProtectionLevelCurrent =
        decimalToWholeNumber(
          draft.productProtection.capitalProtectionLevelCurrent,
        );
      draft.productProtection.capitalProtectionLevelFinal =
        decimalToWholeNumber(
          draft.productProtection.capitalProtectionLevelFinal,
        );
      draft.productProtection.principalBufferLevelFinal = decimalToWholeNumber(
        draft.productProtection.principalBufferLevelFinal,
      );
      draft.productProtection.principalBufferLevelHigh = decimalToWholeNumber(
        draft.productProtection.principalBufferLevelHigh,
      );
      draft.productProtection.principalBufferLevelLow = decimalToWholeNumber(
        draft.productProtection.principalBufferLevelLow,
      );
      draft.productProtection.principalBufferLevelCurrent =
        decimalToWholeNumber(
          draft.productProtection.principalBufferLevelCurrent,
        );
      draft.productProtection.principalBarrierLevelHigh = decimalToWholeNumber(
        draft.productProtection.principalBarrierLevelHigh,
      );
      draft.productProtection.principalBarrierLevelLow = decimalToWholeNumber(
        draft.productProtection.principalBarrierLevelLow,
      );
      draft.productProtection.principalBarrierLevelFinal = decimalToWholeNumber(
        draft.productProtection.principalBarrierLevelFinal,
      );
      draft.productProtection.principalBarrierLevelCurrent =
        decimalToWholeNumber(
          draft.productProtection.principalBarrierLevelCurrent,
        );
      draft.productProtection.putStrikeFinal = decimalToWholeNumber(
        draft.productProtection.putStrikeFinal,
      );
      draft.productProtection.putStrikeCurrent = decimalToWholeNumber(
        draft.productProtection.putStrikeCurrent,
      );
      draft.productProtection.putStrikeLow = decimalToWholeNumber(
        draft.productProtection.putStrikeLow,
      );
      draft.productProtection.putStrikeHigh = decimalToWholeNumber(
        draft.productProtection.putStrikeHigh,
      );

      draft.productGeneral.underlierRankWeightList =
        draft.productGeneral?.underlierRankWeightList?.map((x: any) =>
          decimalToWholeNumber(x),
        ) || [];

      draft.productYield.rateBuilderGrouping =
        draft.productYield.rateBuilderGrouping?.join(', ');
      draft.productYield.rateBuilderReturns =
        draft.productYield.rateBuilderReturns?.join(', ');
      draft.productGeneral.tradeDate = draft.productGeneral.tradeDate
        ? createAccurateDateWithoutTime(draft.productGeneral.tradeDate)
        : null;
      draft.productGeneral.strikeDate = draft.productGeneral.strikeDate
        ? createAccurateDateWithoutTime(draft.productGeneral.strikeDate)
        : null;
      draft.productGeneral.issueDate =
        draft.productGeneral.issueDate || 0
          ? createAccurateDateWithoutTime(draft.productGeneral.issueDate || 0)
          : null;
      draft.productGeneral.finalValuationDate = draft.productGeneral
        .finalValuationDate
        ? createAccurateDateWithoutTime(draft.productGeneral.finalValuationDate)
        : null;
      draft.productGeneral.maturityDate = draft.productGeneral.maturityDate
        ? createAccurateDateWithoutTime(draft.productGeneral.maturityDate)
        : null;
      draft.productGeneral.lookbackInitialDate = draft.productGeneral
        .lookbackInitialDate
        ? createAccurateDateWithoutTime(
            draft.productGeneral.lookbackInitialDate,
          )
        : null;
      draft.productGeneral.lookbackFinalDate = draft.productGeneral
        .lookbackFinalDate
        ? createAccurateDateWithoutTime(draft.productGeneral.lookbackFinalDate)
        : null;
      draft.productGeneral.productDeadlineTimestamp = draft.productGeneral
        .productDeadlineTimestamp
        ? new Date(draft.productGeneral.productDeadlineTimestamp)
        : null;
      draft.productGeneral.productLaunchTimestamp = draft.productGeneral
        .productLaunchTimestamp
        ? new Date(draft.productGeneral.productLaunchTimestamp)
        : null;

      draft.clientSpecific.firstPaymentDate = draft.clientSpecific
        ?.firstPaymentDate
        ? createAccurateDateWithoutTime(draft.clientSpecific.firstPaymentDate)
        : null;
      draft.productCall.expectedMaturity = draft.productCall?.expectedMaturity
        ? createAccurateDateWithoutTime(draft.productCall.expectedMaturity)
        : null;
      draft.productGeneral.averagingInDates = draft.productGeneral
        .averagingInDates
        ? draft.productGeneral.averagingInDates.map((x: any) =>
            x ? createAccurateDateWithoutTime(x) : null,
          )
        : [];
      draft.productGeneral.averagingOutDates = draft.productGeneral
        .averagingOutDates
        ? draft.productGeneral.averagingOutDates.map((x: any) =>
            x ? createAccurateDateWithoutTime(x) : null,
          )
        : [];
      draft.productGeneral.aggregatingDates = draft.productGeneral
        .aggregatingDates
        ? draft.productGeneral.aggregatingDates.map((x: any) =>
            x ? createAccurateDateWithoutTime(x) : null,
          )
        : [];

      draft.productGeneral.reofferClientList = draft.productGeneral
        ?.reofferClientList
        ? draft.productGeneral?.reofferClientList.map((row: any) => {
            return {
              ...row,
              reofferClientRateCurrent: decimalToWholeNumber(
                row.reofferClientRateCurrent,
              ),
              reofferClientRateLow: decimalToWholeNumber(
                row.reofferClientRateLow,
              ),
              reofferClientRateHigh: decimalToWholeNumber(
                row.reofferClientRateHigh,
              ),
              reofferClientRateFinal: decimalToWholeNumber(
                row.reofferClientRateFinal,
              ),
            };
          })
        : [];

      draft.productCln.debtIsinList = draft.productCln?.debtIsinList || [];
      draft.productCln.debtWeightList = draft.productCln?.debtWeightList || [];
      draft.productCln.rateCap = draft.productCln.rateCap
        ? draft.productCln.rateCap?.map((x: any) => {
            return {
              ...x,
              rateCapStartDate: x.rateCapStartDate
                ? createAccurateDateWithoutTime(x.rateCapStartDate)
                : null,
              rateCapEndDate: x.rateCapEndDate
                ? createAccurateDateWithoutTime(x.rateCapEndDate)
                : null,
              rateCap: decimalToWholeNumber(x.rateCap),
            };
          })
        : [];
      draft.productCln.rateMin = draft.productCln.rateMin
        ? draft.productCln.rateMin.map((x: any) => {
            return {
              ...x,
              rateMinStartDate: x.rateMinStartDate
                ? createAccurateDateWithoutTime(x.rateMinStartDate)
                : null,
              rateMinEndDate: x.rateMinEndDate
                ? createAccurateDateWithoutTime(x.rateMinEndDate)
                : null,
              rateMin: decimalToWholeNumber(x.rateMin),
            };
          })
        : [];
      draft.productCln.rateConditionalCoupon = draft.productCln
        .rateConditionalCoupon
        ? draft.productCln.rateConditionalCoupon?.map((x: any) => {
            return {
              ...x,
              rateConditionalCouponStartDate: x.rateConditionalCouponStartDate
                ? createAccurateDateWithoutTime(
                    x.rateConditionalCouponStartDate,
                  )
                : null,
              rateConditionalCouponEndDate: x.rateConditionalCouponEndDate
                ? createAccurateDateWithoutTime(x.rateConditionalCouponEndDate)
                : null,
              rateConditionalCoupon: decimalToWholeNumber(
                x.rateConditionalCoupon,
              ),
            };
          })
        : [];
      draft.productCln.rateBarrierLevel = draft.productCln.rateBarrierLevel
        ? draft.productCln.rateBarrierLevel?.map((x: any) => {
            return {
              ...x,
              rateBarrierLevelStartDate: x.rateBarrierLevelStartDate
                ? createAccurateDateWithoutTime(x.rateBarrierLevelStartDate)
                : null,
              rateBarrierLevelEndDate: x.rateBarrierLevelEndDate
                ? createAccurateDateWithoutTime(x.rateBarrierLevelEndDate)
                : null,
              rateBarrierLevel: decimalToWholeNumber(x.rateBarrierLevel),
            };
          })
        : [];
      // }

      draft.productGeneral.ancillaryFeatures =
        draft.productGeneral.ancillaryFeatures || [];
      if (draft.productYield?.paymentMemory === true) {
        if (
          !draft.productGeneral?.ancillaryFeatures?.includes(
            AncillaryFeature.With_Coupon_Memory,
          )
        ) {
          draft.productGeneral.ancillaryFeatures.push(
            AncillaryFeature.With_Coupon_Memory,
          );
        }
      }

      draft.productYield.variablePayInitialObservationDate = draft.productYield
        .variablePayInitialObservationDate
        ? createAccurateDateWithoutTime(
            draft.productYield.variablePayInitialObservationDate,
          )
        : null;
      draft.productYield.variablePayFinalObservationDate = draft.productYield
        .variablePayFinalObservationDate
        ? createAccurateDateWithoutTime(
            draft.productYield.variablePayFinalObservationDate,
          )
        : null;
      draft.productYield.paymentTypeChangeDate = draft.productYield
        .paymentTypeChangeDate
        ? createAccurateDateWithoutTime(
            draft.productYield.paymentTypeChangeDate,
          )
        : null;
      draft.productYield.leverageFactors = draft.productYield.leverageFactors
        ? draft.productYield.leverageFactors.map((x: any) => {
            return {
              ...x,
              leverageFactorStartDate: x.leverageFactorStartDate
                ? createAccurateDateWithoutTime(x.leverageFactorStartDate)
                : null,
              leverageFactorEndDate: x.leverageFactorEndDate
                ? createAccurateDateWithoutTime(x.leverageFactorEndDate)
                : null,
              leverageFactor: decimalToWholeNumber(x.leverageFactor),
            };
          })
        : [];
      draft.productGrowth.lowerCallStrikeList = draft.productGrowth
        .lowerCallStrikeList
        ? draft.productGrowth.lowerCallStrikeList.map((x: any) => {
            return {
              ...x,
              lowerCallStrikeStartDate: x.lowerCallStrikeStartDate
                ? createAccurateDateWithoutTime(x.lowerCallStrikeStartDate)
                : null,
              lowerCallStrikeEndDate: x.lowerCallStrikeEndDate
                ? createAccurateDateWithoutTime(x.lowerCallStrikeEndDate)
                : null,
              lowerCallStrikeLow: decimalToWholeNumber(x.lowerCallStrikeLow),
              lowerCallStrikeHigh: decimalToWholeNumber(x.lowerCallStrikeHigh),
              lowerCallStrikeFinal: decimalToWholeNumber(
                x.lowerCallStrikeFinal,
              ),
            };
          })
        : [newLowerCallStrike()];

      draft.productYield.cashPoolKnockInVolume = draft.productYield
        .cashPoolKnockInVolume
        ? removeWhiteSpaceFromString(draft.productYield.cashPoolKnockInVolume)
            .split(',')
            ?.map((x) => decimalToWholeNumber(+x))
        : [];

      draft.productYield.cashPoolKnockInLevel = draft.productYield
        .cashPoolKnockInLevel
        ? removeWhiteSpaceFromString(draft.productYield.cashPoolKnockInLevel)
            .split(',')
            ?.map((x) => decimalToWholeNumber(+x))
        : [];

      // todo: probably delete, the mapping happens in the component itself
      draft.productCall.callObservationDateList =
        draft.productCall.callObservationDateList || [];
      draft.productYield.paymentDateList =
        draft.productYield.paymentDateList || [];

      draft.productGeneral.underlierList = incomingUnderlierList(
        draft.productGeneral.underlierList,
      );
      draft.productGrowth.upsideParticipationUnderlierList =
        incomingUnderlierList(
          draft.productGrowth.upsideParticipationUnderlierList,
        );
      draft.productGrowth.lowerCallStrikeUnderlierList = incomingUnderlierList(
        draft.productGrowth.lowerCallStrikeUnderlierList,
      );
      draft.productGrowth.digitalReturnBarrierUnderlierList =
        incomingUnderlierList(
          draft.productGrowth.digitalReturnBarrierUnderlierList,
        );
      draft.productGrowth.absoluteReturnBarrierUnderlierList =
        incomingUnderlierList(
          draft.productGrowth.absoluteReturnBarrierUnderlierList,
        );
      draft.productYield.paymentBarrierUnderlierList = incomingUnderlierList(
        draft.productYield.paymentBarrierUnderlierList,
      );
      draft.productYield.stockReturnCapUnderlierList = incomingUnderlierList(
        draft.productYield.stockReturnCapUnderlierList,
      );
      draft.productYield.stockReturnFloorUnderlierList = incomingUnderlierList(
        draft.productYield.stockReturnFloorUnderlierList,
      );
      draft.productCall.callBarrierLevelUnderlierList = incomingUnderlierList(
        draft.productCall.callBarrierLevelUnderlierList,
      );
      draft.productProtection.putStrikeUnderlierList = incomingUnderlierList(
        draft.productProtection.putStrikeUnderlierList,
      );
      draft.productProtection.principalBarrierLevelUnderlierList =
        incomingUnderlierList(
          draft.productProtection.principalBarrierLevelUnderlierList,
        );
      draft.productProtection.principalBufferLevelUnderlierList =
        incomingUnderlierList(
          draft.productProtection.principalBufferLevelUnderlierList,
        );
      draft.productGrowth.growthType =
        draft.productGrowth.growthType?.length > 0
          ? draft.productGrowth.growthType[0]
          : null; // weird but it's stored as an array

      draft.qcVerified = !!draft.productGeneral.opsReviewStatus;
    } catch (e) {
      console.error('error Mapping Product', e);
      throw e;
    }
  });
};
