import { useQuery } from '@tanstack/react-query';
import { getProduct } from '../../API/product-maintenance.service';
import { PDWProduct } from '../../product-schema/product.schema';
import { useParams } from 'react-router-dom';
import { useMantineColorScheme } from '@mantine/core';

export const fieldsWithConfidenceOver95 = [
  'productYield.paymentDateList.paymentSettlementDate',
  'productGeneral.basketType',
  'productGeneral.finalValuationDate',
  'productGeneral.maturityDate',
  'productGeneral.strikeDate',
  'productProtection.principalBarrierLevelFinal',
];

export const ConfidenceOver95Green = '#86d9a5';
export const ConfidenceOver95GreenDM = '#1a3825';

export const useGetProductByProductId = (
  productId: string | undefined,
): PDWProduct | undefined => {
  const { data: product } = useQuery<{ data: PDWProduct }>(
    ['productData', productId],
    async () => {
      const res = await getProduct(productId?.toUpperCase() || '');
      if (res.status !== 200 || !res.data || !res) {
        throw new Error('Product not found');
      }
      return res as unknown as { data: PDWProduct };
    },
    { enabled: Boolean(productId) },
  );
  return product?.data;
};

export const useIsProductFromLLM = () => {
  const urlParams = useParams();
  const productFromUrl = urlParams?.productId
    ? decodeURIComponent(urlParams?.productId)
    : undefined;
  const product = useGetProductByProductId(productFromUrl);
  return product?.productDataSource === 'LLM Processing';
};

export const useGetConfidenceStyle = (fieldPath: string) => {
  const { colorScheme } = useMantineColorScheme();
  const isFromLLM = useIsProductFromLLM();
  if (!isFromLLM) {
    return {};
  }
  if (fieldsWithConfidenceOver95.includes(fieldPath))
    return {
      backgroundColor:
        colorScheme === 'dark'
          ? ConfidenceOver95GreenDM
          : ConfidenceOver95Green,
    };
  return {};
};
