import React from 'react';
import { useProductFormContext } from '../../../entry-and-edit-contexts/product-form-context';
import { isFieldDisabled, isWeekend } from '../../../../../utilities/utilities';
import { useProductFormDisabled } from '../../../entry-and-edit-contexts/disabled-fields-context';
import { ArrayNumberItem } from '../../../../../components/ArrayNumberItem';
import {
  PFDatePicker,
  PFNumberInput,
} from '../../../reusable-form-component/BaseFormComponents';
import { IconTrash } from '@tabler/icons-react';
import AutoAnimateChildren from '../../../../../components/AutoAnimateChildren';
import { Button } from '@mantine/core';

const RateBarrierLevelsComponent = () => {
  const form = useProductFormContext();
  const disabledContext = useProductFormDisabled();
  const disableFields = isFieldDisabled(
    disabledContext,
    'productCln.rateBarrierLevel',
  );
  const addRateBarrierLevel = () => {
    form.insertListItem(
      'productCln.rateBarrierLevel',
      {
        rateBarrierLevel: undefined,
        rateBarrierLevelStartDate: undefined,
        rateBarrierLevelEndDate: undefined,
      },
      0,
    );
  };
  const removeRateBarrierLevel = (index: number) => {
    form.removeListItem('productCln.rateBarrierLevel', index);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: '1em',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: 0 }}>Rate Barrier Levels</h3>
        <Button
          variant="primary"
          type={'button'}
          disabled={disableFields}
          onClick={addRateBarrierLevel}
        >
          Add Rate Barrier Level
        </Button>
      </div>
      <AutoAnimateChildren>
        {form.values.productCln.rateBarrierLevel?.map(
          (rateBarrierLevel, index) => (
            <div className={'array-entry-section-container'} key={index}>
              <div className={'array-entry-section'}>
                <ArrayNumberItem>{index + 1}.</ArrayNumberItem>
                <PFNumberInput
                  decimalScale={6}
                  label="Rate Barrier"
                  rightSection={<div>%</div>}
                  disabled={disableFields}
                  fieldPath={`productCln.rateBarrierLevel.${index}.rateBarrierLevel`}
                ></PFNumberInput>

                <PFDatePicker
                  placeholder="MM/DD/YYYY"
                  label="Start Date"
                  excludeDate={isWeekend}
                  disabled={disableFields}
                  fieldPath={`productCln.rateBarrierLevel.${index}.rateBarrierLevelStartDate`}
                />

                <PFDatePicker
                  label="End Date"
                  excludeDate={isWeekend}
                  disabled={disableFields}
                  fieldPath={`productCln.rateBarrierLevel.${index}.rateBarrierLevelEndDate`}
                />

                <Button
                  variant={'secondary-small'}
                  type={'button'}
                  disabled={disableFields}
                  onClick={() => removeRateBarrierLevel(index)}
                >
                  <IconTrash size={18}></IconTrash>
                </Button>
              </div>
            </div>
          ),
        )}
      </AutoAnimateChildren>
    </>
  );
};

export default RateBarrierLevelsComponent;
