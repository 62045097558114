export const ProductCompareFieldsBlackList: string[] = [
  'id',
  'version',
  'createTimestamp',
  'createdByUserId',
  'accumulatorDecumulator',
  'merged',
  'revision',
  'startTimestamp',
  'productId',
  'productDataSource',
  'previousStage',
  'previousStatus',
  'templateSelection',
];

export const ProductCopyFieldsBlackList: string[] = [
  ...ProductCompareFieldsBlackList,
  'qcVerified',
  'productGeneral.cusip',
  'productGeneral.isin',
  'productGeneral.fundservID',
  'productGeneral.tenorFinal',
  'productGeneral.tenorCurrent',
  'productGeneral.tenorLow',
  'productGeneral.tenorHigh',
  'productGeneral.productNotionalFinal',
  'productGeneral.productNotionalCurrent',
  'productGeneral.productNotionalLow',
  'productGeneral.productNotionalHigh',
  'productGeneral.estimatedInitialValue',
  'productGeneral.tradeDate',
  'productGeneral.strikeDate',
  'productGeneral.issueDate',
  'productGeneral.finalValuationDate',
  'productGeneral.maturityDate',
];
