import { Underlier } from '../../../product-schema/underlier.schema';
import { Grid, Button } from '@mantine/core';
import { KeyValue } from '../../../components/KeyValueCard';

interface ResponseDataProps {
  responseData: Underlier;
}

const handleDownload = (responseData: Underlier) => {
  const dataStr = JSON.stringify(responseData);
  const dataUri =
    'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
  let exportFileDefaultName = `${responseData.ticker}.json`;
  let linkElement = document.createElement('a');
  linkElement.setAttribute('href', dataUri);
  linkElement.setAttribute('download', exportFileDefaultName);
  linkElement.click();
};

const ResponseCard = ({ responseData }: ResponseDataProps) => {
  return (
    <div>
      <h2>Underlier Added</h2>
      <Grid gutter="md">
        {Object.entries(responseData).map(
          ([key, value]) =>
            value !== null &&
            value !== undefined && (
              <KeyValue key={key} label={key} value={value} />
            ),
        )}
      </Grid>
      <Button
        variant={'secondary'}
        style={{ width: '100%', marginTop: '1.5rem' }}
        onClick={() => handleDownload(responseData)}
      >
        Download
      </Button>
    </div>
  );
};

export default ResponseCard;
