import { ForwardedRef, forwardRef } from 'react';
import {
  PFNumberInput,
  PFSelect,
} from '../reusable-form-component/BaseFormComponents';
import { optionDetailsFields } from '../../../product-schema/pdw-field-subsets';
import {
  OptionTypes,
  OptionObservationTypes,
} from '../../../product-schema/pdw-select-options';

const OptionDetailsFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={'form-section'} ref={ref}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            gap: '1em',
            width: '100%',
          }}
        >
          <PFSelect
            label="Option Type"
            data={[...Object.values(OptionTypes)]}
            fieldPath={optionDetailsFields.optionType}
          />
          <PFSelect
            label="Option Observation Type"
            data={[...Object.values(OptionObservationTypes)]}
            fieldPath={optionDetailsFields.optionObservationType}
          />
          <PFNumberInput
            decimalScale={6}
            label="Option Strike Level"
            fieldPath={optionDetailsFields.optionStrikeLevel}
          />
          <PFNumberInput
            decimalScale={6}
            label="Option Number Of Shares"
            fieldPath={optionDetailsFields.optionNumberOfShares}
          />
        </div>
      </div>
    );
  },
);

OptionDetailsFormComponent.displayName = 'OptionDetailsFormComponent';
export default OptionDetailsFormComponent;
