import React from 'react';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { AncillaryFeature } from '../../../../product-schema/pdw-select-options';
import WithAggregatingComponent from './With-aggregating';
import WithLookbackInitialComponent from './With-lookback-initial';
import WithLookbackFinalComponent from './With-lookback-final';
import WithMinimumReturnComponent from './With-minimum-return';
import WithAverageInComponent from './With-average-in';
import WithAverageOutComponent from './With-average-out';
import WithSupplementalComponent from './With-supplemental';
import WithKnockOutComponent from './With-knock-out';
import { Divider } from '@mantine/core';

const AncillaryFeatureSpecificsComponent = () => {
  const form = useProductFormContext();

  const renderFeature = (feature: AncillaryFeature) => {
    switch (feature) {
      case AncillaryFeature.With_Aggregating:
        return <WithAggregatingComponent></WithAggregatingComponent>;
      case AncillaryFeature.With_Lookback_Initial:
        return <WithLookbackInitialComponent></WithLookbackInitialComponent>;
      case AncillaryFeature.With_Lookback_Final:
        return <WithLookbackFinalComponent></WithLookbackFinalComponent>;
      case AncillaryFeature.With_Minimum_Return:
        return <WithMinimumReturnComponent></WithMinimumReturnComponent>;
      case AncillaryFeature.With_Modified_Averaging_In: // fallthrough
        return (
          <WithAverageInComponent showExclusion={true}></WithAverageInComponent>
        );
      case AncillaryFeature.With_Averaging_In:
        return (
          <WithAverageInComponent
            showExclusion={false}
          ></WithAverageInComponent>
        );
      case AncillaryFeature.With_Averaging_Out:
        return (
          <WithAverageOutComponent
            showExclusion={false}
          ></WithAverageOutComponent>
        );
      case AncillaryFeature.With_Modified_Averaging_Out:
        return (
          <WithAverageOutComponent
            showExclusion={true}
          ></WithAverageOutComponent>
        );
      case AncillaryFeature.With_Knock_Out:
        return <WithKnockOutComponent></WithKnockOutComponent>;
      case AncillaryFeature.With_Supplemental:
        return <WithSupplementalComponent></WithSupplementalComponent>;
    }
  };

  //question: do we want to add '(To determine Initial Value)' type hints?
  // question: when we go to save, do we want to filter out ancillary feature values based on what's checked && what has values
  return (
    <>
      {(
        (form.values.productGeneral?.ancillaryFeatures ||
          []) as AncillaryFeature[]
      )?.map((feature: AncillaryFeature) => (
        <div key={feature}>
          {renderFeature(feature)}
          {feature !== AncillaryFeature.With_Coupon_Memory && // ..vv..
            feature !== AncillaryFeature.With_Minimum_Coupon && // these properties don't cause a form, no divider needed
            feature !==
              form.values.productGeneral?.ancillaryFeatures?.[
                (form.values.productGeneral.ancillaryFeatures?.length || 1) - 1
              ] && (
              <Divider style={{ margin: '2em 1em', width: '90%' }}></Divider>
            )}
        </div>
      ))}
    </>
  );
};

export default AncillaryFeatureSpecificsComponent;
