import {
  PDW_SERVICE,
  PDW_SERVICE_STAGED,
  PRODUCT_MAINTENANCE_ROOT,
} from './api-consts';
import { PDWProduct } from '../product-schema/product.schema';
import axios from 'axios';
import { removeNestedNulls } from '../utilities/utilities';

// PDW_SERVICE

export interface PDWRuleValidationErrorResponse {
  violations: PDWRuleValidationError[];
}

export interface PDWRuleValidationError {
  fieldName: string;
  message: string;
  rejectedValue?: any | any[];
}

// export const validatePDWProduct = (product: PDWProduct) => axios.post(`${PDW_SERVICE}/products/validate`, product);
export const validatePDWProduct = (product: Partial<PDWProduct>) => {
  const productFormSubmit = removeNestedNulls(product);
  return axios.post<PDWRuleValidationErrorResponse>(
    `${PDW_SERVICE}/v2/products/validate`,
    productFormSubmit,
  );
};

export const savePDWProduct = (product: PDWProduct) => {
  const productForSubmit = removeNestedNulls(product);
  return axios.post(`${PDW_SERVICE}/v2/products`, productForSubmit);
};

export const updatePDWProduct = (product: PDWProduct) => {
  const productForSubmit = removeNestedNulls(product);
  return axios.put(`${PRODUCT_MAINTENANCE_ROOT}/v2/products`, productForSubmit);
};

export const getStagedPDWProduct = (productId: string) =>
  axios.get(`${PDW_SERVICE_STAGED}/${productId}`);
export const getUnmergedStagedPDWProducts = () =>
  axios.get(`${PDW_SERVICE_STAGED}/unmerged/all`);
export const setStagedProductAsMerged = (productId: string) =>
  axios.put(`${PDW_SERVICE_STAGED}/merged/${productId}`);

export const getUsersRecentlyModifiedProducts = (
  userName: string,
  limit?: number,
) =>
  axios.get(
    `${PDW_SERVICE}/v2/products/last-interacted?username=${userName}&limit=${limit}`,
  );

// validateProduct(product) {
//   return this.http.post(`${this.PDW_ROOT}/products/validate`, product);
// }

// this.pdwService.saveProduct(this.formGroupValueToPDWProduct(results, 'Ops Review', 'Review Required')).subscribe(() => {
