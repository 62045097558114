import React, { useContext, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import { PFTextInput } from '../../reusable-form-component/BaseFormComponents';
import { getPdwProductByCusipOrIsin } from '../../../../API/product-data-warehouse.service';
import { EditModeContext } from '../../entry-and-edit-contexts/edit-mode-context';
import { useAuth } from '../../../../components/Authentication/AuthProvider';

const CusipEntry = () => {
  const form = useProductFormContext();
  const editMode = useContext(EditModeContext);

  // needed to ensure token before we make the endpoint call
  const { token } = useAuth();

  useEffect(() => {
    if (
      token &&
      !editMode &&
      form.values.productGeneral.cusip &&
      form.values.productGeneral.cusip.length === 9
    ) {
      setTimeout(() => {
        getProductByCusip.mutate(form.values.productGeneral.cusip);
      });
    }
    form.validateField('productGeneral.isin');
  }, [form.values.productGeneral.cusip, token]); // eslint-disable-line react-hooks/exhaustive-deps

  const getProductByCusip: any = useMutation(
    (cusip: string) => getPdwProductByCusipOrIsin(cusip),
    {
      onSuccess: (res) => {
        // if a cusip exists set an error on the form
        form.setFieldError('productGeneral.cusip', 'CUSIP Already Taken');
      },
      onError: (err) => {
        // cusip wasn't found if 404
        // todo: we can handle 500 errors by offering a retry potentially, might not be worth getting into though.
      },
    },
  );

  const checkCusip = async (event: any) => {
    if (event.target?.value && event.target.value.length === 9 && !editMode) {
      getProductByCusip.mutate(event.target.value);
    } else {
      return;
    }
  };

  const { onBlur: formOnBlur } = form.getInputProps('productGeneral.cusip');
  const onCusipBlur = async (event: any) => {
    await formOnBlur(event);
    await checkCusip(event);
  };

  return (
    <PFTextInput
      label="CUSIP"
      fieldPath={'productGeneral.cusip'}
      onBlur={onCusipBlur}
    />
  );
};

export default CusipEntry;
