import React, { ForwardedRef, forwardRef, useContext } from 'react';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { Button, Divider } from '@mantine/core';
import {
  ExpandableNumberFieldset,
  PFNumberInput,
  PFTextInput,
} from '../reusable-form-component/BaseFormComponents';
import { useProductFormDisabled } from '../entry-and-edit-contexts/disabled-fields-context';
import { isFieldDisabled } from '../../../utilities/utilities';
import { ArrayNumberItem } from '../../../components/ArrayNumberItem';
import { ProductFormHiddenFieldsContext } from '../entry-and-edit-contexts/HiddenFields-context';
import { IconTrash } from '@tabler/icons-react';
import AutoAnimateChildren from '../../../components/AutoAnimateChildren';
import { feeDetailsFields } from '../../../product-schema/pdw-field-subsets';

const FeeDetailsFormComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const form = useProductFormContext();
    const disabledContext = useProductFormDisabled();
    const disableFields = isFieldDisabled(
      disabledContext,
      feeDetailsFields.reofferClientList,
    );
    const hiddenFieldsContext = useContext(ProductFormHiddenFieldsContext);

    const addReofferRow = () => {
      if (disableFields) {
        return;
      }
      form.insertListItem(
        feeDetailsFields.reofferClientList,
        {
          reofferClientName: undefined,
          reofferClientRateFinal: undefined,
          reofferClientRateCurrent: undefined,
          reofferClientRateLow: undefined,
          reofferClientRateHigh: undefined,
        },
        0,
      );
      console.log('form', form.values.productGeneral.reofferClientList);
    };
    const removeReofferRow = (index: number) => {
      if (disableFields) {
        return;
      }
      form.removeListItem(feeDetailsFields.reofferClientList, index);
    };

    const reofferListSection =
      !hiddenFieldsContext.hiddenFields.includes('reofferClientList') &&
      form.values.productGeneral.reofferClientList?.map((reOffer, index) => (
        <div className={'array-entry-section-container'} key={index}>
          <div className={'array-entry-section'}>
            <ArrayNumberItem>{index + 1}.</ArrayNumberItem>
            <PFTextInput
              disabled={disableFields}
              label={'Client'}
              fieldPath={`${feeDetailsFields.reofferClientList}.${index}.reofferClientName`}
            />
            <div style={{ display: 'flex', flexFlow: 'row wrap', gap: '1em' }}>
              <PFNumberInput
                decimalScale={6}
                rightSection={<div>%</div>}
                label="Re-Offer Rate (Final)"
                disabled={disableFields}
                fieldPath={`${feeDetailsFields.reofferClientList}.${index}.reofferClientRateFinal`}
              ></PFNumberInput>

              <PFNumberInput
                decimalScale={6}
                rightSection={<div>%</div>}
                label="(High)"
                disabled={disableFields}
                fieldPath={`${feeDetailsFields.reofferClientList}.${index}.reofferClientRateHigh`}
              ></PFNumberInput>

              <PFNumberInput
                decimalScale={6}
                rightSection={<div>%</div>}
                label="(Low)"
                disabled={disableFields}
                fieldPath={`${feeDetailsFields.reofferClientList}.${index}.reofferClientRateLow`}
              ></PFNumberInput>

              <PFNumberInput
                decimalScale={6}
                rightSection={<div>%</div>}
                label="(Current)"
                disabled={disableFields}
                fieldPath={`${feeDetailsFields.reofferClientList}.${index}.reofferClientRateCurrent`}
              ></PFNumberInput>
            </div>
            <Button
              variant={'secondary-small'}
              type={'button'}
              disabled={disableFields}
              onClick={() => removeReofferRow(index)}
            >
              <IconTrash size={18}></IconTrash>
            </Button>
          </div>
        </div>
      ));

    return (
      <div className={'form-section'} ref={ref}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            gap: '1em',
            alignItems: 'center',
          }}
        >
          <h3 style={{ margin: 0 }}>Re-Offer Rates</h3>
          <Button
            variant="primary"
            type={'button'}
            disabled={disableFields}
            onClick={addReofferRow}
          >
            Add Re-Offer Rate
          </Button>
        </div>

        <AutoAnimateChildren
          style={{ width: '100%' }}
          disable={
            (form.values.productGeneral.reofferClientList?.length || 1) < 2
          }
        >
          {reofferListSection}
        </AutoAnimateChildren>
        <Divider style={{ margin: '2em 1em', width: '90%' }}></Divider>
        <div className={'form-subsection'}>
          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="Structuring Fee"
            fieldPath={feeDetailsFields.structureFee}
          ></PFNumberInput>

          <PFNumberInput
            decimalScale={6}
            rightSection={<div>%</div>}
            label="Standard Price"
            fieldPath={feeDetailsFields.standardPrice}
          ></PFNumberInput>

          <ExpandableNumberFieldset
            primaryField={{
              property: feeDetailsFields.salesCreditFinal,
              label: 'Sales Credit',
            }}
            subFields={[
              {
                property: feeDetailsFields.salesCreditHigh,
                label: 'High Sales Credit',
              },
              {
                property: feeDetailsFields.salesCreditLow,
                label: 'Low Sales Credit',
              },
              {
                property: feeDetailsFields.salesCreditCurrent,
                label: 'Current Sales Credit',
              },
            ]}
          />
        </div>
      </div>
    );
  },
);
FeeDetailsFormComponent.displayName = 'FeeDetailsFormComponent';
export default FeeDetailsFormComponent;
