import classes from './documentsWidget.module.scss';
import { openDocumentBlob } from '../../utilities/utilities';
import { IconFileDescription } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { getDocumentByUrl } from '../../API/document.service';
import { colors } from '../../Styles/colors';

export interface DocumentObject {
  name: string;
  url: string;
}

interface DocumentsWidgetProps {
  documents: DocumentObject[];
}

const DocumentsWidget = ({ documents }: DocumentsWidgetProps) => {
  const getDocument = useMutation((url: string) => getDocumentByUrl(url), {
    onSuccess: (doc) => {
      openDocumentBlob(doc.data);
    },
    onError: (error) => {
      console.error('error downloading document', error);
    },
  });
  if (!documents || documents.length === 0) {
    return (
      <div style={{ fontSize: '1em', textAlign: 'center', padding: '1em' }}>
        No documents
      </div>
    );
  }

  return (
    <div className={classes.documentContainer}>
      {documents.map((document) => (
        <div
          key={document.url}
          className={classes.documentRow}
          style={{
            pointerEvents: getDocument.isLoading ? 'none' : 'auto',
            opacity: getDocument.isLoading ? '.6' : '1',
            cursor: getDocument.isLoading ? 'not-allowed' : 'pointer',
          }}
          onClick={() => getDocument.mutate(document.url)}
        >
          <IconFileDescription size={20} />
          <div>{document.name}</div>
          <>
            {getDocument.error && getDocument.variables === document.url && (
              <span
                style={{
                  padding: '0 1em',
                  color: colors['error-red'],
                  fontSize: '.9rem',
                }}
              >
                *Error Getting Document
              </span>
            )}
          </>
        </div>
      ))}
    </div>
  );
};

export default DocumentsWidget;
