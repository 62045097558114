import { read, utils, writeFile } from 'xlsx';
import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Modal,
  rem,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
} from '@mantine/core';
import { useState } from 'react';
import { IconFileText, IconUpload, IconX } from '@tabler/icons-react';
import { Dropzone, FileWithPath, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone';
import { useProductFormContext } from '../../entry-and-edit-contexts/product-form-context';
import {
  dateListReducerFunction,
  DateRow,
  DateRowRadioOptions,
  emptyCallDateObj,
  emptyCouponDateObj,
  getCallObservationDateList,
  getPaymentDateList,
} from './CalendarDates';
import { getJsDateFromExcel } from '../../../../utilities/utilities';
import { modals } from '@mantine/modals';

type ExcelFileHeaders =
  | 'Coupon Observation Dates'
  | 'Coupon Settlement Dates'
  | 'Call Observation Dates'
  | 'Call Settlement Dates';

export const CalendarDateExcelUpload = () => {
  const form = useProductFormContext();
  const [file, setFile] = useState<FileWithPath | null>(null);
  const { colorScheme } = useMantineColorScheme();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const handelDownloadTemplate = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.book_append_sheet(wb, ws, 'Calendar Dates');
    const headers: ExcelFileHeaders[] = [
      'Coupon Observation Dates',
      'Coupon Settlement Dates',
      'Call Observation Dates',
      'Call Settlement Dates',
    ];
    utils.sheet_add_json(ws, [], {
      // utils.sheet_add_json(ws, [{...headers}], {
      header: headers,
      skipHeader: false,
    });
    ws['!cols'] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];
    writeFile(wb, 'CalendarDatesTemplate.xlsx');
  };

  const handleFileDrop = (acceptedFile: FileWithPath[]) => {
    setFile(acceptedFile[0]);
  };

  const handleFileSubmit = async () => {
    if (file) {
      try {
        const fileBytes = await file.arrayBuffer();
        const wb = read(fileBytes);
        const data: Record<ExcelFileHeaders, string>[] = utils.sheet_to_json(
          wb.Sheets[wb.SheetNames[0]],
        );
        const callDateList: DateRow[] = [];
        const couponDateList: DateRow[] = [];
        for (const row of data) {
          const callObservationDate = row['Call Observation Dates'];
          const callSettlementDate = row['Call Settlement Dates'];
          const couponObservationDate = row['Coupon Observation Dates'];
          const couponSettlementDate = row['Coupon Settlement Dates'];

          if (callObservationDate || callSettlementDate) {
            callDateList.push({
              ...emptyCallDateObj,
              dateType: DateRowRadioOptions.CALL,
              observationDate: getJsDateFromExcel(+callObservationDate),
              settlementDate: getJsDateFromExcel(+callSettlementDate),
            });
          }
          if (couponObservationDate || couponSettlementDate) {
            couponDateList.push({
              ...emptyCouponDateObj,
              dateType: DateRowRadioOptions.COUPON,
              observationDate: getJsDateFromExcel(+couponObservationDate),
              settlementDate: getJsDateFromExcel(+couponSettlementDate),
            });
          }
        }

        const dateRowsToAdd: DateRow[] = [
          ...couponDateList,
          ...callDateList,
        ].reduce(dateListReducerFunction, []);
        console.log('dateRowsToAdd', dateRowsToAdd);

        modals.openConfirmModal({
          title: 'Confirm dates: This will overwrite any existing dates',
          labels: { confirm: 'Confirm', cancel: 'Cancel' },
          children: (
            <Group>
              <Table striped withTableBorder>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Coupon Settlement</Table.Th>
                    <Table.Th>Coupon Observation</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {couponDateList.map((row) => (
                    <Table.Tr key={row.id}>
                      <Table.Td>
                        {new Intl.DateTimeFormat('en-US').format(
                          row?.settlementDate || undefined,
                        )}
                      </Table.Td>
                      <Table.Td>
                        {new Intl.DateTimeFormat('en-US').format(
                          row?.observationDate || undefined,
                        )}
                        {/*{row?.observationDate?.toLocaleString()}*/}
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>

              <Table striped withTableBorder>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Call Settlement</Table.Th>
                    <Table.Th>Call Observation</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {callDateList.map((row) => (
                    <Table.Tr key={row.id}>
                      <Table.Td>
                        {new Intl.DateTimeFormat('en-US').format(
                          row?.settlementDate || undefined,
                        )}
                      </Table.Td>
                      <Table.Td>
                        {new Intl.DateTimeFormat('en-US').format(
                          row?.observationDate || undefined,
                        )}
                        {/*{row?.observationDate?.toLocaleString()}*/}
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </Group>
          ),
          onConfirm: () => {
            form.setFieldValue(
              'productYield.paymentDateList',
              getPaymentDateList(dateRowsToAdd) as any[],
            );
            form.setFieldValue(
              'productCall.callObservationDateList',
              getCallObservationDateList(dateRowsToAdd) as any[],
            );
            setIsModalOpened(false);
          },
        });
      } catch (e) {
        console.error('error reading file', e);
      }
    }
  };

  return (
    <>
      <Tooltip label={'Upload Excel File'} position={'bottom'}>
        <ActionIcon
          variant={'transparent'}
          onClick={() => setIsModalOpened(true)}
          size={'md'}
        >
          <IconFileText size={40} />
        </ActionIcon>
      </Tooltip>

      <Modal
        opened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        title={'Upload Excel File'}
      >
        <Button pl={0} onClick={handelDownloadTemplate} variant={'transparent'}>
          Download Template
        </Button>
        {(form.values?.productGeneral?.cusip ||
          form.values?.productGeneral?.isin) && (
          <Anchor
            href={`https://www.sec.gov/edgar/search/#/q=${form.values?.productGeneral?.cusip || form.values?.productGeneral?.isin}`}
            target="_blank"
          >
            View on SEC
          </Anchor>
        )}
        <p>
          Upload an excel file with dates formatted as MM/DD/YY
          <br /> ex: <strong>05/11/24</strong>
        </p>

        <Dropzone
          onDrop={handleFileDrop}
          multiple={false}
          style={{ cursor: 'pointer' }}
          radius="md"
          bg={colorScheme === 'dark' ? 'gray.8' : 'gray.1'}
          accept={MS_EXCEL_MIME_TYPE}
        >
          <Stack
            justify={'center'}
            align="center"
            gap="xl"
            mih={220}
            style={{ pointerEvents: 'none' }}
          >
            <Dropzone.Accept>
              <IconUpload
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: 'var(--mantine-color-blue-6)',
                }}
                stroke={1.5}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: 'var(--mantine-color-red-6)',
                }}
                stroke={1.5}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <>
                {file ? (
                  <>
                    <Title order={3}>{file.name}</Title>
                    <Text>
                      Last Modified:{' '}
                      {new Date(file.lastModified)?.toLocaleDateString(
                        'en-US',
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        },
                      )}
                    </Text>
                    <Text>Size: {file.size} bytes</Text>
                  </>
                ) : (
                  <IconFileText
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: 'var(--mantine-color-dimmed)',
                    }}
                    stroke={1.5}
                  />
                )}
              </>
            </Dropzone.Idle>

            {!file && (
              <div>
                <Text size="md" inline>
                  Drag Excel Date File or Click To Select File
                </Text>
                {/*<Text size="sm" c="dimmed" inline mt={7}>*/}
                {/*  Attach as many files as you like, each file should not exceed 5mb*/}
                {/*</Text>*/}
              </div>
            )}
          </Stack>
        </Dropzone>

        <Button
          fullWidth={true}
          mt={'xl'}
          disabled={!file}
          onClick={handleFileSubmit}
          size={'sm'}
        >
          Submit File
        </Button>
      </Modal>
    </>
  );
};
