import React, { ForwardedRef, forwardRef } from 'react';
import {
  countries,
  listings,
  offeringTypes,
  QuotingType,
  targetMarkets,
} from '../../../product-schema/pdw-select-options';
import {
  PFSelect,
  PFMultiSelect,
} from '../reusable-form-component/BaseFormComponents';
import { preferenceFields } from '../../../product-schema/pdw-field-subsets';

const ProductPreferencesComponent = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={'form-section'} ref={ref}>
        <div className={'form-subsection'}>
          <PFSelect
            label="Offering"
            data={offeringTypes}
            fieldPath={preferenceFields.offeringType}
          />

          <PFMultiSelect
            label="Country Distribution"
            data={countries}
            fieldPath={preferenceFields.countryDistribution}
          ></PFMultiSelect>

          <PFSelect
            label="Listing"
            data={listings}
            fieldPath={preferenceFields.listing}
          />
        </div>
        <div className={'form-subsection'}>
          <PFSelect
            label="Quoting Type"
            data={[...Object.values(QuotingType)]}
            fieldPath={preferenceFields.secondaryMarketQuotingType}
          />

          <PFSelect
            label="Target Markets"
            data={targetMarkets}
            fieldPath={preferenceFields.targetMarket}
          />
        </div>
      </div>
    );
  },
);

ProductPreferencesComponent.displayName = 'ProductPreferencesComponent';
export default ProductPreferencesComponent;
