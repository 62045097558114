import React, { useState } from 'react';
import {
  LoadingOverlay,
  TextInput,
  Text,
  Button,
  Blockquote,
  Code,
} from '@mantine/core';
import { searchFactsetUnderliers } from '../../../API/market-data-maintenance.service';
import { Underlier } from '../../../product-schema/underlier.schema';
import { useMutation } from '@tanstack/react-query';
import FactsetUnderlierItem from './FactsetUnderlierItem';
import { IconInfoCircle } from '@tabler/icons-react';

interface UnderlierSearchFactsetProps {
  onUnderlierAdded: () => void;
  setFactsetSelectedUnderlier: React.Dispatch<
    React.SetStateAction<Underlier | null>
  >;
}

const UnderlierSearchFactset = ({
  onUnderlierAdded,
  setFactsetSelectedUnderlier,
}: UnderlierSearchFactsetProps) => {
  const [factSetSearchTicker, setFactSetSearchTicker] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  const { data: factsetResponse, ...searchFactset } = useMutation(
    () => searchFactsetUnderliers([factSetSearchTicker]),
    {
      onSuccess: (res) => {
        if (res.data?.length === 0) {
          setErrorMessage('No results found');
        } else {
          setErrorMessage('');
        }
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 401) {
          setErrorMessage('Underlier not found in factset database.');
        } else {
          setErrorMessage('An error occurred while searching.');
        }
      },
    },
  );

  const handleSearch = () => {
    searchFactset.mutate();
  };

  return (
    <div style={{ margin: '1rem' }}>
      <LoadingOverlay
        visible={searchFactset.isLoading}
        overlayProps={{ blur: 1 }}
      />
      <Blockquote color="blue" icon={<IconInfoCircle />} mt="xl">
        A bloomberg ticker follows the format:
        <br></br>
        <Code>&lt;ticker&gt; &lt;bloomberg exchange code&gt;</Code>
        <br></br>
        Example:
        <br></br>
        <Code>MSFT UW</Code>
      </Blockquote>
      <TextInput
        value={factSetSearchTicker}
        placeholder={'Enter Bloomberg ticker, ex. AAPL UW'}
        onChange={(event) => setFactSetSearchTicker(event.currentTarget.value)}
        style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}
      />
      {errorMessage && (
        <Text ta={'center'} style={{ color: 'red', weight: 600 }}>
          {errorMessage}
        </Text>
      )}
      <Button
        disabled={factSetSearchTicker?.length === 0}
        variant="primary-full"
        onClick={handleSearch}
      >
        Search Factset Database
      </Button>

      {factsetResponse?.data?.length > 0 &&
        factsetResponse?.data.map((underlier: Underlier, index: number) => (
          <FactsetUnderlierItem
            key={
              underlier?.ticker ||
              index + (underlier?.underlierId || '' + index)
            }
            underlier={underlier}
            onUnderlierAdded={onUnderlierAdded}
            setSelectedUnderlier={setFactsetSelectedUnderlier}
          />
        ))}
    </div>
  );
};

export default UnderlierSearchFactset;
