import React from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Text,
  TextInput,
  Checkbox,
  Select,
  LoadingOverlay,
  Grid,
  Button,
} from '@mantine/core';
import { updateUnderlier as updateUnderlierService } from '../../../API/market-data-maintenance.service';
import {
  Underlier,
  assetTypeOptions,
  uneditableFields,
  underlierCurrencyCodes,
} from '../../../product-schema/underlier.schema';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

interface UnderlierFormProps {
  initialValues: Underlier;
  setSelectedUnderlier: (underlier: Underlier | null) => void;
  setIsEditing: (editing: boolean) => void;
}

const UnderlierFormUpdate = ({
  setSelectedUnderlier,
  initialValues,
  setIsEditing,
}: UnderlierFormProps) => {
  const form = useForm<Underlier>({
    initialValues: initialValues,
  });
  const submitUnderlierEdit = useMutation(
    (newValues: Underlier) => updateUnderlierService(newValues),
    {
      onSuccess: (res) => {
        setSelectedUnderlier(res.data);
        notifications.show({
          id: 'underlier-update',
          title: `Underlier Updated`,
          message: 'Successfully updated underlier!',
          color: 'green',
          withCloseButton: true,
          autoClose: 10000,
        });
        setIsEditing(false);
      },
      onError: () => {
        notifications.show({
          id: 'underlier-update',
          title: 'Error',
          message: 'Error updating underlier.',
          color: 'red',
          withCloseButton: true,
          autoClose: 10000,
        });
      },
    },
  );

  const handleFormSubmit = () => {
    submitUnderlierEdit.mutate(form.values);
  };

  return (
    <div>
      <LoadingOverlay
        visible={submitUnderlierEdit.isLoading}
        overlayProps={{ blur: 1 }}
      />
      {submitUnderlierEdit.isSuccess && (
        <Text>Changes saved successfully!</Text>
      )}
      <form>
        {Object.keys(initialValues).map((key) => {
          if (!uneditableFields.includes(key)) {
            const isBoolean =
              typeof initialValues[key as keyof Underlier] === 'boolean';
            if (isBoolean) {
              return (
                <Checkbox
                  style={{ marginTop: '1rem' }}
                  key={key}
                  label={key}
                  checked={form.values[key as keyof Underlier] as boolean}
                  onChange={(event) =>
                    form.setFieldValue(key, event.currentTarget.checked)
                  }
                />
              );
            } else if (key === 'assetType') {
              return (
                <Select
                  key={key}
                  label={key}
                  value={form.values[key as keyof Underlier] as string}
                  data={assetTypeOptions}
                  onChange={(value) => form.setFieldValue(key, value)}
                />
              );
            } else if (key === 'currency') {
              return (
                <Grid key={key}>
                  <Grid.Col style={{ marginTop: 10 }} span={12}>
                    <Select
                      key={key}
                      label={key}
                      data={underlierCurrencyCodes}
                      value={form.values[key as keyof Underlier] as string}
                      onChange={(value) => form.setFieldValue(key, value)}
                    />
                  </Grid.Col>
                </Grid>
              );
            } else {
              return (
                <TextInput
                  style={{ marginTop: '1rem' }}
                  key={key}
                  label={key}
                  value={form.values[key as keyof Underlier] as string}
                  onChange={(event) =>
                    form.setFieldValue(key, event.currentTarget.value)
                  }
                />
              );
            }
          }
          return null;
        })}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            gap: '2rem',
            marginTop: '1.5rem',
          }}
        >
          <Button variant={'secondary'} onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
          <Button variant="primary" type="button" onClick={handleFormSubmit}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UnderlierFormUpdate;
