import React from 'react';
import { Button, Paper, Text } from '@mantine/core';

type MissingDataTableProps = {
  data: any[];
  handleTickerClick: (underlier: any) => void;
};

const MissingDataTable = ({
  data,
  handleTickerClick,
}: MissingDataTableProps) => (
  <Paper p="md" style={{ overflowX: 'auto' }}>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ borderBottom: '2px solid #e0e0e0' }}>
          <th style={{ padding: '10px 15px' }}>
            <Text fw={700}>Bloomberg Ticker</Text>
          </th>
          <th style={{ padding: '10px 15px' }}>
            <Text fw={700}>Ticker</Text>
          </th>
          <th style={{ padding: '10px 15px' }}>
            <Text fw={700}>Security Name</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr
            key={index}
            style={{ borderBottom: '1px solid #e0e0e0', cursor: 'pointer' }}
          >
            <td style={{ padding: '10px 15px' }}>
              <Button
                variant="primary"
                onClick={() => handleTickerClick(item.underlier)}
              >
                {item.underlier?.bloombergTicker}
              </Button>
            </td>
            <td style={{ padding: '10px 15px' }}>
              <Text>{item.underlier?.ticker}</Text>
            </td>
            <td style={{ padding: '10px 15px' }}>
              <Text>{item.underlier?.securityName}</Text>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </Paper>
);

export default MissingDataTable;
