export const colors = {
  primaryBlue: '#22465f',
  primaryGreen: '#4c645e',
  primaryGreenLight: '#c3dbc7',
  'dark-secondary-btn': '#8e8d8c',
  'dark-secondary-btn-hover': '#64615d',
  'dark-primary-btn-hover': '#343b5e',
  'neutral-gray-1': '#b9b9b9de',
  'graphing-blue-gray-1': '#435560',
  'luma-half-baked': '#93c3d5',
  'graphing-luma-selective-yellow-1': '#876708',
  'graphing-luma-selective-yellow-2': '#ae850a',
  'darkening-background': 'rgba(0, 0, 0, 0.75)',
  'modal--background-overlay': 'rgba(0, 0, 0, 0.7)',
  'graphing-blue-gray-2': '#546a78',
  'graphing-luma-selective-yellow-4': '#f2bb17',
  'confirm-green': '#46b11d',
  'graphing-luma-daintree-2': '#185677',
  'graphing-luma-half-baked-3': '#4792ae',
  'text-grey': '#333538',
  'graphing-luma-selective-yellow-3': '#d4a20c',
  'graphing-luma-daintree-4': '#2585b9',
  'tag-grey': '#eff2f4',
  'graphing-luma-yellow-green-5': '#aac743',
  'grid-blue-2': '#d9dfe2',
  'tag-blue': '#b6d6e2',
  'hover-grey': '#dadada',
  'disabled-grey': '#e8e8e8',
  'graphing-luma-daintree-3': '#1e6e99',
  'luma-orange': '#ff961c',
  'grid-blue-1': '#7f929e',
  'graphing-luma-yellow-green-2': '#5d6e21',
  'selected-grey': '#bbb',
  'graphing-luma-selective-yellow-5': '#f4c63e',
  'graphing-luma-yellow-green-3': '#788d2a',
  'error-red': '#af2a2a',
  'link-blue': '#2a86d1',
  'luma-jet': '#292929',
  'medium-grey': '#717171',
  'graphing-blue-gray-3': '#647f90',
  'disabled-text-grey': '#868686',
  'luma-yellow-green': '#c9de7d',
  'graphing-luma-yellow-green-1': '#434f17',
  'input-field-grey': '#cdcdcd',
  'entry-field-grey': '#f1f1f1',
  'graphing-luma-yellow-green-4': '#93ae34',
  'graphing-blue-gray-4': '#7a92a2',
  'tag-green': '#d7e79d',
  'graphing-luma-half-baked-4': '#5fa5be',
  'graphing-luma-half-baked-5': '#7db5ca',
  'graphing-blue-gray-5': '#93a7b4',
  'button-hover': '#4594b0',
  'luma-daintree': '#00263e',
  'graphing-luma-daintree-5': '#339dd7',
  'graphing-luma-daintree-1': '#113d55',
  'grid-blue-3': '#bfc9cf',
  'graphing-luma-half-baked-1': '#2f6274',
  'luma-selective-yellow': '#f5b901',
  'light-nav-blue': '#2B577D',
  white: '#fff',
  'graphing-luma-half-baked-2': '#3b7a91',
  'cta-tabs-fill-active': '#cbe880',
  'product-information-product-section-background-hover': '#d2d2d2',
  'platform-navigation-regular-user-admin-deselected': '#033956',
  'product-information-product-section-background-active': '#e5e5e5',
  // secondaries colors
  blue: '#00263E',
  blueMedium: '#003252',
  blueDark: '#001929',
  blueDarker: '#000D14',
  blueLight: '#003F66',
  blueLighter: '#004B7A',
  yellow: '#F5B901',
  yellowMedium: '#FFB005',
  yellowDark: '#FFA312',
  yellowDarker: '#FF961C',
  yellowLight: '#FEC10B',
  yellowLighter: '#FED133',
  green: '#C9DE7D',
  greenMedium: '#BCD65C',
  greenDark: '#B4D14D',
  greenDarker: '#A4C431',
  greenLight: '#D0E28D',
  greenLighter: '#D7E79D',
  ocean: '#93C3D5',
  ocean35: '#93C3D559',
  oceanMedium: '#6DADC5',
  oceanDark: '#4F9EBA',
  oceanDarker: '#4594B0',
  oceanLight: '#89BED1',
  oceanLighter: '#B6D6E2',
  orange: '#FFB901',
  black: '#000000',
  grey: '#292929',
  greyMedium: '#666666',
  greyDark: '#333333',
  greyDarker: '#0A0A0A',
  greyLight: '#999999',
  greyLight75: '#999999BF',
  greyLighter: '#DBDBDB',
  greyLightest: '#C2C2C2',
  greyFade: '#EFF2F4',
  failRed: '#E7A39A',
  messageWarn: '#E7D29A',
  messageInfo: '#93C3D5',
  successGreen: '#D1E19A',
  redDarker: '#C10000',
  limeGreen: '#39D86B',
  red: '#D83232',
  //luma dashboard colors
  accent10: '#faffe3',
  accent20: '#eff9c3',
  accent30: '#e1f0a2',
  accent40: '#cee180',
  accent50: '#aac743',
  accent60: '#95ab3c',
  accent70: '#708420',
  accent80: '#4a5a0c',
  accent90: '#273201',
  negative10: '#efacad',
  negative20: '#d95d5e',
  negative30: '#961b1e',
  negative40: '#5c0b0b',
  negative60: '#961b1e',
  neutral00white: '#ffffff',
  neutral10: '#f2f4f5',
  neutral20: '#e7ecee',
  neutral30: '#d8dfe2',
  neutral40: '#c4cdd1',
  neutral50: '#a9b3b8',
  neutral60: '#858f93',
  neutral70: '#5a6368',
  neutral75: '#454b4e',
  neutral80: '#2f3336',
  neutral85: '#1a1d1f',
  neutral90: '#070708',
  positive20: '#9fcd5a',
  positive10: '#d5e6aa',
  positive30: '#65a343',
  positive40: '#314c22',
  positive60: '#65a343',
  primary10: '#e0f4ff',
  primary20: '#a0dcf8',
  primary30: '#61c0ed',
  primary40: '#26a0dc',
  primary50: '#007dc2',
  primary60: '#02629d',
  primary70: '#004570',
  primary75: '#292929',
  primary80Daintree: '#00263e',
  primary90: '#00090f',
  warning10: '#f7e78a',
  warning20: '#dfcb22',
  warning30: '#a99e34',
  warning40: '#5a5624',
  warning60: '#a99e34',
  boxShadow: '#00000026',
  negativeUnderlierTicker: '#b62a27',
  positiveUnderlierTicker: '#82c241',

  lightThemeEntrySections: '#c7dee6de',
  darkThemeAddition: '#0b680b',
  darkThemeModification: 'rgb(72 65 28 / 61%)',
  darkThemeDeletion: '#701d1d',
  darkThemeNewValue: '#585e3b',
  lightThemeAddition: '#b3ec99',
  lightThemeModification: 'rgb(217 217 148 / 60%)',
  lightThemeDeletion: '#ffb7b7',
  lightThemeNewValue: '#c3c36b',
} as const;
