import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  EventStatusEnum,
  EventTypes,
  getEventsByProductId,
  EventCoreRecord,
} from '../../API/event-service';

export const useIsProductCalled = (productId: string | undefined) => {
  const urlParams = useParams();
  const productFromUrl = urlParams?.productId
    ? decodeURIComponent(urlParams?.productId)
    : undefined;
  const getEvents = useQuery({
    queryKey: ['getProductEvents', productFromUrl],
    queryFn: productFromUrl
      ? () => getEventsByProductId(productFromUrl)
      : undefined,
    enabled: !!productFromUrl,
  });
  if (!productId) {
    return false;
  }
  return hasProductBeenCalled(getEvents.data?.data);
};
export const hasProductBeenCalled = (events: EventCoreRecord[] | undefined) => {
  if (!events) {
    return false;
  }
  return events.some((event: EventCoreRecord) => {
    return (
      event.eventStatus === EventStatusEnum.CONFIRMED &&
      (event.eventType === EventTypes.AUTO_CALL ||
        event.eventType === EventTypes.ISSUER_CALL)
    );
  });
};
