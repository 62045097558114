import ReactJson from '@microlink/react-json-view';
import { Button, Tooltip, useMantineColorScheme } from '@mantine/core';
import { ProductCompareFieldsBlackList } from './JsonCompareBlackLists';
import { useEffect, useRef } from 'react';

interface JsonViewAndEditProps {
  productJson: any;
  applyChangesCallback: (value: any) => void;
}

export const JsonViewAndEdit = ({
  productJson,
  applyChangesCallback,
}: JsonViewAndEditProps) => {
  const originalProductJSON = useRef(null);
  useEffect(() => {
    originalProductJSON.current = productJson;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUndoChanges = () => {
    applyChangesCallback(originalProductJSON.current);
  };

  const { colorScheme } = useMantineColorScheme();
  const handleJsonEdit = (edit: any) => {
    if (ProductCompareFieldsBlackList.includes(edit.name)) {
      return false;
    } else {
      // handle empty string: convert them to nulls
      if (edit.new_value === '') {
        if (edit.namespace.length === 0) {
          edit.updated_src[edit.name] = null;
        } else {
          let temp;
          for (const field of edit.namespace) {
            temp = temp ? temp[field] : edit.updated_src[field];
          }
          temp[edit.name] = null;
        }
      }
      applyChangesCallback(edit.updated_src);
    }
  };
  return (
    <div className={'react-json-scale-svg'}>
      <div
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '40px',
          zIndex: 1000,
        }}
      >
        <Tooltip
          label={
            'Undo changes made while in JSON edit mode. Changes before edit should be unaffected.'
          }
        >
          <Button onClick={handleUndoChanges}>Undo Changes</Button>
        </Tooltip>
      </div>
      <ReactJson
        src={productJson}
        theme={colorScheme === 'dark' ? 'chalk' : 'summerfruit:inverted'}
        quotesOnKeys={false}
        onEdit={handleJsonEdit}
        validationMessage={
          'The field you are trying to modify is not editable.'
        }
      />
    </div>
  );
};
