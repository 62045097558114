import axios from 'axios';
import { USER_SERVICE_ROOT } from './api-consts';

export interface TrainingCourse {
  id: string;
  coursePackage: string; // na
  courseId: string;
  courseName: string; // required
  status: string | boolean;
  trainingType: string;
  publisher: string; // required
  carrierCode: string;
  startDate: string | null | undefined | Date; // date
  endDate: string | null | undefined | Date; // date
  createDate: string | null | undefined | Date; // na
  alias: string;
}

export const getPublishers = () =>
  axios.get<string[]>(`${USER_SERVICE_ROOT}/useraccess/publishers`);
export const getCoursesViaPost = (publishers: string[]) =>
  axios.post<TrainingCourse[]>(
    `${USER_SERVICE_ROOT}/useraccess/courselist`,
    publishers,
  );
export const saveCourses = (courses: TrainingCourse[]) =>
  axios.post(`${USER_SERVICE_ROOT}/useraccess/courses?all=true`, courses);
