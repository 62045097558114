import axios from 'axios';
import { MARKET_DATA_SERVICE } from './api-consts';
import { Underlier } from '../product-schema/underlier.schema';

export interface GetUnderlierPricesRequest {
  fsymIds?: string[];
  bloombergTickers?: string[];
  underlierIds?: string[];
  startDate: string | null | undefined; // ex: "2023-01-01",
  endDate: string | null | undefined; // ex: "2023-01-01",
  priceType: string | 'FINAL'; // ex: "FINAL"
}

export interface GetUnderlierPricesResponseObject {
  metadata: {
    underlierId: string;
    fsymId: string;
    bloombergTicker: string;
  };
  marketDateTimestamp: string; // ex: "2019-08-24T14:15:22Z",
  priceType: string | 'FINAL';
  price: number;
  open: number;
  high: number;
  low: number;
  vwap: number;
  marketCap: number;
  dailyVolume: number;
  turnover: number;
  tradeCount: number;
  source: string | 'Factset';
}

export const getUnderlierPrices = (payload: GetUnderlierPricesRequest) =>
  axios.post<GetUnderlierPricesResponseObject[]>(
    `${MARKET_DATA_SERVICE}/market-data/prices`,
    payload,
  );

export const getUnderlier = ({
  bloombergTickers,
  rics,
}: {
  bloombergTickers: string[];
  rics: string[];
}) =>
  axios.post<Underlier[]>(`${MARKET_DATA_SERVICE}/underlier/getunderliers`, {
    bloombergTickers,
    rics,
  });

// to get a single price by id / bloomberg ticker / fsymId
// export const getUnderlierPrice = (id: string) => axios.get<GetUnderlierPricesResponseObject>(`${MARKET_DATA_SERVICE}/market-data/prices/` + id);
