import { CustomCellRendererProps } from 'ag-grid-react';
import { Badge } from '@mantine/core';

type UnderlierPillsCellRendererProps = CustomCellRendererProps<any> & {
  tickers: string[];
  activeTicker?: string;
};

export const UnderlierPillsCellRenderer = (
  params: UnderlierPillsCellRendererProps,
) => {
  if ((params.tickers?.length || 0) === 0) {
    return null;
  }

  return (
    <div style={{ padding: '0.25rem' }}>
      {params.activeTicker && (
        <Badge
          style={{
            display: 'inline-block',
            marginRight: '0.5rem',
          }}
          radius={'sm'}
          color={'green'}
        >
          {params.activeTicker}
        </Badge>
      )}
      {params.tickers
        ?.filter((x: string) => x !== params.activeTicker)
        .map((ticker, i) => (
          <Badge
            style={{
              display: 'inline-block',
              marginRight: '0.5rem',
            }}
            key={i}
            radius={'sm'}
            color={params.activeTicker === ticker ? 'green' : 'gray'}
          >
            {ticker}
          </Badge>
        ))}
    </div>
  );
};
