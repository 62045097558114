import {
  ObservationFrequencies,
  RegistrationTypes,
  TenorUnits,
  WrapperTypes,
} from '../product-schema/pdw-select-options';
import { PDWProduct } from '../product-schema/product.schema';
import { get, values } from 'lodash';
import { ProductFormDisabledContextProps } from '../pages/product-entry-and-edit/entry-and-edit-contexts/disabled-fields-context';
import dayjs from 'dayjs';
import { getDocumentByUrl } from '../API/document.service';

import { parseISO, format, addMinutes } from 'date-fns';

export const convertTenorToMonths = (
  tenor: number | undefined | null,
  tenorUnit: TenorUnits | undefined | null,
): number | null => {
  if (!tenor || !tenorUnit) {
    return null;
  }
  if (tenorUnit === TenorUnits.Years) {
    return Math.ceil(tenor * 12);
  } else if (tenorUnit === TenorUnits.Weeks) {
    return Math.ceil(tenor / 4.33);
  } else {
    // TenorUnits.Months fallthrough
    return tenor;
  }
};

export const getRegistrationType = (productData: PDWProduct) => {
  const wrapperType = productData.productGeneral.wrapperType;
  if (wrapperType === WrapperTypes.CD) {
    return 'Certificate of Deposit';
  } else if (wrapperType === WrapperTypes.MARKET_LINKED_TRUST) {
    return RegistrationTypes.REGISTERED_NOTE;
  } else if (wrapperType === WrapperTypes.NOTE) {
    const regType = productData.productGeneral.registrationType;
    if (
      regType === RegistrationTypes.REGISTERED_NOTE ||
      regType === RegistrationTypes.PRIVATE_PLACEMENT
    ) {
      return RegistrationTypes.REGISTERED_NOTE;
    } else if (regType === RegistrationTypes.REG_S) {
      return 'Reg S Note';
    } else if (regType === RegistrationTypes.THREE_A_TWO) {
      return '3a2 Exempt';
    }
  }
  return productData.productGeneral.registrationType;
};

export const roundToNearest = (numToRound: number, numToRoundTo: number) => {
  numToRoundTo = 1 / numToRoundTo;
  return Math.round(numToRound * numToRoundTo) / numToRoundTo;
};

export const isWeekday = (date: Date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

export const isWeekend = (date: Date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

export const isFieldDisabled = (
  disabledContext: ProductFormDisabledContextProps,
  prop?: string,
) => {
  if (!prop) {
    return disabledContext.formDisabledInfo.formIsDisabled;
  }
  return disabledContext.formDisabledInfo.formIsDisabled
    ? !disabledContext.formDisabledInfo.enabledFields?.includes(prop)
    : false;
};

export const combineArraysUnique = (uniqueArray: any[], values: any[]) => {
  return [...Array.from(new Set([...uniqueArray, ...values]))];
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function mmddyyyyDate(date: Date | null | undefined) {
  if (!date) {
    return null;
  } else {
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear()
    );
  }
}

export const camelCaseToWords = (
  s: string | null | undefined,
): string | null | undefined => {
  if (s == null) {
    return s;
  }
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

// default returns YYYY-MM-DD
export function formatDate(
  date: string | Date | null | undefined,
  separator = '-',
) {
  if (!date || !(date instanceof Date)) {
    return date;
  }

  const returnDate = [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join(separator);
  return returnDate === 'Invalid date' ? null : returnDate;
}

export const addBusinessDaysToDate = (
  startingDate: Date,
  daysToAdd: number | undefined | null,
  holidayDates?: string[],
) => {
  let finalDate = dayjs(formatDate(startingDate));
  let daysLeftToAdd = daysToAdd || 0;
  while (daysLeftToAdd > 0) {
    finalDate = finalDate.add(1, 'days');
    if (!isDateWeekendOrHoliday(finalDate.toDate(), holidayDates)) {
      daysLeftToAdd--;
    }
  }
  return finalDate.toDate();
};

export function formatISOStyleDateTime(
  date: Date | null | undefined,
  separator = '-',
) {
  if (!date || !(date instanceof Date)) {
    return date;
  }
  const returnDate =
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join(separator) +
    'T' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':');
  return returnDate === 'Invalid date' ? null : returnDate;
}

export const isDateWeekendOrHoliday = (
  date: Date,
  holidays: string[] | null | undefined,
) => {
  if (isWeekend(date)) {
    return true;
  }
  return !!(holidays && holidays.includes(formatDate(date) || 'notFound'));
};

export function isEmptyObject(obj: any): boolean {
  // return values(obj).every(x => !x || x?.length === 0);
  return values(obj).every(
    (x) =>
      !x ||
      (Array.isArray(x) &&
        (x.length === 0 || x.every((y: any) => isEmptyObject(y)))),
  );
}

export const filterEmptyObjects = (x: any) => {
  return !isEmptyObject(x);
};

/**
 * Returns a new object with the same keys as `object`, but with the values
 * produced by running each value through `mapFn`.
 * @param object The object to map
 * @param mapFn The function that produces an element of the new object from an element of the old object.
 */
export const objectMap = (object: any, mapFn: any) => {
  return Object.keys(object).reduce((result: any, key: any) => {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
};

export const removeNestedNulls = (obj: any) => {
  return JSON.parse(JSON.stringify(obj), (key, value: any) => {
    if (
      value === null ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    )
      return undefined;
    return value;
  });
};
// use this to keep undefined values when saving json
export const jsonReplacerKeepUndefined = (key: any, value: any) =>
  typeof value === 'undefined' ? null : value;

// converts nulls to undefined if needed
// export const jsonReviverNullToUndefined = (key: any, value: any) => {
//   if (value === null) {
//     return undefined;
//   }
//   return value;
// }

// pass in date in the format of '2022-12-30' and it will format it when creating a new date to avoid 'day off by 1' issues...
// thanks javascript dates 😭
export const createAccurateDateWithoutTime = (
  dateString: string | null | undefined,
) => {
  if (!dateString) {
    return null;
  }
  return new Date(dateString.replaceAll('-', '/'));
};

export const getJsDateFromExcel = (excelDate: number): Date | null => {
  return new Date(Date.UTC(0, 0, excelDate));
};

export const scrollIntoViewWithOffset = (
  element: HTMLElement,
  yOffset: number,
) => {
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
};

export const removeWhiteSpaceFromString = (str: string) => {
  if (!str) {
    return str;
  }
  return str.replace(/\s/g, '');
};

export const getCookie = (cookieName: string) => {
  let name = cookieName + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getFrequencyNum = (payPeriod: ObservationFrequencies): number => {
  switch (payPeriod) {
    case ObservationFrequencies.BI_MONTHLY: {
      return 24;
    }
    case ObservationFrequencies.BI_WEEKLY: {
      return 26;
    }
    case ObservationFrequencies.MONTHLY: {
      return 12;
    }
    case ObservationFrequencies.QUARTERLY: {
      return 4;
    }
    case ObservationFrequencies.SEMI_ANNUALLY: {
      return 2;
    }
    case ObservationFrequencies.WEEKLY: {
      return 52;
    }
    default: {
      return 1;
    }
  }
};

export const openDocumentByUrl = (url: string) => {
  getDocumentByUrl(url)
    .then((doc: any) => {
      openDocumentBlob(doc.data);
    })
    .catch((err: any) => {
      console.error('error downloading document', err);
    });
};

export const openDocumentBlob = (
  doc: any,
  downloadName: string | null = null,
) => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.rel = 'noreferrer';
  link.href = window.URL.createObjectURL(doc);
  if (downloadName) {
    link.download = downloadName;
  }
  link.click();
  window.URL.revokeObjectURL(link.href);
};

export const formSectionHasValues = ({
  formValues,
  sectionFormFields,
}: {
  formValues: any;
  sectionFormFields: string[] | undefined;
}): boolean => {
  return formValues && sectionFormFields
    ? sectionFormFields.some((x: string) => {
        const val = get(formValues, x);
        return (
          val !== undefined &&
          val !== false &&
          val !== null &&
          val !== '' &&
          (!Array.isArray(val) || val.filter(filterEmptyObjects).length !== 0)
        );
      })
    : false;
};

export const formSectionHasInvalidValues = ({
  formErrors,
  sectionFormFields,
}: {
  formErrors: any;
  sectionFormFields: string[] | undefined;
}) => {
  return formErrors && sectionFormFields
    ? Object.keys(formErrors).filter((x) =>
        sectionFormFields?.some((y) => {
          // console.log('what is a replace?', x.replaceAll(/[0-9].*/g, ''));
          if (x.includes(y)) {
            return true;
          }
          return x.replaceAll(/[0-9].*/g, '').includes(y);
        }),
      )
    : [];
};

export const toScreamingSnakeCase = (str: string | undefined | null) => {
  if (!str) {
    return str;
  }
  return str.toUpperCase().replace(/ /g, '_');
};

export const replaceEmptyStringWithNull = (value: any) => {
  if (value === '') {
    return null;
  }
  return value;
};

export const isNullOrEmptyString = (value: any) => {
  return value == null || value === '';
};

export const getTimeZoneAbbreviation = (date: Date) => {
  try {
    const timeZonePart = new Intl.DateTimeFormat('en-US', {
      timeZoneName: 'short',
    })
      .formatToParts(date)
      .find((part) => part.type === 'timeZoneName');

    return timeZonePart ? timeZonePart.value : '';
  } catch (error) {
    console.error(
      'Error occurred while getting time zone abbreviation:',
      error,
    );
    return '';
  }
};

export const convertToTimezone = (date: string) => {
  try {
    const formattedDate = parseISO(date.endsWith('Z') ? date : `${date}Z`);
    const timeZoneOffset = new Date().getTimezoneOffset();
    const localDate = addMinutes(formattedDate, -timeZoneOffset);
    const formattedDateTime = format(localDate, 'h:mm a');
    const formattedDateOnly = format(localDate, 'dd-MMM-yyyy');
    const timeZoneAbbreviation = getTimeZoneAbbreviation(localDate);

    return `${formattedDateTime} ${timeZoneAbbreviation} on ${formattedDateOnly}`;
  } catch (error) {
    console.error('Error occurred while formatting date and time:', error);
    return 'Invalid date';
  }
};

export const quickFilterCommaParser = (quickFilter: string) => {
  return quickFilter.split(',').map((x) => x.trim());
};
