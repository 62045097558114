import axios from 'axios';
import { PRODUCT_METRIC_ROOT } from './api-consts';

export interface UnderlierHolidayPayload {
  bloombergTickers: string[];
  rics: string[];
}

export const getUnderlierHolidays = (holidayPayload: UnderlierHolidayPayload) =>
  axios.post(`${PRODUCT_METRIC_ROOT}/holidays/tickers`, holidayPayload);
