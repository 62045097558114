import {
  formSectionHasInvalidValues,
  formSectionHasValues,
  scrollIntoViewWithOffset,
} from '../../../utilities/utilities';
import React, { useMemo } from 'react';
import { FormSectionProperties } from '../form-section.types';
import {
  Badge,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useProductFormContext } from '../entry-and-edit-contexts/product-form-context';
import { IconSortAscending } from '@tabler/icons-react';
import classes from './productFormTableOfContents.module.scss';

interface ProductFormTableOfContentsProps {
  formSectionAttributes: FormSectionProperties[];
  formSectionHandlers: any;
  allExpanded: boolean;
  onExpandButtonClick: () => void;
  handleMoveActiveSectionsToTop: () => void;
}

export const ProductFormTableOfContents = ({
  formSectionAttributes,
  formSectionHandlers,
  allExpanded,
  onExpandButtonClick,
  handleMoveActiveSectionsToTop,
}: ProductFormTableOfContentsProps) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const form = useProductFormContext();

  const sectionsWithValues = useMemo(() => {
    const sectionsWithValues: string[] = [];
    formSectionAttributes.forEach((x) => {
      if (
        formSectionHasValues({
          formValues: form.values,
          sectionFormFields: x.formFields,
        })
      ) {
        sectionsWithValues.push(x.id);
      }
    });
    return sectionsWithValues;
  }, [form.values, formSectionAttributes]);

  const invalidSections: string[] = useMemo(() => {
    const sectionsWithErrors: string[] = [];
    formSectionAttributes.forEach((x) => {
      if (
        formSectionHasInvalidValues({
          formErrors: form.errors,
          sectionFormFields: x.formFields,
        })?.length > 0
      ) {
        sectionsWithErrors.push(x.id);
      }
    });
    return sectionsWithErrors;
  }, [form.errors, formSectionAttributes]);

  const scrollToSection = (sectionId: string, focus: boolean) => {
    const el = document.getElementById(sectionId);
    if (el) {
      // this is to deal with the fact that the form sections are not expanded when the scrolling happens
      if (focus) {
        formSectionHandlers.setState(
          formSectionAttributes.map((x) => {
            return {
              ...x,
              expanded: false,
            };
          }),
        );
      }

      setTimeout(() => {
        scrollIntoViewWithOffset(el, -110);
        formSectionHandlers.setState(
          formSectionAttributes.map((x) => {
            return x.id === sectionId
              ? {
                  ...x,
                  expanded: true,
                }
              : { ...x, expanded: focus ? false : x.expanded };
          }),
        );
      });
    }
  };

  const getLeftBorder = (sectionId: string) => {
    if (invalidSections.includes(sectionId)) {
      return colorScheme === 'dark'
        ? `5px solid ${theme.colors.red[8]}`
        : `5px solid ${theme.colors.red[5]}`;
    } else if (sectionsWithValues.includes(sectionId)) {
      return colorScheme === 'dark'
        ? `5px solid ${theme.colors.blue[8]}`
        : `5px solid ${theme.colors.blue[6]}`;
    } else {
      return colorScheme === 'dark'
        ? `5px solid ${theme.colors.gray[7]}`
        : `5px solid ${theme.colors.gray[4]}`;
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4 style={{ margin: '0.5rem' }}>Table Of Contents</h4>
          <Tooltip
            label={'Move Active Sections To Top'}
            position={'bottom'}
            withArrow
            openDelay={2000}
          >
            <IconSortAscending
              size={38}
              style={{ padding: '0.5rem', cursor: 'pointer' }}
              onClick={handleMoveActiveSectionsToTop}
            />
          </Tooltip>
        </div>

        <Badge
          style={{ cursor: 'pointer', marginRight: '1rem' }}
          variant={'outline'}
          radius={'sm'}
          onClick={onExpandButtonClick}
        >
          {allExpanded ? 'Collapse All' : 'Expand All'}
        </Badge>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          paddingLeft: '1em',
          marginLeft: '1em',
          // borderLeft: theme.colorScheme === 'dark' ? `3px solid ${theme.colors.blue[8]}` : `3px solid ${theme.colors.blue[6]}`,
          marginBottom: '1em',
        }}
      >
        {formSectionAttributes?.map((section) => (
          <div
            key={section.id}
            className={classes.tableOfContentsLink}
            onClick={(e) => {
              e.preventDefault();
              scrollToSection(section.id, false);
            }}
          >
            <div
              style={{
                paddingLeft: '1em',
                borderLeft: getLeftBorder(section.id),
              }}
            >
              {section.label}
            </div>

            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                scrollToSection(section.id, true);
              }}
              className={classes.tableOfContestsFocusButton}
            >
              Focus
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
