import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  AUTH_SERVICE_ROOT,
  oauth_client_id,
  webclient,
} from '../../API/api-consts';
import { useLocalStorage } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { LoadingOverlay } from '@mantine/core';
import { colors } from '../../Styles/colors';
import { isLocal } from '../../enviroments/.env';
import { logout, removeRMT, rmtApiCall } from '../../API/auth-service';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { notifications } from '@mantine/notifications';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export const LOGIN_PATH = '/login';

const AuthContext = React.createContext<any>(null);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const getFullJWTResponse = (accessToken: string) => {
  if (!accessToken) {
    return null;
  }
  return axios.get(`${AUTH_SERVICE_ROOT}/token-info/user`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      withCredentials: true,
    },
  });
};

// sets up an api call for the rmt token

const loginApiCall = (userName: string, password: string) => {
  if (!userName || !password) {
    return Promise.reject('Missing Credentials');
  }

  datadogRum.setUser({
    id: userName,
    email: userName,
  });

  datadogLogs.setUser({
    id: userName,
    email: userName,
  });

  return axios.post(
    `${AUTH_SERVICE_ROOT}/oauth/token`,
    { username: userName, password: password },
    {
      params: {
        grant_type: 'password',
        client_id: oauth_client_id,
        scope: webclient,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    },
  );
};

export const refreshAccessToken = (refreshToken: string) => {
  delete axios.defaults.headers.common['Authorization'];
  return axios.post(
    `${AUTH_SERVICE_ROOT}/oauth/token?grant_type=refresh_token&client_id=${oauth_client_id}`,
    `grant_type=refresh_token&refresh_token=${refreshToken.replace(/['"]+/g, '')}`,
  );
};

export const setupRefreshTokenHandler = (
  refreshToken: string,
  navigate: NavigateFunction,
) => {
  if (!refreshToken) {
    return; // maybe navigate to login? maybe not
  }
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalConfig = error.config;
      originalConfig!.headers = { ...originalConfig!.headers };
      console.log('interceptor err', error, axios.defaults.headers.common);
      if (
        error.response.status === 401 &&
        error.config &&
        !error.config._retry &&
        refreshToken
      ) {
        originalConfig._retry = true;
        const refreshTokenRes = await refreshAccessToken(refreshToken).catch(
          (err) => null,
        );
        if (!refreshTokenRes) {
          navigate(LOGIN_PATH);
          return Promise.reject(error);
        } else {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + refreshTokenRes?.data?.access_token;
          // return axios.request(originalRequest);
          return axios(originalConfig);
        }
      }
      return Promise.reject(error);
    },
  );
};

const setDatadogUserProperties = (data: any) => {
  datadogRum.setUserProperty('name', data.name);
  datadogRum.setUserProperty('company', data.company);
  datadogLogs.setUserProperty('name', data.name);
  datadogLogs.setUserProperty('company', data.company);
};

const stopDatadogSession = () => {
  datadogRum.stopSession();
  datadogRum.clearGlobalContext();
  datadogRum.clearUser();
};

const AuthProvider = ({ children, initialTokenResponse }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  // our loader function in the root component sets the initial response to be either a full token or a null on failure to retrieve access token
  useEffect(() => {
    if (!initialTokenResponse) {
      if (location.pathname !== LOGIN_PATH) {
        navigate(LOGIN_PATH);
      }
    } else {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + initialTokenResponse?.data?.access_token;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [token, setToken] = React.useState<any | null>(
    initialTokenResponse?.data || null,
  );

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    localStorageAccessToken,
    setLocalStorageAccessToken,
    removeLocalStorageAccessToken,
  ] = useLocalStorage({
    key: 'devAccessToken',
    defaultValue: null,
  });
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    localStorageRefreshToken,
    setLocalStorageRefreshToken,
    removeLocalStorageRefreshToken,
  ] = useLocalStorage({
    key: 'devRefreshToken',
    defaultValue: null,
  });

  const clearAccessTokens = () => {
    delete axios.defaults.headers.common['Authorization'];
    if (isLocal) {
      removeLocalStorageAccessToken();
      removeLocalStorageRefreshToken();
    }
  };

  const loginCall: any = useMutation(
    (data: any) => loginApiCall(data.username, data.password),
    {
      onSuccess: (token) => {
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + token.data.access_token;
        setToken(token.data);
        rmtApiCall();
        setDatadogUserProperties(token.data);
      },
      onError: (err: any) => {
        const message =
          err?.response?.status === 400
            ? 'Invalid Credentials!'
            : 'There seems to be some issues with our services.';
        notifications.show({
          color: 'red',
          title: 'Login Failed',
          message,
        });
        setToken(null);
      },
    },
  );

  useEffect(() => {
    if (token) {
      if (isLocal) {
        setLocalStorageRefreshToken(token.refresh_token);
        setLocalStorageAccessToken(token.access_token);
      }
      setupRefreshTokenHandler(token.refresh_token, navigate);
    } else {
      clearAccessTokens();
      return;
    }
    if (location.state?.previous && location.state?.previous !== LOGIN_PATH) {
      navigate(location.state.previous);
    } else if (location.pathname === LOGIN_PATH) {
      navigate('/');
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = async (username: string, password: string) => {
    loginCall.mutate({ username, password });
  };

  const handleLogout = () => {
    removeRMT().finally(triggerLogout);
  };

  const triggerLogout = () => {
    logout().then(() => {
      clearAccessTokens();
      setToken(null);
      stopDatadogSession();
      navigate(LOGIN_PATH);
    });
  };

  const value = useMemo(
    () => ({
      token,
      setToken,
      onLogin: handleLogin,
      onLogout: handleLogout,
    }),
    [token], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <AuthContext.Provider value={value}>
      {loginCall.isLoading && (
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <LoadingOverlay
            visible={true}
            loaderProps={{
              size: 'sm',
              color: colors['luma-selective-yellow'],
              variant: 'bars',
            }}
            overlayProps={{ opacity: 0.1 }}
          />
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
